import React, { useEffect} from 'react';
import _, { range } from 'lodash';
import { ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import './dormitoryBackroom.scss';
import { getYear5 } from '../../../utils/date';
import { onlyNumberFormatter } from '../../../utils/common';


// 공제내역 팝업
const DeductDetail = observer((props: any) => {
  const {lodgedHistoryStore: store} = useStores();

  useEffect(() => {
    if (!store.isDeductionPop) return;  
    
    (async () => {
      await store.getDeductList();
    })();

  }, [store.isDeductionPop]);

  if (!store.isDeductionPop) return<></>;

  return (
    <div id="pop_history_deduction" className="pop_dim_wrap pop_modal pop_lg" style={{ display: store.isDeductionPop ? '' : 'none' }}>
      <div className="pop_wrap">
          <div className="title_wrap">
              <h3>공제내역</h3>
              <button className="pop_btn_close pop_close" onClick={(e) => store.isDeductionPop = false}>닫기</button>
          </div>
          <div className="contents_wrap">
              <div className="title_contents bd0">
                {store.guestData.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[store.guestData.room_work_site] === WORK_SITE_CODE.울산공장 ? 
                  <h3>총 공제내역 : <span className="color_green">{store.deductData ? (store.deductData.total_deduct === '' ? 0 : onlyNumberFormatter(Number(store.deductData.total_deduct))) : 0 }원</span></h3>
                  : <h3>총 공제내역 : <span className="color_green">{store.deductData ? (store.deductData.total_amount === '' ? 0 : onlyNumberFormatter(Number(store.deductData.total_amount))) : 0 }원</span></h3> 
                }
                  <div className="right_area form_inline">
                      <select name="" id="" className="form_control" style={{'width': 120+'px'}}value={store.year} 
                          onChange={(e:any) => {
                            store.setYear(e.target.value);
                            
                          }}>
                          {getYear5(true).map((year:any, index: number) => (
                              <option key={index} value={year}>{year}</option>
                          ))}
                      </select>
                      <select name="" id="" className="form_control" style={{'width': 100+'px'}} value={store.month} 
                          onChange={(e: any) => {
                            store.setMonth(e.target.value);
                          }}>
                          {range(0, 12, 1).map((item:any, index:number) => (
                            <option key={index} value={`${item + 1}`.padStart(2, '0')}>{`${item + 1}`.padStart(2, '0')}</option>
                          ))}
                      </select>
                  </div>
              </div>
              {store.guestData.room_type == ROOM_TYPE_CODE.dormitory_room ? // 기숙사
              <div className="table_normal_list table_write mt10">
                  <table>
                      <caption></caption>
                      <colgroup>
                          <col width="130"/>
                          <col width="*"/>
                          <col width="130"/>
                          <col width="*"/>
                      </colgroup>
                      <tbody>
                      <tr>
                          <th>기본료</th>
                          <td className="ar">{store.deductData ? (store.deductData.basic_fee === '' ?  0 : onlyNumberFormatter(Number(store.deductData.basic_fee))) : 0 }원</td>
                          <th>유틸리티비용</th>
                          <td className="ar">{store.deductData ? (store.deductData.util_fee === '' ?  0 : onlyNumberFormatter(Number(store.deductData.util_fee))) : 0 }원</td>
                      </tr>
                      <tr>
                          <th>침구대</th>
                          <td className="ar">{store.deductData ? (store.deductData.bdng_fee === '' ?  0 : onlyNumberFormatter(Number(store.deductData.bdng_fee))) : 0 }원</td>
                          <th>초과금액</th>
                          <td className="ar">{store.deductData ? (store.deductData.exces_amnt === '' ?  0 : onlyNumberFormatter(Number(store.deductData.exces_amnt))) : 0 }원</td>
                      </tr>
                      <tr>
                          <th>기타</th>
                          <td className="ar">{store.deductData ? (store.deductData.other_chrgs === '' ?  0 : onlyNumberFormatter(Number(store.deductData.other_chrgs))) : 0 }원</td>
                          <th></th>
                          <td className="ar"></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              : <></>}
              {store.guestData.room_type == ROOM_TYPE_CODE.company_room ? //사택
              <div className="table_normal_list table_write mt10">
                  <table>
                      <caption></caption>
                      <colgroup>
                          <col width="130"/>
                          <col width="*"/>
                          <col width="130"/>
                          <col width="*"/>
                      </colgroup>
                      <tbody>
                      <tr>
                          <th>기본료</th>
                          <td className="ar">{store.deductData ? (store.deductData.basic_fee === '' ? 0 : onlyNumberFormatter(Number(store.deductData.basic_fee))) : 0 }원</td>
                          <th>개별전기료</th>
                          <td className="ar">{store.deductData ? (store.deductData.indvd_elbll === '' ? 0 : onlyNumberFormatter(Number(store.deductData.indvd_elbll))) : 0 }원</td>
                      </tr>
                      <tr>
                          <th>공용전기료</th>
                          <td className="ar">{store.deductData ? (store.deductData.comon_elctr === '' ? 0 : onlyNumberFormatter(Number(store.deductData.comon_elctr))) : 0 }원</td>
                          <th>하/수도료</th>
                          <td className="ar">{store.deductData ? (store.deductData.swrg_fee === '' ? 0 : onlyNumberFormatter(Number(store.deductData.swrg_fee))) : 0 }원</td>
                      </tr>
                      <tr>
                          <th>기타</th>
                          <td className="ar">{store.deductData ? (store.deductData.other_chrgs === '' ? 0 : onlyNumberFormatter(Number(store.deductData.other_chrgs))): 0 }원</td>
                          <th></th>
                          <td className="ar"></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              : <></>}
              {store.guestData.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[store.guestData.room_work_site] === WORK_SITE_CODE.울산공장 ? //울산 출장자 숙소
              <div className="table_normal_list table_write mt10">
                  <table>
                      <caption></caption>
                      <colgroup>
                          <col width="130"/>
                          <col width="*"/>
                          <col width="130"/>
                          <col width="*"/>
                      </colgroup>
                      <tbody>
                      <tr>
                          <th>키반납</th>
                          <td className="ar">{store.deductData ? (store.deductData.room_charge === '' ? 0 : (store.deductData.room_charge === "Y" ? 0 : '15,000' )) : 0 }원</td>
                          <th>숙박비</th>
                          <td className="ar">{store.deductData ? (store.deductData.room_charge === '' ? 0 : onlyNumberFormatter(Number(store.deductData.room_charge))) : 0 }원</td>
                      </tr>
                      <tr>
                          <th>식권금액</th>
                          <td className="ar">{store.deductData ? (store.deductData.meal_charge === '' ? 0 : onlyNumberFormatter(Number(store.deductData.meal_charge))) : 0 }원</td>
                          <th>미반납액</th>
                          <td className="ar">{store.deductData ? (store.deductData.unreturn_charge === '' ? 0 : onlyNumberFormatter(Number(store.deductData.unreturn_charge))) : 0 }원</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          : <></>} 
          </div>
          <div className="button_wrap">
              <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={(e) => store.isDeductionPop = false}>확인</button>
          </div>
      </div>
  </div>
  );
});

export default DeductDetail;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLocusGuestType, { LocusGuestResType, LocusGuestDataType } from '../../hooks/useLocusGuestType';
import LocusTotalTemplete from '../locusTotal';
import GuestType from '../../components/atoms/select/guestType';
import RoomType from '../../components/atoms/select/roomType';
import Visable from '../../components/atoms/select/visable';
import Pagination from '../../components/organismas/pagination';
import { ROOM_TYPE, GUEST_TYPE } from '../../constants';
import { getViewPageIdx } from '../../utils/common';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';

// 이용자 범위설정
const LocusGuestType = observer((props: any) => {
  
  const [
    data, offset, limit, totalPage,
    roomType, guestType, isVisable,
    setRoomType, setGuestType, setIsVisable,
    setOffset, reload, setWorksiteType
  ]: [LocusGuestResType, number, number, number, any, number, number, Function, Function, Function, Function, Function, any] = useLocusGuestType();
  const {headerStore: headerStore} = useStores();
  const { userInfo = {}, worksites = [], worksiteMap = {} } = useSelector((state: any) => ({
    worksites: state.base.worksites,
    worksiteMap: state.base.worksiteMap,
    userInfo: state.user
  }))

  useEffect(() => {    
    headerStore.getAuthMenuRole('1-1');
    //setRoomType(headerStore.paramAuthRoomTypeRole);
    setWorksiteType(headerStore.workSite);
    if(roomType === undefined) {
      setRoomType(headerStore.paramAuthRoomTypeRole);
    }
  },[headerStore.workSite]);


  const onDetailPageHandler = (id: number) => props.history.replace(`/locus/guest/type/${id}/detail`);
  const onRoomWorkType = (roomData : any, workData : any) => {
    setRoomType(roomData);
    setWorksiteType(workData);
  }

  return (
    <LocusTotalTemplete
      className=""
      id="wrap"
      {...props}
      isLoading={headerStore.isLoading}
    >
      <div className="group_tab mt30">
        <RoomType
            className="tab_lg tabs clearfix"
            onChange={(roomType: number) => onRoomWorkType(roomType, headerStore.workSite)}
            value={roomType}
        />
      </div>

      <h4>
        이용자범위 설정
        <div className="right_area">
          <GuestType className="form_control" value={guestType} onChange={(guestType: number) => setGuestType(guestType)} />
          <Visable className="form_control" value={isVisable} onChange={(visable: number) => setIsVisable(visable)} />
        </div>
      </h4>

      {/* table start */}
      <div className="tab_contents on">
        <div className="table_normal_list event_on box_scroll_x" style={{ overflowX: 'auto' }}>
          <table>
            <caption className="sr_only">이용자범위 설정 목록</caption>
            <colgroup>
              <col width="5%"></col>

              <col width="23.525%"></col>
              <col width="23.525%"></col>
              <col width="23.525%"></col>
              <col width="23.525%"></col>
            </colgroup>

            <thead>
              <tr>
                <th>No.</th>
                <th>사업장</th>
                <th>숙소유형</th>
                <th>이용자타입</th>
                <th>표시여부</th>
              </tr>
            </thead>
            <tbody>
              {data.results.length === 0 ? (
                <tr>
                  <td colSpan={5} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              ):(
               data.results.map((item: LocusGuestDataType, idx: number) => (
                <tr key={idx}>
                  <td>
                    {getViewPageIdx(offset, limit, idx)}
                  </td>
                  <td style={{cursor: 'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{worksiteMap[item.work_site]?.name}</td>
                  <td style={{cursor: 'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{ROOM_TYPE[item.room_type]}</td>
                  <td style={{cursor: 'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{GUEST_TYPE[item.guest_type]}</td>
                  <td style={{cursor: 'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{item.is_visible ? "표시" : "미표시"}</td>
                </tr>
              )))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="board_paging">
        <ul>
          <Pagination
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            totalPage={totalPage}
          />
        </ul>
      </div>

    <div className="submit">
      <div className="fl">
        {headerStore.writeRoleFlag?
          <button type="button" className="btn btn_lg btn_darkblue" onClick={() => {props.history.push('/locus/guest/type/write')}} style={{ cursor: 'pointer' }}>
            등록
          </button>
          :
          <></>
        }
      </div>
    </div>
    </LocusTotalTemplete>
  );
});

export default LocusGuestType;

import { takeEvery, put, call } from 'redux-saga/effects';
import { isMobile } from 'react-device-detect';
import intl from 'react-intl-universal';

import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SSO,
  LOGIN_COMPLETE,
  SNS_LOGIN_REQUEST,
  SNS_INTEGRATE_REQUEST,
  SIGNUP_REQUEST,
  LOGOUT_REQUEST,
  AUTH_CLEAR,
} from '../modules/auth';

import { USERINFO_COMPLETE, USERINFO_CLEAR, USERINFO_REQUEST } from '../modules/user';

import { SHOW_ALERT_MODAL } from '../modules/modal';

import { auth, user } from '../../apis';

import { ERROR_CODE } from '../../constants/error';
import { useStores } from '../mobx';

function* login({ payload, history }: any) {
  // console.log('[SAGA] API CALL: login');
  //const { headerStore : store } = useStores(); 
 
  try {
    const {data} = yield call(auth.login, payload);
    yield put({
      type: LOGIN_COMPLETE,
      payload: {
        accessToken: data.token,
        isLogin: true,
        userInfo: data.user
      },
    });
    yield put({
      type: USERINFO_REQUEST,
      payload: {
        
      },
    });

    // store.userInfo = data;
    // console.log('store.userInfo -----------------> ', store.userInfo);
    // console.log('login.................');
    //if (!process.env.REACT_APP_MODE || process.env.REACT_APP_MODE === 'dev') {
      if(isMobile){
        history.push('/mobile/main');  
      }else {
        history.push('/home');
      }
    //}
  } catch (err) {
    // console.log('[SAGA] API error');
    
    if (err.response.status === 403) { // 권한
      // console.log(err.response.data.detail)
      yield put({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: 'error',
          msg: err.response.data.detail,
          redirectPath: false,
          statusCode: err.response.status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });  
    } else if (err.response.status === 401) { // 로그인 실패
      // console.log(err.response.data.detail)
      yield put({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: 'error',
          msg: err.response.data.detail,
          redirectPath: false,
          statusCode: err.response.status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
    } else if (err.response.status === 400) {
      // console.log(err.response?.data?.code)
      if (err.response?.data?.code) {
        // console.log(ERROR_CODE[err.response?.data?.code])
      }
    }

    return;
    // history.push('/')
  }
}

// sso 로그인
function* login_sso({ ssoPayload, history }: any) {
  // console.log('[SAGA] API CALL: login');
  //const { headerStore : store } = useStores(); 
 
  try {
    const {data} = yield call(auth.ssoSignin, ssoPayload);
    yield put({
      type: LOGIN_COMPLETE,
      payload: {
        accessToken: data.token,
        isLogin: true,
        userInfo: data.user
      },
    });
    yield put({
      type: USERINFO_REQUEST,
      payload: {
        
      },
    });

    // if(isMobile){
    //   history.push('/mobile/main');  
    // }else {
      history.push('/home');
    // }

  } catch (err) {
    // console.log('[SAGA] API error');
    history.push('/single/signin/fail');
    
    if (err.response.status === 403) { // 권한
      // console.log(err.response.data.detail)
      yield put({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: 'error',
          msg: err.response.data.detail,
          redirectPath: false,
          statusCode: err.response.status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });  
    } else if (err.response.status === 401) { // 로그인 실패
      // console.log(err.response.data.detail)
      yield put({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: 'error',
          msg: err.response.data.detail,
          redirectPath: false,
          statusCode: err.response.status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
    } else if (err.response.status === 400) {
      // console.log(err.response?.data?.code)
      if (err.response?.data?.code) {
        // console.log(ERROR_CODE[err.response?.data?.code])
      }
    }else if (err.response.status === 500) {
      // console.log(err.response?.data?.code)
      if (err.response?.data?.code) {
        // console.log(ERROR_CODE[err.response?.data?.code])
      }
      history.push('/single/signin/fail');
    }

    // history.push('/')
    return;
  }
}

// function* signup({ payload }: any) {
//   console.log('[SAGA] API CALL: signup');
//   try {
//     const res = yield call(auth.signup, payload);
//     console.log('[SAGA] API response');
//     console.log(res);
//     yield put({
//       type: SHOW_ALERT_MODAL,
//       payload: {
//         title: '회원가입 성공',
//         msg: '회원가입을 성공했습니다. 로그인해주세요',
//         redirectPath: '/',
//         statusCode: res.status,
//         errorCode: '',
//         type: 'success',
//         isModalShow: true,
//       },
//     });
//   } catch (err) {
//     console.log('[SAGA] API error');
//     console.log(err)
    
//     const errorMap: any = {};
//     const s = Object.keys(err.data).map(key => {
//       errorMap[`${key}_${err.statusCode}`] = true;
//       return err.data[key].join(' ');
//     });
//     const a = `${err.status}_http`;
//     const errorTitle = intl.get(a);

//     yield put({
//       type: SHOW_ALERT_MODAL,
//       payload: {
//         title: errorTitle,
//         msg: s.join(''),
//         redirectPath: false,
//         statusCode: err.status,
//         errorCode: '',
//         type: 'error',
//         isModalShow: true,
//       },
//     });
//   }
// }

// function* snsLogin({ payload, history }: any) {
//   console.log('[SAGA] API CALL: snsLogin');
//   try {
//     const res = yield call(auth.snsLogin, payload);
//     console.log('[SAGA] API response');
//     console.log(res);
//     yield put({
//       type: LOGIN_COMPLETE,
//       payload: {
//         accessToken: res.data.access,
//         refreshToken: res.data.refresh,
//       },
//     });
//     history.push('/');
//   } catch (err) {
//     console.log('[SAGA] API error');
//     console.log(err);
//   }
// }

// function* snsIntegrate({ payload, history }: any) {
//   console.log('[SAGA] API CALL: snsLogin');
//   try {
//     const { data } = yield call(auth.snsIntegrate, payload);
//     console.log('[SAGA] API response');
//     console.log(data);
//     yield put({ type: USERINFO_COMPLETE, payload: data });
//     yield put({
//       type: SHOW_ALERT_MODAL,
//       payload: {
//         title: 'success',
//         msg: '연동성공',
//         redirectPath: false,
//         statusCode: false,
//         errorCode: 0,
//         type: 'success',
//         isModalShow: true,
//       },
//     });
//   } catch (err) {
//     console.log('[SAGA] API error');
//     console.log(err);
//   }
// }

function* logout({ payload, history, msg }: any) {
  // console.log('[SAGA] API CALL: logout');
  try {
    const res = yield call(auth.logout, {});
    yield put({ type: AUTH_CLEAR });
    yield put({ type: USERINFO_CLEAR });
    if (msg) {
      // console.log(msg);
    }
    
    history.push('/');
  } catch (err) {
    // console.log('[SAGA] API error');
    // console.log(err);
    return;
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_REQUEST, login);
  yield takeEvery(LOGIN_REQUEST_SSO, login_sso);
  // yield takeEvery(SIGNUP_REQUEST, signup);
  // yield takeEvery(SNS_LOGIN_REQUEST, snsLogin);
  // yield takeEvery(SNS_INTEGRATE_REQUEST, snsIntegrate);
  yield takeEvery(LOGOUT_REQUEST, logout);
}

export default authSaga;

import React from 'react';

//휴대폰번호 input
const Phone = (props: any) => {
  const numberRegex = new RegExp(/[^0-9]/, 'g');
  let changedProps = {
    ...props,
    onChange: (e: any) => {
      if (!e.target.value.match(numberRegex) && e.target.value.length <= 11) {
        props.onChange(e);
      }
    }
  }
  return (
    <input {...changedProps}  />
  );
};

export default Phone;

import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Deduct {
  static URL = `${IP}`;

  /**
   * 
   * @param payload
   */

  //검침량 조회
  @apiHandler()
  static async getMeters(payload: any) {
    const PATH = `/deduct/meterlist/`;

    return await axios.get(`${Deduct.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  //검침량 정보 생성
  @apiHandler()
  static async createMeter(payload: any) {
    const PATH = `/deduct/meterlist/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //검침량 삭제
  @apiHandler()
  static async deleteMeter(id: string) {
    const PATH = `/deduct/meterlist/${id}/`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  //당월공제자관리 조회
  @apiHandler()
  static async gets(fnName: string, payload: any) {
    const PATH = `/deduct/${fnName}/`;

    return await axios.get(`${Deduct.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //당월공제자관리 저장
  @apiHandler()
  static async post(fnName: string, payload: any) {
    const PATH = `/deduct/${fnName}/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async patch(fnName: string, payload: any) {
    const PATH = `/deduct/${fnName}/`;

    return await axios.patch(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async put(fnName: string, id: number, payload: any) {
    const PATH = `/deduct/${fnName}/${id}/`;

    return await axios.put(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async delete(fnName: string, id: number) {
    const PATH = `/deduct/${fnName}/${id}/`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //당월 공제자 관리 남양 기숙사 저장
  @apiHandler()
  static async createNamyangDormi(payload: any) {
    const PATH = `/deduct/namyangdormi/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //당월 공제자 관리 남양 기숙사 삭제
  @apiHandler()
  static async deleteNamyangDormi(id: string) {
    const PATH = `/deduct/namyangdormi//?id=${id}`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자 관리 남양 사택 저장
  @apiHandler()
  static async createNamyangCompany(payload: any) {
    const PATH = `/deduct/namyangcompany/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 마감확정
  @apiHandler()
  static async worksiteFinal(id: number, payload: any) {
    const PATH = `/deduct/worksitefinal/${id}/`;

    return await axios.put(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 남양 사택 삭제
  @apiHandler()
  static async deleteNamyangCompany(id: string) {
    const PATH = `/deduct/namyangcompany/?id=${id}`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 전주 기숙사 저장
  @apiHandler()
  static async createJeonjuDormi(payload: any) {
    const PATH = `/deduct/jeonjudormi/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 전주 기숙사 삭제
  @apiHandler()
  static async deleteJeonjuDormi(id: string) {
    const PATH = `/deduct/jeonjudormi/?id=${id}`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 전주 사택 저장
  @apiHandler()
  static async createJeonjuCompany(payload: any) {
    const PATH = `/deduct/jeonjucompany/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 전주 사택 삭제
  @apiHandler()
  static async deleteJeonjuCompany(id: string) {
    const PATH = `/deduct/jeonjucompany/?id=${id}`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 아산 기숙사 저장
  @apiHandler()
  static async createAsanDormi(payload: any) {
    const PATH = `/deduct/asandormi/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
 
  //공제자관리 아산 기숙사 삭제
  @apiHandler()
  static async deleteAsanDormi(id: string) {
    const PATH = `/deduct/asandormi/?id=${id}`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 아산 사택 저장
  @apiHandler()
  static async createAsanCompany(payload: any) {
    const PATH = `/deduct/asancompany/`;

    return await axios.post(`${Deduct.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 아산 사택 삭제
  @apiHandler()
  static async deleteAsanCompany(id: string) {
    const PATH = `/deduct/asancompany/?id=${id}`;

    return await axios.delete(`${Deduct.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제자관리 마감확정
  @apiHandler()
  static async workSiteFinal(id: string) {
    const PATH = `/deduct/worksitefinal/${id}/`;

    return await axios.patch(`${Deduct.URL}${PATH}`, {
      is_finalize: 'Y',
    }, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /* 울산 - 공제내역 관리 리스트 조회 */
  @apiHandler()
  static async getDeductList(payload: any) {
    const PATH = `/deduct/ulsandeductablelist/`;
    return await axios.get(`${Deduct.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Deduct;

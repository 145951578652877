import { NumberSequence } from '@ag-grid-community/core';
import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

interface loginPayload {
  employee_number: string;
  password: string;
  cellphone: string;
}

interface snsLoginPayload {
  username: string;
  sns: {
    key: string;
    platform: string;
  };
}

export interface SignupPayload {
  password: string;
  password2: string;
  employee_number: string;
  gender: Number;
  duty: string;
  birthdate: string;
  email: string;
}

export interface UpdateUserPayload {
  new_password: string;
  new_password2: string;
  assigned_task: string;
  cellphone: string;
  email : string;
}

class Auth {
  static URL = `${IP}`;

  static async login(payload: loginPayload) {
    const PATH = '/admin/auth/signin/';
    return await axios.post(`${Auth.URL}${PATH}`, {}, {
      auth: {
        username: payload.employee_number,
        password: payload.password
      },
    });
  }

  @apiHandler()
  static async signinAuth(payload: {}) {
    //console.log(payload)
    const PATH = '/admin/auth/profile/auth/';

    return await axios.post(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async signinVerify(payload: {number: string, token: string}) {
    const PATH = '/admin/auth/profile/verify/';

    return await axios.post(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 로그인 - 인증번호 발송
  @apiHandler()
  static async signinManagerAuth(payload: any) {
    const PATH = '/admin/auth/managerauth/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  // 로그인 - 인증번호 확인
  @apiHandler()
  static async signinSmsAuth(payload: any) {
    const PATH = '/admin/auth/smsauth/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  // sso 로그인
  @apiHandler()
  static async ssoSignin(payload: any) {
    const PATH = '/admin/auth/sso_signin/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  @apiHandler()
  static async snsLogin(payload: snsLoginPayload) {
    const PATH = '/auth/signin/sns/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  @apiHandler()
  static async snsIntegrate(payload: snsLoginPayload) {
    const PATH = '/auth/profile/';
    return await axios.put(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // @apiHandler()
  // static async signup(payload: any) {
  //   const PATH = '/admin/auth/signup/';
  //   return await axios.post(`${Auth.URL}${PATH}`, payload);
  // }

  //프로필가져오기
  @apiHandler()
  static async getProfile(payload: any) {
    const PATH = '/admin/auth/profile/';
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //개인정보 수정
  @apiHandler()
  static async getProfileByEmployeeNumber({employeeNumber}: {employeeNumber: Number}) {
    const PATH = `/admin/auth/profile/${employeeNumber}/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getProfileByMemberEmployeeNumber({employeeNumber}: {employeeNumber: Number}) {
    const PATH = `/admin/auth/profile1/${employeeNumber}/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async checkUser(payload: {employee_number: string}) {
    const PATH = `/admin/auth/signup/check/user/`;
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }
  @apiHandler()
  static async signupAuth(payload: {cellphone: string}) {
    const PATH = `/admin/auth/signup/auth/`;
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  //인증번호확인
  @apiHandler()
  static async signupVerify(payload: {cellphone: string, number: string, token: string}) { 
    const PATH = `/admin/auth/signup/verify/`;
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  @apiHandler()
  static async signup(id : number, payload: SignupPayload) { 
    //const PATH = `/admin/auth/signup/`;
    const PATH = `/admin/auth/managerpassword/${id}/`;
    return await axios.put(`${Auth.URL}${PATH}`, payload);
  }


  //비밀번호 확인
  @apiHandler()
  static async passwordCheck(payload: {password: string}) { 
    const PATH = `/admin/auth/profile/check/password/`;
    return await axios.post(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async update(employeeNumber: number, payload: UpdateUserPayload) { 
    const PATH = `/admin/auth/profile/${employeeNumber}/`;
    return await axios.patch(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

    

  @apiHandler()
  static async logout(payload: any) {
    const PATH = '/admin/auth/signout/';
    return await axios.post(`${Auth.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 권한 상세
  @apiHandler()
  static async getManagerAuth(employeeNumber: string) {
    const PATH = `/admin/auth/managerauth/${employeeNumber}/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 권한 수정
  @apiHandler()
  static async putManagerAuth(employeeNumber: string, payload: any) {
    const PATH = `/admin/auth/managerauth/${employeeNumber}/`;
    return await axios.put(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 관리자 상세 조회
  @apiHandler()
  static async getAdminManager(employeeNumber: string) {
    const PATH = `/admin/auth/managercheck/${employeeNumber}/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 관리자 정보 , 권한 수정
  @apiHandler()
  static async putAdminManager(id: number, payload: any) {
    const PATH = `/admin/auth/managerupdate/${id}/`;
    return await axios.put(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 관리자 정보 , 권한 등록
  @apiHandler()
  static async postAdminManager(payload: any) {
    const PATH = `/admin/auth/manager/`;
    return await axios.post(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 관리자 정보 , 권한 삭제
  @apiHandler()
  static async deleteAdminManager(id: number) {
    const PATH = `/admin/auth/managerupdate/${id}/`;
    return await axios.delete(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 관리자 가입 시 정보조회
  @apiHandler()
  static async getTempAdminInfo(employeeNumber: string) {
    const PATH = `/admin/auth/managerrsearch/${employeeNumber}/`;
    return await axios.get(`${Auth.URL}${PATH}`);
  }

  // 메인 입숙, 이숙, 퇴숙 현황
  @apiHandler()
  static async getMainCheckinStat(payload: any) {
    const PATH = `/admin/lodge/checkinstat/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 아이디 찾기 인증하기 버튼 클릭
  @apiHandler()
  static async findIdAuth(payload: {cellphone: string, name: string}) {
    const PATH = '/admin/auth/find/id/auth/';
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 인증번호 확인후 아이디 받기
  @apiHandler()
  static async findIdVerify(payload: { token: string, name: string, cellphone: string, number: string }) {
    const PATH = '/admin/auth/find/id/verify/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 비밀번호 찾기 - 비밀번호 찾기 인증하기 버튼 클릭
  @apiHandler()
  static async findPasswordAuth(payload: {employee_number: string, cellphone: string}) {
    const PATH = '/admin/auth/find/password/auth/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 비밀번호 찾기 - 인증번호 확인후 아이디 받기
  @apiHandler()
  static async findPasswordVerify(payload: { token: string, employee_number: string, cellphone: string, number: string }) {
    const PATH = '/admin/auth/find/password/verify/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 비밀번호 찾기 - 비밀번호 변경
  @apiHandler()
  static async findPasswordChange(payload: { token: string, employee_number: string, cellphone: string, password: string, password2: string }) {
    const PATH = '/admin/auth/find/password/change/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 메인 입숙,퇴숙 통계 (시계열)
  @apiHandler()
  static async getMainCheckinOutStat() {
    const PATH = `/admin/lodge/timeseries/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Auth;

import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Editor } from '@tinymce/tinymce-react';

import { notice, core } from '../../../../apis';
import { NOTICE_TYPE, VOC_CATEGORY } from '../../../../constants';
import { formatByString } from '../../../../utils/date';
import ConfirmBtn from '../../../atoms/button/confirm';

import FileUpload from '../../../molecules/fileUpload';
import ImageUpload from '../../../molecules/fileUpload';
import Select from '../../../atoms/select';
import _ from 'lodash';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../../routes';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import { parserEscape, showAlert } from '../../../../utils/common';
import DatePicker from '../../../organismas/input/datepicker';
import { useStores } from '../../../../store/mobx';
import { observer } from 'mobx-react';
import CustomCalendar from '../../../organismas/customCalendar';

const WriteContent = observer((props: any) => {
  const dispatch = useDispatch();
  const {vocStore: store} = useStores();
  const {headerStore: headerStore} = useStores();

  const item = store.item;
  const id = item && item.id? item.id : -1;
  const isCreate = props.isEdit && !props.match.params.id;
  const baseUrl = '/lodged/maintenance';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const goBack = () => props.history.replace(isCreate ? baseUrl : `${baseUrl}/${id}`);

  const [hours, setHours] = useState<any[]>([{ value: '', label: '시간선택', optionIdx: 0 }]);

  const [itemContents, setItemContents] = useState<string>(item && item.contents? item.contents : '');
  const editorRef = useRef<any>(null);
  const editorInputRef = useRef<any>(null);

  useEffect(() => {
    const temps = _.range(0, 24, 1).map(num => ({ value: (num+'').padStart(2, '0'), label: (num+'').padStart(2, '0') }));
    setHours([{ value: '', label: '시간선택' }, ...temps]);

    if(store.item){
      if (store.item.category1) {
        const c1: any = VOC_CATEGORY.find((c: any) => c.name === store.item.category1);
        store.category2 = [
          { value: '', name: '2차 분류항목을 선택하세요', child: [] },
          ...c1.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
        ];
      }
      if (store.item.category2) {
        const c2: any = store.category2.find((c: any) => c.name === store.item.category2);
        store.category3 = [
          { value: '', name: '3차 분류항목을 선택하세요', child: [] },
          ...c2.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
        ];
      }
      if (store.item.category3) {
        const c3: any = store.category3.find((c: any) => c.name === store.item.category3);
        store.getCategories4();
        // store.category4 = [
          // { value: '', name: '4차 분류항목을 선택하세요', child: [] },
          // ...c3.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
        // ];
      }
    }
  }, []);

  const onSaveHandler = async () => {
    item.contents = itemContents;

    if(!item.category1){
      info('1차 분류항목을 선택해주세요');
      return;
    }
    if (!store.isChange) {
      info('변경된 항목이 없습니다.');
      return;
    }

    if (!item.title) {
      info('제목을 입력해주세요.');
      return;
    }

    if (!item.contents) {
      info('내용을 작성해주세요');
      return;
    }

    if(!item.found_at_date){
      info('발생 일자를 입력해주세요.')
      return;
    }

    if(!item.found_at_time){
      info('발생 일시를 입력해주세요.')
      return;
    }

    try {
      headerStore.isLoading = true;
      let msg = '등록 완료했습니다.';
      if (id > -1) {
        msg = '수정 완료했습니다.';
      }

      store.item.work_site = headerStore.workSite;
      let data : any = await store.saveItem();
      headerStore.isLoading = false;
      setTimeout(function () {
        if(data.save) {
          info(msg);
          back();
        }else {
          // info("문제가 발생했습니다");
          return;
        }
      }, 250);
      // goBack();
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      // errorHandler({ status, data }, '저장 중 오류발생');
    }
  };

  const back = () => {
    props.history.replace(`${baseUrl}`);
  }

  const onCancelhandler = () => {
    item.contents = itemContents;

    if (isCreate) {
      goBack();
    } else {
      if(store.isChange) {
        dispatch({
          type: SHOW_CONFIRM_MODAL,
          payload: {
            isModalShow: true,
            title: '확인',
            msg: '수정한 내용이 삭제됩니다.\n 목록으로 이동하시겠습니까?',
            action: goBack,
          },
        });
      } else {
        goBack();
      }
    }
  };

  const onBackHandler = () => {
    item.contents = itemContents;

    if (!store.isChange) {
      goBack();
    } else {
      dispatch({
        type: SHOW_CONFIRM_MODAL,
        payload: {
          isModalShow: true,
          title: '확인',
          msg: '수정한 내용이 삭제됩니다.\n목록으로 이동하시겠습니까?',
          action: goBack,
        }
      });
    }
  };

  if (!item) return <></>;

  return (
    <>
      <h4>유지보수내역 { isCreate ? '등록' : '수정' }</h4>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">VoC 정보</caption>
          <colgroup>
            <col width="200"/>
            <col width="*"/>
            <col width="200"/>
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr>
              <th>유형</th>
              <td colSpan={3}>
                <div className="form_inline">
                  <select name="" id="" className="form_control"
                    style={{width:234 + 'px'}}
                    value={item.category1}
                    onChange={(e) => {
                      const category: any = VOC_CATEGORY.find(c => c.name === e.target.value);
                      // console.log('category', category);
                      item.category1 = category ? category.name : '' ;
                      item.category2 = '';
                      item.category3 = '';
                      item.category4 = '';
                      if (item.category1 && category.child.length > 0) {
                        store.category2 = [
                          { value: '', name: '2차 분류항목을 선택하세요', child: [] },
                          ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                        ];
                      } else {
                        store.category2 = [];
                      }
                      store.category3 = [];
                      store.category4 = [];
                    }}
                  >
                    <option value="">1차 분류항목을 선택하세요</option>
                    {VOC_CATEGORY.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                  </select>
                  {store.category2.length > 0 ? (
                  <select name="" id="" className="form_control"
                    style={{width:234 + 'px'}}
                    value={item.category2}
                    onChange={(e) => {
                      const category: any = store.category2.find(c => c.name === e.target.value);
                      item.category2 = category ? category.name : '';
                      item.category3 = '';
                      item.category4 = '';
                      if (item.category2 && category.child.length > 0) {
                        store.category3 = [
                          { value: '', name: '3차 분류항목을 선택하세요', child: [] },
                          ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child  }))
                        ];
                      } else {
                        store.category3 = [];
                      }
                      store.category4 = [];
                    }}
                  >
                    {store.category2.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                  </select>
                  ) : <></>}
                  {store.category3.length > 0 ? (
                    <select name="" id="" className="form_control" 
                      style={{width:234 + 'px'}}
                      value={item.category3}
                      onChange={(e) => {
                        const category: any = store.category3.find((c: any) => c.name === e.target.value);
                        item.category3 = category ? category.name : '';
                        item.category4 = '';
                        store.getCategories4()
                        if (item.category3 && store.category4) {              
                        } else {
                          store.category4 = [];
                        }
                      }}
                    >
                      {store.category3.map((category: any, index: number) => (
                        <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  ) : <></>}
                  {store.category4.length > 0 ? (
                    <select name="" id="" className="form_control" 
                      style={{width:234 + 'px'}}
                      value={item.category4}
                      onChange={(e) => item.category4 = e.target.value}
                    >
                    {store.category4.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                    </select>
                  ) : <></>}
                </div>
              </td>
            </tr>

            <tr>
              <th>발생장소</th>
              <td>
                <div className="wrap_file">
                  <input id="" className="upload_name form_control" placeholder="" disabled={true} value={
                    item.places_etc ? 
                      ('기타 > '+ item.places_etc + ' > ' + item.places_etc2) :
                      item.places.map((place: any) => place.building_name === '기타'
                            ? `${place.building_name} > ${place.room_name}-${place.floor_name}`
                            : `${place.room_type_name} > ${place.building_name} > ${place.building_dong ? place.building_dong + '>' : ''} ${place.room_name}`
                      ).join(', ')
                  }/>
                  <button type="button" className="btn btn_sm btn_gray" onClick={() => props.setIsPlaceOpen(true)}>선택</button>
                </div>
              </td>
              <th>발생일시</th>
              <td>
                <div className="form_inline">
                  <div className="datepicker_searchwrap" style={{marginRight: '10px'}}>
                    {/* <DatePicker
                      value={item.found_at_date}
                      width={250}
                      onChange={(date: string) => item.found_at_date = date}
                      isPrevMonthView={true}
                    /> */}
                    <CustomCalendar
                      value={item.found_at_date}
                      onChange={(date: string) => item.found_at_date = date}
                    ></CustomCalendar> 
                  </div>
                  <div>
                    <Select
                      className="form_control"
                      options={hours}
                      value={item.found_at_time}
                      onChange={(e: any) => item.found_at_time = e.target.value}
                    /> 시경
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <th>작성자</th>
              <td colSpan={3}>{item.author_name} ({item.author_employee_number})</td>
              {/* <th>작성일시</th>
              <td>{formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}</td> */}
            </tr>

            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form_control"
                  value={item.title}
                  onChange={(e: any) => item.title = e.target.value}
                />
              </td>
            </tr>

            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <Editor
                  apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={parserEscape(item.contents)}
                  onEditorChange={(e) => {
                    setItemContents(e);
                  }}
                  init={{
                    width: 1100,
                    height: 500,
                    resize: 'both',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'image code'
                      //'image media link tinydrive code imagetools'
                    ],
                    toolbar:
                      'undo redo | fontselect fontsizeselect | ' +
                      'bold italic | forecolor backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_file_types : 'gif,jpg,jpeg,png',
                    file_picker_callback : function(callback, value, meta) {
                      if(meta.filetype == 'image') {
                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        editorInputRef.current = input;
                        (editorInputRef.current as any).click();
                        
                        input.onchange = function () {
                          let file : any = input.files;
                          file = file[0];

                          if (file.size >= 10485760) {        
                            alert(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
                            return;
                          }else {
                            let reader = new FileReader();

                            reader.onload = async function (e : any) {
                              let editorImageForm = new FormData();
                              editorImageForm.append('path', file);
                              let reusltData : any = null;
                              try {
                                headerStore.isLoading = true;
                                reusltData = await core.fileUpload<{
                                  id?: number;
                                  path?: string;
                                  name?: string;
                                  category?: string | number;
                                }>(editorImageForm);

                                
                                callback(`${process.env.REACT_APP_MEDIA}/`+reusltData.data.path, {alt : file.name});
                                headerStore.isLoading = false;
                              } catch (error) {
                                headerStore.isLoading = false;
                                // console.log(error);

                                const { status, data } = error;

                                if (status === 401) {
                                  if (!window.localStorage.getItem("isRetryLogin")) {
                                    window.localStorage.setItem("isRetryLogin", "retry");
                                  }
                                  window.location.href = '/';
                                  return ;
                                } else if (status === 403) {
                                  alert('접근권한이 없습니다.');
                                  return ;
                                }else if ( status === 500 ) {
                                  window.location.href = '/500';
                                  return;
                                }
                          
                              }

                            };
                            
                            reader.readAsDataURL(file);

                          }
                          editorInputRef.current = null;
                          input.remove();
                        };
                      }
                    }
                  }}
                />
              </td>
            </tr>

            <tr>
              <th>이미지</th>
              <td colSpan={3}>
                <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={item.images}
                  onSetData={(data: any[]) => item.images = data}
                />
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan={3}>
                <FileUpload
                  type="file"
                  fileId="attacheFile"
                  items={item.files}
                  onSetData={(data: any[]) => item.files = data}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*table*/}
      <div className="submit ac">
        <div className="fl">
          <button type="button" className="btn_lg btn_darkgray" onClick={() => props.history.replace(baseUrl)}>목록</button>
        </div>
        <div className="fr">
          <button type="button" className="btn_lg btn_darkblue btn_outline" onClick={()=> {onSaveHandler()}}>완료</button>
        </div>
      </div>
    </>
  );
});

export default WriteContent;

import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { LOGOUT_REQUEST } from '../../../store/modules/auth';
// import { USERINFO_REQUEST } from '../../../store/modules/user';
import { INIT_REQUEST } from '../../../store/modules/base';

import { ROOM_TYPE, WORK_SITE } from '../../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { isEmpty, split } from 'lodash';
import ReactDOM from 'react-dom';
import { formatByString_YYYYMMDD } from '../../../utils/date';
import { MessageContext } from '../../../routes';

interface Props {
    isNotLoginRequire: boolean
    history: any
}

//공통헤더
const Header = observer((props: Props) => {
    const { info } = useContext(MessageContext);
    const {headerStore: headerStore} = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }))
    const dispatch = useDispatch();
    const authWorkSite = window.localStorage.getItem('auth_worksite')?.replace(/\"/gi,"");
    const firstUserInfo = window.localStorage.getItem('work_site');
    const [reload, setReload] = useState<boolean>(false);
    
    useEffect(() => {
    if (props.isNotLoginRequire) { return }
        if (!window.localStorage.getItem('accessToken') ) {
            props.history.push('/')
            return
        }
        //back 막기
        window.history.pushState(null, document.title, props.history.location.href); 
        window.addEventListener('popstate', function(event) { 
            window.history.pushState(null, document.title, props.history.location.href); 
            info('뒤로가기를 할 수 없습니다.');
        });
        
        headerStore.getAuthWorkSiteRole();
        
        let options: any[] = [];
        for ( let i = 0; i < headerStore.authWorkSiteLen[0].length; i++) {
            options.push(<option key={i} value={headerStore.authWorkSiteLen[0][i]}>{WORK_SITE[headerStore.authWorkSiteLen[0][i]]}</option>)
        }
        //최초 설정   
        headerStore.setWorkSite(headerStore.selectWorkSite? headerStore.selectWorkSite : headerStore.authWorkSiteLen[0][0]);
        headerStore.firstWorkSite(headerStore.selectWorkSite? headerStore.selectWorkSite : headerStore.authWorkSiteLen[0][0]);
        ReactDOM.render(options, document.getElementById("worksite"));
        
        let payload = {
            accessToken: window.localStorage.getItem('accessToken'),
            userInfo : window.localStorage.getItem('userInfo'),
        }
        dispatch({
            type: INIT_REQUEST,
            payload: payload,
            history: props.history,
        });

    }, [reload]);

    const authData =() => {
        setReload(true);
    }

    return (
        <div id="header">
            <div className="group_member">
            <h1>
                {props.isNotLoginRequire? (
                    <a href="/" className="sr_only">WESN AMS Platform</a>
                ) : (
                    <a className="sr_only">WESN AMS Platform</a>
                )}
            </h1>
                {/* /applicant/judge */}
                <ul className="nav_member clearfix">
                    {!props.isNotLoginRequire && (
                        <>
                            <li className="place">
                                <select name="" value={headerStore.selectWorkSite} id="worksite" className="form_control" onChange={(e:any) => {headerStore.setWorkSite(e.target.value); headerStore.setSelectWorkSite(e.target.value);}}>
                                </select>
                            </li>
                            <li className="user">
                                <a style={{'cursor':'pointer'}}>
                                    <span className="name">{userInfo.username}</span> 님은
                                    <span className="room">{WORK_SITE[userInfo.work_site]}</span>에 근무 중 입니다
                                    <p className="fixed_text">
                                        {userInfo.loginHistory && userInfo.loginHistory.created_at? '최근 접속 일자: '+formatByString_YYYYMMDD(userInfo.loginHistory.created_at) : ''} 
                                        <span className="ml20">{userInfo.loginHistory && userInfo.loginHistory.ip_address? 'IP: '+userInfo.loginHistory.ip_address : ''}</span>
                                    </p> 
                                </a>
                            </li>
                            <li className="logout" onClick={(e: any) => { 
                                headerStore.init();
                                dispatch({ type: LOGOUT_REQUEST, history: props.history })
                            }}><a style={{'cursor':'pointer'}} >Logout</a></li>
                        </>
                    )}
                    <li className="info"><a style={{'cursor':'pointer'}}></a></li>
                </ul>
            </div>
        </div>
    )
})

export default Header;

import { FakeHorizontalScrollController } from '@ag-grid-community/core/dist/cjs/gridBodyComp/fakeHorizontalScrollController';
import {action, computed, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { EXHIBIT_STATUS, NOTICE_TYPE, SEARCH_TYPE } from '../../constants';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { BoardStore } from './boardStore';
import { HeaderStore } from './headerStore';

export class BuildingStore extends BoardStore {
  tabIndex = 0;
  workSite : any;
  changeFloors: any = {};
  changeFloorRooms: any = {};
  floorRooms: any[] = [];
  work_site: number = 0;
  changeChk:boolean = false;

  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      tabIndex: observable,
      workSite: observable,
      changeFloors: observable,
      changeFloorRooms: observable,
      floorRooms: observable,
      work_site: observable,
      changeChk : observable,
      
      floorCount: computed,
      getItems: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      saveItem: action,
      deleteItem: action,
      deleteItems: action,
    });

    this.initDatas();
  }

  private initDatas () {
  }

  init () {
    // console.log('init');

    this.workSite = this.user.work_site;
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.checkeds = {};
    this.item = null;
    this.orgItem = null;
    this.changeChk = false;
    this.floorRooms = [];
    this.tabIndex = 0;
    // this.item = {
    //   id: -1,
    //   name: '',
    //   dong: '',
    //   total_floor: 0,
    //   total_area: 0,
    //   site_area: 0,
    // };
  }

  get floorCount() {
    return (this.item && this.item.floors && this.item.floors.length) || 0;
  }

  @debounce(500)
  async getItems() {
    // const user = JSON.parse(window.sessionStorage.getItem('user') || '');
    const filter: any = {
      work_site: this.work_site,
      // > 0 ? this.workSite : user.work_site,
      limit: this.limit,
      offset: this.offset,
    };
    // console.log('getItems.filter: ', filter);
    
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBuilds(filter);
      this.items = data.results;
      // console.log('건물 data!!!!!!!!!!!!>>>>>>>>>>>>>>>>>>>>>>>>>>>>', data);
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;
      return data.results;
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return;
    }
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
  }

  newItem() {
    this.item = {
      work_site: this.work_site,
      id: -1,
      name: '',
      dong: '',
      total_floor: 0,
      total_area: 0,
      site_area: 0,
      floors: [],
    };
    // console.log('newItem>>>>>>>>>>>>>>>>>>>>>>>>>>', this.item);
    this.orgItem = { ...this.item };
    // console.log('orgItem>>>>>>>>>>>>>>>>>>>>>>>>>>', this.orgItem);
  }

  newFloor () {
    this.item.floors.push({
      id: -1,
      name: '',
      total_room: 0,
    });
  }

  async selectedItem(item: any) {
    // delete item.floors;
    if (item) {
      this.item = item;
      this.orgItem = { ...item };
      await this.getFloorRooms();
    } else {
      this.item = null;
      this.orgItem = null;
    }
  }

  get isChange() {
    if (this.item && this.orgItem) {
      const item = {
        work_site: this.item.work_site,
        name: this.item.name,
        dong: this.item.dong,
        total_floor: this.item.total_floor,
        total_area: this.item.total_area,
        site_area: this.item.site_area,
      };
      const orgItem = {
        work_site: this.orgItem.work_site,
        name: this.orgItem.name,
        dong: this.orgItem.dong,
        total_floor: this.orgItem.total_floor,
        total_area: this.orgItem.total_area,
        site_area: this.orgItem.site_area,
      };
      return JSON.stringify(item) !== JSON.stringify(orgItem)
    } else {
      return false;
    }
  }

  async saveItem() {
    // console.log('BuildingStore.saveItem:', this.item);
    const item = { ...this.item };
    const d: any = {
      work_site: this.work_site,
      name: item.name,
      dong: item.dong,
      total_floor: item.total_floor,
      total_area: item.total_area,
      site_area: item.site_area,
     };
    let data: any = null;
    if (this.item.id < 0) {
      try{
        this.headerStore.isLoading = true;
        const result = await apis.build.createBuilding(d);
        const item = result.data;
        item.author = '';
        item.created_at = new Date();
        item.floors = [];
        this.items.push(item);
        this.selectedItem(item);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    } else {
      try{
        this.headerStore.isLoading = true;
        delete d.work_site;
        const result = await apis.build.updateBuilding(this.item.id, d);
        if (result) {
          data = result.data;
          Object.keys(data).forEach((key: string) => this.item[key] = data[key]);
          this.selectedItem({ ...this.item })
        }
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItem() {
    // console.log('BuildingStore.deleteItem:', this.item);
    if (this.item) {
      try{
        this.headerStore.isLoading = true;
        await apis.build.deleteBuilding(this.item.id);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItems() {
    const keys = Object.keys(this.checkeds);
    // console.log(keys);
    if (keys.length > 0) {
      try{
        this.headerStore.isLoading = true;
        const actions = keys.map((checkKey: string) => apis.build.deleteBuilding(Number(checkKey)));
        await Promise.all(actions);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async getFloorRooms() {
    if (this.item) {
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.build.getFloorRooms({ building: this.item.id });
        this.item.floors = [];
        this.item.floors = data;
        this.floorRooms = data;
        this.headerStore.isLoading = false;
        return {
          'save' : true,
          'status' : 200,
          'data' : data
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        // console.log("store catch");
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }
}

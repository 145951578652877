import React from 'react';

interface Props {
  label: string,
  color: 'red' | 'purple' | 'blue' | 'grey' | 'orange' | 'green',
}

const Tag = ({ color, label }: Props) => {
  const defaultStyle = {
    display: 'inline-block',
    minWidth: '60px',
    fontWeight: 400,
    width: '63px',
    height: '26px',
    lineHeight: '24px',
    borderRadius: '13px',
    fontSize: '12px',
    border: '1px solid',
  }

  const colorStyles = {
    red: {
      borderColor: '#f7cccb',
      backgroundColor: '#faf3f3',
      color: '#f1615d',
    },
    purple: {
      borderColor: '#ccc9ea',
      backgroundColor: '#f3f3f8',
      color: "#6157c4",
    },
    blue: {
      borderColor: '#cddaf4',
      backgroundColor: '#f7fafd',
      color: '#5985dc',
    },
    grey: {
      borderColor: '#e0e0e0',
      backgroundColor: '#fafafa',
      color: '#666666',
    },
    orange: {
      borderColor: '#fadec3',
      backgroundColor: '#fefaf6',
      color: '#ed9239',
    },
    green: {
      borderColor: '#b4e6dd',
      backgroundColor: '#f4fbfa',
      color: '#05ad8e',
    },
  }

  return (
    <span style={{...defaultStyle, ...colorStyles[color], textAlign: 'center'}}>
      {label}
    </span>
  );
};

export default Tag;

import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Editor } from '@tinymce/tinymce-react';

import { formatByString, format_YYYYMMDDhhmm } from '../../../../utils/date';

import FileUpload from '../../../molecules/fileUpload';
import ImageUpload from '../../../molecules/fileUpload';
import Select from '../../../atoms/select';
import _, { sortedUniqBy } from 'lodash';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../../routes';
import ChoiceGuests from '../../../organismas/popup/choiceGuests';
import DatePicker from '../../../organismas/input/datepicker';
import { observer } from 'mobx-react';
import { useStores } from '../../../../store/mobx';
import { parserEscape } from '../../../../utils/common';
import * as apis from '../../../../apis';
import CustomCalendar from '../../../organismas/customCalendar';

const WriteContent = observer((props: any) => {
  const dispatch = useDispatch();
  const {noticeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/manage/notice'
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const item: any = store.item;
  const id = item?.id || -1;
  const isCreate = props.isEdit && !props.match.params.id;
  const refId = props.location.search ? props.location.search.split('=')[1] : '';
  const [contents, setContents] = useState<string>('');
  const [itemContents, setItemContents] = useState<string>(item?.contents || '');
  const [mailMessage, setMailMessage] = useState<string>(item?.mail_message || '');
  const [isChoiceGuestsOpen, setIsChoiceGuestsOpen] = useState<boolean>(false);

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [ sendObjectCheck, setSendObjectCheck] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);
  const [mailChecking , setMailChecking] = useState<boolean>(false);

  const editorRef = useRef<any>(null);
  const editorInputRef = useRef<any>(null);

  //
  useEffect(() => {
    (async () => {
      store.worksite = headerStore.workSite;
      if(item?.sms_message !== '') {
        setChecking(true);
        setContents(item?.sms_message);
      }
      if(item?.mail_message !== '' && item?.mail_title !== '') {
        setMailChecking(true);
        
      }
    })();
  }, [headerStore.workSite]);
  //
  const goBack = () => props.history.replace(isCreate && !refId ? baseUrl : `${baseUrl}/${refId ? refId : id}`);

  const onSaveHandler = async () => {

    item.contents = itemContents;
    item.mail_message = mailMessage;

    if (!store.isChange()) {
      info('변경된 항목이 없습니다.');
      return;
    }

    if (item.category < 0) {
      info('공지유형을 선택해주세요.');
      return;
    }

    if (!item.title) {
      info('제목을 입력해주세요.');
      return;
    }

    if (!item.contents) {
      info('내용을 작성해주세요');
      return;
    }

    if(!item.display_after || !item.display_before) {
      info('기간 설정을 해주세요');
      return;
    }

    if(checking === true){
      
      let cellMap = item.guests.map((item:any, index:number) => {
        
        return item.guest_cellphone
        
      }).join(";")
      
      

      if(contents !== "" && item.guests.length <= 0) {
        info("문자 내용 입력 또는 발송대상을 선택해주세요");
        return;
      }

      const payload : any = {
        work_site: headerStore.workSite,
        employee_number: userInfo.employeeNumber,
        cellphones : ''+cellMap,
        message : '[공지사항]'+contents,
      }
      if(checking === true){
        payload['send_date'] = format_YYYYMMDDhhmm();
        payload['send_status'] = "즉시발송"
      }

      try{
        headerStore.isLoading = true;
        await apis.sms.smsSend(payload);
        // goBack();
        headerStore.isLoading = false;
      }catch(err){
        headerStore.isLoading = false;
          // console.log(err);
          const { status, data } = err;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
    
      }

    }

    let mailMap = "";
    item.guests.forEach((item:any, index:number) => {
    
      if(item.email) {
        
        mailMap = mailMap  + item.email + ","
      }
      
    });

    if(mailChecking === true && mailMap !== ""){
      if(item.mail_title !== "" && item.mail_message !== "" && item.guests.length <= 0) {
        info("메일 제목 및 내용을 입력 또는 발송대상을 선택해주세요");
        return;
      }
      
      const payload : any = {
        // work_site: headerStore.workSite,
        // employee_number: userInfo.employeeNumber,
        subject : '[공지사항]' + item.mail_title,
        email : ''+mailMap.slice(0, mailMap.length-1),
        body : item.mail_message,
      }

      // if(mailChecking === true){
      //   payload['send_date'] = format_YYYYMMDDhhmm();
      //   payload['send_status'] = "즉시발송"
      // }
      console.log(payload);
      try{
        headerStore.isLoading = true;
        await apis.sms.mailSend(payload);
        headerStore.isLoading = false;
        // goBack();
      }catch(err){
        headerStore.isLoading = false;
        // console.log(err);
        const { status, data } = err;

        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("문제가 발생했습니다.");
        return;
      }

    }

    try {
      headerStore.isLoading = true;
      let msg = '등록 완료했습니다.';
      if (id > -1) {
        msg = '수정 완료했습니다.';
      }
      store.worksite = headerStore.workSite;
      console.log(item.guests);
      let data : any = await store.saveItem();
      headerStore.isLoading = false;
      setTimeout(function () {
        if(data.save) {
          info(msg);
          goBack();
          return;
        }else {
          // info("문제가 발생했습니다")
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      let msg = data?.guests[0];
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (msg) {
        if(msg === "This list may not be empty."){
          info("발송대상을 선택해주세요.");
          return;
        }
        info(msg);
        return;
      }else {
        info("문제가 발생했습니다")
        return;
      }
      //errorHandler({ status, data }, '저장 중 오류발생');
    }
  };

  const onCancelhandler = () => {
    item.contents = itemContents;
    item.mail_message = mailMessage;

    if (isCreate) {
      goBack();
    } else {
      if(store.isChange()) {
        dispatch({
          type: SHOW_CONFIRM_MODAL,
          payload: {
            isModalShow: true,
            title: '확인',
            msg: '수정한 내용이 삭제됩니다.\n 목록으로 이동하시겠습니까?',
            action: goBack,
          },
        });
      } else {
        goBack();
      }
    }
  };

  const onBackHandler = () => {
    item.contents = itemContents;
    item.mail_message = mailMessage;

    if (!store.isChange()) {
      goBack();
    } else {
      dispatch({
        type: SHOW_CONFIRM_MODAL,
        payload: {
          isModalShow: true,
          title: '확인',
          msg: '수정한 내용이 삭제됩니다.\n목록으로 이동하시겠습니까?',
          action: goBack,
        }
      });
    }
  };

  const onShowChoiceGuestsPopupHandler = (isOpen: boolean) => {
    setIsChoiceGuestsOpen(isOpen);
  };

  const onUpdateGuests = (selectedGuests: any) => {
    item.guests = selectedGuests;
    //console.log('item.guests>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', item.guests);
  };

  let [openTabIdx, setOpenTabIdx] = useState<number>(-1);

  const [guideType, setGuideType] = useState<number>();
  const [prevType, setPrevType] = useState<number>();

  let onClickChangeGuideTypeHandler = (idx: number) => {
      if (idx === prevType){
          setGuideType(-1);
          setPrevType(-1);
      } else{
          setGuideType(idx);
          setPrevType(idx);
      }
  }

  if (!item) return <></>;

  return (
    <>
      <h4>공지사항 { isCreate ? '등록' : '수정' }</h4>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">공지사항</caption>
          <colgroup>
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>작성자</th>
              <td>{item.author_name} ({item.author_employee_number})</td>
              <th>작성일시</th>
              <td>{formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}</td>
            </tr>
            <tr>
                <th><span className="emp_red">기간 설정</span></th>
                <td colSpan={3}>
                  <div className="datepicker_searchwrap form_inline">
                    {/* <DatePicker
                      value={item.display_after}
                      width={250}
                      onChange={(date: string) => item.display_after = date}
                      isPrevMonthView={true}
                    /> */}
                    <CustomCalendar
                      value={item.display_after}
                      width={250}
                      onChange={(date: string) => item.display_after = date}
                      isPrevMonthView={true}
                    ></CustomCalendar> 
                    <span className="dash">~</span>
                    {/* <DatePicker
                      value={item.display_before}
                      width={250}
                      onChange={(date: string) => item.display_before = date}
                      isPrevMonthView={true}
                    /> */}
                    <CustomCalendar
                      value={item.display_before}
                      width={250}
                      onChange={(date: string) => item.display_before = date}
                      isPrevMonthView={true}
                    ></CustomCalendar> 
                  </div>
                </td>
              </tr>
            <tr></tr>
            <tr>
              <th><span className="emp_red">공지유형</span></th>
              <td colSpan={3}>
                <div className="search_form fl">
                  <Select
                    className="form_control"
                    options={store.categories}
                    value={item.category}
                    onChange={(e: any) => (item.category = Number(e.target.value))}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <th><span className="emp_red">제목</span></th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form_control"
                  value={item.title}
                  onChange={(e: any) => (item.title = e.target.value)}
                />
              </td>
            </tr>

            <tr>
              <th><span className="emp_red">내용</span></th>
              <td colSpan={3}>
                <Editor
                  id="tiny_editor_1"
                  apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={parserEscape(item.contents)}
                  onEditorChange={(e) => {
                    setItemContents(e);
                  }}
                  init={{
                    selector : '#tiny_editor_1',
                    width: 1100,
                    height: 500,
                    resize: 'both',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'image code'
                      //'image media link tinydrive code imagetools'
                    ],
                    toolbar:
                      'undo redo | fontselect fontsizeselect | ' +
                      'bold italic | forecolor backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_file_types : 'gif,jpg,jpeg,png',
                    file_picker_callback : function(callback, value, meta) {
                      if(meta.filetype == 'image') {
                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        editorInputRef.current = input;
                        (editorInputRef.current as any).click();
                        
                        input.onchange = function () {
                          let file : any = input.files;
                          file = file[0];

                          if (file.size >= 10485760) {        
                            alert(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
                            return;
                          }else {
                            let reader = new FileReader();

                            reader.onload = async function (e : any) {
                              let editorImageForm = new FormData();
                              editorImageForm.append('path', file);
                              let reusltData : any = null;
                              try {
                                headerStore.isLoading = true;
                                reusltData = await apis.core.fileUpload<{
                                  id?: number;
                                  path?: string;
                                  name?: string;
                                  category?: string | number;
                                }>(editorImageForm);

                                
                                callback(`${process.env.REACT_APP_MEDIA}/`+reusltData.data.path, {alt : file.name});
                                headerStore.isLoading = false;
                              } catch (error) {
                                headerStore.isLoading = false;
                                // console.log(error);

                                const { status, data } = error;

                                if (status === 401) {
                                  if (!window.localStorage.getItem("isRetryLogin")) {
                                    window.localStorage.setItem("isRetryLogin", "retry");
                                  }
                                  window.location.href = '/';
                                  return ;
                                } else if (status === 403) {
                                  alert('접근권한이 없습니다.');
                                  return ;
                                }else if ( status === 500 ) {
                                  window.location.href = '/500';
                                  return;
                                }
                          
                              }

                            };
                            
                            reader.readAsDataURL(file);

                          }
                          editorInputRef.current = null;
                          input.remove();
                        };
                      }
                    }
                  }}
                />
              </td>
            </tr>

            <tr>
              <th>이미지</th>
              <td colSpan={3}>
                <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={item.images}
                  onSetData={(data: any[]) => item.images = data}
                />
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan={3}>
                <FileUpload
                  type="file"
                  fileId="attacheFile"
                  items={item.files}
                  onSetData={(data: any[]) => item.files = data}
                />
              </td>
            </tr>
            <tr>
              <th>
                문자
                <label className="input_check pop_notext ml10">
                  <input
                    type="checkbox"
                    id=""
                    name="check_notice"
                    
                    // checked={item.is_sms_send}
                    checked={checking}
                    onChange={(e: any) => {(item.is_sms_send = e.target.checked);}}
                    onClick={(e: any)=> setChecking(e.target.checked)}
                  />
                  <span className="label_text"></span>
                </label>
              </th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form_control"
                  placeholder="내용을 입력하세요"
                  value={item.sms_message}
                  onChange={(e: any) => {setContents(e.target.value); (item.sms_message = e.target.value)}}
                />
              </td>
            </tr>
            <tr>
              <th>
                메일
                <label className="input_check pop_notext ml10">
                  <input
                    type="checkbox"
                    id=""
                    name="check_notice1"
                    // checked={item.is_mail_send}
                    checked={mailChecking}
                    onChange={(e: any) => (item.is_mail_send = e.target.checked)}
                    onClick={(e: any)=> setMailChecking(e.target.checked)}
                  />
                  <span className="label_text"></span>
                </label>
              </th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form_control mb10"
                  placeholder="제목을 입력하세요"
                  value={item.mail_title}
                  onChange={(e: any) => (item.mail_title = e.target.value)}
                />
                <Editor
                  id="tiny_editor_2"
                  apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={parserEscape(item.mail_message)}
                  onEditorChange={(e) => {
                    setMailMessage(e);
                  }}
                  init={{
                    selector : "#tiny_editor_2",
                    width: 1100,
                    height: 500,
                    resize: 'both',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'image code'
                      //'image media link tinydrive code imagetools'
                    ],
                    toolbar:
                      'undo redo | fontselect fontsizeselect | ' +
                      'bold italic | forecolor backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_file_types : 'gif,jpg,jpeg,png',
                    file_picker_callback : function(callback, value, meta) {
                      if(meta.filetype == 'image') {
                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        editorInputRef.current = input;
                        (editorInputRef.current as any).click();
                        
                        input.onchange = function () {
                          let file : any = input.files;
                          file = file[0];

                          if (file.size >= 10485760) {        
                            alert(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
                            return;
                          }else {
                            let reader = new FileReader();

                            reader.onload = async function (e : any) {
                              let editorImageForm = new FormData();
                              editorImageForm.append('path', file);
                              let reusltData : any = null;
                              try {
                                headerStore.isLoading = true;
                                reusltData = await apis.core.fileUpload<{
                                  id?: number;
                                  path?: string;
                                  name?: string;
                                  category?: string | number;
                                }>(editorImageForm);

                                
                                callback(`${process.env.REACT_APP_MEDIA}/`+reusltData.data.path, {alt : file.name});
                                headerStore.isLoading = false;
                              } catch (error) {
                                headerStore.isLoading = false;
                                // console.log(error);

                                const { status, data } = error;

                                if (status === 401) {
                                  if (!window.localStorage.getItem("isRetryLogin")) {
                                    window.localStorage.setItem("isRetryLogin", "retry");
                                  }
                                  window.location.href = '/';
                                  return ;
                                } else if (status === 403) {
                                  alert('접근권한이 없습니다.');
                                  return ;
                                }else if ( status === 500 ) {
                                  window.location.href = '/500';
                                  return;
                                }
                          
                              }

                            };
                            
                            reader.readAsDataURL(file);

                          }
                          editorInputRef.current = null;
                          input.remove();
                        };
                      }
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th><span className="emp_red">발송 대상</span></th>
              <td colSpan={3}>
                <button
                  type="button"
                  className="btn_sm btn_darkblue btn_outline mr10"
                  onClick={() => onShowChoiceGuestsPopupHandler(true)}>
                  검색
                </button>
                {item.guests && item.guests.length > 0 ? (
                  <div className="items_area">
                    {<a className="item">{item.guests.length === 0 ? '' : `${item.guests.length} 명`}</a>}
                    
                    {/*item.guests.length === 0 ? '' : 
                    <>
                      Object.keys(item.guests).map((guests: any, index: number) => { 
                          //console.log('guests>>>>>>>>>>>>>>>>>>>>', item.guests[guests].guest_name);
                          return <a className="item">{item.guests[guests].guest_name}</a>
                      })
                    </>
                  */}
                  </div>
                ) : (<></>)}
              </td>
            </tr>
              
          </tbody>
        </table>
        <dl className="lst_terms left_area">
          <dt 
            onClick={() => {openTabIdx === 0 ? setOpenTabIdx(-1) : setOpenTabIdx(0); onClickChangeGuideTypeHandler(0)}}
            className={`${openTabIdx === 0 ? 'on' : ''} `}
          >
            <p className="title">{`${openTabIdx === 0 ? '발송 대상 목록(접기)' : '발송 대상 목록(펼치기)'}`}</p>
            <a href=" javascript:void(0)" className="btn_toggle_type2 ir">
              {'펼치기/접기'}
            </a>
          </dt>
          <dd className={`${openTabIdx === 0 ? 'on' : ''}`}>
            {Object.keys(item.guests).map((guests: any, index: number) => { 
              return <a className="item">{item.guests[guests].guest_name}</a>
            })}
          </dd>
        </dl>
      </div>
      {/*table*/}
      <div className="submit">
        <div className="fl">
          <button type="button" className="btn_lg btn_darkgray" onClick={() => props.history.replace(baseUrl)}>목록</button>
        </div>
        <div className="fr">
          <button type="button" className="btn_lg btn_darkblue btn_outline" onClick={onSaveHandler}>완료</button>
          <button type="button" className="btn_lg btn_darkblue" onClick={onCancelhandler}>취소</button>
        </div>
      </div>

      {isChoiceGuestsOpen ? (
      <ChoiceGuests
        category = {item.category}
        isOpen={isChoiceGuestsOpen}
        guests={item.guests}
        setIsOpen={setIsChoiceGuestsOpen}
        onApply={onUpdateGuests}
      />
      ) : (<></>)}
    </>
  );
});

export default WriteContent;

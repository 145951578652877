import _ from 'lodash';
import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { getTodayDate } from '../../utils/date';
import { BoardStore } from './boardStore';
import { addMonths, format } from 'date-fns';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class DeductMeterStore extends BoardStore {
  tabIndex = 0;
  year = '';
  month = '';
  day = '';
  workSiteName = '';
  //loading
  headerStore : HeaderStore;
  xlsParamDate = '';
  xlsDwonFlag = false;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      tabIndex: observable,
      year: observable,
      month: observable,
      day: observable,
      workSiteName: observable,
      xlsParamDate: observable, 
      xlsDwonFlag: observable, 

      initDatas: action,
      getItems: action,
      createMeter: action,
      deleteMeter: action,
    });

    this.initDatas();
  }

  initDatas () {
    const date = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');
    this.year = date[0];
    this.month = date[1];
    this.day = '22';
    this.xlsParamDate = '';
    this.xlsDwonFlag = false;
  }

  init () {
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.item = null;
    this.orgItem = null;
    this.xlsParamDate = '';
    this.xlsDwonFlag = false;
  }

  destory () {
    super.destory();
    this.init();
  }

  @debounce(500)
  async getItems() {
    // console.log('getItems: ', this.search, this.limit, this.offset);
    const filter: any = {
      worksite: this.workSiteName,
      limit: this.limit,
      offset: this.offset,
    };
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.getMeters(filter);
      this.items = data.map((item: any, index: number) => ({ index: index + 1, ...item }));

      this.headerStore.isLoading = false;
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return;
    }
  }

  //검침량 저장
  async createMeter(data: any) {
    try{
      this.headerStore.isLoading = true;
      await apis.deduct.createMeter(data);
      await this.getItems();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  //검침량 삭제
  async deleteMeter(item: any) {
    try{
      this.headerStore.isLoading = true;
      await apis.deduct.deleteMeter(item.id);
      await this.getItems();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}

import {action, makeObservable, observable} from 'mobx';

export class HeaderStore {
  userInfo: any = [];
  workSite: any;
  headerChek: boolean = false;
  selectWorkSite: number = 0;
  
  roomRoleFamily_room : any;
  roomRoleDormitory_room : any;
  roomRoleCompany_room : any;
  roomRoleVip_room : any;
  roomRoleBusiness_room : any;
  roomRoleBusiness_room_group : any;
  roomRoleGitar : any;
  roomRoleNothing : any;
  readRoleFlag = true;
  writeRoleFlag = true;
  deleteRoleFlag = true;
  downRoleFlag = true;
  mobileRoleFlag = true;
  finallyRoleFlag = true;
  menuRoleFlag = true;
  authRoomTypeRoleTotal : number = 0;
  authMenuRoleTotal = 0;
  authWorkSiteTotal = 0;
  len : number = 0;
  authWorkSiteLen : any[] = [];
  paramAuthRoomTypeRole : any;
  paramAuthWorkSiteRole : any;
  paramAuthBuildingRole : any;
  newTown : any;
  bonDong : any;
  building : any;

  //loading
  isLoading : boolean = false;

  //save check
  isSaveCheck : boolean = false;

  constructor(root: any) {
    makeObservable(this, {
        workSite: observable,
        userInfo: observable,
        headerChek: observable,
        selectWorkSite: observable,
        roomRoleFamily_room : observable,
        roomRoleDormitory_room : observable,
        roomRoleCompany_room : observable,
        roomRoleVip_room : observable,
        roomRoleBusiness_room : observable,
        roomRoleBusiness_room_group : observable,
        roomRoleGitar : observable,
        roomRoleNothing : observable,
        readRoleFlag : observable,
        writeRoleFlag : observable,
        deleteRoleFlag : observable,
        downRoleFlag : observable,
        mobileRoleFlag : observable,
        menuRoleFlag : observable,
        authRoomTypeRoleTotal : observable,
        authMenuRoleTotal : observable,
        authWorkSiteTotal : observable,
        authWorkSiteLen : observable,
        paramAuthRoomTypeRole : observable,
        paramAuthWorkSiteRole : observable,
        paramAuthBuildingRole : observable,
        newTown : observable,
        bonDong : observable,
        building : observable,
        isLoading : observable,
        isSaveCheck : observable,
        
        firstWorkSite: action, 
        setWorkSite: action,
        getAuthRoomRole : action,
        getAuthBuilding : action,
        setSelectWorkSite: action
    });
  }

  //초기화 >> 로그인 또는 로그아웃 새로고침시 init 실행
  init() { 
    this.workSite = 0;
    //this.headerChek = false;
    this.selectWorkSite = 0;
    this.roomRoleFamily_room = '';
    this.roomRoleDormitory_room = '';
    this.roomRoleCompany_room = '';
    this.roomRoleVip_room = '';
    this.roomRoleBusiness_room = '';
    this.roomRoleBusiness_room_group = '';
    this.roomRoleGitar = '';
    this.roomRoleNothing = '';
    this.readRoleFlag = true;
    this.writeRoleFlag = true;
    this.deleteRoleFlag = true;
    this.downRoleFlag = true;
    this.mobileRoleFlag = true;
    this.finallyRoleFlag = true;
    this.authRoomTypeRoleTotal = 0;
    this.authMenuRoleTotal = 0;
    this.authWorkSiteTotal = 0;
    this.len = 0;
    this.authWorkSiteLen = [];
    this.paramAuthRoomTypeRole = '';
    this.paramAuthWorkSiteRole = '';
    this.paramAuthBuildingRole = '';
    this.newTown = '';
    this.bonDong = '';
    this.building = '';
  }

  //숙소유형권한
  getAuthRoomRole(){
    const authRoomTypeRole = window.localStorage.getItem('auth_roomtype')?.replace(/\"/gi,"");
    if(authRoomTypeRole !== null) {
      const rommRolelen: any[] = [authRoomTypeRole?.split(",")];
      
      this.paramAuthRoomTypeRole = authRoomTypeRole;

      let FamilyroomRole =  '1';
      let DormitoryRoomRole = '2';
      let CompanyRoomRole = '3';
      let VipRoomRole = '4';
      let BusinessRoomRole = '5';
      let BusinessRoomgroupRole = '6';
      let GitarRole = '7';
      let NothingRole = '8';
      let rommCnt = rommRolelen[0].length;

      for ( let i = 0; i <= rommCnt; i++) {
        let roomRole = rommRolelen[0][i];
        if(roomRole?.indexOf(FamilyroomRole) > -1) {
          this.roomRoleFamily_room = roomRole;
        }
        if(roomRole?.indexOf(DormitoryRoomRole) > -1) {
          this.roomRoleDormitory_room = roomRole;
        }
        if(roomRole?.indexOf(CompanyRoomRole) > -1) {
          this.roomRoleCompany_room = roomRole;
        }
        if(roomRole?.indexOf(VipRoomRole) > -1) {
          this.roomRoleVip_room = roomRole;
        }
        if(roomRole?.indexOf(BusinessRoomRole) > -1) {
          this.roomRoleBusiness_room = roomRole;
        }
        if(roomRole?.indexOf(BusinessRoomgroupRole) > -1) {
          this.roomRoleBusiness_room_group = roomRole;
        }
        if(roomRole?.indexOf(GitarRole) > -1) {
          this.roomRoleGitar = roomRole;
        }
        if(roomRole?.indexOf(NothingRole) > -1) {
          this.roomRoleNothing = roomRole;
        }
      }
    }
  }

  //메뉴 및 조회,등록,삭제,다운로드,승인 및 반려(모바일 포함) 권한 
  getAuthMenuRole(menuLv : any) {
    //각화면 별 아이디[ex)'1-2'] 받아서 처리 진행
    const authMenuRole = window.localStorage.getItem('auth_menu')?.replace(/\"/gi,"");
    //메뉴롤 초기화 
    this.readRoleFlag = true;
    this.writeRoleFlag = true;
    this.deleteRoleFlag = true;
    this.downRoleFlag = true;
    this.mobileRoleFlag = true;
    this.finallyRoleFlag = true;
    this.menuRoleFlag = true;

    if(authMenuRole !== null) {
      const menuRolelen: any[] = [authMenuRole?.split(",")];
      this.authMenuRoleTotal = menuRolelen.length;
      
      let readRole =  'R' //읽기
      let writeRole = 'W' //쓰기
      let deleteRole = 'D'  //삭제
      let downRole = 'M'  //다운로드
      let mobileRole = 'P'  //기본권한
      let finallyRole = 'F' //최종 입숙,이숙,퇴숙 권한
      
      let menuRole : any = '';
      for (let index = 0; index < menuRolelen[0].length; index++) {
        if(menuRolelen[0][index].indexOf(menuLv) >= 0) {
          menuRole =  menuRolelen[0][index].split("-");
          this.menuRoleFlag = true;
          break;
        } else {
            this.menuRoleFlag = false;
        }
      }
      if(menuRole !== undefined) {
        if(menuRole.length === 3) {
          if(!(menuRole[2].indexOf(readRole) > -1)){
            this.readRoleFlag = false;  
            // console.log('읽기권한 없음');
          }
          if(!(menuRole[2].indexOf(writeRole) > -1)){
            this.writeRoleFlag = false;
            // console.log('쓰기권한 없음');
          }
          if(!(menuRole[2].indexOf(deleteRole) > -1)){
            this.deleteRoleFlag = false;
            // console.log('삭제권한 없음');
          }
          if(!(menuRole[2].indexOf(downRole) > -1)){
            this.downRoleFlag = false;  
            // console.log('다운로드권한 없음');
          }
          if(!(menuRole[2].indexOf(mobileRole) > -1)){
            this.mobileRoleFlag = false;  
            // console.log('모바일승인권한 없음');
          }
          if(!(menuRole[2].indexOf(finallyRole) > -1)){
            this.finallyRoleFlag = false;
            // console.log('최종 입숙 퇴숙 이숙 승인권한 없음');
          }
        }
      }
      
    }
  }

  //사업장 권한
  getAuthWorkSiteRole() {
    const authWorkSite = window.localStorage.getItem('auth_worksite')?.replace(/\"/gi,"");
    if(authWorkSite !== null) {
      this.authWorkSiteLen = [authWorkSite?.split(",")];
      this.paramAuthWorkSiteRole = authWorkSite;
    }
  }
  
  //남양 > 뉴타운,본동 권한
  getAuthBuilding(){
    const authBuilding = window.localStorage.getItem('auth_building')?.replace(/\"/gi,"");    
    if(authBuilding !== null) {
      const buildingRolelen: any = [authBuilding?.split(",")];
      this.paramAuthBuildingRole = authBuilding;
      
      let buildCnt = buildingRolelen[0].length
      this.building = buildingRolelen[0];
      for (let i = 0; i <= buildCnt; i++) {
            if(!buildingRolelen[0][i]) 
            this.newTown = buildingRolelen[0][0];
            this.bonDong = buildingRolelen[0][1];
      }
    }
  }

  firstWorkSite(w: number) {
    this.workSite = w;
    this.headerChek = true;
  }

  //헤더 셀렉트 박스에 선택된 사업장 담아놓는 함수
  setWorkSite(w: number) {
    this.workSite = w;
  }

  //현재 선택된 사업장을 담아놓는 함수
  setSelectWorkSite(w: number) {
    this.selectWorkSite = w;
  }
}

import React from 'react';
import { BAD_UNSELECT_COLOR, BAD_SELECT_COLOR } from '../../../color';

const Select = (props: any) => {
  const selectedYN = props.selected;

  const backgroundColor = props.selected
    ? BAD_SELECT_COLOR
    : BAD_UNSELECT_COLOR;

  return (
    <a className="room" style={{ backgroundColor, cursor:'pointer'}} onClick={props.onClick} 
        onMouseOver={e => (selectedYN? '' : e.currentTarget.style.backgroundColor = BAD_SELECT_COLOR)}
        onMouseOut={e => (selectedYN? '' : e.currentTarget.style.backgroundColor = BAD_UNSELECT_COLOR)} >
      {props.children}
    </a>
  );
};

export default Select;

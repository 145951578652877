import React, {useState, useRef, useContext} from 'react';
import NameInput from '../../atoms/input/name';
import PhoneInput from '../../atoms/input/phone';
import { MessageContext } from '../../../routes';
import { auth } from '../../../apis';
import './index.scss';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';

//아이디 찾기 팝업
const SearchId = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const { info } = useContext(MessageContext);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [number, setNumber] = useState<string>("");

  const [verifyToken, setVerifyToken] = useState<string>("");
  const [employeeNumber, setEmployeeNumber] = useState<string>("");
  const [verifyCount, setVerifycount] = useState<number>(0);
  
  const ref = useRef<any>(null);

  const init = () => {
    setName('');
    setPhone('');
    setNumber('');
    setVerifyToken('');
    setEmployeeNumber('');
  }

  // 인증하기 버튼 클릭
  const onClickVerifySendHandler = async () => {
    if(!name || name === '') {
      info("성명을 확인해주세요.");
      return;
    }
    if (phone.length !== 11 ) {
      info("휴대폰 번호를 확인해주세요.");
      return;
    }
    try {
      headerStore.isLoading = true;
      let { data } = await auth.findIdAuth({
        name,
        cellphone: phone
      });

      headerStore.isLoading = false;
      info("입력하신 정보로 인증번호를 발송하였습니다");
      setVerifyToken(data.token);
    } catch ({status, data}) {
      headerStore.isLoading = false;
      // console.log(status, data);
      info("입력하신 정보가 회원가입 시 입력한 정보와 일치하지 않습니다")
      return;
    }
  }

  // 확인버튼 클릭
  const onClickVerifyHandler = async () => {
    if (!verifyToken) {
      info("인증번호를 발송하지 않았습니다.");
      return
    }
    if (number.length !== 6) {
      info("인증번호가 잘못되었습니다.");
      return
    }
    try {
      headerStore.isLoading = true;
      let { data } = await auth.findIdVerify({
        name,
        number,
        token: verifyToken,
        cellphone: phone,
      })
      ref.current.scrollIntoView({behavior: 'smooth', block: 'end'});
      headerStore.isLoading = false;
      info("아이디 찾기를 완료했습니다.");
      setEmployeeNumber(data.employee_number);
    } catch ({status, data}) {
      headerStore.isLoading = false;
      // console.log(status, data);
      if (status === 404) {
        info("가입하지 않는 사용자입니다.")
        return;
      } else if (status === 400) {
        if (data?.code === "invalid_number") {
          info("인증번호가 잘못되었습니다.");
          return;
        }
      }

      info("문제가 발생했습니다.");
      return;
    }
  }

  return (
    <div
      id="pop_srch_id"
      className="pop_dim_wrap pop_modal pop_md"
      style={{ display: props.isShow ? 'block' : 'none' }}
    >
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>아이디 찾기</h3>
          <button
            className="pop_btn_close pop_close"
            onClick={() => {init(); props.onCloseSearchIdPopupHandler();}}
          >
            닫기
          </button>
        </div>
        <div className="contents_wrap">
          <ul className="tabs">
            <li className="w50">
              <a href="javascript:void(0)" className="on">
                아이디 찾기
              </a>
            </li>
            <li className="w50" onClick={() => {init(); props.onMoveIdToPasswordHandler();}}>
              <a href="javascript:void(0)">비밀번호 찾기</a>
            </li>
          </ul>
          <div className="tab_contents on" ref={ref}>
            <div className="form_write">
              <div className="row">
                <div className="col w100">
                  <label htmlFor="input_username" className="title_form">
                    성명
                  </label>
                  <NameInput
                    type="text"
                    id="input_username"
                    className="form_control"
                    placeholder="성명을 입력해주세요"
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col w100">
                  <label htmlFor="input_phone_forid" className="title_form">
                    휴대폰 번호
                  </label>
                  <div className="form_phone">
                    <PhoneInput
                      type="text"
                      id="input_phone_forid"
                      className="form_control"
                      placeholder="숫자만 입력해주세요"
                      value={phone}
                      onChange={(e: any) => setPhone(e.target.value)}
                    />
                    <button className="btn btn_gray" onClick={onClickVerifySendHandler}>인증하기</button>
                    {/*<button className="btn btn_lg btn_gray">재전송</button>*/}
                  </div>
                </div>
              </div>

              <ul className="lst_warning">
                <li>회원가입 시 입력한 휴대폰 번호를 입력해주세요</li>
              </ul>

              <div className="row">
                <div className="col w100">
                  <label htmlFor="input_cnum_forid" className="title_form">
                    인증번호
                  </label>
                  <p className="form_phone">
                    <input
                      type="text"
                      id="input_cnum_forid"
                      className="form_control"
                      placeholder="인증번호를 입력해주세요"
                      maxLength={6}
                      value={number}
                      onChange={(e: any) => {
                        e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                        setNumber(e.target.value);
                      }}
                    />
                    <button className="btn btn_gray" onClick={onClickVerifyHandler}>확인</button>
                  </p>
                </div>
              </div>

              {/*아이디 찾기 결과*/}
              <dl className="lst_basicinfo" >
                <dt>아이디 찾기 결과</dt>
                <dd>
                  <ul>
                    <li>
                      <strong>아이디</strong>
                      <span>{employeeNumber}</span>
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <div className="button_wrap btn_length_1">
          <button
            type="button"
            className="btn btn_xlg btn_darkblue pop_close"
            onClick={(e: any) => {init(); props.onCloseSearchIdPopupHandler(e)}}
            //onClick={props.onCloseSearchIdPopupHandler}
          >
            완료
          </button>
        </div>

      </div>
    </div>
  );
});

export default SearchId;

// base은 prefix로 _BASE_을 붙인다.
// 특수상황은 _BASE_를 뺸다

const kr = {
  'day-0': '일',
  'day-1': '월',
  'day-2': '화',
  'day-3': '수',
  'day-4': '목',
  'day-5': '금',
  'day-6': '토',

  'kr': '한국어',
  'en': "영어",

  '_BASE_signin': "로그인",
};

export default kr;

import { observer } from 'mobx-react';
import React from 'react';
import TempleteDefault from '../../components/templetes/default';
import { useStores } from '../../store/mobx';

interface Props {
    id: string;
    className: string;
    children: React.ReactNode;
    isNotLoginRequire?: boolean;
    history: any;
    isLoading?: boolean;
}

const LodgedTotal = observer((props: Props) => {
    const { loadgedTotalStore : store } = useStores();
    const {headerStore: headerStore} = useStores();

    return (
        <TempleteDefault
            {...props}
            id="wrap"
            className=""
            isLoading={headerStore.isLoading}
        >
            <div className="group_tab mt30"> 
                <ul className="tab_lg tabs clearfix">
                    <li>
                        <a 
                            href="javascript:void(0);" 
                            onClick={(e:any) => {
                                        if(!props.history.location.pathname.includes("/guest/status/setting")) {store.init();}
                                        props.history.replace("/guest/status/setting")}
                                    } 
                            className={props.history.location.pathname.includes("/guest/status/setting") ? "on" : ""}
                        >
                            현황 및 설정
                        </a>
                    </li>
                    <li>
                        <a 
                            href="javascript:void(0);" 
                            onClick={(e:any) => {
                                        if(!props.history.location.pathname.includes("/guest/status/checkin")) {store.init();}
                                        props.history.replace("/guest/status/checkin")}
                                    } 
                            className={props.history.location.pathname.includes("/guest/status/checkin") ? "on" : ""}
                        >
                            입숙
                        </a>
                    </li>
                    <li>
                        <a 
                            href="javascript:void(0);"
                            onClick={() => {
                                        if(!props.history.location.pathname.includes("/guest/status/checkout")) {store.init();}
                                        props.history.replace("/guest/status/checkout")}
                                    } 
                            className={props.history.location.pathname.includes("/guest/status/checkout") ? "on" : ""}>
                            퇴숙
                        </a>
                    </li>
                    <li>
                        <a 
                            href="javascript:void(0);" 
                            onClick={() => {
                                        if(!props.history.location.pathname.includes("/guest/status/checkchange")) {store.init();}
                                        props.history.replace("/guest/status/checkchange")}
                                    } 
                            className={props.history.location.pathname.includes("/guest/status/checkchange") ? "on" : ""}>
                            이숙
                        </a>
                    </li>
                </ul>
            </div>
            <div className="tab_contents on">
                {props.children}
            </div> {/* tab_content */}
        </TempleteDefault>
    )
})

export default LodgedTotal;
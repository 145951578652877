import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ROOM_TYPE, ROOM_TYPE_CODE, VOC_CATEGORY, WORK_SITE } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { formatByString } from '../../../utils/date';
import MobileHeader from '../header';
import '../mb_common.scss';
import MobileVocDetail from './detail';
import MobileVocReg from './reg';

const MobileVoc = observer((props: any) => {
    const { headerStore: headerStore } = useStores(); //권한
    const { mobileTotalStore : store } = useStores();
    const { vocStore: vocStore } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [reload, setReload] = useState<boolean>(false);
    const [room_types, setRoom_types] = useState<any[]>([]);
    const [isDetailPop, setIsDetailPop] = useState<boolean>(false);
    const [isRegPop, setIsRegPop] = useState<boolean>(false);

    const { info, warning, error }: MessageImpl = useContext(MessageContext);

    useEffect(() => {
        headerStore.getAuthMenuRole('3-2');         //메뉴권한 및 기본권한
        headerStore.getAuthWorkSiteRole();          //사업장권한
        headerStore.getAuthRoomRole();              //숙소권한
        headerStore.getAuthBuilding();              //건물 권한

        setRoom_types(headerStore.paramAuthRoomTypeRole.split(","));
        
        if(headerStore.mobileRoleFlag === true){
            store.auth_Menu = 'Y';
        }else{
            store.auth_Menu = 'N';
        }
        store.limit = 10;

    }, [reload, headerStore.workSite]);

    useEffect(() => {
        (async () => {
            store.employee_number = userInfo.employeeNumber;
            store.work_site = headerStore.workSite;
            store.roomtype = headerStore.paramAuthRoomTypeRole;
            if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                store.authBuilding = headerStore.paramAuthBuildingRole;
            }
            await getVocList();
        })();
    }, [headerStore.workSite, store.limit, store.selectRoomType, store.selectBuilding, store.selectDong, store.selectCategory, store.selectGubun, store.selectStatus]);

    const getVocList = async() => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.getVocList();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    setReload(true);
                    return;
                }else {
                    // info("문제가 발생했습니다.");
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
            return;
        }
    }

    useEffect(() => {
        (async () => {
            try{
                store.buildingList = [];
                store.selectWorkSite = headerStore.workSite;
                
                let data : any;
                if(store.selectRoomType != 0){
                    headerStore.isLoading = true;
                    data = await store.getBuildingList();   // 건물명
                    headerStore.isLoading = false;
                    setTimeout(function () {
                        if(data.save) {
                            store.dongList = [];
                            store.selectBuilding = '';
                            store.selectDong = '';
                        }else {
                            // info("문제가 발생했습니다.");
                            return;
                        }
                    }, 250);
                }
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectRoomType]);

    useEffect(() => {
        (async () => {
            try{
                store.selectWorkSite = headerStore.workSite;
                headerStore.isLoading = true;
                let data : any = await store.getBuildingDongList();   // 건물 > 동
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        store.selectDong = '';
                        return;
                    }else {
                        // info("문제가 발생했습니다.");
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectBuilding]);

    const plusOffset = () => {
        store.limit += 10;
    }

    const oneTypeCode = (code: string) => {
        if(code === "숙소내") {
            return <span className="desc">숙소내</span>
        }else if(code === "숙소외") {
            return <span className="desc case2">숙소외</span>
        }else if(code === "사건사고") {
            return <span className="desc case3">사건사고</span>
        }else if(code === "서비스불만") {
            return <span className="desc case4">서비스불만</span>
        }else if(code === "기타") {
            return <span className="desc case5">기타</span>
        }
        
        return<></>;
    }
   
    return (
        <>
            <div id="wrap" className={headerStore.isLoading ? "wrap_mobile loading" : "wrap_mobile wrap_mobile_main"}>
                <MobileHeader {...props} history={props.history} />
                <div id="container">
                    {/* <div className="group_title">
                            <h4 className="title_contents">{WORK_SITE[headerStore.workSite]}</h4>
                        </div>
                        <hr/> */}

                    <div className="form_write">
                        <div className="row col_3ea">
                            <div className="col">
                                <select name="" id="select_rooltype" className="form_control"
                                    value={store.selectRoomType}
                                    onChange={(e:any) => store.selectRoomType = e.target.value}
                                >
                                    <option value="0">숙소유형</option>
                                    {room_types.map((item: any, index: number) => {
                                        if(Number(item) !== 0 && Number(item) !== 7) {
                                            return (<option key={index} value={item}>{ ROOM_TYPE[item] }</option>);
                                        }    
                                    })}
                                </select>
                            </div>
                            <div className="col">
                                <select name="" id="select_dong" className="form_control"
                                    value={store.selectBuilding}
                                    onChange={(e:any) => store.selectBuilding = e.target.value}
                                    disabled={!store.selectRoomType || store.buildingList.length === 0 ? true : false}
                                >
                                    <option value="">건물명</option>
                                    {store.buildingList.map((item: any, idx: number) => (
                                        <option key={idx} value={item.name}>{ item.name }</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col">
                                <select name="" id="select_ho" className="form_control"
                                    value={store.selectDong}
                                    onChange={(e:any) => store.selectDong = e.target.value}
                                    disabled={!store.selectRoomType || !store.selectBuilding || store.dongList.length === 0 ? true : false}
                                >
                                    <option value="">동</option>
                                    {store.dongList.map((item: any, idx: number) => (
                                        <option key={idx} value={item.dong}>{ item.dong }</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row col_3ea">
                            <div className="col">
                                <select name="" id="select_type1" className="form_control"
                                    value={store.selectGubun}
                                    onChange={(e:any) => store.selectGubun = e.target.value}
                                >
                                    <option value="">구분</option>
                                    <option value="true">유지보수</option>
                                    <option value="false">VOC</option>
                                </select>
                            </div>
                            <div className="col">
                                <select name="" id="select_type2" className="form_control"
                                    value={store.selectStatus}
                                    onChange={(e:any) => store.selectStatus = e.target.value}
                                >
                                    <option value="">진행상태</option>
                                    <option value="false">처리중</option>
                                    <option value="true">완료</option>
                                </select>
                            </div>
                            <div className="col">
                                <select name="" id="select_type3" className="form_control"
                                    value={store.selectCategory}
                                    onChange={(e:any) => store.selectCategory = e.target.value}
                                >
                                    <option value="">1차분류</option>
                                    {VOC_CATEGORY.map((category: any, index: number) => (
                                        <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr/> 
                    <div className="group_title">
                        <h4 className="title_contents ir">리스트</h4>
                        <div className="right_area">
                            <button type="button" className="btn btn_xs btn_darkblue" onClick={()=>{setIsRegPop(true);}}>유지운영등록</button>
                        </div>
                    </div>

                    <ul className="lst_faq">
                        {store.list.length <= 0 ? (
                            <div className="blank_area">
                                <p>데이터가 없습니다</p>
                            </div>
                        ) :(
                            store.list.map((item: any, index: number) => {
                                let placeName = '';
                                if(item.places){
                                    if (item.places.length > 0) {
                                        const place = item.places[0];
                                        if (item.places.length === 1) {
                                            placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}`;
                                        } else {
                                            placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}외 ${item.places.length - 1}곳`;
                                        }
                                    }
                                }
                            
                            return (
                                <li key={index} onClick={()=>{ store.getVocData(item.id); vocStore.getItem(item.id); setIsDetailPop(true);}}> {/*vocStore.getItem(item.id); */}
                                    <a href="javascript:void(0);">
                                        <div className="info_area clearfix">
                                            <span className="type">{item.is_maintenance ? '유지보수' : 'VOC'}</span>
                                            {oneTypeCode(item.category1)}
                                            <span className="time">{item.created_at? formatByString(item.created_at, 'yyyy.MM.dd HH:mm') :''}</span>
                                        </div>
                                        <div className="desc_area">
                                            <span className="txt_ellipsis">{item.title}</span>
                                        </div>
                                    </a>
                                </li>
                            )})
                        )}
                    </ul>
                    {store.limit < store.totalCount ? (
                        <button className="btn btn_lg btn_outline btn_more" onClick={plusOffset}>
                            더보기<i className="ico_plus"></i>
                        </button>
                    ) : ''}
                </div>

                {/* 상세 팝업 */}
                <MobileVocDetail 
                    isDetailPop = {isDetailPop}
                    onClose = {() => {setIsDetailPop(false); vocStore.newItem();}}
                />

                {/* 등록 팝업 */}
                <MobileVocReg 
                    isRegPop = {isRegPop}
                    onClose = {() => {setIsRegPop(false); vocStore.newItem();}}
                    isUpdate={false}
                />
            </div>
        </>
    );
});

export default MobileVoc;

import React from 'react';

import DefaultTemplate from '../../components/templetes/default';
import AuditList from '../../components/templetes/audit';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';

const AuditListPage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <AuditList {...props} />
    </DefaultTemplate>
  );
});
export default AuditListPage;

import { range } from 'lodash';
import React, { useState, useEffect, useContext } from 'react';

//selectbox
const DaySelect = (props: any) => {
  var date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
 
  const getFirstDay = new Date(year, month - 1, 1).getDay();
  const getLastDate = new Date(year, month, 0).getDate();

  console.log('Dayselect props.day : ' + props.day);
 
  return (
    <>
      <select name="" id="" className="form_control col_xs ml20"
        value={props.day}
        onChange={props.onChange}
      >
        {range(16, getLastDate, 1).map((item:any, index:number) => (
          <option key={index} value={`${item + 1}`.padStart(2, '0')}>{`${item + 1}`.padStart(2, '0')}</option>
        ))}
      </select>
    </>
  );
};

export default DaySelect;

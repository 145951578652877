import React, { useContext, useState, useEffect } from 'react';
import _, { map } from 'lodash';
import { observer } from 'mobx-react';
import { MessageContext, MessageImpl } from '../../../../routes';
import { useStores } from '../../../../store/mobx';
import { getTodayDate } from '../../../../utils/date';

const RejectPopup = observer((props: any) => {
  const {auditStore: store} = useStores();
  const {headerStore : headerStore} = useStores();
  const yyyyMMdd: string[] = store.rejectTerminated_at ? store.rejectTerminated_at.split('-') : getTodayDate()[0].split('-')
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [days, setDays] = useState<string[]>([]);
  const [year, setYear] = useState<string>(yyyyMMdd[0]);
  const [month, setMonth] = useState<string>(yyyyMMdd[1]);
  const [day, setDay] = useState<string>(yyyyMMdd[2]);

  useEffect(() => {
    headerStore.getAuthMenuRole('2-5');
    // console.log('testing...');
    if (props.isOpen) {
      changeMonth(month);
      if (store.selectedGuest.status === 4) {
        store.rejectTerminated_at = store.selectedGuest.terminated_at;
        store.rejectDescription = store.selectedGuest.description;
        const temps = store.rejectTerminated_at.split('-');
        setYear(temps[0]);
        setMonth(temps[1]);
        setDay(temps[2]);
      }
    }
  }, [props.isOpen]);

  const changeMonth = (_month: string) => {
    const count = new Date(Number(year), Number(_month), 0).getDate();
    setDays(_.range(0, count, 1).map(day => `${day+1}`.padStart(2, '0')));
  }

  const onChangeValuie = (key: string, setFn: Function, value: string) => {
    store.rejectTerminated_at = `${year}-${month}-${value}`;
    setFn(value);
    switch (key) {
      case 'year':
      case 'month':
        changeMonth(value);
        break;
    }
  }

  const close = async () => {
    store.rejectTerminated_at = '';
    store.rejectDescription = '';
    props.setIsOpen(false);
  };

  const onApply = () => {
    if(store.selectedGuest.status === 4) {
      props.setIsOpen(false);
      return;
    }
    
    if(!store.rejectTerminated_at){
      store.rejectTerminated_at = getTodayDate()[0];
    }
    if (!store.rejectDescription) {
      info('사유를 입력해 주세요.');
      return;
    }
    console.log(props.guest);
    props.onApply(props.guest, 4);
    props.setIsOpen(false);
  }
 
  return (
    <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm">
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>{store.selectedGuest.status === 4 ? '반려사유' : '반려처리'}</h3>
          <button className="pop_btn_close pop_close" onClick={close}>닫기</button>
        </div>
        <div className="contents_wrap">
          <div className="process_list">
            <dl>
              <dt>반려항목</dt>
              <dd>
                <select className="form_control" disabled={store.selectedGuest.status === 4 ? true : false}>
                  <option>재요청</option>
                </select>
              </dd>

              {/* 반려 Case일 경우 ~ 기한 설정 항목 삭제 */}
              <dt>기한 설정</dt>
              <dd>
                <select 
                  className="form_control year"
                  value={year}
                  onChange={(e: any) => onChangeValuie('year', setYear, e.target.value)}
                  disabled={store.selectedGuest.status === 4 ? true : false}
                >
                  {_.range(Number(year), Number(year) + 1, 1).map(item => (
                    <option>{item}</option>
                  ))}
                </select>
                <select
                  className="form_control month"
                  value={month}
                  onChange={(e: any) => onChangeValuie('month', setMonth, e.target.value)}
                  disabled={store.selectedGuest.status === 4 ? true : false}
                >
                  {_.range(1, 13, 1).map(month => (
                    <option value={`${month}`.padStart(2, '0')}>{`${month}`.padStart(2, '0')}</option>
                  ))}
                </select>
                <select
                  className="form_control day"
                  value={day}
                  onChange={(e: any) => onChangeValuie('month', setDay, e.target.value)}
                  disabled={store.selectedGuest.status === 4 ? true : false}
                >
                  {days.map(day => (
                    <option value={day}>{day}</option>
                  ))}
                </select>
              </dd>

              <dt>사유</dt>
              <dd>
                <textarea
                  className="form_control"
                  value={store.rejectDescription}
                  onChange={(e: any) => store.rejectDescription = e.target.value}
                  disabled={store.selectedGuest.status === 4 ? true : false}
                ></textarea>
              </dd>
            </dl>
          </div>
        </div>
        <div className="button_wrap btn_length_1">
          {store.selectedGuest.status === 4 ? (
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={close}>확인</button>
          ) : (
            <>
            {headerStore.writeRoleFlag ? 
              <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={onApply}>완료</button> : <></>
            }
            </>
          )}
        </div>
      </div>
    </div>
  )
});

export default RejectPopup;

import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import DefaultTemplate from '../../components/templetes/default';
import { auth } from '../../apis';
import { GENDER_CODE, WORK_SITE } from '../../constants';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';
import { MessageContext, MessageImpl } from '../../routes';

const MyPageInfo = observer((props: any) => {
    const { user } = useSelector((state: any) => ({
        user: state.user
    }))
    const [password, setPassword] = useState<string>("");
    const {headerStore: headerStore} = useStores();

    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    

    const onClickHandler = async () => {
        try {
            headerStore.isLoading = true;
            let data = await auth.passwordCheck({
                password
            });
            headerStore.isLoading = false;
            props.history.push(`/mypage/info/update`, true);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
            return;
        }
    }

    return (
        <>
            <DefaultTemplate
                id="wrap"
                className=""
                isLoading={headerStore.isLoading}
                {...props}
            >
                <div className="tab_contents on">
                    <h4>기본정보</h4>
                    <div className="table_normal_list table_write">
                        <table>
                            <caption className="sr_only">기본정보</caption>
                            <colgroup>
                                <col width="15%" />
                                <col width="*" />
                                <col width="15%" />
                                <col width="*" />
                                <col width="15%" />
                                <col width="*" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>관리번호(아이디)</th>
                                    <td>{user.employeeNumber}</td>
                                    <th>성명</th>
                                    <td>{user.username}</td>
                                    <th>생년월일</th>
                                    <td>{user.birthdate}</td>
                                </tr>
                                <tr>
                                    <th>성별</th>
                                    <td>{user.gender === 1? '남' : '여'}</td>
                                    <th>휴대폰번호</th>
                                    <td>{user.phone}</td>
                                    <th>소속회사</th>
                                    <td>{user.company}</td>
                                </tr>
                                <tr>
                                    <th>근무지</th>
                                    <td>{WORK_SITE[user.work_site]}</td>
                                    <th>담당업무</th>
                                    <td>{user.assigned_task}</td>
                                    <th>이메일</th>
                                    <td className="br0">{user.email}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  {/*table*/}

                    <h4>정보변경을 위한 비밀번호 확인</h4>
                    <div className="form_write form_confirm clearfix">
                        <div className="row">
                            <div className="col clearfix">
                                <label htmlFor="input_pw" className="title_form">비밀번호확인</label>
                                <p className="form_phone">
                                    <input type="password" id="input_pw" className="form_control form_sm" placeholder="비밀번호를 입력해주세요." value={password} onChange={(e: any) => setPassword(e.target.value)}/>
                                    <button className="btn btn_sm btn_gray" onClick={onClickHandler}>완료</button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultTemplate>
        </>
    );
});

export default MyPageInfo;

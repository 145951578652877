import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import ConfirmBtn from '../../atoms/button/confirm';

import { lodge } from '../../../apis';

import { BOOKING_STATUS_CODE, STATUS_CODE } from '../../../constants';
import { rightBig, getTodayDate, getYear5, getYear, getMonth, getDate, getMonths, getDays } from '../../../utils/date';

import './reflect.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { MessageContext, MessageImpl } from '../../../routes';

const REJECT = BOOKING_STATUS_CODE.rejected // 재요청
const TERMINATED = BOOKING_STATUS_CODE.terminated // 거절

interface Guest {
  id: number;
  status: number;
  room_type: number;

  status_changed_at: string;
  status_reject_description: string;
  status_terminated_at: string;
  status_terminated_description: string;
}

interface Booking {
  id: number;
  applicant: number;
  created_at: string;
  
  room_type: number;
  room_work_site: string;

  guests: Guest[];
  files: File[];
}

export interface Props {
  id: number; // booking.id 또는 guest.id
  isShowReflect: boolean;
  onClose: Function;
  onSubmit: Function;
  terminatedDisable?: boolean;
  booking: Booking;
}

//반려 팝업
const Reflect = observer((props: any) => {
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const {applicantJudgeStore: store} = useStores();
  const [terminateYear, setTerminateYear] = useState<string>(getYear());
  const [terminateMonth, setTerminateMonth] = useState<string>(getMonth());
  const [terminateDay, setTerminateDay] = useState<string>(getDate());
  const [reflectType, setReflectType] = useState<number>(REJECT);
  const [description, setDescription] = useState<string>("");
  //const [terminateAt, setTerminateAt] = useState<string>(store.selectRow.status_terminated_at);

  useEffect(() => {
    popInit();
  }, [props.isShowReflect]);

  const onSubmitHandler = () => {
    if(!description || description === '') {
      info("사유를 입력해주세요");
      return false;
    }
    props.onSubmit(reflectType, terminateYear + '-' + terminateMonth + '-' + terminateDay, description);
  }

  const getMsg = () => {
    return reflectType === REJECT ? "재요청을 진행하시겠습니까?" : "거절을 진행하시겠습니까?"
  }

  const popInit = () => {
    setTerminateYear(getYear());
    setTerminateMonth(getMonth());
    setTerminateDay(getDate());
    setReflectType(REJECT);
    setDescription('');
  }

  return (
    <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: props.isShowReflect? "block": "none" }}>
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>반려처리</h3>
          <button className="pop_btn_close pop_close" onClick={() => {popInit(); props.onClose();}}>닫기</button>
        </div>
        <div className="contents_wrap">
          <div className="process_list">
            <dl>
                <dt>반려항목</dt>
                <dd>
                  {store.selectRow.status === BOOKING_STATUS_CODE.applied || store.selectRow.status === BOOKING_STATUS_CODE.stayHold? (
                    <select className="form_control" value={reflectType} onChange={(e: any) => setReflectType(parseInt(e.target.value))}>
                      <option value={REJECT}>재요청</option>
                      { !(props?.terminatedDisable) && <option value={TERMINATED}>거절</option>}
                    </select>
                  ):(
                    <select className="form_control" value={store.selectRow.status} disabled>
                      <option value={REJECT}>재요청</option>
                      <option value={TERMINATED}>거절</option>
                    </select>
                  )}
                </dd>

                {store.selectRow.status === BOOKING_STATUS_CODE.applied || store.selectRow.status === BOOKING_STATUS_CODE.stayHold? (
                    //반려 Case일 경우 ~ 기한 설정 항목 삭제
                    reflectType === REJECT && (
                      <>
                        <dt>기한 설정</dt>
                        <dd>
                            <select className="form_control year" value={terminateYear} onChange={(e)=> setTerminateYear(e.target.value)}>
                              {getYear5(false).map((year:any, index: number) => (
                                <option key={index} value={year}>{year}</option>
                              ))}
                            </select>
                            <select className="form_control month" value={terminateMonth} onChange={(e)=> setTerminateMonth(e.target.value)}>
                              {getMonths(terminateYear, false).map((year:any, index: number) => (
                                <option key={index} value={year}>{year}</option>
                              ))}
                            </select>
                            <select className="form_control day" value={terminateDay} onChange={(e)=> setTerminateDay(e.target.value)}>
                              {getDays(terminateYear, terminateMonth, true).map((year:any, index: number) => (
                                <option key={index} value={year}>{year}</option>
                              ))}
                            </select>
                        </dd>
                      </>
                      )
                    ) :(
                      store.selectRow.status === REJECT && (
                      <>
                        <dt>기한</dt>
                        <dd>
                          {store.selectRow.status_terminated_at}
                        </dd>
                      </>
                      )
                )}
                

                <dt>사유</dt>
                <dd>
                {store.selectRow.status === BOOKING_STATUS_CODE.applied || store.selectRow.status === BOOKING_STATUS_CODE.stayHold? (
                    <textarea className="form_control"
                      value={description}
                      onChange={(e: any) => setDescription(e.target.value)}
                    >
                    </textarea>
                  ) : (
                    <textarea className="form_control"
                      value={store.selectRow.status_description}
                      disabled
                    >
                    </textarea>
                  )}
                </dd>
            </dl>
          </div>
        </div>
        <div className="button_wrap btn_length_1">
          {store.selectRow.status === BOOKING_STATUS_CODE.applied || store.selectRow.status === BOOKING_STATUS_CODE.stayHold? (
            <ConfirmBtn
              className="btn btn_lg btn_darkblue pop_close"
              action={onSubmitHandler}
              title={""}
              label="확인"
              msg={getMsg()}
            />
          ) : (
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={props.onClose}>확인</button>
          )}
        </div>
      </div>
    </div>
  )
});

export default Reflect;

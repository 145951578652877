import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import MobileCheckLnb from '..';
import { ROOM_TYPE, ROOM_TYPE_CODE } from '../../../../constants';
import { MessageContext, MessageImpl } from '../../../../routes';
import { useStores } from '../../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../../store/modules/modal';
import '../../mb_common.scss';

const MobileCheckTotal = observer((props: any) => {
    const { mobileTotalStore : store } = useStores();
    const {headerStore: headerStore} = useStores();
    const [room_types, setRoom_types] = useState<any[]>([]);
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    
    useEffect(() => {
        (async () => {
            try{
                headerStore.getAuthMenuRole('2-3');
                headerStore.getAuthBuilding();
                headerStore.getAuthRoomRole();

                setRoom_types(headerStore.paramAuthRoomTypeRole.split(","));

                store.work_site = headerStore.workSite;
                store.roomtype = headerStore.paramAuthRoomTypeRole;
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.building = headerStore.paramAuthBuildingRole;
                    }
                }
                headerStore.isLoading = true;
                await store.getTotalList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.limit, headerStore.workSite]);

    useEffect(() => {
        (async () => {
            try{
                store.buildingList = [];
                store.selectWorkSite = headerStore.workSite;
                store.dongList = [];
                store.selectBuilding = '';
                store.selectDong = '';
                
                headerStore.isLoading = true;
                if(store.selectRoomType != 0){
                    let data : any = await store.getBuildingList();   // 건물명
                    headerStore.isLoading = false;
                    setTimeout(function () {
                        if(data.save) {
                            // setReload(true);
                            return;
                        }else {
                            // info("문제가 발생했습니다.");
                            return;
                        }
                    }, 250);
                }

                let data : any = await store.getTotalList();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        // setReload(true);
                        return;
                    }else {
                        //info("문제가 발생했습니다.");
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectRoomType]);

    useEffect(() => {
        (async () => {
            try{
                store.selectWorkSite = headerStore.workSite;
                store.dongList = [];
                store.selectDong = '';
               
                headerStore.isLoading = true;
                let data : any = await store.getBuildingDongList();   // 건물 > 동
                headerStore.isLoading = false;
                // setTimeout(function () {
                    if(data.save) {
                        // setReload(true);
                        let data2: any = await store.getTotalList();
                        if(data2.save) {
                            return;
                        }else {
                            return;
                        }
                        return;
                    }else {
                        //info("문제가 발생했습니다.");
                        return;
                    }
                // }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectBuilding]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                await store.getTotalList();
                headerStore.isLoading = false;
                
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectDong]);

    // 검색 클릭
    const onSearch = async() => {
        try {
            headerStore.isLoading = true;
            store.limit = 10;
            await store.getTotalList();
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            info("문제가 발생했습니다");
            return;
        }
    }

    const plusOffset = () => {
        store.limit += 10;
    }

    return (
        <>
            <MobileCheckLnb
                id=""
                className=""
                isLoading = {headerStore.isLoading}
                {...props}
            >
                <div className="form_write">
                    <div className="row col_3ea">
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectRoomType}
                                onChange={(e:any) => store.selectRoomType = e.target.value}
                            >
                                <option value="0">숙소유형</option>
                                {room_types.map((item: any, index: number) => {
                                    if(Number(item) !== 0 && Number(item) !== 7) {
                                        return (<option key={index} value={item}>{ ROOM_TYPE[item] }</option>);
                                    }    
                                })}
                            </select>
                        </div>
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectBuilding}
                                onChange={(e:any) => store.selectBuilding = e.target.value}
                                disabled={!store.selectRoomType || store.buildingList.length === 0 ? true : false}
                            >
                                <option value="">건물명</option>
                                {store.buildingList.map((item: any, idx: number) => (
                                    <option key={idx} value={item.name}>{ item.name }</option>
                                ))}
                            </select>
                        </div>
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectDong}
                                onChange={(e:any) => store.selectDong = e.target.value}
                                disabled={!store.selectRoomType || !store.selectBuilding || store.dongList.length === 0 ? true : false}
                            >
                                <option value="">동</option>
                                {store.dongList.map((item: any, idx: number) => (
                                    <option key={idx} value={item.dong}>{ item.dong }</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col w100">
                            <div className="form_search">
                                <input type="text" className="form_control" placeholder="이름을 입력해주세요" 
                                    value={store.text}
                                    onChange={(e:any) => {store.text = e.target.value}}
                                    onKeyDown={(e: any) => {
                                        if (e.keyCode === 13) onSearch();
                                    }}
                                />
                                <button type="button" className="btn_srch" onClick={() => onSearch()}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                {/* 입숙현황 리스트 */}
                <div className="tab_contents on">  {/* 'on' 추가 시 해당 내용 show */}
                    <div className="group_title">
                        <h4 className="title_contents">입숙현황 리스트</h4>
                    </div>
                    <hr />

                    {store.list.length <= 0 ? (
                        <div className="blank_area">
                            <p>데이터가 없습니다</p>
                        </div>
                    ) : (
                        store.list.map((item: any, idx: number) => (
                            <dl className="lst_checkin">
                                <dt>
                                    <ul>
                                        <li>{item.guest_name}</li>
                                        <li>{ROOM_TYPE[item.room_type]}</li>
                                        <li>{item.guest_cellphone}</li>
                                    </ul>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>
                                            <strong>숙소정보</strong>
                                            <span>{item.room_building_name} {item.room_building_dong} {item.room_name}호</span>
                                        </li>
                                        <li>
                                            <strong>입숙일</strong>
                                            <span>{item.checkin_at ? item.checkin_at : (<>{item.stay_after}<br/>(입숙예정일)</>)}</span>
                                        </li>
                                        <li>
                                            <strong>만료일</strong>
                                            <span>{item.room_type === ROOM_TYPE_CODE.dormitory_room || item.room_type === ROOM_TYPE_CODE.company_room? item.expired_at : ''}</span>
                                        </li>
                                    </ul>
                                </dd>
                            </dl>
                        ))
                    )}
                    
                </div>
                {/* <MoreBtn
                    offset={store.offset}
                    limit={store.limit}
                    fetchData={store.getCheckInList}
                    data={resultList}
                    onClick={(reqGuests: any) => setResultList(resultList.concat(reqGuests))}
                /> */}
                {store.limit < store.totalCount ? (
                    <button className="btn btn_lg btn_outline btn_more" onClick={plusOffset}>
                        더보기<i className="ico_plus"></i>
                    </button>
                ) : ''}
            </MobileCheckLnb>
        </>
    );
});

export default MobileCheckTotal;

import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../components/templetes/default';

const AccountApplication = (props: any) => {
  return (
    <>
      <DefaultTemplate
        id="wrap"
        className=""
        {...props}
      >
        <div className="tab_contents on">
            <h4>계정신청현황 리스트
                <div className="right_area">
                    <select name="" id="" className="form_control" style={{'width': 110+'px'}}>
                        <option value="">근무지</option>
                    </select>
                    <select name="" id="" className="form_control" style={{'width': 110+'px'}}>
                        <option value="">근무지</option>
                    </select>
                    <select name="" id="" className="form_control" style={{'width': 130+'px'}}>
                        <option value="">활성여부</option>
                    </select>
                    <select name="" id="" className="form_control" style={{'width': 110+'px'}}>
                        <option value="">상태</option>
                    </select>
                </div>
            </h4>
            <div className="table_normal_list">
                <table>
                    <caption className="sr_only">숙소유형별 이용자범위 설정 등록현황</caption>
                    <colgroup>
                        {/*<col width="4%"/>*/}
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        {/*<th>
                            <label className="input_check pop_notext">
                                <input type="checkbox" id="" name="check_notice"/>
                                <span className="label_text"></span>
                            </label>
                        </th>*/}
                        <th>관리번호</th>
                        <th>성명</th>
                        <th>근무지</th>
                        <th>소속</th>
                        <th>생년월일</th>
                        <th>성별</th>
                        <th>휴대폰번호</th>
                        <th>활성여부</th>
                        <th>담당업무</th>
                        <th>상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/*데이타 없을 시*/}
                    {/*<tr>
                    <td colspan="" className="blank_area type_white">
                        <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                </tr>*/}

                    <tr>
                        {/*<td>
                            <label className="input_check pop_notext">
                                <input type="checkbox" id="" name="" />
                                <span className="label_text"></span>
                            </label>
                        </td>*/}
                        <td>1321010</td>
                        <td>홍길동</td>
                        <td>남양사업장본동</td>
                        <td>영선</td>
                        <td>801015</td>
                        <td>남</td>
                        <td>010-4721-4654</td>
                        <td><span className="state_type type3">활성</span></td>
                        <td>전기</td>
                        <td><span className="point red">신청대기</span></td>
                    </tr>
                    <tr>
                        <td>1321010</td>
                        <td>홍길동</td>
                        <td>남양사업장본동</td>
                        <td>영선</td>
                        <td>801015</td>
                        <td>남</td>
                        <td>010-4721-4654</td>
                        <td><span className="state_type type5">비활성</span></td>
                        <td>전기</td>
                        <td><span className="point blue">승인요청</span></td>
                    </tr>
                    <tr>
                        <td>1321010</td>
                        <td>홍길동</td>
                        <td>남양사업장본동</td>
                        <td>영선</td>
                        <td>801015</td>
                        <td>남</td>
                        <td>010-4721-4654</td>
                        <td><span className="state_type type3">활성</span></td>
                        <td>전기</td>
                        <td><span className="point green">승인</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>  {/*table*/}
            
                <div className="board_paging">
                <ul>
                    <li><a href="javascript:void(0);" className="paging_first">paging_first</a></li>
                    <li><a href="javascript:void(0);" className="paging_prev">paging_prev</a></li>
                    <li><a href="javascript:void(0);" className="active">1</a></li>
                    <li><a href="javascript:void(0);">2</a></li>
                    <li><a href="javascript:void(0);">3</a></li>
                    <li><a href="javascript:void(0);">4</a></li>
                    <li><a href="javascript:void(0);">5</a></li>
                    <li><a href="javascript:void(0);">6</a></li>
                    <li><a href="javascript:void(0);">7</a></li>
                    <li><a href="javascript:void(0);">8</a></li>
                    <li><a href="javascript:void(0);">9</a></li>
                    <li><a href="javascript:void(0);">10</a></li>
                    <li><a href="javascript:void(0);" className="paging_next">paging_next</a></li>
                    <li><a href="javascript:void(0);" className="paging_last">paging_last</a></li>
                </ul>
            </div>   {/*board_paging*/}

            <div className="submit ac">
                <button className="btn btn_lg btn_outline">관리자등록</button>
                {/*<button className="btn btn_lg btn_darkblue">삭제</button>*/}
            </div>
        </div> {/* tab_content */}

        <div id="pop_regist_admin" className="pop_dim_wrap pop_modal pop_sm" style={{ display: 'none' }}>
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>관리자 등록</h3>
                    <button className="pop_btn_close pop_close">닫기</button>
                </div>
                <div className="contents_wrap">
                    <div className="table_normal_list table_write">
                        <table>
                            <caption className="sr_only">관리자 등록</caption>
                            <colgroup>
                                <col width="125"/>
                                <col width="*"/>
                            </colgroup>
                            <tbody>
                            {/*데이타 없을 시*/}
                            {/*<tr>
                                <td colspan="" className="blank_area type_white">
                                    <p className="txt_blank">데이터가 없습니다.</p>
                                </td>
                            </tr>*/}
                            <tr>
                                <th>관리번호</th>
                                <td><input type="text" className="form_control" placeholder="관리번호를 입력해주세요"/></td>
                            </tr>
                            <tr>
                                <th>성명</th>
                                <td><input type="text" className="form_control" placeholder="성명을 입력해주세요"/></td>
                            </tr>
                            <tr>
                                <th>소속</th>
                                <td><input type="text" className="form_control" placeholder="소속을 입력해주세요"/></td>
                            </tr>
                            <tr>
                                <th>근무지</th>
                                <td>
                                    <select name="" id="" className="form_control">
                                        <option value="">남양사업장</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰번호</th>
                                <td><input type="text" className="form_control" placeholder="휴대폰번호를 입력해주세요"/></td>
                            </tr>
                            <tr>
                                <th>이메일주소</th>
                                <td><input type="text" className="form_control" placeholder="이메일주소를 입력해주세요"/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>  {/*table*/}
                </div>
                <div className="button_wrap btn_length_1">
                    <button type="button" className="btn btn_lg btn_outline pop_close">취소</button>
                    <button type="button" className="btn btn_lg btn_darkblue pop_close">확인</button>
                </div>
            </div>
        </div>

        {/* alert 팝업 */}
        <div id="" className="pop_dim_wrap pop_modal pop_alert" style={{ display: 'none' }}>
            <div className="pop_wrap">
                <button className="pop_btn_close">닫기</button>
                <div className="message_table">
                    <div className="messageWrap">
                        사용이 불가능한 관리번호입니다
                    </div>
                </div>
                <div className="button_wrap">
                    <button type="button" className="btn_pop_default btn_darkblue pop_close">확인</button>
                </div>
            </div>
        </div>
      </DefaultTemplate>
    </>
  );
};

export default AccountApplication;

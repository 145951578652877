import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;

class Core {
  static URL = `${IP}`;

  @apiHandler()
  static async fileUpload<T>(payload: any): Promise<{data: T, status: number}> {
    const PATH = '/core/file/upload/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async downloadByBookingIds(ids: string) {
    const PATH = `/admin/lodge/guest/download/?booking=${ids}`;
    return await axios.get(`${Core.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        responseType: 'blob'
      },
    });
  };
  
  @apiHandler()
  static async getWorkSites(payload: {}) {
    const PATH = '/admin/worksite/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 파일 다운로드
  @apiHandler()
  static async xlsFileDownload(payload: any) {
    const PATH = '/admin/lodge/xlsdownload/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 유지운영 > voc 리스트 다운로드
  @apiHandler()
  static async xlsvoclist(payload: any) {
    const PATH = '/admin/board/xlsvoclist/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload, 
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 파일 다운로드 입숙자 리스트
  @apiHandler()
  static async xlsgeustlist1(payload: any) {
    const PATH = '/admin/lodge/xlsgeustlist1/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 파일 다운로드 입숙 리스트
  @apiHandler()
  static async xlsgeustlist2(payload: any) {
    const PATH = '/admin/lodge/xlsgeustlist2/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

// 파일 다운로드 퇴숙 리스트
@apiHandler()
static async xlsgeustlist3(payload: any) {
  const PATH = '/admin/lodge/xlsgeustlist3/';
  return await axios.get(`${Core.URL}${PATH}`, {
    params : payload,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
    },
  });
}

  // 파일 다운로드 이숙 리스트
  @apiHandler()
  static async xlsgeustlist4(payload: any) {
    const PATH = '/admin/lodge/xlsgeustlist4/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제관리 > 검침량 공통 엑셀 업로드
  @apiHandler()
  static async fileDeductList(payload: any) {
    const PATH = '/core/file/convert/deduct/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제관리 > 당월 공제자 관리 > 남양 (사택) 엑셀 업로드
  @apiHandler()
  static async fileNamyangCompanyDeductList(payload: any) {
    const PATH = '/core/file/convert/namyangcompany/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제관리 > 당월 공제자 관리 > 남양 (기숙사) 엑셀 업로드
  @apiHandler()
  static async fileNamyangDormiDeductList(payload: any) {
    const PATH = '/core/file/convert/namyangdormi/ ';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  
  //공제관리 > 당월 공제자 관리 > 아산 (사택) 엑셀 업로드
  @apiHandler()
  static async fileAsanCompanyDeductList(payload: any) {
    const PATH = '/core/file/convert/asancompany/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제관리 > 당월 공제자 관리 > 아산 (기숙사) 엑셀 업로드
  @apiHandler()
  static async fileAsanDormiDeductList(payload: any) {
    const PATH = '/core/file/convert/asandormi/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제관리 > 당월 공제자 관리 > 전주 (사택)) 엑셀 업로드
  @apiHandler()
  static async fileJeonjuCompanyDeductList(payload: any) {
    const PATH = '/core/file/convert/jeonjucompany/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제관리 > 당월 공제자 관리 > 전주 (기숙사) 엑셀 업로드
  @apiHandler()
  static async fileJeonjuDormiDeductList(payload: any) {
    const PATH = '/core/file/convert/jeonjudormi/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자현황 및 이력 > 강제 입숙 > 기숙사 다건 엑셀 업로드
  @apiHandler()
  static async fileUploadDormitory(payload: any) {
    const PATH = '/core/file/convert/managercheckin/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자현황 및 이력 > 강제 입숙 > 출장자 단체 엑셀 업로드
  @apiHandler()
  static async fileUploadBusinessRoomGroup(payload: any) {
    const PATH = '/core/file/convert/managercheckinb/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 검침량 리스트
  @apiHandler()
  static async xlsDeductMeter(payload: any) {
    const PATH = '/deduct/meterlistexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 울산 - 공제자관리 리스트
  @apiHandler()
  static async xlsDeductUlsandeductList(payload: any) {
    const PATH = '/deduct/ulsandeductlistexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 울산 - 공제자관리(공제내역작성 버튼 클릭 전) 리스트
  @apiHandler()
  static async xlsDeductUlsanutil(payload: any) {
    const PATH = '/deduct/ulsanutilexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 울산 - 공제관리 조회 리스트
  @apiHandler()
  static async xlsDeductibleList(payload: any) {
    const PATH = '/deduct/ulsandeductablelistexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 울산 - 출장자 숙소 리스트
  @apiHandler()
  static async xlsDeuctUlsanBusinessList(payload: any) {
    const PATH = '/deduct/ulsansingleselectexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 남양 - 공제자관리 리스트 (기숙사)
  @apiHandler()
  static async xlsDeductNamyangDormiList(payload: any) {
    const PATH = '/deduct/namyangdormiexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 남양 - 공제자관리 리스트 (사택)
  @apiHandler()
  static async xlsDeductNamyangCompanyList(payload: any) {
    const PATH = '/deduct/namyangcompanyexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 전주 - 공제자관리 리스트 (기숙사)
  @apiHandler()
  static async xlsDeductJeonjuDormiList(payload: any) {
    const PATH = '/deduct/jeonjudormiexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 전주 - 공제자관리 리스트 (사택)
  @apiHandler()
  static async xlsDeductJeonjuCompanyList(payload: any) {
    const PATH = '/deduct/jeonjucompanyexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 아산 - 공제자관리 리스트 (기숙사)
  @apiHandler()
  static async xlsDeductAsanDormiList(payload: any) {
    const PATH = '/deduct/asandormiexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 엑셀 파일 다운로드 아산 - 공제자관리 리스트 (사택)
  @apiHandler()
  static async xlsDeductAsanCompanyList(payload: any) {
    const PATH = '/deduct/asancompanyexcel/?';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // [공통] 첨부된 파일 다운로드
  @apiHandler()
  static async fileDownloadFileSaver(payload : any) {
    const PATH = '/admin/board/filedownload/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // [공통] 첨부된 파일 다운로드
  @apiHandler()
  static async fileDownload(payload : any) {
    const PATH = '/core/file/filedownload/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
      responseType: 'blob'
    });
  }
}

export default Core

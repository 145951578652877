import { useContext, useEffect, useState } from 'react';

import {
  manager
} from '../../apis';

import {
  GetAuthorizePayload,
  CreateAuthorizedPayload
} from '../../apis/manager';
import { MessageContext, MessageImpl } from '../../routes';
import { useStores } from '../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

interface M {
  id: number
  checked: boolean;
}

const useAuthorize = (adminId: number) => {
  const [worksite, setWorksite] = useState<number[]>([]);
  const [roomtype, setRoomtype] = useState<number[]>([]);
  const [menu, setMenu] = useState<number[]>([]);
  const [building, setBuilding] = useState<number[]>([]);
  const {headerStore : headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  useEffect(() => {
    (async () => {
      try {
        headerStore.isLoading = true;
        let { data } = await manager.getAuthorize({ adminId });
        headerStore.isLoading = false;
        setTimeout(function () {
          if(data.save) {
            setWorksite(data.authorized.worksite);
            setRoomtype(data.authorized.roomtype);
            setMenu(data.authorized.menu);
            setBuilding(data.authorized.building);
          }else {
            info("문제가 발생했습니다");
            return;
          }
        }, 250);
      } catch ({status, data}) {
        headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
              info("문제가 발생했습니다");
              return;
            }
            // errorHandler({ status, data }, '마감확정중 오류발생');
          }
    })()
  }, [adminId])

  const submit = async () => {
    const payload: CreateAuthorizedPayload = {
      worksite: worksite,
      roomtype: roomtype,
      menu: menu,
      building: building
    }

    try {
      await manager.createAuthorizedPayload({ adminId }, payload);
    } catch (err) {
      const { status, data } = err;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      //return err;
    }
    
    return false;
  }

  return {
    worksite, roomtype, menu, building,
    setWorksite, setRoomtype, setMenu, setBuilding,
    submit
  }
}

export default useAuthorize;
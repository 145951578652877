import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ROOM_TYPE_CODE, ROOM_TYPE } from '../../../constants';
import { useStores } from '../../../store/mobx';

interface Props {
  id?: string
  className?: string
  onChange: Function
  value: any
}

//숙소타입 탭 버튼
const RoomType =  observer((props: Props) => {
  const {headerStore: headerStore} = useStores();
  const [reload, setReload] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      getRole();
    })();
}, [reload]);

  const getRole = () => {
    headerStore.getAuthRoomRole();
    setReload(true);
  }

  return (
      
      <ul id={props?.id} className={props?.className} >
        {<li className={props.value === headerStore.paramAuthRoomTypeRole || props.value === -1 ? "on" : ""} onClick={() => props.onChange(headerStore.paramAuthRoomTypeRole)} style={{ cursor: 'pointer' }}>
          <a className={props.value === headerStore.paramAuthRoomTypeRole || props.value === -1 ? "on" : ""}>
            전체
          </a>
        </li>}
        {Number(headerStore.roomRoleBusiness_room) ===  Number(ROOM_TYPE_CODE.business_room) ? 
            <li onClick={() => props.onChange(ROOM_TYPE_CODE.business_room)} style={{ cursor: 'pointer' }}>
              <a className={props.value === ROOM_TYPE_CODE.business_room ? "on" : ""}>
                {ROOM_TYPE[ROOM_TYPE_CODE.business_room]}
              </a>
            </li>
            :
            <></>
        }
        {Number(headerStore.roomRoleBusiness_room_group) === Number(ROOM_TYPE_CODE.business_room_group) ? 
          <li onClick={() => props.onChange(ROOM_TYPE_CODE.business_room_group)} style={{ cursor: 'pointer' }}>
            <a className={props.value === ROOM_TYPE_CODE.business_room_group ? "on" : ""}>
              {ROOM_TYPE[ROOM_TYPE_CODE.business_room_group]}
            </a>
          </li>
          :
          <></>
        }
        {Number(headerStore.roomRoleVip_room) === Number(ROOM_TYPE_CODE.vip_room) ? 
          <li onClick={() => props.onChange(ROOM_TYPE_CODE.vip_room)} style={{ cursor: 'pointer' }}>
            <a className={props.value === ROOM_TYPE_CODE.vip_room ? "on" : ""}>
              {ROOM_TYPE[ROOM_TYPE_CODE.vip_room]}
            </a>
          </li>
          :
          <></>
        }
        {Number(headerStore.roomRoleDormitory_room) === Number(ROOM_TYPE_CODE.dormitory_room) ? 
          <li onClick={() => props.onChange(ROOM_TYPE_CODE.dormitory_room)} style={{ cursor: 'pointer' }}>
            <a className={props.value === ROOM_TYPE_CODE.dormitory_room ? "on" : ""}>
              {ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}
            </a>
          </li>
          :
          <></>
        }
        {Number(headerStore.roomRoleCompany_room) === Number(ROOM_TYPE_CODE.company_room) ? 
          <li onClick={() => props.onChange(ROOM_TYPE_CODE.company_room)} style={{ cursor: 'pointer' }}>
            <a className={props.value === ROOM_TYPE_CODE.company_room ? "on" : ""}>
              {ROOM_TYPE[ROOM_TYPE_CODE.company_room]}
            </a>
          </li>
          :
          <></>
        }
        {Number(headerStore.workSite) === 1? (
          <>
          </>
        ) : (
          <>
          {Number(headerStore.roomRoleFamily_room) === Number(ROOM_TYPE_CODE.family_room) ? 
            <li onClick={() => props.onChange(ROOM_TYPE_CODE.family_room)} style={{ cursor: 'pointer' }}>
              <a className={props.value === ROOM_TYPE_CODE.family_room ? "on" : ""}>          
                {ROOM_TYPE[ROOM_TYPE_CODE.family_room]}
              </a>
            </li>
            :
            <></>
          }
          </>
        )}
      </ul>

  );
}); 
export default RoomType;

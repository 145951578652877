import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Lodge {
  static URL = `${IP}`;

  // 입숙신청 - 입숙완료
  @apiHandler()
  static async checkin(id: number, body: any) {
    //const PATH = `/admin/lodge/guest/bulk/checkin/?ids=${ids}`;
    const PATH = `/admin/lodge/booking/${id}/checkin/`;

    return await axios.post(`${Lodge.URL}${PATH}`, body, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용내역 - 퇴숙완료
  @apiHandler()
  static async checkout(ids: any, body: {checkout_at: string, checkout_keyin_at: string}) {
    const PATH = `/admin/lodge/guest/bulk/checkout/?ids=${ids}`;

    return await axios.post(`${Lodge.URL}${PATH}`, body, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 배방 취소
  @apiHandler()
  static async lodgeCancel(id: number) {
    const PATH = `/admin/lodge/booking/${id}/roomcancel/`;
    return await axios.post( `${Lodge.URL}${PATH}`, {}, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 입숙신청 -입숙취소
  @apiHandler()
  static async lodgeCancle(ids: any) {
    const PATH = `/admin/lodge/guest/bulk/cancel/?ids=${ids}`;

    return await axios.patch(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙신청-상세페이지-저장
  @apiHandler()
  static async lodgeUpdate(id: any, payload: any) {
    const PATH = `/admin/lodge/guest/${id}/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 남양의 경우 사택 승인
  @apiHandler()
  static async companyHouseNamyang(guestId: number, payload: any) {
    const PATH = `/admin/lodge/nambooking/${guestId}/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙신청-배방오픈(출장자 단체)
  @apiHandler()
  static async businessGroupAccept(id: number, payload: any) {
    const PATH = `/admin/lodge/business-room-group/${id}/check/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙신청-배방오픈(기숙사 단체)
  @apiHandler()
  static async dormitorySingleAccept(guestId: number, payload: any) {
    const PATH = `/admin/lodge/dormitory/${guestId}/assign/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙신청-배방오픈(사택)
  @apiHandler()
  static async companyHouseSingleAccept(guestId: number, payload: any) {
    const PATH = `/admin/lodge/company-house/${guestId}/assign/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙신청-가족숙소 배방
  @apiHandler()
  static async familyRoomAssign(guestId: number, payload: any) {
    const PATH = `/admin/lodge/family-room/${guestId}/assign/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getGroupGuests(payload: { booking: number }) {
    const PATH = `/admin/lodge/guest-group/`;
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  // 입숙신청-배방완료(영빈관)
  @apiHandler()
  static async vipSingleAssign(bookingId: number, payload: {room: string}) {
    const PATH = `/admin/lodge/vip-room/${bookingId}/assign/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async lodgeBookingPatch(bookingId: number, payload: { files?: number[], guests?: number[] }) {
    const PATH = `/admin/lodge/booking/${bookingId}/`;

    return await axios.patch(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 관리자 신청 반려
  @apiHandler()
  static async lodgeReject(bookingId: number, payload: { description: string, terminated_at: string }) {
    const PATH = `/admin/lodge/booking/${bookingId}/reject/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 관리자 신청 거절
  @apiHandler()
  static async lodgeTerminated(bookingId: number, payload: { description: string}) {
    const PATH = `/admin/lodge/booking/${bookingId}/terminate/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 입숙자 현황 및 이력 입숙자 리스트
  @apiHandler()
  static async lodgeGuests(payload: any | null) {
    const PATH = `/admin/lodge/guest/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙, 이숙, 퇴숙 현황 - 입숙 리스트
  @apiHandler()
  static async lodgeGuestsCheckin(payload: any | null) {
    const PATH = `/admin/lodge/guest/checkin/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙, 이숙, 퇴숙 현황 - 퇴숙 리스트
  @apiHandler()
  static async lodgeGuestsCheckout(payload: any | null) {
    const PATH = `/admin/lodge/guest/checkout/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 퇴숙 신청
  @apiHandler()
  static async lodgeGuestCheckoutApplicant(bookinId: number, payload: any | null) {
    const PATH = `/admin/lodge/booking/${bookinId}/checkout/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 퇴숙 진행코드값 변경
  @apiHandler()
  static async lodgeGuestCheckoutUpdate(payload: any | null) {
    const PATH = `/admin/lodge/checkoutreject/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 강제 이숙(방변경)
  @apiHandler()
  static async lodgeGuestCheckChange(payload: any | null) {
    const PATH = `/admin/lodge/managermove/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 리스트 -> 상세
  @apiHandler()
  static async lodgeGuestsDetail(id:number, payload: any | null) {
    const PATH = `/admin/lodge/guest/${id}/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 상세 -> 이용이력
  @apiHandler()
  static async lodgeGuestsHistory(payload: any, guest: number) {
    const PATH = `/admin/lodge/guest/history/?guest_employee_number=${guest}`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 -> 관리내역 등록
  @apiHandler()
  static async lodgeManagementCreate(payload: any) {
    const PATH = `/admin/lodge/management/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 -> 관리내역 수정
  @apiHandler()
  static async lodgeManagementUpdate(payload: any, guestId: number) {
    const PATH = `/admin/lodge/management/${guestId}/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 -> 관리내역 조회
  @apiHandler()
  static async lodgeManagement(guestId: number) {
    const PATH = `/admin/lodge/management/?guest=${guestId}`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 -> 관리내역 단건 조회
  @apiHandler()
  static async lodgeManagementDetail(mngId: number) {
    const PATH = `/admin/lodge/management/${mngId}/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙자이력 -> 관리내역 삭제
  @apiHandler()
  static async lodgeManagementDelete(guestId: number) {
    const PATH = `/admin/lodge/management/${guestId}/`;

    return await axios.delete(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 심사 및 입숙처리 - 입숙예정일 변경 요청 (사택)
  @apiHandler()
  static async lodgeReshedule(bookingId: number, payload: any) {
    const PATH = `/admin/lodge/company-house/${bookingId}/reschedule/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 심사 및 입숙처리 - 입숙예정일 변경 요청 (기숙사)
  @apiHandler()
  static async lodgeResheduleDormitory(bookingId: number, payload: any) {
    const PATH = `/admin/lodge/dormitory/${bookingId}/reschedule/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 심사 및 입숙처리 - 입숙예정일 승인 (사택) 증빙서류 요청상태로 변경
  @apiHandler()
  static async lodgescheduleCheck(bookingId: number) {
    const PATH = `/admin/lodge/company-house/${bookingId}/check/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 심사 및 입숙처리 - 사택 증빙서류 재요청
  @apiHandler()
  static async lodgeDocumentReCheck(bookingId: number, payload: any) {
    const PATH = `/admin/lodge/company-house/${bookingId}/recheck/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 심사 및 입숙처리 - 입숙예정일 최종승인 (사택)
  @apiHandler()
  static async lodgescheduleApprove(bookingId: number) {
    const PATH = `/admin/lodge/company-house/${bookingId}/approve/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 심사 및 입숙처리 - 입숙예정일 최종승인 (기숙사)
  @apiHandler()
  static async lodgescheduleApproveDormitory(bookingId: number) {
    const PATH = `/admin/lodge/dormitory/${bookingId}/approve/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 단체 배방 진행 완료 - 승인 처리
  @apiHandler()
  static async lodgeGroupscheduleApprove(bookingId: number) {
    const PATH = `/admin/lodge/business-room-group/${bookingId}/approve/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 퇴숙 상세
  @apiHandler()
  static async getGuestCheckoutDetail(id: number){
    const PATH = `/admin/lodge/guest1/${id}/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이숙신청 리스트
  @apiHandler()
  static async getGuestMoveList(payload: any | null){
    const PATH = `/admin/lodge/guest/move/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이숙신청 리스트 상세
  @apiHandler()
  static async getGuestMoveDetail(id: number){
    const PATH = `/admin/lodge/guest/move/${id}/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //이숙승인
  @apiHandler()
  static async moveApplicate(guestId: number, payload: any | null){
    const PATH = `/admin/lodge/guest/move/${guestId}/complete/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //이숙거절
  @apiHandler()
  static async moveReject(guestId: number, payload: any | null){
    const PATH = `/admin/lodge/guest/move/${guestId}/reject/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //관리자 이숙승인
  @apiHandler()
  static async moveApprove(guestId: number, payload: any){
    const PATH = `/admin/lodge/guest/move/${guestId}/approve/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //관리자 1:1 이숙처리
  @apiHandler()
  static async oneToOneMoveAccess(id: number){
    const PATH = `/admin/lodge/movecomplite/${id}/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 영빈관 예약
  @apiHandler()
  static async vipRoomBooked(payload: any){
    const PATH = `/admin/lodge/vip-room/book/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 기숙사 예약
  @apiHandler()
  static async dormitoryBooked(payload: any){
    const PATH = `/admin/lodge/dormitory/book/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 기숙사 다건 예약
  @apiHandler()
  static async dormitoryListBooked(payload: any){
    const PATH = `/admin/lodge/dormitory/books/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사택 예약
  @apiHandler()
  static async companyHouseBooked(payload: any){
    const PATH = `/admin/lodge/company-house/book/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 출장자 개인 예약
  @apiHandler()
  static async businessRoomSingleBooked(payload: any){
    const PATH = `/admin/lodge/business-room-single/book/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 출장자 개인 예약
  @apiHandler()
  static async businessRoomGroupBooked(payload: any){
    const PATH = `/admin/lodge/business-room-group/book/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 출장자 개인 예약
  @apiHandler()
  static async familyRoomBooked(payload: any){
    const PATH = `/admin/lodge/family-room/book/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //입숙자 현황 및 이력 (입숙자 통계)
  @apiHandler()
  static async guestTotalStat(payload: any | null){
    const PATH = `/checkin/checkstats/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //입숙자현황표 리스트
  @apiHandler()
  static async guestStatusList(payload: any | null){
    const PATH = `/checkin/checkdongstats1/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //입숙자현황표 상세 리스트
  @apiHandler()
  static async guestStatusDetail(payload: any | null){
    const PATH = `/checkin/checkinreport/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //정기이숙 설정 리스트
  @apiHandler()
  static async getFixedList(payload: any | null){
    const PATH = `/checkin/movesetlista/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기이숙 설정 등록
  @apiHandler()
  static async setFixedData(payload: any){
    const PATH = `/checkin/regularmove/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기이숙 설정 on/off 수정
  @apiHandler()
  static async fixedDataOnOff(id: number, payload: any){
    const PATH = `/checkin/regularmove/${id}/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  //방상태 변경
  @apiHandler()
  static async roomStatusChange(id: number, payload: any){
    const PATH = `/checkin/roomstatus/${id}/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이숙처리 시 데이터 담기
  @apiHandler()
  static async lodgeMoveCheck(employee_number: number, status: number, payload: any) {
    const PATH = `/member/lodge/delays/?employee_number=${employee_number}&status=${status}`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //SMS 발송
  @apiHandler()
  static async smsSendAPI(payload: any){
    const PATH = `/admin/lodge/sendsms/`

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //입숙, 이숙, 퇴숙 현황 > 현황 및 설정 > 월별 입숙, 이숙, 퇴숙 현황
  @apiHandler()
  static async occupantStatiStics(payload: any | null){
    const PATH = `/admin/lodge/checkmonth/`

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //입숙, 이숙, 퇴숙 현황 > 현황 및 설정 > 관리 현황
  @apiHandler()
  static async managementStatus(payload: any | null){
    const PATH = `/admin/lodge/checkrequest/`

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

 //관리자 업무이관 처리
  @apiHandler()
  static async escalationAccess(payload: any){
    const PATH = `/admin/lodge/escalation/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 기숙사 배방, 우선순위표
  @apiHandler()
  static async getFirstAssign(payload: any){
    const PATH = `/admin/lodge/firstassign/`;

    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 만료일 변경
  @apiHandler()
  static async updateExpireDate(payload: any){
    const PATH = `/admin/lodge/expire/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //공제 조회
  @apiHandler()
  static async getDeductDetail(payload: any){
    const PATH = `/deduct/memberdeducta/`;

    return await axios.get(`${Lodge.URL}${PATH}`,  {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 비고 수정
  @apiHandler()
  static async updateMemo(id: number, payload: any){
    const PATH = `/admin/lodge/guest1/${id}/`;

    return await axios.put(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //입숙 취소
  @apiHandler()
  static async lodgeJudgeCancle(id: number) {
    const PATH = `/admin/lodge/booking/${id}/cancel/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async lodgeRoomCancel(id: number) {
    const PATH = `/admin/lodge/booking/${id}/roomcancel/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Lodge;

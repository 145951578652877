import React from 'react';

const EmptyCell = (props: any) => {
  return (
    <div
      style={{
        width: `${100 / 7}%`,
        height: `4vh`,
      }}
    />
  );
};

export default EmptyCell;

import React, { useState, useEffect, useRef, useContext } from 'react';
import * as apis from '../../../apis'
import { formatByString } from '../../../utils/date';
import _ from 'lodash';
import { ROOM_TYPE } from '../../../constants';
import Pagination from '../../organismas/pagination';
import { useDispatch } from 'react-redux';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { HeaderStore } from '../../../store/mobx/headerStore';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import { isConstructorDeclaration, isTemplateSpan } from 'typescript';




const Room = observer(({ item, floor, change }: { item: any, floor: number, change: Function }) => {
  const dispatch = useDispatch();

  const [id, setId] = useState<number>(item.id);
  const [name, setName] = useState<number>(item.name);
  const [room_type, setRoom_type] = useState<number>(item.room_type);
  const [gender_type, setGender_type] = useState<number>(item.gender_type);
  const [quota, setQuota] = useState<number>(item.quota);
  const {headerStore: headerStore} = useStores();
  const [worksite, setWorksite] = useState<any>(headerStore.workSite);
  const {buildingStore: store} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [saveChk, setSaveChk] = useState<boolean>(false);

  // const saveHandler = async (isCall : boolean) => {
  //   if (!isCall) return;
  const saveHandler = async (item : any) => {
    headerStore.getAuthWorkSiteRole();
    
    const payload = {
      floor,
      name,
      room_type,
      gender_type,
      worksite,
      quota 
    };

    if (id > -1) {
      try {
        headerStore.isLoading = true;
        await apis.build.setRoom(id, payload);
       
        const item = store.item;
        store.item = item;
        let data : any = await store.getFloorRooms();
        // console.log(data);

        headerStore.isLoading = false;
        // setTimeout(function () {
          if(data.save) {
            store.changeChk = true;
    
            setSaveChk(true);
            return;
          }else {
            setSaveChk(false);
            store.changeChk = false;

            if(data.data) {
              info(data.data.code);
              return;
            }
          }
        // }, 100);
      } catch (err) {
        headerStore.isLoading = false;
        // console.log("templetes catch");
        // console.log(err);
        const { status, data } = err;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }else {

          setSaveChk(false);
          store.changeChk = false;

          if(err.data.code) {
            // info("문제가 발생했습니다.");
            info(err.data.code);
            return;
          }else {
            info("문제가 발생했습니다.");
            return;
          }
        }
      }
    } else {
      try {
        headerStore.isLoading = true;
        await apis.build.addRoom(payload);
        const item = store.item;
        
        setId(item.id);
        store.item = item;
        let data = await store.getFloorRooms();

        //change(store.floorRooms);
        headerStore.isLoading = false;
      }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("문제가 발생했습니다.");
        return;
      }
    }
  };

  const deleteHandler = (item : any) => {
    const payload = {
      floor,
      name,
      room_type,
      gender_type,
      worksite,
      quota ,
      id
    };
    //console.log(payload);

    
    if (payload.id > -1) {
      dispatch({
        type: SHOW_CONFIRM_MODAL,
        payload: {
          isModalShow: true,
          title: '확인',
          msg: `${payload.name}호 정보를 삭제하시겠습니까?`,
          action: async () => {
              try {
                headerStore.isLoading = true;
                await apis.build.deleteRoom(payload.id);

                const item = store.item;
                store.item = item;
                let data : any = await store.getFloorRooms();
                headerStore.isLoading = false;
                setTimeout(function () {
                  if(data.save) {
                    store.changeChk = true;
            
                    setSaveChk(true);
                    return;
                  }else {
                    setSaveChk(false);
                    store.changeChk = false;

                    if(data.data) {
                      info(data.data.code);
                      return;
                    }
                  }
                }, 250);
        
              } catch (err) {
                // console.log(err);
                headerStore.isLoading = false;
                const { status, data } = err;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }else {

                  setSaveChk(false);
                  store.changeChk = false;

                  if(err.data) {
                    info(err.data.code);
                    return;
                  }else {
                    info("문제가 발생했습니다.");
                    return;
                  }
                }
              }
            },
          },
        });
    }
  }

  const onChangeValue = (val: string, fn: Function) => {
    fn(val);
  };

  return (
    <ul className="lst_ho">
      <li>
        <input
          type="text"
          className="form_control"
          placeholder="객실번호"
          value={name}
          // onBlur={() => {
          //   saveHandler(item.name !== name) 
          //   setTimeout(() => {
          //     if(!store.changeChk) {
          //       onChangeValue(item.name, setName)
          //     }
          //   }, 100);
          // }}
          onChange={(e: any) => onChangeValue(e.target.value, setName)} />
      </li>
      <li>
        <select
          name=""
          id=""
          className="form_control form_sm"
          value={room_type}
          // onBlur={() => {
          //   saveHandler(item.room_type !== room_type);
          //   setTimeout(() => {
          //     if(!store.changeChk) {
          //       setRoom_type(item.room_type);
          //     }
          //   }, 100);
          // }}
          onChange={(e: any) => {
            setRoom_type(Number(e.target.value))
            //item.room_type = Number(e.target.value);
          }}
          >
          {Object.keys(ROOM_TYPE).map((key: any, index: number) => (
            <option key={index} value={key}>{ROOM_TYPE[key]}</option>
          ))}
        </select>
      </li>
      <li>
        <select
          name=""
          id=""
          className="form_control form_sm"
          value={gender_type}
          // onBlur={() => {
          //   saveHandler(item.gender_type !== gender_type) 
          //   setTimeout(() => {
          //     if(!store.changeChk) {
          //       setGender_type(item.gender_type)
          //     }
          //   }, 100);
          // }}
          onChange={(e: any) => setGender_type(Number(e.target.value))}
        >
            <option value="0">무관</option>
            <option value="1">남</option>
            <option value="2">여</option>
        </select>
      </li>
      <li>
        <select
          name=""
          id=""
          className="form_control form_sm"
          value={quota}
          // onBlur={() => {
          //   saveHandler(item.quota !== quota) 
          //   setTimeout(() => {
          //     if(!store.changeChk) {
          //       setQuota(item.quota);
          //     }
          //   }, 100);
          // }}
          onChange={(e: any) => setQuota(Number(e.target.value))}
        >
            <option value="0">0명</option>
            <option value="1">1명</option>
            <option value="2">2명</option>
            <option value="3">3명</option>
            <option value="4">4명</option>
        </select>
      </li>
      <li>
        <button type="button" className="btn btn_xs btn_darkblue" 
          onClick={() => {
            saveHandler(item);
            setTimeout(() => {
              if(!store.changeChk) {
                // console.log(store.changeChk);
                onChangeValue(item.name, setName);
                setRoom_type(item.room_type);
                setGender_type(item.gender_type);
                setQuota(item.quota);
              }
            }, 150);
          }}>저장</button>
        <button type="button" className="btn btn_xs btn_outline" 
          onClick={() => {
            deleteHandler(item);
            setTimeout(() => {
              if(!store.changeChk) {
                onChangeValue(item.name, setName);
                setRoom_type(item.room_type);
                setGender_type(item.gender_type);
                setQuota(item.quota);
              }
            }, 150);
          }}>삭제</button>
      </li>
    </ul>
  );
});


const Floor = observer(({ item, index, store }: { item: any, index: number, store: any }) => {
  const dispatch = useDispatch();
  const roomView = useRef(null);
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    headerStore.getAuthMenuRole('3-1');
  }, []);

  const setScrollMove = () => {
    if (roomView.current) {
      const t = setTimeout(() => {
        (roomView.current as any).scrollLeft = (roomView.current as any).scrollWidth;// + 50;
        clearTimeout(t);
      }, 200);
    };
  };

  const onAddRoomHandler = () => {
    const index = item.rooms.findIndex((room: any) => room.id < 0);
    
    // let minusIndex = -1;
    // item.rooms.forEach((room: any, idx: number)=>{
    //   if(room.id < 0) {
    //     minusIndex = --minusIndex;
    //   }
    // })
    
     if (index > -1) {
       info("저장 후 추가해주세요");
       return;
     };
    
    item.rooms.push({
      // id: minusIndex,
      id: -1,
      floor: item.id,
      name: '',
      room_type: 0,
      gender_type: 1,
      quota: 1,
      is_available: true,
    });
    
    // console.log(item.rooms);
    setScrollMove();
  };

  const onDeleteRoomHandler = () => {
    // const _rooms = [ ...rooms ];
    if (item.rooms.length > 0) {
      const lastRoom = item.rooms[item.rooms.length -1];
      if (lastRoom.id > -1) {
        dispatch({
          type: SHOW_CONFIRM_MODAL,
          payload: {
            isModalShow: true,
            title: '확인',
            msg: `${lastRoom.name}호 정보를 삭제하시겠습니까?`,
            action: async () => {
              try {
                headerStore.isLoading = true;
                await apis.build.deleteRoom(lastRoom.id);
                item.rooms.splice(item.rooms.length -1, item.rooms.length);
                setScrollMove();
                headerStore.isLoading = false;
              }catch ({ status, data }) {
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                
//                info("문제가 발생했습니다.");
                return;
              }
            },
          },
        });
      } else {
        // _rooms.splice(_rooms.length -1, _rooms.length);
        item.rooms.splice(item.rooms.length -1, item.rooms.length);
        setScrollMove();
      }
      // setRooms(_rooms);
    }
  };

  if (!item) return <></>;

  return (
    <>
      <h4>{item.name}층 ({item.rooms.length})
        <div className="right_area">
          <>
          {headerStore.writeRoleFlag?
            <button type="button" className="btn btn_xs btn_outline" onClick={onAddRoomHandler}>
              <span className="ico_add"></span>객실추가
            </button>
            : <></>
          }
          </>
          {/* <>
          {headerStore.deleteRoleFlag?
            <button type="button" className="btn btn_xs btn_outline" onClick={onDeleteRoomHandler}>
              <span className="ico_del"></span>객실삭제
            </button> 
            :<></>
          }
          </> */}
        </div>
      </h4>
      <div className="group_floor" ref={roomView}>
        <div className="outer_floor">
          <ul className="lst_ho title_row">
            <li>호수</li>
            <li>유형</li>
            <li>성별</li>
            <li>정원</li>
          </ul>
          {item.rooms.map((room: any, index: number) => {
            const onChange = (item: any) => store.floorRooms[index] = item;
            return <Room key={`${item.id}_${room.id}`} floor={item.id} item={room} change={onChange} />;
          })}
        </div>
      </div>
    </>
  );
});

const RoomManager = observer((props: any) => {
  
  const {buildingStore: store} = useStores();
  const item = store.item;

  const selectedItem = async (item: any) => {
    store.item = item;
    await store.getFloorRooms();
  };

  if (store.items.length === 0) return <></>;

  return (
    <>
      <div className={['tab_contents', store.tabIndex === 1 ? 'on' : ''].join(' ')}>
        <h4>건물 목록</h4>
        <div className="table_normal_list event_on box_scroll_x">
          <table>
            <caption className="sr_only">건물 목록</caption>
            <colgroup>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <thead>
            <tr>
              <th>건물명</th>
              <th>동</th>
              <th>층</th>
              <th>세대수</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>수정일</th>
            </tr>
            </thead>
            <tbody>
            {store.items.length === 0 ? (
              <tr>
                <td colSpan={8} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((_item: any, index: number) => (
                <tr key={index} style={{ backgroundColor: item && _item.id === item.id ? '#f7faffff' : '#f7faff00', cursor: 'pointer'}}  onClick={() => selectedItem(_item)}>
                  <td>{_item.name}</td>
                  <td>{_item.dong}</td>
                  <td>총 {_item.floors.length} 층</td>
                  <td>{_.sumBy(_item.floors, 'total_room')} 세대</td>
                  <td>{_item.author}</td>
                  <td>{formatByString(_item.created_at, 'yyyy.MM.dd')}</td>
                  <td>{formatByString(_item.modified_at, 'yyyy.MM.dd')}</td>
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>

        <div className="board_paging">
          <ul>
            <Pagination
              offset={store.offset}
              limit={store.limit}
              totalPage={store.totalPage}
              setOffset={(offset: number) => store.setOffset(offset)} />
          </ul>
        </div>   {/*board_paging*/}

        {store.floorRooms.map((floor: any, index: number) =>
          <Floor key={`${floor.id}_${index}`} index={index} item={floor} store={store} />
        )}

      </div> {/* tab_content */}
    </>
  );
});

export default RoomManager;

import React, {MouseEvent} from 'react';

interface Props {
  onClick(e: any): void
  children: any
  background?: boolean
  backgroundGray?: boolean
  large?: boolean
  middle?: boolean
  small?: boolean
  xsmall?: boolean
  outline?: boolean
}

const Button = (props: Props) => {
  let defaultClass = ['btn']
  
  if (props.background) {
    defaultClass.push('btn_darkblue')
  }

  if (props.backgroundGray) {
    defaultClass.push('btn_gray')
  }

  if (props.outline) {
    defaultClass.push('btn_outline');
  }

  if (props.large) {
    defaultClass.push('btn_lg')
  } else if (props.middle) {
    defaultClass.push('btn_md')
  } else if (props.small) {
    defaultClass.push('btn_sm')
  } else if (props.xsmall) {
    defaultClass.push('btn_xs')
  }

  return (
    <button
      className={defaultClass.join(' ')}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export default Button;

import React, { useState, useEffect } from 'react';
import DefaultTemplate from '../../components/templetes/default';

import useSignupUser from '../../hooks/useSignupUser';
import { GENDER_CODE, WORK_SITE } from '../../constants';

import './index.scss';
import { getEscapeString, INPUT_TYPE } from '../../utils/escapestring';
import { useDispatch } from 'react-redux';
import { auth } from '../../apis';
import { onShowMsg } from '../../store/modules/modal';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';

const Signup = observer((props: any) => {
  // const {
  //   signupUser, onSetSignupUser,
  //   pw1, pw2, setPw1, setPw2,
  //   email, cellphone, setEmail, setCellphone,
  //   setUserDuty, setUserBirthDate, setChangeGender,
  //   verifyCode, setVerifyCode, onAuth, onVerify,
  //   signupSubmit
  // } = useSignupUser();
    
  const [employeeNumber, setEmployeeNumber] = useState<number>();
  const [adminData, setAdminData] = useState<any>([]);
  const [gender, setGender] = useState<number>(1);
  const [birthdate, setBirthdate] = useState<string>('');
  const [assignedTask, setAssignedTask] = useState<string>('');
  const [pw1, setPw1] = useState<string>('');
  const [pw2, setPw2] = useState<string>('');
  const [email, setEmail] = useState<string>("");
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { headerStore : hstore } = useStores();
  let [expire, setExpire] = useState<number>(0); // 만료시간
  const [isVerifyCompleted, setIsVerifyCompleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
      let interval: any;
      hstore.init();
      if (isVerify) {
        interval = setInterval(() => {
          setExpire(expire - 1);
        }, 1000)
        if (expire <= 0) {
          clearInterval(interval);
          //setToken("")
          setIsVerify(false);
          setIsVerifyCompleted(false);
          dispatch(onShowMsg({msg:"인증 시간이 만료되었습니다."}));
        }
      }
      return () => interval && clearInterval(interval);
  }, [expire]);

  // 사번으로 조회
  const onSetSignupUser = async (employeeNumber: number) => {
    if(!employeeNumber) {
      dispatch(onShowMsg({ msg: '관리번호를 입력해주세요' }));
      return;
    }

    try {
      hstore.isLoading = true;
      let { data } = await auth.getTempAdminInfo(employeeNumber.toString());
      if(data === "Not Find"){
        dispatch(onShowMsg({ msg: '조회된 데이터가 없습니다.' }));  
      }else {
        setAdminData(data);
        setGender(data.gender);
        setBirthdate(data.birthdate);
        setAssignedTask(data.assigned_task);
        dispatch(onShowMsg({ msg: '조회를 완료했습니다' }));
      }
      hstore.isLoading = false;
    } catch (err) {
      // console.log(err);
      hstore.isLoading = false;
      dispatch(
        onShowMsg({ msg: '조회중 문제발생' })
      )
    } 
  }

  // 휴대폰 번호로 인증번호 요청
  const onAuth = async (cell: string) => {
    try {
      hstore.isLoading = true;
      //let {data} = await auth.signupAuth({ cellphone })
      let {data} = await auth.signupAuth({ cellphone : cell })
      setToken(data.token);

      dispatch(onShowMsg({ msg: '인증번호 전송을 완료했습니다.' }));
      setIsVerify(true);
      setExpire(180);
      hstore.isLoading = false;
    } catch (err) {
      hstore.isLoading = false;
      // console.log(err);
      setIsVerify(false);
      dispatch(
        onShowMsg({ msg: '인증요청중 문제발생' })
      )
    }
  }
  // 인증번호 검증
  const onVerify = async (cell: string) => {
    if(!verifyCode) {
      dispatch(onShowMsg({ msg: '인증번호를 입력해주세요' }));
      return;
    }

    try {
      hstore.isLoading = true;
      let {data} = await auth.signupVerify({
        number: verifyCode,
        cellphone : cell,
        token,
      })

      dispatch(onShowMsg({ msg: '인증을 완료했습니다.' }));
      setIsVerify(true);
      setIsVerifyCompleted(true);
      hstore.isLoading = false;
    } catch (err) {
      hstore.isLoading = false;
      // console.log(err);
      dispatch(
        onShowMsg({ msg: '인증중 문제발생' })
      )
    }
  }

  // 회원가입
  const signupSubmit = async (cb: Function) => {
    const [isValidate, msg] = validate()
    if (isValidate) {
      dispatch(onShowMsg({ msg: msg }));
      return
    }

    if(!birthdate) {
      dispatch(onShowMsg({ msg: '생년월일을 입력해주세요' }));
      return
    }
    if(!assignedTask) {
      dispatch(onShowMsg({ msg: '담당업무를 입력해주세요' }));
      return
    }

    if (!isVerifyCompleted) {
      dispatch(onShowMsg({msg:"인증을 진행해주세요"}));
      return;
    }

    let payload: any = {
      new_password: pw1,
      new_password2: pw2,
      gender: gender,
      assigned_task: assignedTask,
      cellphone: adminData.cellphone,
      birthdate: birthdate,
      email
      /* employee_number: (signupUser?.employee_number).toString(),
      gender: signupUser?.gender,
      duty: signupUser?.duty,
      birthdate: signupUser?.birthdate,
       */
    }

    try {
      hstore.isLoading = true;
      let data = await auth.signup(adminData.id, payload);
      // console.log(data)
      dispatch(onShowMsg({ msg: '회원가입이 완료되었습니다' }));
      cb();
      hstore.isLoading = false;
    } catch ({status, data}) {
      hstore.isLoading = false;
      // console.log('data==================>',data);
      if(Number(status) === 400){
        if(data.code === 'invalid_new_password'){
          dispatch(onShowMsg({ msg: '비밀번호는 영문, 특수문자(!, @, #, $, %, ^, &, *), 숫자를 조합하여 입력바랍니다. \n비밀번호는 8~20자리까지 입력할 수 있습니다.' }));
          return;
        }else if(data.email[0] === "This field may not be blank."){
          dispatch(onShowMsg({ msg: '이메일을 입력하여 주세요.' }));
          return;
        }
      }
      dispatch(onShowMsg({ msg: '회원가입중 문제발생' }));
    }
  }

  const validate = (): [boolean, string] => {
    let isValidate: boolean = false;
    let msg: string = '';

    if (!adminData.employee_number) {
      isValidate = true;
      msg= '사번(관리번호) 조회를 하지 않아 회원가입을 진행할 수 없습니다.' 
    } 
    //else if (!signupUser?.duty || !signupUser?.birthdate) {
    //  isValidate = true;
    //  msg= '담당업무 OR 생년월일을 입력하지 않았습니다.' 
    //} 
    else if ((pw1.length < 8)) {
      isValidate = true;
      msg= '비밀번호는 최소 8자리 입니다.' 
    } 
    /*else if (/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/g.test(pw1)) {
      isValidate = true;
      msg= '비밀번호는 8~20자 영문, 특수문자, 숫자 중 세가지를 조합해야햡니다.' 
    }*/
     else if (!pw1.length || !pw2.length || pw1 !== pw2) {
      isValidate = true;
      msg= '비밀번호를 입력하지 않았거나 비밀번호가 일치하지 않습니다.' 
    } else if (!isVerify) {
      isValidate = true;
      msg= '인증을 진행하지 않았습니다.' 
    }
    /* else if (signupUser?.user.birthdate.length !== 6) {
      isValidate = true;
      msg= '생년월인은 6자리입니다'
    } else if (!signupUser?.user.gender) {
      isValidate = true;
      msg= '성별을 선택해야 합니다.' 
    } */

    return [isValidate, msg]
  }

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={isLoading}
      isNotLoginRequire
      {...props}
    >
      <div className="row clearfix">
        <div className="col col_left group_title">
          <h2>회원가입</h2>
          <p className="desc">가입 후 관리자 기능을 이용하실 수 있습니다</p>
          <div className="bg_img"></div>
        </div>
        <div className="col col_right">
          <h4 className="mt0">
            기본 정보 입력
            <div className="right_area">
              <ul className="lst_help">
                <li>모든 입력사항 필수 입력 입니다</li>
              </ul>
            </div>
          </h4>
          <div className="form_write clearfix">
            <div className="row">
              <div className="col">
                <label htmlFor="input_phonenumber2" className="title_form">관리번호</label>
                <p className="form_phone">
                  <input type="text" id="input_phonenumber2" className="form_control form_sm" placeholder="관리번호를 입력해주세요" 
                    value={employeeNumber} 
                    maxLength={7}
                    onChange={(e: any) => {
                        e.target.value = getEscapeString(e.target.value, INPUT_TYPE.employee);
                        setEmployeeNumber(e.target.value);
                    }}
                  />
                  <button className="btn btn_sm btn_gray" onClick={ () => onSetSignupUser(employeeNumber || 0)}>적용</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table_normal_list table_write bd0">
        <table>
          <caption className="sr_only">관리내역</caption>
          <colgroup>
            <col width="15%" />
            <col width="*" />
            <col width="15%" />
            <col width="*" />
            <col width="15%" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>관리번호(아이디)</th>
              <td><input type="text" className="form_control" disabled value={adminData.employee_number} /></td>
              <th>성명</th>
              <td><input type="text" className="form_control" disabled value={adminData.name} /></td>
              <th>생년월일</th>
              <td>
                <input type="text" className="form_control"
                  maxLength={6}
                  value={birthdate}
                  onChange={(e:any) => setBirthdate(getEscapeString(e.target.value, INPUT_TYPE.phoneNum))} 
                />
              </td>
            </tr>
            <tr>
              <th>성별</th>
              <td>
                <label className="input_radio" htmlFor="sex_user_choice1">
                  <input type="radio" id="sex_user_choice1" name="sex" 
                    checked={gender === GENDER_CODE.male} 
                    onChange={()=> {setGender(GENDER_CODE.male)}}
                  />
                  <span className="label_text">남자</span>
                </label>
                <label className="input_radio" htmlFor="sex_user_choice2" >
                  <input type="radio" id="sex_user_choice2" name="sex" 
                    checked={gender === GENDER_CODE.female} 
                    onChange={()=> {setGender(GENDER_CODE.female)}}
                  />
                  <span className="label_text">여자</span>
                </label>
              </td>
              <th>휴대폰번호</th>
              <td><input type="text" className="form_control" disabled value={adminData.cellphone} /></td>
              <th>소속회사</th>
              <td><input type="text" className="form_control" disabled value={adminData.company} /></td>
            </tr>
            <tr>
              <th>근무지</th>
              <td><input type="text" className="form_control" disabled value={WORK_SITE[adminData.work_site_id]} /></td>
              <th>담당업무</th>
              <td className="br0">
                <input type="text" className="form_control" 
                  maxLength={20}
                  value={assignedTask}
                  onChange={(e:any) => setAssignedTask(e.target.value)} 
                />
              </td>
              <td colSpan={2} className="blank"></td>
            </tr>
          </tbody>
        </table>
      </div>  {/*table*/}

      <h4>추가 정보 입력</h4>
      <div className="form_write clearfix">
        <div className="row">
          <div className="col">
            <label htmlFor="input_pw" className="title_form">비밀번호<span className="emp_red"></span></label>
            <input type="password" id="input_pw" className="form_control form_sm"
              maxLength={20}
              placeholder="비밀번호를 입력해주세요" 
              value={pw1} 
              onChange={(e: any) => setPw1(e.target.value)} 
            />
          </div>
          <div className="col">
            <label htmlFor="input_pw2" className="title_form">비밀번호 확인<span className="emp_red"></span></label>
            <input type="password" id="input_pw2" className="form_control form_sm" 
              maxLength={20}
              placeholder="비밀번호를 다시 한번 입력해주세요" 
              value={pw2} 
              onChange={(e: any) => setPw2(e.target.value)} 
            />
          </div>
        </div>

        <ul className="lst_warning">
          <li>비밀번호는 8~20자로 영문 , 특수문자, 숫자 중 세 가지를 조합하여 입력해주세요. 영문 대문자와 소문자를 구별해주세요</li>
        </ul>

        <div className="row">
          <div className="col">
            <label htmlFor="input_phonenumber" className="title_form">휴대폰 번호</label>
            <div className="">
              <input type="text" id="input_phonenumber" className="form_control form_sm" placeholder="숫자만 입력해주세요" 
                value={adminData.cellphone} 
                //onChange={(e: any) => setCellphone(e.target.value)} 
                disabled
              />
            </div>
          </div>

          <div className="col">
            <label htmlFor="input_email" className="title_form">이메일 주소</label>
            <div className="">
              <input type="text" id="input_email" className="form_control form_sm" placeholder="메일주소를 입력해주세요" value={email} onChange={(e: any) => setEmail(e.target.value)} />
            </div>
          </div>
        </div>

        <ul className="lst_warning">
          <li>핸드폰 번호 또는 이메일이 다를 경우, HMC 인사 DB에서 해당 정보를 변경하시면 본 플랫폼에서도 자동으로 변경됩니다</li>
        </ul>
      </div>

      <h4>인증하기</h4>

      <div className="form_write clearfix">
        <div className="row">
          <div className="col">
            <label htmlFor="input_phonenumber2" className="title_form">휴대폰 번호 인증</label>
            <p className="form_phone">
              <input type="text" id="input_phonenumber2" className="form_control form_sm" 
                placeholder="숫자만 입력해주세요" 
                value={adminData.cellphone} 
                disabled 
              />
              <button className="btn btn_sm btn_outline" onClick={()=> onAuth(adminData.cellphone)}>인증하기</button>
            </p>
            <p className="form_phone mt10">
              <input type="text" id="input_phonenumber2" className="form_control form_sm" placeholder="인증번호를 입력해주세요" value={verifyCode} onChange={(e: any) => setVerifyCode(e.target.value)} />
              <span className="time">{expire && Math.floor(expire / 60)}:{expire && expire % 60}</span>
              <button className="btn btn_sm btn_outline" onClick={() => onVerify(adminData.cellphone)}>확인</button>
            </p>
          </div>
        </div>
      </div>

      <div className="submit ac">
        <button className="btn btn_lg btn_outline" onClick={() => props.history.replace('/')}>취소</button>
        <button className="btn btn_lg btn_darkblue" onClick={() => signupSubmit(() => props.history.replace('/'))}>완료</button>
      </div>
    </DefaultTemplate>
  );
});

export default Signup;
import React, { useContext, useState, useEffect } from 'react';
import { voc as api } from '../../../apis';
import { observer } from 'mobx-react';
import _, { map } from 'lodash';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import '../mb_common.scss';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const ItemRenderer = observer((props: any) => {
  const [isChecked, setIsChecked] = useState<boolean>(props.item.isChecked);
  const onChangeHandler = (val: boolean) => {
    setIsChecked(val);
    props.onChangeState(val);
  }

  return (
    <li className="active">
      <p>
        <label className="input_check pop_notext">
          <input type="checkbox" id="" name="" checked={isChecked} onClick={() => onChangeHandler(!isChecked)} />
          <span className="label_text"></span>
        </label>
      </p>
      <p>현대자동차</p>
      <p>{props.item.name}</p>
      <p>{props.item.employee_number}</p>
    </li>
  );
})

const MobileChoiceManager = observer ((props: any) => {
  const [sources, setSources] = useState<any[]>([]);
  const [targets, setTargets] = useState<any[]>([]);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const {headerStore: headerStore} = useStores();
  const [contents, setContents] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (props.isOpen) {
        await fetchItems();
        setTargets(props.managers.map((manager: any, index: number) => ({isChecked: false, ...manager})));
      }
    })();
  }, [props.isOpen]);

  const fetchItems = async () => {

    const payload = {
      work_site : headerStore.workSite,
    }

    try{
      headerStore.isLoading = true;
      const { data } = await api.getManages(payload);
      const tmpData: any[] = data.map((item: any) => ({isChecked: false, ...item}));
      props.managers.forEach((manager: any) => {
        const index = tmpData.findIndex((item: any) => item.id === manager.id);
        tmpData.splice(index, 1);
      });
      setSources(tmpData);
      headerStore.isLoading = false;
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  };

  const onRightHandler = () => {
    const tmpSources = sources.filter(item => item.isChecked === true)
      .map(item => {
        const tmp = {...item};
        tmp.isChecked = false;
        return tmp;
      });
    const tmpSources2 = [...sources.filter(item => item.isChecked !== true)];
    const tmpTargets = [...targets, ...tmpSources];
    setTargets(_.sortBy(tmpTargets, 'name'));
    setSources(_.sortBy(tmpSources2, 'name'));
  };

  const onLeftHandler = () => {
    const tmpTargets = targets.filter(item => item.isChecked === true)
      .map(item => {
        const tmp = {...item};
        tmp.isChecked = false;
        return tmp;
      });
    const tmpTargets2 = targets.filter(item => item.isChecked !== true);
    const tmpSources = [...sources, ...tmpTargets];
    setSources([..._.sortBy(tmpSources, 'name')]);
    setTargets([..._.sortBy(tmpTargets2, 'name')]);
  };

  const close = async () => {
    setTargets([]);
    setSources([]);
    props.setIsOpen(false);
  };

  const onApply = async () => {
    if (targets.length === 0) {
      info('담당자를 선택하세요.');
      return;
    }
    if(!contents || contents === '' || contents === null) {
      info('이관사유를 입력해주세요');
      return false;
    }

    await props.onUpdateManagers(targets, contents);
    close();
  };

  return (
    <div
      id="pop_set_manager"
      className="pop_modal"
      style={{ display: props.isOpen ? 'block' : 'none' }}
    >
      <div className="pop_wrap">
        <div className="title_wrap">
            {/* <button className="pop_btn_prev">이전 페이지</button> */}
            <h3>담당자 설정</h3>
            <button className="pop_btn_close pop_close" onClick={close}>닫기</button>
        </div>

        <div className="contents_wrap">
          <div className="group_title"><h4 className="title_contents">전체 담당자</h4></div>
          <div className="detail_list">
            <div className="col w100">
              <ul className="col_content">
                {sources.map((source, index) => (
                  <ItemRenderer key={source.id} item={source} onChangeState={(val: boolean) => source.isChecked = val} setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}/>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="btn_wrap ac">
              <button className="btn btn_sm btn_outline btn_prev" onClick={onLeftHandler}>이동</button>
              <button className="btn btn_sm btn_outline btn_next" onClick={onRightHandler}>이동</button>
          </div> 

          <div className="group_title"><h4 className="title_contents">담당자</h4></div>
          <div className="detail_list">
            <div className="col w100">
              <ul className="col_content">
                {targets.map((target, index) => (
                  <ItemRenderer key={target.id} item={target} onChangeState={(val: boolean) => target.isChecked = val} setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}/>
                ))}
              </ul>
            </div>
          </div>

          <div className="group_title"><h4 className="title_contents">사유</h4></div>
          <div className="detail_list">
            <textarea className="form_control" value={contents} onChange={(e: any) => setContents(e.target.value)}></textarea>
          </div>
        </div>
        
        <div className="button_wrap">
          <button type="button" className="btn_md btn_darkblue" onClick={onApply}>적용</button>
        </div>
      </div>
    </div>
  )
});

export default MobileChoiceManager;
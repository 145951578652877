import React, { useEffect, useContext, useState } from 'react';
import { lodge } from '../../../apis';
import SelectBuilding from '../../atoms/select/buildings';
import SelectFloor from '../../atoms/select/floor';
import Floors from '../../molecules/floor';
import { ROOM_TYPE_CODE, GUEST_TYPE, LODGE_STATUS_CODE } from '../../../constants';
import _ from 'lodash';
import './backroom.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { MessageContext, MessageImpl } from '../../../routes';
import { getWorkSitePhoneNum } from '../../../utils/common';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

// 가족숙소 배방
const FamilyBackroom = observer((props: any) => {
  const {applicantJudgeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const { info }: MessageImpl = useContext(MessageContext);
  const [badIdx, setSelectBadIdx] = useState<number>();

  useEffect(() => {
    if (!store.isFamilyPop) return;
    if (!store.selectRow.id) return;
    
    store.workSite = store.selectRow.room_work_site;

    (async () => {
      if(store.openRoomInfoPop){    // 배방 확인 팝업일 경우
        await store.getOneBuildInfo();
      }else {
        // 건물, 층 select 정보 가져오기
        await store.getBuildsInfo();
        await store.getFloorsInfo();
      }
    })();
  }, [store.isFamilyPop])

  // 배방시 room 칸 클릭
  const onRoomClickHandlerToSelect = (selectedRoom: any, idx: number) => {
    let tempRoom: any = _.cloneDeep(store.room);
    tempRoom = {}
    tempRoom[selectedRoom.id] = selectedRoom;
    tempRoom['room'] = selectedRoom;
    store.room = tempRoom;    // 선택한 방 담기
    
    //setSelectBadIdx(idx);   // 선택한 방이 2인 이상인 경우때문에 badIdx 담기
  }

  // 선택 풀기
  const onRoomClickHandlerToUnSelect = (room: any) => {
    store.room = [];
  }

  // 배방 등록
  const onCompleteHandler = async () => {
    if(Object.keys(store.room).length <= 0){
      info('배방정보를 입력해주세요');
      return false;
    }

    try { 
      if(!store.manageCheckin && !store.manageCheckChange) {
        headerStore.isLoading = true;
        let { data } = await lodge.familyRoomAssign(store.selectRow.id, {room: Object.keys(store.room)[0]});
        
        // 배방 완료 문자 발송
        /*
        let guestsCellPhone = store.selectRow.guestList.map((item: any) => {
          return item.guest_cellphone;
        });
        
        let smsPayload: any = {
          cellphone : guestsCellPhone.join(','),
          message : '서류승인이 완료되었습니다. ( 메뉴 : 마이페이지>>신청관리 ) 배방 정보 : '+store.building.name + store.building.dong + store.room['room'].name
                  + '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(store.selectRow.room_work_site, store.selectRow.room_type)
        }
        */

        //console.log(store.selectRow.id);
        //await store.getItem(store.selectRow.id);
      
        console.log(props.applicant_cellphone);
        //let applicant_cellphone = store.applicant.applicant_cellphone;
        let smsPayload: any = {
          cellphone : props.applicant_cellphone,
          message : '축하드립니다.'
                  + '\nR&D STAY 당첨되어 배정이 완료되었습니다.'
                  + '\n(메뉴:마이페이지>신청관리)'
                  + '\n배방정보 : ' + store.building.name + store.building.dong + store.room['room'].name
                  + '\n자세한 숙소 이용은 사내 메일로 발송예정입니다.'
                  + '\n\nR&D STAY 사용 일정이 취소되신다면,'
                  + '\n(메뉴 : 마이페이지 > 신청관리 > R&D STAY > 이용자 신청 취소)'
                  + '\n\n7일전 취소시 : 재신청 가능'
                  + '\n- 6일 ~ 하루전 취소시 : 당해년도 사용한 것으로 간주'
                  + '\n- 당일취소 및 미통보시 : 향후 1년간 재신청 불가'
                  + '\n\n기타 문의는 관리사무소로 연락주세요'
                  + '\n031-356-4286'
        }
        const { data: sms } = await lodge.smsSendAPI(smsPayload);

        headerStore.isLoading = false;
        store.isFamilyPop = false;
        info('배방이 완료되었습니다.');
        store.roomInit();
        store.getItems();
      }else if(store.manageCheckChange) {   // 강제 방변경
        let payload = {
          guest_id: store.selectRow.guest_id,
          room_id: Object.keys(store.room)[0],
          booking_id: store.selectRow.booking_id
        }

        headerStore.isLoading = true;
        let { data } = await lodge.lodgeGuestCheckChange(payload);

        headerStore.isLoading = false;
        info('방변경이 완료되었습니다.');
        store.isFamilyPop = false;
        store.roomInit();
        props.moveComplete();
      }
    } catch ({ data, status }) {
      headerStore.isLoading = false;
      // console.log(data, status);

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("배방 중 문제가 발생했습니다.");
      return;
    }
  }
  
  // 이용중 방
  const isDisable = (room: any, idx: number) => {
    if(store.openRoomInfoPop) {
      return ((!room.roomstatus[idx] && room.roomstatus[idx] && (room.roomstatus.filter((r:any) => r.guest_id || r.status !== 1).length > 0)) 
            || room.room_type !== ROOM_TYPE_CODE.family_room 
            || (room.roomstatus[idx].guest && room.roomstatus[idx].guest.status !== LODGE_STATUS_CODE.checkout && room.roomstatus[idx].guest.id !== store.selectRow.guestIds[0])
            )
    }
    return ((!room.roomstatus[idx] && room.roomstatus[idx] && (room.roomstatus.filter((r:any) => r.guest_id || r.status !== 1).length > 0)) 
            || room.room_type !== ROOM_TYPE_CODE.family_room || (room.roomstatus[idx].guest && room.roomstatus[idx].guest.status !== LODGE_STATUS_CODE.checkout)
            );
  }

  // 방 선택 표시
  const isSelect = (room: any, idx: number) => {
    if(store.openRoomInfoPop) {
      return (store.selectRow.room === room.id);
    }
    //return (store.room[room.id] && badIdx === idx)
    return store.room[room.id]
  }
  
  if (!store.isFamilyPop) return<></>;
  
  return (
    <div id="pop_assign_roomtype" className="pop_dim_wrap pop_modal pop_lg" style={{display: store.isFamilyPop? 'block' : 'none'}}>
        <div className="pop_wrap">
            <div className="title_wrap">
                <h3>배방</h3>
                <button className="pop_btn_close pop_close" onClick={() => {store.roomInit(); store.isFamilyPop = false}}>닫기</button>
            </div>
            <div className="contents_wrap">
                <div className="title_contents bd0">
                    <h3>이용자정보</h3>
                </div>
                {store.selectRow && Object.keys(store.selectRow.guestList).length > 0 ? (store.selectRow.guestList.map((guest: any, idx: number) => (
                  <div className="table_normal_list table_write" key={idx}>
                      <table>
                          <caption className="sr_only">이용자정보 상세</caption>
                          <colgroup>
                              <col width="160"/>
                              <col width="*"/>
                              <col width="160"/>
                              <col width="*"/>
                              <col width="160"/>
                              <col width="*"/>
                          </colgroup>
                          <tbody>
                          <tr>
                              <th>소속회사유형</th>
                              <td>{GUEST_TYPE[guest.guest_type]}</td>
                              <th>성명</th>
                              <td>{guest.guest_name}</td>
                              <th>성별</th>
                              <td>{guest.guest_gender === 1 ? '남' : '여'}</td>
                          </tr>
                          <tr>
                              <th>휴대폰</th>
                              <td>{guest.guest_cellphone}</td>
                              <th>신청자와의 관계</th>
                              <td>{guest.guest_relation}</td>
                              <th>차량번호</th>
                              <td>{guest.guest_car_number1}</td>
                          </tr>
                          <tr>
                              <th>배방정보</th>
                              {store.openRoomInfoPop ? (
                                <td colSpan={5}>{store.selectRow.room_building_name} {store.selectRow.room_building_dong} {store.selectRow.room_name}호</td>
                              ):(
                                <td colSpan={5}>{store.building && store.building.name && store.building.dong? store.building.name +' '+ store.building.dong :''} {store.room['room'] ? store.room['room'].name +'호' : ''}</td>
                              )}
                          </tr>
                          </tbody>
                      </table>
                  </div> 
                ))) : ''}

                <div className="title_contents bd0">
                    <h3>배방정보 확인 {!store.openRoomInfoPop && store.floors.length <= 0? (<span style={{color:'#f1615d'}}>선택한 건물에 배방가능한 방이 없습니다.</span>) : ''}</h3>
                    <div className="right_area">
                      {store.openRoomInfoPop ? '' : (
                        <>
                        <SelectBuilding
                          changeBuildHandler={(e: any) => {
                            store.selectFloor = 0;
                            store.building = store.buildings[parseInt(e.target.value)];
                            store.getFloorsInfo();
                          }}
                          building={store.building}
                          buildings={store.buildings}
                          dongs={store.dongs}
                        />
                        <SelectFloor floors={store.floors} value={store.selectFloor}
                        onChangeFloor={(e:any) => {
                            store.selectFloor = e.target.value; //store.room = store.floors[e.target.value]
                          }} />
                        {store.floors.length <= 0? (<li style={{color:'#f1615d'}}>선택한 건물에 배방가능한 방이 없습니다.</li>) : ''}
                        </>
                      )}
                    </div>
                </div>

                <div className="box_scroll_y">
                    <Floors
                      floors={store.floors}
                      selectRoom={store.room}
                      selectFloor={store.selectFloor}
                      onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                      onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                      isUsable={() => { }}
                      isDisable={isDisable}
                      isSelect={isSelect}
                    />
                </div>
            </div>
            <div className="button_wrap">
              {store.openRoomInfoPop ? (
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={() => {store.roomInit(); store.isFamilyPop = false}}>확인</button>
              ) :(
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={onCompleteHandler}>완료</button>
              )}
            </div>
        </div>
    </div>
  )
});

export default FamilyBackroom;

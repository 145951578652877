// 이용신청 inputbox 정규식

export const INPUT_TYPE: any = {
    'carNum': /[^0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,      // 차량번호
    'department': /[^0-9|a-zA-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,  // 소속부서
    'employeeNum': /[^0-9|a-zA-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, // 사번
    'name': /[^a-zA-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,           // 성명
    'phoneNum': /[^0-9]/g,                          // 휴대폰 번호
    'office':/[^0-9|a-zA-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,     // 회사명
    'employee': /[^a-zA-Z|0-9]/g,                  // 사번 - 숫자, 영어만
}

//케이스에 맞는 값 포맷
export function getEscapeString(str: string, type: string) {
    let regex  = type;
    let tempStr: string = str.replace(regex, '');
    return tempStr;
}
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import LocusTemplate from '../../pages/locusTotal';
import Pagination from '../../components/organismas/pagination';

import { ROOM_TYPE_CODE, ROOM_TYPE } from '../../constants';
import { formatByString_YYYYMMDD } from '../../utils/date';
import { pledge } from '../../apis';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { MessageContext, MessageImpl } from '../../routes';
import { parserEscape } from '../../utils/common';
import { core } from '../../apis';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { refStructEnhancer } from 'mobx/dist/internal';

const ACTION_STATUS:{[key: string]: string} = {
  update: "업데이트",
  partial_update: "업데이트",
  create: "추가",
  1: "최초생성",
}

const Write = observer((props: any) => {
  const [contents, setContents] = useState<string>("");
  const {headerStore: headerStore} = useStores();
  const {pledgeStore: pledgeStore} = useStores();
  const [file, setFile] = useState<any>(null);
  const [filePath, setFilePath] = useState<string>('');
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [roomType, setRoomType] = useState<number>();

  const editorRef = useRef<any>(null);
  const editorInputRef = useRef<any>(null);
  const [itemContents, setItemContents] = useState<string>(contents);

  useEffect(() => {
    headerStore.getAuthRoomRole();
    if(roomType === undefined) {
      let room : any = headerStore.paramAuthRoomTypeRole[0]; 
      setRoomType(Number(room));
    }
    setRoomType(props.roomType);
    setContents(props?.pledge?.contents || props?.pledge?.data?.contents || '');
    setFilePath(props?.pledge?.pledge_file || '');
    // console.log('filePath',filePath);
    // console.log('pledge', props.pledge.pledge_file);
    headerStore.getAuthMenuRole('1-1');
  }, [props.pledge, headerStore.workSite])
  
  // console.log('file',file);
  const init = () => {
    setContents('');
    setFile(null);
    setFilePath('');
  }

  //입숙서약서 등록
  const enRollmentPatchHandler = async() => {
    setContents(itemContents);
    let form = new FormData()
    form.append('contents', parserEscape(itemContents));
    form.append('work_site',headerStore.workSite);
    form.append('room_type',props.roomType);

    if (file) {
      form.append('pledge_file', file)
    }
    
    if(Number(headerStore.workSite) === 1 && Number(roomType) === 4){
      info('울산공장은 영빈관 입숙서약서 를(을) 등록 하실 수 없습니다.');
      return false; 
    }
    if(Number(headerStore.workSite) === 5 && Number(roomType) === 4){
      info('마북은 영빈관 입숙서약서 를(을) 등록 하실 수 없습니다.');
      return false; 
    }
    if(Number(headerStore.workSite) === 6 && Number(roomType) === 4){
      info('의왕은 영빈관 입숙서약서 를(을) 등록 하실 수 없습니다.');
      return false; 
    }
    if(Number(headerStore.workSite) === 7 && Number(roomType) === 4){
      info('기타는 영빈관 입숙서약서 를(을) 등록 하실 수 없습니다.');
      return false; 
    }
    if(Number(headerStore.workSite) === 1 && Number(roomType) === 1){
      info('울산공장은 가족숙소를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false; 
    }
    if(Number(headerStore.workSite) === 1 && Number(roomType) === 3){
      info('울산공장은 사택을 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false; 
    }
    if(Number(headerStore.workSite) === 3 && Number(roomType) === 1){
      info('전주공장은 가족숙소를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 5 && Number(roomType) === 3){
      info('마북은 사택을 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 5 && Number(roomType) === 1){
      info('마북은 가족숙소를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 5 && Number(roomType) === 3){
      info('의왕은 사택을 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 5 && Number(roomType) === 1){
      info('의왕은 가족숙소를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 7 && Number(roomType) === 3){
      info('기타사업장은 사택을 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 7 && Number(roomType) === 1){
      info('기타사업장은 가족숙소를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 2 && Number(roomType) === 5){
      info('남양사업장은 출장사 숙소(개인)를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 4 && Number(roomType) === 6){
      info('아산공장 출장자 숙소(단체)를 이용 할 수 없기에 등록 하실 수 없습니다.');
      return false;
    }
    if(Number(headerStore.workSite) === 4 && Number(roomType) === 1){
      info('아산공장 가족숙소는 기숙사 입숙서약서를 통해 확인 하실수 있습니다.');
      return false;
    }

    try {
      headerStore.isLoading = true;
      let { data } = await pledge.createPledge(form);
      info('등록이 완료되었습니다');
      props.complete();
      init();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  }

  //입숙서약서 수정
  const onPatchHandler = async () => {
    setContents(itemContents);
    let form = new FormData()
    form.append('contents', parserEscape(itemContents));
    form.append('work_site',headerStore.workSite);
    form.append('room_type',props.roomType);

    if (file) {
      form.append('pledge_file', file)
    }

    if(itemContents === props.pledge.contents && !file){
      info('변경된 항목이 없습니다.');
      return false;
    }

    try {
      headerStore.isLoading = true;
      let { data } = await pledge.patchPledge(props.pledge.id, form);
      info('수정이 완료되었습니다');
      props.complete();
      init();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  }

  // 입력취소 버튼
  const onWriteCancelHandler = () => {
    props.setReload(!props.reload);
    props.complete(false);
    init();
  }

  return (
    <>
      <div className="table_normal_list">
        <table>
          <caption className="sr_only">숙소이용안내 등록</caption>
          <colgroup>
            <col width="140" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>내용</th>
              <td>
              <Editor
                  id="tiny_editor_1"
                  apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={parserEscape(contents)}
                  onEditorChange={(e) => {
                    setItemContents(e);
                  }}
                  init={{
                    selector : '#tiny_editor_1',
                    width: 1000,
                    height: 500,
                    resize: 'both',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'image code'
                      //'image media link tinydrive code imagetools'
                    ],
                    toolbar:
                      'undo redo | fontselect fontsizeselect | ' +
                      'bold italic | forecolor backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_file_types : 'gif,jpg,jpeg,png',
                    file_picker_callback : function(callback, value, meta) {
                      if(meta.filetype == 'image') {
                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        editorInputRef.current = input;
                        (editorInputRef.current as any).click();
                        
                        input.onchange = function () {
                          let file : any = input.files;
                          file = file[0];

                          if (file.size >= 10485760) {        
                            alert(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
                            return;
                          }else {
                            let reader = new FileReader();

                            reader.onload = async function (e : any) {
                              let editorImageForm = new FormData();
                              editorImageForm.append('path', file);
                              let reusltData : any = null;
                              try {
                                headerStore.isLoading = true;
                                reusltData = await core.fileUpload<{
                                  id?: number;
                                  path?: string;
                                  name?: string;
                                  category?: string | number;
                                }>(editorImageForm);
                                headerStore.isLoading = false;
                                
                                callback(`${process.env.REACT_APP_MEDIA}/`+reusltData.data.path, {alt : file.name});
                              } catch (error) {
                                // console.log(error);
                                headerStore.isLoading = false;

                                const { status, data } = error;

                                if (status === 401) {
                                  if (!window.localStorage.getItem("isRetryLogin")) {
                                    window.localStorage.setItem("isRetryLogin", "retry");
                                  }
                                  window.location.href = '/';
                                  return ;
                                } else if (status === 403) {
                                  alert('접근권한이 없습니다.');
                                  return ;
                                }else if ( status === 500 ) {
                                  window.location.href = '/500';
                                  return;
                                }
                          
                              }

                            };
                            
                            reader.readAsDataURL(file);

                          }
                          editorInputRef.current = null;
                          input.remove();
                        };
                      }
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>첨부파일</th>
              <td>
                <div className="wrap_file w100">
                  <input id="input_copy" className="upload_name form_control" value={file? file.name : filePath} disabled />
                  <input type="file" id="pledge-upload" className="upload_hidden" onChange={(e: any) => setFile(e.target.files[0])} onClick={(e: any) => e.target.value = null} />
                  <label htmlFor="pledge-upload" className="btn btn_sm btn_gray">파일찾기</label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="submit ac mt15">
        {/* <button type="button" className="btn btn_md btn_darkblue btn_outline">취소</button> */}
        {props.setEdit === true? (
          <>
          {headerStore.writeRoleFlag ? <button type="button" className="btn btn_md btn_outline" onClick={onWriteCancelHandler}>입력 취소</button> : <></>}
          {headerStore.writeRoleFlag ? <button type="button" className="btn_md btn_darkblue" onClick={onPatchHandler}>수정</button> : <></>}
          </>
        ) : (
          <>
          {headerStore.writeRoleFlag ? <button type="button" className="btn_md btn_darkblue" onClick={enRollmentPatchHandler}>신규 등록</button> : <></>}
          </>
        )}
      </div>
    </>
  );
});

const PledgePage = observer((props: any) => {
  const [reload, setReload] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const {headerStore: headerStore} = useStores();
  const {pledgeStore: pledgeStore} = useStores();

  const [workSite, setWorkSite] = useState<number>(1);
  const [roomType, setRoomType] = useState<number>();

  const [offset, setOffset] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [selectedPledge, setSelectedPledge] = useState<any>({});
  const [historyPledges, setHistoryPledges] = useState<any>();
  const [edit, setEdit] = useState<boolean>(false);

  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {    
    headerStore.getAuthRoomRole();
  },[reload]);

  useEffect(() => {
    setEdit(false);
   (async () => {
      try {
        headerStore.isLoading = true;
        setSelectedPledge([]);
        if(!roomType && headerStore.paramAuthRoomTypeRole.split(',')[0]) {
          setRoomType(headerStore.paramAuthRoomTypeRole.split(',')[0]);
        }

        // if(roomType !== undefined) {
        //   let { data } = await pledge.getPledges({
        //     work_site: headerStore.workSite,
        //     room_type: roomType,
        //   });
        //   setSelectedPledge(data.length && data[0] || [])
        // }

        if(roomType !== undefined) {
          // const payload:any = {
          //   work_site: headerStore.workSite,
          //   room_type: roomType,
          // }
          pledgeStore.work_site = headerStore.workSite;
          pledgeStore.room_type = roomType;
          pledgeStore.getHistoryPledge();
          // setTotalPage(Math.ceil(histories.count / limit));
        }
        headerStore.isLoading = false;
      } catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("문제가 발생했습니다");
        return;
      }
    })()
  }, [reload, limit, headerStore.workSite, roomType, offset])

  // 렌더링 이슈 : 페이지로드되기전 계산부터 되야하는데 페이지로드가 먼저되어서 오류가 발생함 그래서 페이지로드 되고 다시 계산식을 불러오기위해 작성
  useEffect(() => {
  }, [pledgeStore.reloadTest]);

  return (
    <>
      <LocusTemplate
        {...props}
        setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
        id="wrap"
        className=""
      >
        <h4 className="">
          입숙서약서 등록
            <div className="right_area">
            {/*<OptionByWorksite value={workSite} onChange={(worksite: number) => setWorkSite(worksite)}/>*/}
            {/* roomType */}
            <select name="" id="" className="form_control" value={roomType} onChange={(e: any) => {
              setRoomType(e.target.value);
              setWorkSite(headerStore.workSite);
            }}>
              {headerStore.roomRoleBusiness_room ?<option value={ROOM_TYPE_CODE.business_room}>{ROOM_TYPE[ROOM_TYPE_CODE.business_room]}</option> : <></>}
              {headerStore.roomRoleBusiness_room_group ? <option value={ROOM_TYPE_CODE.business_room_group}>{ROOM_TYPE[ROOM_TYPE_CODE.business_room_group]}</option> : <></>}
              {headerStore.roomRoleVip_room ? <option value={ROOM_TYPE_CODE.vip_room}>{ROOM_TYPE[ROOM_TYPE_CODE.vip_room]}</option> : <></>}
              {headerStore.roomRoleDormitory_room ? <option value={ROOM_TYPE_CODE.dormitory_room}>{ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}</option> : <></>}
              {headerStore.roomRoleCompany_room ? <option value={ROOM_TYPE_CODE.company_room}>{ROOM_TYPE[ROOM_TYPE_CODE.company_room]}</option> : <></>}
              {headerStore.roomRoleFamily_room ? <option value={ROOM_TYPE_CODE.family_room}>{ROOM_TYPE[ROOM_TYPE_CODE.family_room]}</option> : <></>}
            </select>
          </div>
        </h4>

        <Write
          pledge={selectedPledge}
          setPledge={setSelectedPledge}
          reload={reload}
          setReload={() => setReload(!reload)}
          complete={() => {setReload(!reload); setEdit(false);}}
          workSite={headerStore.workSite}
          roomType={roomType}
          setRoomType={setRoomType}
          setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
          setEdit={edit}
        />

        <h4>입숙서약서 히스토리</h4>
        <div className="table_normal_list">
          <table>
            <caption className="sr_only">등록된 체크사항</caption>
            <colgroup>
              <col width="3%" />
              <col width="*" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>내용</th>
                <th>등록일자</th>
              </tr>
            </thead>
            <tbody>
              {pledgeStore.getHisPledge.length <= 0 ? (
                <tr>
                  <td colSpan={3} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              ):(
                pledgeStore.getHisPledge.map((pledge: any, pledgeIdx: number) => (
                  <tr key={pledgeIdx} onClick={() => {setSelectedPledge(pledge); setEdit(true)}} title={pledge.contents} style={{cursor: 'pointer'}}>
                    <td>{offset + pledgeIdx + 1}</td>
                    <td dangerouslySetInnerHTML={{ __html: parserEscape(pledge?.contents) }} ></td>
                    <td>{formatByString_YYYYMMDD(pledge.created_at)}</td>
                  </tr>
              )))}
            </tbody>
          </table>
        </div>

        <div className="board_paging">
          <ul>
            <Pagination
              offset={offset}
              limit={limit}
              setOffset={setOffset}
              setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
              totalPage={totalPage}
            />
          </ul>
        </div>  {/*board_paging*/}

        <div className="submit">
          {/* <div className="fl">
						<button type="button" className="btn_md btn_darkblue btn_outline">표시순서변경</button>
					</div> */}
          <div className="fr">
            {/* <button type="button" className="btn_md btn_darkgray">삭제</button> */}
          </div>
        </div>
      </LocusTemplate>
    </>
  );
});

export default PledgePage;

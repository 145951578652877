import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useLocusGuestType, { LocusDocumentType, LocusDocumentResType } from '../../hooks/useLocusDocument';

import DefaultTemplete from '../../components/templetes/default';
import LocusTotalTemplete from '../locusTotal';
import RoomType from '../../components/atoms/select/roomType';
import GuestType from '../../components/atoms/select/guestType';
import VisibleType from '../../components/atoms/select/visibleType';
import Require from '../../components/atoms/select/require';
import Button from '../../components/atoms/button';

import Pagination from '../../components/organismas/pagination';

import { getViewPageIdx } from '../../utils/common';

import { ROOM_TYPE, GUEST_TYPE, FILE_UPLOAD } from '../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';


// 이용자 범위설정
const LocusDocument = observer((props: any) => {
  const [
    data, offset, limit, totalPage,
    roomType, guestType, visibleType, isRequire,
    setRoomType, setGuestType, setVisibleType, setIsRequire,
    setOffset, setWorksiteType,
  ]: [LocusDocumentResType, number, number, number, number, number, number, number, Function, Function, Function, Function, Function, any] = useLocusGuestType();
  
  const {headerStore: headerStore} = useStores();
  
  useEffect(() => {
    headerStore.getAuthMenuRole('1-1');
    setWorksiteType(headerStore.workSite);
    if(roomType === undefined) {
      setRoomType(headerStore.paramAuthRoomTypeRole);
    }
  },[headerStore.workSite]);

  
  const { worksites = [], worksiteMap = {} } = useSelector((state: any) => ({
    worksites: state.base.worksites,
    worksiteMap: state.base.worksiteMap,
  }))

  const onDetailPageHandler = (id: number) => props.history.replace(`/locus/guest/document/${id}/detail`);
  const onRoomWorkType = (roomData : any, workData : any) => {
    setRoomType(roomData);
    setWorksiteType(workData);
  }

  return (
    <LocusTotalTemplete
      className=""
      id="wrap"
      {...props}
      isLoading={headerStore.isLoading}
    >
      <div className="group_tab mt30">
        <RoomType
            className="tab_lg tabs clearfix"
            onChange={(roomType: number) => onRoomWorkType(roomType, headerStore.workSite)}
            value={roomType}
        />
      </div>

      <h4>
        입숙서류 목록
        <div className="right_area">
          <GuestType className="form_control" value={guestType} onChange={(guestType: number) => setGuestType(guestType)} />
          <VisibleType className="form_control" value={visibleType} onChange={(visibleType: number) => setVisibleType(visibleType)} />
          <Require className="form_control" value={isRequire} onChange={(isRequier: number) => setIsRequire(isRequier)} />
        </div>
      </h4>

      {/* table start */}
      <div className="tab_contents on">
        <div className="table_normal_list event_on box_scroll_x" style={{ overflowX: 'auto' }}>
          <table>
            <caption className="sr_only">입숙서류 목록</caption>
            <colgroup>
              <col width="5%"></col>

              <col width="18%"></col>
              <col width="18%"></col>
              <col width="18%"></col>
              <col width="18%"></col>
              <col width="18%"></col>
              <col width="18%"></col>
            </colgroup>

            <thead>
              <tr>
                <th> No.</th>
                <th>사업장</th>
                <th>분류</th>
                <th>숙소유형</th>
                <th>이용자타입</th>
                <th>표시공간</th>
                <th>필수여부</th>
              </tr>
            </thead>
            <tbody>
              {data.results.length === 0 ? (
                <tr>
                  <td colSpan={7} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              ):(
                data.results.map((item: LocusDocumentType, idx: number) => (
                <tr key={idx}>
                  <td>
                    {getViewPageIdx(offset, limit, idx)}
                  </td>
                  <td style={{cursor:'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{worksiteMap[item.work_site]?.name}</td>
                  <td style={{cursor:'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{item.name}</td>
                  <td style={{cursor:'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{ROOM_TYPE[item.room_type]}</td>
                  <td style={{cursor:'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{GUEST_TYPE[item.guest_type]}</td>
                  <td style={{cursor:'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{FILE_UPLOAD[item.visible_type]}</td>
                  <td style={{cursor:'pointer'}} onClick={() => onDetailPageHandler(item.id)}>{item.is_required ? "필수" : "옵션"}</td>
                </tr>
              )))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="board_paging">
        <ul>
          <Pagination
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            totalPage={totalPage}
          />
        </ul>
      </div>

      {/* 하단버튼 */}
      <div className="submit">
        <div className="fl">
          {headerStore.writeRoleFlag?
              <button type="button" className="btn btn_lg btn_darkblue" onClick={() => {props.history.push('/locus/guest/document/write')}} style={{ cursor: 'pointer' }}>
                등록
              </button>
              :
              <></>
          }
        </div>
      </div>
    </LocusTotalTemplete>
  );
});

export default LocusDocument;

import React from 'react';

interface Props {
  name?: string
  id?: string
  className?: string
  onChange: Function
  limit: number
}

//리스트에서 보일 데이터 갯수 select
const Limit = (props: Props) => {
  return (
    <select name={props.name} id={props.id} className={props.className} value={props.limit} onChange={(e: any) => props.onChange(parseInt(e.target.value))}>
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </select>
  );
};

export default Limit;

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from '../../organismas/pagination';
import ConfirmBtn from '../../atoms/button/confirm';
import { getViewPageIdx } from '../../../utils/common';
import { formatByString, getNextDay, getNextMonth, getTodayDate } from '../../../utils/date';
import Select from '../../atoms/select';
import DatePicker from '../../organismas/input/datepicker';
import LimitSelect from '../../atoms/select/limit';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import CustomCalendar from '../../organismas/customCalendar';
const AuditList = observer((props: any) => {
  const dispatch = useDispatch();
  const {auditStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/guest/audit';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [items, setItems] = useState<any[]>(store.items);

  useEffect(() => {
    headerStore.getAuthMenuRole('2-5');
    store.worksite = headerStore.workSite;
    (async () => {
      await store.getItems();
    })();
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, [headerStore.workSite]);

  const onSearch = () => store.getItems();

  const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

  const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);

  const onEditHandler = () => {
    const selectedItems = Object.keys(store.checkeds);
    if (selectedItems && selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }
    if (selectedItems.length > 1) {
      info('다건의 항목이 선택되어 있습니다.');
      return;
    }
    const item = store.checkeds[selectedItems[0]];
    props.history.replace(`${baseUrl}/${item.id}/update`, item);
  };

  const onDeletehandler = async () => {
    const selectedItems = Object.keys(store.checkeds);
    if (selectedItems && selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data : any = await store.deleteItems();
            headerStore.isLoading = false;
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
                return;
              }else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;

            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              alert('접근권한이 없습니다.');
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            
            errorHandler({ status, data }, '삭제중 오류발생');
            return;
          }
        },
      }
    });
  };

  return (
    <>
      <h4>정기서류검사 검색</h4>
      <div className="table_modify_list">
          <dl className="dateonly" style={{ marginRight: '10px' }}>
            <dt>작성일자</dt>
            <dd className="form_inline">
              <div className="datepicker_searchwrap">
                {/* 오늘기준 1달전 */}
                {/* <DatePicker
                  value={store.created_after}
                  width={250}
                  onChange={(date: string) => store.created_after = date}
                  isPrevMonthView={true}
                  max={store.created_before}
                /> */}
                <CustomCalendar
                  max={store.created_before}
                  value={store.created_after}
                  onChange={(date:string) => store.created_after = date}
                ></CustomCalendar>
                <span className="dash">~</span>
                {/* <input type="text" className="form_control datepicker" /> */}
                {/* 오늘기준 1달후 */}
                {/* <DatePicker
                  value={store.created_before}  
                  width={250}
                  onChange={(date: string) => store.created_before = date}
                  isPrevMonthView={true}
                  min={store.created_after}
                /> */}
                <CustomCalendar
                  min={store.created_after}
                  value={store.created_before}
                  onChange={(date:string) => store.created_before = date}
                ></CustomCalendar>
              </div>
            </dd>
          </dl>
          <dl className="search">
              <dt>검색어</dt>
              <dd>
                  <Select
                    className="form_control"
                    options={store.searchTypes}
                    value={store.searchType}
                    onChange={(e: any) => store.searchType = Number(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form_control"
                    value={store.search}
                    onKeyUp={e => (e.key === 'Enter' ? onSearch() : () => {})}
                    onChange={e => store.search = e.target.value}
                  />
                  <button type="button" className="btn btn_sm btn_gray" onClick={onSearch}>
                    검색
                  </button>
              </dd>
          </dl>
      </div>

      <h4>정기서류검사 리스트
        <div className="right_area">
          <LimitSelect
              className="form_control"
              limit={store.limit}
              onChange={(limit: number) => {
                store.setLimit(limit);
                store.setOffset(0);
              }}
            />
        </div>
      </h4>

      <div className="table_normal_list mt30">
        <table>
          <caption className="sr_only">공지 관리 목록</caption>
          <colgroup>
            {/*<col width="4%" />*/}
            <col width="10%" />
            <col width="*" />
            <col width="10%" />
            <col width="8%" />
            <col width="8%" />
          </colgroup>
          <thead>
            <tr>
              {/*<th>
                <label className="input_check pop_notext">
                  <input type="checkbox" id="" name="check_notice" checked={store.allCheckFlg} onChange={onCheckedByAll} />
                  <span className="label_text"></span>
                </label>
              </th>*/}
              <th>NO</th>
              <th>제목</th>
              <th>작성일</th>
              <th>이미지</th>
              <th>첨부파일</th>
            </tr>
          </thead>
          <tbody>
            {!store.items.length ? (
              <tr>
                <td colSpan={5} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((item, index) => (
                <tr key={index}>
                  {/*<td>
                    <label className="input_check pop_notext">
                      <input
                        type="checkbox"
                        checked={store.checkeds[item.id] ? true : false}
                        onChange={(e: any) => onCheckedHandler(e, item)}
                      />
                      <span className="label_text"></span>
                    </label>
                  </td>*/}
                  <td>{getViewPageIdx(store.offset, store.limit, index)}</td>
                  <td
                    className="al"
                    onClick={() => {
                      store.selectedItem(item);
                      props.history.replace(`${baseUrl}/${item.id}`);
                    }}
                    style={{ cursor: 'pointer' }}
                    >
                    <a href="javascript:void(0);" className="btn_link">
                      {item.title}
                    </a>
                  </td>
                  <td>{formatByString(item.created_at, 'yyyy.MM.dd')}</td>
                  <td>
                    <a 
                      className={item.images.length > 0 ? "btn_image" : "btn_image blank"}
                    ></a>
                  </td>
                  <td>
                    <a
                      className={item.files.length > 0 ? "btn_file" : "btn_file blank" }
                    ></a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/*table*/}
      <div className="board_paging">
        <ul>
          <Pagination
            offset={store.offset}
            limit={store.limit}
            totalPage={store.totalPage}
            setOffset={(offset: number) => store.setOffset(offset)} />
        </ul>
      </div>
      {/*board_paging*/}

      <div className="submit">
        <div className="fl">
          {headerStore.writeRoleFlag ? 
            <button
              type="button"
              className="btn btn_lg btn_darkblue btn_darkblue"
              onClick={() => {
                store.newItem();
                props.history.replace(`${baseUrl}/create`);
              }}
            >
              등록
            </button>
            : <></>
          }
        </div>
        <div className="fr">
          {/*{headerStore.writeRoleFlag ? <button type="button" className="btn btn_lg btn_darkblue btn_outline" onClick={onEditHandler}>수정</button> : <></>}*/}
          {/*<button type="button" className="btn btn_lg btn_gray" onClick={onDeletehandler}>삭제</button>*/}
        </div>
      </div>
    </>
  );
});

export default AuditList;

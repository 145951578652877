import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import LodgedTotal from '..';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { formatByString_YYYYMMDD } from '../../../utils/date';
import { getDate, getDays, getMonth, getMonths, getYear, getYear5 } from '../../../utils/date';
import { showConfirm } from '../../../utils/common';
import { LODGE_MOVE_CODE, MOVE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE } from '../../../constants';
import MngFileUpload from '../../../components/organismas/form/managementFile';
import ConfirmBtn from '../../../components/atoms/button/confirm';
import MoveBackroomDormitory from '../../../components/organismas/popup/movedormitoryBackroom';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const LodgedCheckChangeDetail = observer((props: any) => {
    const dispatch = useDispatch();
    const { loadgedTotalStore : store } = useStores(); //입숙리스트 가져오기 api
    const {applicantJudgeStore: roomStore} = useStores();
    const {headerStore: headerStore} = useStores();
    const { info, warning, error }: MessageImpl = useContext(MessageContext); //메세지 핸들러
    const [reload, setReload] = useState<boolean>(false);
    const [ismoveAppPop, setIsmoveAppPop] = useState<boolean>(false);
    const [isShowReflect, setIsShowReflect] = useState<boolean>(false);
    const [isImgPop, setIsImgPop] = useState<boolean>(false);
    const [imgPath, setImgPath] = useState<string>('');

    //rendering시 
    useEffect(()=> {
        if(!store.tempCheckChangeDetailItem || store.tempCheckChangeDetailItem.length === 0) {
            props.history.replace(`/guest/status/checkchange`);
            return;
        }
        (async ()=> {
            //console.log('넘어온데이터==============>', store.tempCheckChangeDetailItem);
            try{
                headerStore.isLoading = true;
                headerStore.getAuthMenuRole('2-3');
                headerStore.getAuthWorkSiteRole();
                fetchData();
                //console.log('이숙상세데이터 ======>', store.checkChangeDetailItem);
                headerStore.isLoading = false;
            }catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
        return () => {
            if (store.findTabs('/lodged/type') < 0) {
                store.init();
            }
        }
    },[reload]);

    // 상세
    const fetchData = async() => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.getGuestMoveDetail();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    setReload(true);
                }else {
                  return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            info("문제가 발생했습니다");
                return;
        }
    }
    
    const popInit = () => {
        store.manageYear = getYear();
        store.manageMonth = getMonth();
        store.manageDay = getDate();
        store.isKeyReturn = true;
        store.keyDescription = ''
        store.keySelect = '1';
        store.isEquipment  = true;
        store.equipmentDescription = '';
        store.images = [];
        store.memo = '';
        store.status_description = '';
        roomStore.workSite = '';
        roomStore.selectRow = [];
        roomStore.room = [];
    }

    // 정기 / 상시 이숙처리
    const moveAccess = async()=> {
        showConfirm('이숙처리 하시겠습니까?', dispatch, async () => {
            if(store.manageYear === '' || store.manageMonth === '' || store.manageDay === ''){
                info('필수 입력사항을 체크하여주세요');
                return false;
            }else if(!store.isKeyReturn && store.keySelect === '기타' && (store.keyDescription === '' || store.keyDescription === null)){
                info('열쇠 미 반납 이유를 적어주세요');
                return false;
            }else if(!store.isEquipment && (store.equipmentDescription === '' || store.equipmentDescription === null)){
                info('비품 이상 여부를 적어주세요');
                return false;
            }
            
            try {
                headerStore.isLoading = true;
                //store.roomId = parseInt(Object.keys(roomStore.room)[0]);
                let data : any = await store.moveAccess();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsmoveAppPop(false);
                        info('이숙처리가 완료되었습니다');
                        setReload(!reload);
                        popInit();
                    }else {
                      return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        });
    }

    // 정기/상시 이숙 배방
    const roomMoveApprove = async() => {
        try {
            headerStore.isLoading = true;
            store.roomId = parseInt(Object.keys(roomStore.room)[0]);
            let data : any = await store.moveApprove();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('승인이 완료되었습니다');
                    setReload(!reload);
                    popInit();
                    store.getGuestMoveList();
                }else {
                    info('승인이 실패되었습니다');
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }
    
    // 승인
    const moveApprove = async()=> {
        showConfirm('승인 하시겠습니까?', dispatch, async () => {
            try {
                headerStore.isLoading = true;
                let data : any = await store.moveApprove();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        info('승인이 완료되었습니다');
                        setReload(!reload);
                    }else {
                        info('승인이 실패되었습니다');
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                    if (!window.localStorage.getItem("isRetryLogin")) {
                      window.localStorage.setItem("isRetryLogin", "retry");
                    }
                    window.location.href = '/';
                    return ;
                } else if (status === 403) {
                    (window as any).store.dispatch({
                      type: SHOW_ALERT_MODAL,
                      payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                      },
                    });
                    return ;
                }else if ( status === 500 ) {
                    window.location.href = '/500';
                    return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })
    }

    // 거절처리
    const moveReject = async() => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.moveReject();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('이숙처리가 거절되었습니다');
                    popInit();
                    setIsShowReflect(false);
                    setReload(!reload);
                }else {
                    info('이숙처리가 실패되었습니다');
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    // 1:1 이숙처리
    const oneToOneMoveAccess = async()=> {
        showConfirm('이숙처리 하시겠습니까?', dispatch, async () => {
            try {
                headerStore.isLoading = true;
                let data : any = await store.oneToOneMoveAccess();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        info('이숙처리가 완료되었습니다');
                        popInit();
                        setReload(!reload);
                    }else {
                        info('이숙처리 중 문제가 발생되었습니다');
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                    if (!window.localStorage.getItem("isRetryLogin")) {
                      window.localStorage.setItem("isRetryLogin", "retry");
                    }
                    window.location.href = '/';
                    return ;
                } else if (status === 403) {
                    (window as any).store.dispatch({
                      type: SHOW_ALERT_MODAL,
                      payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                      },
                    });
                    return ;
                }else if ( status === 500 ) {
                    window.location.href = '/500';
                    return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })
    }

    const getMoveStatus = (item: any) => {
        if(item.status === MOVE_STATUS_CODE.apply && store.tempCheckChangeDetailItem.category !== LODGE_MOVE_CODE.one) {
            return (
                <>
                    {headerStore.mobileRoleFlag ? 
                        <>
                            <button className="btn btn_lg btn_darkblue btn_outline" onClick={() => {store.moveAppData = item; setIsShowReflect(true)}}>거절</button>
                            <button className="btn btn_lg btn_darkblue" onClick={()=> {store.moveAppData = item; openRoomPop()}}>승인</button>
                        </>
                    : <></>}
                </>
            )
        }else if(item.status === MOVE_STATUS_CODE.resApprove && store.tempCheckChangeDetailItem.category === LODGE_MOVE_CODE.one) {
            return (
                <>
                    {headerStore.mobileRoleFlag ? 
                        <>
                            <button className="btn btn_lg btn_darkblue btn_outline" onClick={() => {store.moveAppData = item; setIsShowReflect(true)}}>거절</button>
                            <button className="btn btn_lg btn_darkblue" onClick={()=> {store.moveAppData = item; moveApprove()}}>승인</button>
                        </>
                    :<></>}
                </>
            )
        }else if(item.status === MOVE_STATUS_CODE.approve && store.tempCheckChangeDetailItem.category !== LODGE_MOVE_CODE.one && headerStore.finallyRoleFlag) {   // 정기, 상시 이숙
            item.category = store.tempCheckChangeDetailItem.category;
            return <button className="btn btn_lg btn_darkblue" onClick={()=>{store.setmoveAppData(item); setIsmoveAppPop(true)}}>이숙처리</button>
        }else if(item.status === MOVE_STATUS_CODE.approve && store.tempCheckChangeDetailItem.category === LODGE_MOVE_CODE.one && headerStore.finallyRoleFlag) {   // 1:1 이숙
            return <button className="btn btn_lg btn_darkblue" onClick={()=>{store.setmoveAppData(item); oneToOneMoveAccess();}}>이숙처리</button>
        }
        // else if(item.status === MOVE_STATUS_CODE.reject && headerStore.mobileRoleFlag) {
        //     return( 
        //         <>
        //             <button className="btn btn_lg btn_darkblue" onClick={()=>{store.moveAppData = item; setIsShowReflect(true)}}>거절사유</button>
        //         </>
        //     )
        // }

        return <></>;
    }

    const openRoomPop = () => {
        //roomStore.workSite = store.tempCheckChangeDetailItem.guest.guest_work_site_id;
        roomStore.workSite = headerStore.workSite;
        roomStore.selectRow.room_type = ROOM_TYPE_CODE.dormitory_room;
        roomStore.selectRow.stay_after = store.tempCheckChangeDetailItem.moved_at;
        roomStore.selectRow.stay_before = store.tempCheckChangeDetailItem.guest.expired_at;
        roomStore.selectRow.guest_gender = parseInt(store.tempCheckChangeDetailItem.guest.guest_gender);
        roomStore.isDormitoryPop = true;
    }
    
    if(!store.tempCheckChangeDetailItem || store.tempCheckChangeDetailItem.length === 0) return <></>;

    return (
        <LodgedTotal
            id=""
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
        <div className="tab_contents on">
            <h4>기본정보</h4>
            <ul className="lst_status type2">
                <li>
                    <strong>숙소유형</strong>
                    <span>{store.checkChangeDetailItem.guest? ROOM_TYPE[store.checkChangeDetailItem.guest.room_type] : ''}</span>
                </li>
                <li>
                    <strong>이용사업장</strong>
                    <span>{store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.room_work_site : ''}</span>
                </li>
                <li>
                    <strong>입숙일</strong>
                    <span>{store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.checkin_at : ''}</span>
                </li>
                <li>
                    <strong>입숙만료일</strong>
                    <span>{store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.expired_at : ''}</span>
                </li>
                <li>
                    <strong>배방</strong>
                    <span>{store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.room_building_name : ''} {store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.room_building_dong : ''} {store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.room_name : ''}호</span>
                </li>
            </ul>           

            {store.tempCheckChangeDetailItem.category === LODGE_MOVE_CODE.one? (
                <>
                    <h4>이숙신청 상대 정보</h4>     
                    <div className="table_normal_list">
                        <table>
                        <caption className="sr_only">이숙신청 상대 정보 입력</caption>
                        <colgroup>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>사번 (아이디)</th>
                            <th>성명</th>
                            <th>성별</th>
                            <th>소속부서</th>
                            <th>휴대폰 번호</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.guest_employee_number : ''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.guest_name : ''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.guest_gender === 1? '남' : '여' :''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.guest_department : ''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.guest_cellphone : ''}</td>
                        </tr>
                        </tbody>
                        </table>
                    </div>

                    <div className="table_normal_list mt20">
                        <table>
                        <caption className="sr_only">이숙신청 상대 정보 입력</caption>
                        <colgroup>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>숙소유형</th>
                            <th>이용사업장</th>
                            <th>입숙일</th>
                            <th>입숙만료일</th>
                            <th>배방</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{store.checkChangeDetailItem.other_guest? ROOM_TYPE[store.checkChangeDetailItem.other_guest.room_type] : ''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.room_work_site : ''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.checkin_at : ''}</td>
                            <td>{store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.expired_at : ''}</td>
                            <td>
                                {store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.room_building_name : ''}
                                {store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.room_building_dong : ''}
                                {store.checkChangeDetailItem.other_guest? store.checkChangeDetailItem.other_guest.room_name : ''}호
                            </td>
                        </tr>
                        </tbody>
                        </table>
                    </div>
                </>
            ): ''}

            <h4>이숙신청 정보 입력</h4>
            <div className="table_normal_list table_write">
                <table>
                <caption className="sr_only">이숙신청 정보 입력</caption>
                <colgroup>
                    <col width="160"/>
                    <col width="*"/>
                </colgroup>
                <tbody>
                {/*데이타 없을 시*/}
                {/*<tr>
                    <td colspan="" className="blank_area type_white">
                        <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                </tr>*/}
                <tr>
                    <th>기존근무조</th>
                    <td>{store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.guest_party : ''}</td>
                </tr>
                <tr>
                    <th>실제근무조</th>
                    <td>
                        <label className="input_radio" htmlFor="shift_check">
                            <input type="radio" id="shift_check" name="shift" checked={store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.guest_party === store.checkChangeDetailItem.party : true} disabled/> 
                            <span className="label_text">동일</span>
                        </label>
                        <label className="input_radio ml30" htmlFor="shift_check2">
                            <input type="radio" id="shift_check2" name="shift" checked={store.checkChangeDetailItem.guest? store.checkChangeDetailItem.guest.guest_party !== store.checkChangeDetailItem.party : false} disabled/> 
                            <span className="label_text">다름</span>
                        </label>
                        <span className="ml30">{store.checkChangeDetailItem.party}</span>
                    </td>
                </tr>
                <tr>
                    <th>이숙요청일</th>
                    <td>{store.checkChangeDetailItem.moved_at}</td>
                </tr>
                <tr>
                    <th>이숙희망숙소</th>
                    <td>{store.checkChangeDetailItem.desired_room}</td>
                </tr>
                <tr>
                    <th>요청사항</th>
                    <td>
                    {store.checkChangeDetailItem.claim}
                    </td>
                </tr>
                <tr>
                    <th>비품 이상유무 체크</th>
                    <td>
                        <div className="images_area">
                            {store.checkChangeDetailItem.guest && store.checkChangeDetailItem.guest.checkin_files.length > 0 ? (
                                store.checkChangeDetailItem.guest.checkin_files.map((image: any, index: number) => (
                                    <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                        <a key={index} >
                                        <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                        </a>
                                    </div>
                                ))
                            ) :''}
                        </div>

                        <label className="input_radio">
                            <input type="radio" id="fixtures_check" name="fixtures" checked={store.checkChangeDetailItem.is_equipment_ok} disabled/> 
                            <span className="label_text">숙소 상태 및 비품이 이상이 있습니다.</span>
                        </label>
                        <label className="input_radio ml30">
                            <input type="radio" id="fixtures_check2" name="fixtures" checked={!store.checkChangeDetailItem.is_equipment_ok} disabled/> 
                            <span className="label_text">숙소 상태 및 비품 등에 이상이 없습니다</span>
                        </label>
                    </td>
                </tr>
                {store.checkChangeDetailItem.is_equipment_ok? (
                    <tr>
                        <th>이상 품목</th>
                        <td>
                            {store.checkChangeDetailItem.equipment_description}
                        </td>
                    </tr>
                ) :''}
                {store.checkChangeDetailItem.status === MOVE_STATUS_CODE.reject && headerStore.mobileRoleFlag? (
                    <tr>
                        <th>거절사유</th>
                        <td>{store.tempCheckChangeDetailItem.status_description}</td>
                    </tr>
                ) :''}
                </tbody>
                </table>
            </div>  {/*table*/}

            <div className="submit">
                <button className="btn btn_lg btn_gray fl" onClick={() => props.history.replace(`/guest/status/checkchange/`)}>목록</button>
                <div className="fr" id="buttonList">
                    {getMoveStatus(store.checkChangeDetailItem)}
                </div>
            </div>

            <div id="pop_change_request" className="pop_dim_wrap pop_modal pop_sm" style={{display: ismoveAppPop? 'block': 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>이숙완료처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() =>{popInit(); setIsmoveAppPop(false)}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <ul className="request_date">
                            <li>
                                <strong>이숙요청일</strong>
                                <span>{store.moveAppData.moved_at}</span>
                            </li>
                            <li>
                                <strong>신청일</strong>
                                <span>{store.moveAppData.created_at? formatByString_YYYYMMDD(store.moveAppData.created_at) : ''}</span>
                            </li>
                        </ul>
                        <div className="process_list">
                            <dl>
                                <dt>열쇠 반납 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="return_check">
                                            <input type="radio" id="return_check" name="return" checked={store.isKeyReturn} onClick={() => store.isKeyReturn = true}/> 
                                            <span className="label_text">반납 완료</span>
                                        </label>
                                        <label className="input_radio" htmlFor="return_check2">
                                            <input type="radio" id="return_check2" name="return" checked={!store.isKeyReturn} onClick={() => store.isKeyReturn = false}/> 
                                            <span className="label_text">미 반납</span>
                                        </label>
                                    </div>

                                    {!store.isKeyReturn? (
                                        <div className="input_box">
                                            <select className="form_control" value={store.keySelect} onChange={(e:any) => store.keySelect = e.target.value}>
                                                <option value="열쇠 분실">열쇠 분실</option>
                                                <option value="기타" >기타</option>
                                            </select>
                                            <input type="text" className="form_control" placeholder="미 반납 이유를 적어주세요."
                                                value={store.keyDescription} onChange={(e:any) => store.keyDescription = e.target.value} disabled={store.keySelect === '기타'? false : true}/>
                                        </div>
                                    ) :  (
                                        <>
                                        {/* <select className="form_control year" value={store.keyoutYear} onChange={(e:any) => store.keyoutYear = e.target.value}>
                                            {getYear5(false).map((year:any, index: number) => (
                                                <option key={index} value={year}>{year}</option>
                                                ))}
                                        </select>
                                        <select className="form_control month" value={store.keyoutMonth} onChange={(e:any) => store.keyoutMonth = e.target.value}>
                                            {getMonths(store.keyoutYear, false).map((year:any, index: number) => (
                                                <option key={index} value={year}>{year}</option>
                                                ))}
                                        </select>
                                        <select className="form_control day" value={store.keyoutDay} onChange={(e:any) => store.keyoutDay = e.target.value}>
                                            {getDays(store.keyoutYear, store.keyoutMonth, true).map((year:any, index: number) => (
                                                <option key={index} value={year}>{year}</option>
                                                ))}
                                        </select> */}
                                        </>
                                    )}
                                </dd>
                                    
                                <dt>비품 이상 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio">
                                            <input type="radio" id="fixtures_check3" name="fixtures2" checked={store.isEquipment} onClick={() => store.isEquipment = true}/> 
                                            <span className="label_text">이상 없음</span>
                                        </label>
                                        <label className="input_radio">
                                            <input type="radio" id="fixtures_check4" name="fixtures2" checked={!store.isEquipment} onClick={()=> store.isEquipment= false}/> 
                                            <span className="label_text">이상 있음</span>
                                        </label>
                                    </div>

                                    {!store.isEquipment? (
                                        <input type="text" className="form_control" placeholder="비품 이상 여부를 적어주세요."
                                            value={store.equipmentDescription} onChange={(e:any) => store.equipmentDescription = e.target.value}/>
                                    ) : ''}
                                </dd>
                            </dl>
                        </div>

                        <div className="table_normal_list mt10">
                            <table>
                                <caption className="sr_only">숙소 이미지 첨부</caption>
                                <colgroup>
                                    <col width="*"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="ac">숙소 이미지 첨부</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="al">
                                    <MngFileUpload type="image" fileId="attacheFile" items={store.images} onSetData={(data: any[]) => store.images = [...data]} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>  {/*table*/}

                        {/* <ul className="request_date">
                            <li>
                                <strong>이숙숙소정보</strong>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={() => openRoomPop()}>배방</button>
                                <span>선택한 객실 : {roomStore.room['room'] ? roomStore.room['room'].building_name +' '+ roomStore.room['room'].name : ''}</span>
                                <span>이용자 희망 객실 : {store.moveAppData.desired_room}</span>

                            </li>
                        </ul> */}

                        <div className="process_list mt20">
                            <dl>
                                <dt>최종 이숙일</dt>
                                <dd>
                                    <select className="form_control year" value={store.manageYear} onChange={(e:any) => store.manageYear = e.target.value}>
                                        {getYear5(true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                            ))}
                                    </select>
                                    <select className="form_control month" value={store.manageMonth} onChange={(e:any) => store.manageMonth = e.target.value}>
                                        {getMonths(store.manageYear, true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                            ))}
                                    </select>
                                    <select className="form_control day" value={store.manageDay} onChange={(e:any) => store.manageDay = e.target.value}>
                                        {getDays(store.manageYear, store.manageMonth, false).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                            ))}
                                    </select>
                                </dd>
                                <dt>비고입력</dt>
                                <dd>
                                    <textarea className="form_control" value={store.memo} onChange={(e:any) => store.memo = e.target.value}></textarea>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={moveAccess}>완료</button>
                    </div>
                </div>
            </div>

            {/* 거절 popup */}
            <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowReflect? "block": "none" }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>거절처리</h3>
                    <button className="pop_btn_close pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                    <div className="process_list">
                        <dl>
                            <dt>사유</dt>
                            <dd>
                                {parseInt(store.moveAppData.status) === MOVE_STATUS_CODE.reject ? (
                                    <textarea className="form_control"
                                        value={store.tempCheckChangeDetailItem.status_description}
                                        disabled
                                    >
                                    </textarea>
                                ) : (
                                    <textarea className="form_control"
                                        value={store.status_description}
                                        onChange={(e: any) => store.status_description = e.target.value}
                                    >
                                    </textarea>
                                )}
                            </dd>
                        </dl>
                    </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {parseInt(store.moveAppData.status) === MOVE_STATUS_CODE.reject ? (
                            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>확인</button>
                        ) : (
                            <ConfirmBtn
                                className="btn btn_lg btn_darkblue pop_close"
                                action={moveReject}
                                title={""}
                                label="확인"
                                msg={"거절하시겠습니까?"}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
        
        {/* 기숙사 배방오픈 */}
        <MoveBackroomDormitory 
            desired_room={store.moveAppData.desired_room}
            moveApprove={roomMoveApprove}
            setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
        />

        {/* 이미지 팝업 */}
        <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
            <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
            <div className="pop_wrap">
                <div className="contents_wrap">
                    <div className="slide">
                        <p className="img_area">
                            <img src={imgPath} alt=""/>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        </LodgedTotal>
    )
})

export default LodgedCheckChangeDetail;
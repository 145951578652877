import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { ADD_TAB, CLICK_TAB, CLOSE_TAB, CHANGE_TAB } from '../../../store/modules/base';

import './index.scss';

import { WORK_SITE } from '../../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import ReactDOM from 'react-dom';

//lnb
const DEFAULT_TAB_PATH = '/home';
const Lnb = observer((props: any) => {
    const authMenu = window.localStorage.getItem('auth_menu')?.replace(/\"/gi,"");
    
    const dispatch = useDispatch();
    const { opened = [], select = -1 } = useSelector((state: any) => ({
      opened: state.base.tab.opened,
      select: state.base.tab.select,
    }));
    const [stateLnbSelect, setStateLnbSelect] = useState<number>();

    const [stateWrapExpend, setStateWrapExpend] = useState(false);
    const onWrapExpendHandler = function () {
        if (props.isClickActive) {
            setStateWrapExpend(!stateWrapExpend);
        }
    };

    const compMenu = (val: string) => {
        const vals = val.split('/');
        if (vals.length > 2) {
          return vals.slice(0,3).join('/');
        } else {
          return val;
        }
      }

    const m:{[key: string]: number} = {
        '/manage/room': 0,
        '/locus/guest/type': 0,
        '/locus/document': 0,
        '/manage/use/guide': 0,
        '/manage/pledge': 0,
        '/lodged/applicant': 0,
        '/applicant/judge': 0,

        '/guest/history':1,
        '/guest/board' :1,
        '/guest/status' : 1,
        '/lodged/guest': 1,
        '/lodged/checkout': 1,
        '/guest/audit': 1,
        '/guest/deductible': 1,

        '/lodged/basicinfo': 2,
        '/lodged/maintenance': 2,

        '/manage/sms': 3,
        '/manage/member': 3,
        '/manage/notice': 3,
        '/manage/faq': 3,

        '/account/permission':5,
        '/account/application':5,

        '/admin/regist_apply': 6,
        '/admin/manage_auth': 6,
    }
    
    const currentSelectedMenu = () => {
        setStateLnbSelect(m[props.history.location.pathname])
    }
    
    const level1 = [
      <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 0 ? "active" : ""}`} onClick={() => setStateLnbSelect(0)}>입숙신청관리</a></dt>
    , <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 1? "active" : ""}`} onClick={() => {setStateLnbSelect(1); addTabHandler('/guest/history');}}>입숙자관리</a></dt>
    , <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 2? "active" : ""}`} onClick={() => setStateLnbSelect(2)}>숙소관리</a></dt>
    , <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 3? "active" : ""}`} onClick={() => setStateLnbSelect(3)}>이용관리</a></dt>
    , <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 4? "active" : ""}`} onClick={() => setStateLnbSelect(4)}>마이페이지</a></dt>
    , <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 5? "active" : ""}`} onClick={() => setStateLnbSelect(5)}>계정관리</a></dt>
    // , <dt><a style={{'cursor':'pointer'}} className={`${stateLnbSelect === 6? "active" : ""}`} onClick={() => setStateLnbSelect(6)}>관리자관리</a></dt>
    ]

    const level2 = [
        [<li key={1}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/locus/guest/type')} className={props.history.location.pathname.includes("/locus/guest")? "active" : ""}>기준설정</a></li>
        ,<li key={2}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/applicant/judge')} className={props.history.location.pathname.includes("/applicant/judge")? "active" : ""}>심사 및 입숙처리</a></li>
        ]
        ,[<li key={3}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/guest/history')} className={props.history.location.pathname.includes("/guest/history")? "active" : ""}>입숙자현황 및 이력</a></li>
        ,<li key={4}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/guest/board')} className={props.history.location.pathname.includes("/guest/board")? "active" : ""}>입숙자현황표</a></li>
        ,<li key={5}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/guest/status/setting')} className={props.history.location.pathname.includes("/guest/status")? "active" : ""}>입숙, 이숙, 퇴숙 현황</a></li>
        ,<li key={6}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/guest/deductible/meter')} className={props.history.location.pathname.includes("/guest/deductible/meter") || props.history.location.pathname.includes("/guest/deductible/") ? "active" : ""}>공제내역</a></li>
        ,<li key={7}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/guest/audit')} className={props.history.location.pathname.includes("/guest/audit")? "active" : ""}>정기서류검사</a></li>
        ]
        ,[<li key={8}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/lodged/basicinfo')} className={props.history.location.pathname.includes('/lodged/basicinfo')? "active" : ""}>기본정보설정</a></li>
        ,<li key={9}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/lodged/maintenance')} className={props.history.location.pathname.includes('/lodged/maintenance')? "active" : ""}>유지운영</a></li>
        ]
        ,[<li key={10}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/manage/member')} className={props.history.location.pathname.includes('/manage/member')? "active" : ""}>회원 현황</a></li>
        ,<li key={11}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/manage/notice')} className={props.history.location.pathname.includes('/manage/notice')? "active" : ""}>공지 관리</a></li>
        ,<li key={12}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/manage/sms')} className={props.history.location.pathname.includes('/manage/sms')? "active" : ""}>문자발송</a></li>
        ,<li key={13}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/manage/faq')} className={props.history.location.pathname.includes('/manage/faq')? "active" : ""}>FAQ</a></li>
        ]
        ,[<li key={14}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/mypage/info')} className={props.history.location.pathname.includes('/mypage/info')? "active" : ""}>개인정보</a></li>
        ]
        ,[<li key={15}><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/account/permission')} className={props.history.location.pathname.includes('/account/permission')? "active" : ""}>계정 및 권한관리</a></li>
        // ,<li><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/account/application')} className={props.history.location.pathname.includes('/account/application')? "active" : ""}>계정 신청 현황</a></li>
        // ]
        // ,[<li><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/admin/regist_apply')} className={props.history.location.pathname.includes('/admin/regist_apply')? "active" : ""}>관리자계정 관리</a></li>
        // ,<li><a style={{'cursor':'pointer'}} onClick={() => addTabHandler('/admin/manage_auth')} className={props.history.location.pathname.includes('/admin/manage_auth')? "active" : ""}>권한관리</a></li>
        ]
    ];
    
    
    useEffect(() => {
        currentSelectedMenu();
        let len: any[] = [authMenu?.split(",")];    //메뉴 권한 
        
        let menu: any[] = [];

        let taglevel1: any[] = [];
        let taglevel2: any[] = [];
        let taglevel3: any[] = [];
        let taglevel4: any[] = [];
        let taglevel5: any[] = [];
        let taglevel6: any[] = [];
        let taglevel7: any[] = [];

        let tag2level1: any[] = [];
        let tag2level2: any[] = [];
        let tag2level3: any[] = [];
        let tag2level4: any[] = [];
        let tag2level5: any[] = [];
        let tag2level6: any[] = [];
        let tag2level7: any[] = [];
        let count = len[0].length;

        for ( let i = 0; i < count; i++) {
            const menuLv1 = len[0][i].split("-");
            if(menuLv1.length === 3) {// 길이 3일경우 해당 메뉴에 대한 권한 존재 11R , 12R 식으로 판단
                if(menuLv1[0] === '1' && taglevel1.length === 0) taglevel1.push(level1[menuLv1[0]-1]);                
                if(menuLv1[0] === '2' && taglevel2.length === 0) taglevel2.push(level1[menuLv1[0]-1]);
                if(menuLv1[0] === '3' && taglevel3.length === 0) taglevel3.push(level1[menuLv1[0]-1]);
                if(menuLv1[0] === '4' && taglevel4.length === 0) taglevel4.push(level1[menuLv1[0]-1]);
                if(menuLv1[0] === '5' && taglevel5.length === 0) taglevel5.push(level1[menuLv1[0]-1]);
                if(menuLv1[0] === '6' && taglevel6.length === 0) taglevel6.push(level1[menuLv1[0]-1]);
                // if(menuLv1[0] === '7' && taglevel7.length === 0) taglevel7.push(level1[menuLv1[0]-1]);

                if(menuLv1[0] === '1') tag2level1.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
                if(menuLv1[0] === '2') tag2level2.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
                if(menuLv1[0] === '3') tag2level3.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
                if(menuLv1[0] === '4') tag2level4.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
                if(menuLv1[0] === '5') tag2level5.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
                if(menuLv1[0] === '6') tag2level6.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
                // if(menuLv1[0] === '7') tag2level7.push(level2[menuLv1[0]-1][menuLv1[1]-1]);
            }
        
        }
        if(tag2level1.length > 0) { // 메뉴에 대한 권한이 존재할경우 2depth 메뉴 그리기
            menu.push(taglevel1);
            menu.push(<dd className={`${stateLnbSelect === 0? "on" : ""}`}><ul className="lst_sub">{tag2level1}</ul></dd>);
        }
        if(tag2level2.length > 0) {
            menu.push(taglevel2);
            menu.push(<dd className={`${stateLnbSelect === 1? "on" : ""}`}><ul className="lst_sub">{tag2level2}</ul></dd>);
        }
        if(tag2level3.length > 0) {
            menu.push(taglevel3);
            menu.push(<dd className={`${stateLnbSelect === 2? "on" : ""}`}><ul className="lst_sub">{tag2level3}</ul></dd>);
        }
        if(tag2level4.length > 0) {
            menu.push(taglevel4);
            menu.push(<dd className={`${stateLnbSelect === 3? "on" : ""}`}><ul className="lst_sub">{tag2level4}</ul></dd>);
        }
        if(tag2level5.length > 0) {
            menu.push(taglevel5);
            menu.push(<dd className={`${stateLnbSelect === 4? "on" : ""}`}><ul className="lst_sub">{tag2level5}</ul></dd>);
        }
        if(tag2level6.length > 0) {
            menu.push(taglevel6);
            menu.push(<dd className={`${stateLnbSelect === 5? "on" : ""}`}><ul className="lst_sub">{tag2level6}</ul></dd>);
        }
        if(tag2level7.length > 0) {
            menu.push(taglevel7);
            menu.push(<dd className={`${stateLnbSelect === 6? "on" : ""}`}><ul className="lst_sub">{tag2level7}</ul></dd>);
        }
    
        if(menu){
            ReactDOM.render(menu, document.getElementById("menu"));
        }else {
            ReactDOM.render(<></>, document.getElementById("menu"));
        }

    }, [props.history.location.pathname]);


    //메뉴 선택시 TAB 추가
    const addTabHandler = (path = DEFAULT_TAB_PATH) => {
      const idx = opened.findIndex((item: string) => compMenu(item) === compMenu(path));
      if (idx < 0) {
        dispatch({
          type: ADD_TAB,
          path,
        });
      } else {
        dispatch({
          type: CLICK_TAB,
          select: idx,
        });
      }
    };
    
    return (
        <div id="nav_lnb" className={`${stateWrapExpend? "on " : ""}`}>
            <a style={{'cursor':'pointer'}} className="btn_close ir" onClick={() => onWrapExpendHandler()}>닫기</a>
            <dl className="lst_lnb" id="menu">
                
            </dl>
        </div>
    )
})

export default Lnb;

import { POINT_CONVERSION_COMPRESSED } from 'constants';
import React from 'react';
import { ROOM_TYPE_CODE, ROOM_TYPE } from '../../../constants';

interface Props {
  name?: string
  id?: string
  className?: string
  onChange: Function
  value: number
  onlyData?: boolean
}

const Visable = (props: Props) => {
  return (
    <select name={props.name} id={props.id} className={props.className || 'form_control pc'} value={props.value} onChange={(e: any) => props.onChange(parseInt(e.target.value))}>
      {!props.onlyData && (<option value={-1}>전체</option>)}
      <option value={1}>표시</option>
      <option value={0}>미표시</option>
    </select>
  );
};

export default Visable;

import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class User {
  static URL = `${IP}`;

  @apiHandler()
  static async getUsers(payload: any) {
    const PATH = '/admin/auth/user/';
    return await axios.get(`${User.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  @apiHandler()
  static async getWorksite() {
    const PATH = '/admin/worksite/';
    return await axios.get(`${User.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  // 회원 현황 상세 회원 상태 히스토리 상태 데이터
  @apiHandler()
  static async suspendedGetId(id : number) {
    const PATH = `/admin/auth/memberuse/?id=${id}`;
    return await axios.get(`${User.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  //회원 현황 상세에서 이용정지
  @apiHandler()
  static async suspendedId(id: number, payload: any){
    const PATH = `/admin/auth/memberuse/?id=${id}`;
    return await axios.post(`${User.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  //회원 현황 상세에서 관리자 지정
  @apiHandler()
  static async updateAdmin(payload: any){
    const PATH = `/admin/auth/memberuse/`;
    return await axios.put(`${User.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }
}

export default User;

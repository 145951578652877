import React, { useContext, useEffect, useState } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { includes } from 'lodash';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import YearSelect from '../yearSelect';
import MonthSelect from '../monthSelect';
import { numberFormatter } from '../../../utils/common';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import { useSelector } from 'react-redux';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';

const UlsanInit = observer((props: any) => {
    const { userInfo = {} } = useSelector((state: any) => ({
      userInfo: state.user
    }));
    const {ulsanStore: store} = useStores();
    const {headerStore : headerStore} = useStores();
    const [grid1, setGrid1] = useState<any>(null);
    const baseUrl = '/guest/deductible';

    const { info, warning, error }: MessageImpl = useContext(MessageContext);

    const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
    const [downloadText, setDownloadText] = useState<string>("");

    useEffect(() => {
      store.init();
      headerStore.getAuthMenuRole('2-4');
      (async () => {
        headerStore.isLoading = true;
        try {
          let utilItems : any = await store.getUlsanUtilItems();
          if(utilItems.save) {
            await store.getItems();
            headerStore.isLoading = false;
            return;
          } else {
            headerStore.isLoading= false;
            return;
          }
        }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
          info("문제가 발생했습니다");
          return;
      }
    })();
      return () => {
        if (!store.findTabs(baseUrl)) {
          store.destory();
          store.init();
        }
      }
    }, [store.year, store.month]);

    // 울산공장: 1, 남양연구소: 2, 전주공장: 3, 아산공장: 4,
    if (!includes([1], Number(headerStore.workSite)) || store.step !== 1) return <></>;

    const onSourceGrid1Ready = (params: any) => {
      setGrid1(params.api);
    };

    const onApply = () => {
      store.calcPrics();
    };

    const onSave = async() => {
      if(store.items.length <= 0) {
        info('당월 검침량이 존재하지 않습니다.');
        return;
      }
      
      headerStore.isLoading = true;
      let data : any = await store.saveUtil();
      headerStore.isLoading = false;
      
      if(data.save) {
        info('저장완료 했습니다.');
        await store.getItems();
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }
      
    };

    const onNext = () => {
      store.items = [];
      store.step = 2;
    };

    // 다운로드 
    const xlsDownload = async()=>{
      try {
          headerStore.isLoading = true;
          const payload: any = {
            employee_number : userInfo.employeeNumber,
            worksite: '울산',
            deduct_date: `${store.year}${store.month}`,
            reason : downloadText,
          };
      
          let { data } = await Core.xlsDeductUlsanutil(payload);
          if(data) {
              FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
          }
          setIsShowDownloadPop(false);
          setDownloadText('');
          headerStore.isLoading = false;
      }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
      }
    }

    const isApply = () => store.item !== null;

    return (
      <>
        <div className="table_modify_list mt10">
          <dl className="autowidth">
            <dt>년도</dt>
            <dd>
              <YearSelect year={store.year} onChange={(e: any) => store.year = e.target.value} />
            </dd>
          </dl>
          <dl className="autowidth">
            <dt></dt>
            <dd>
              <MonthSelect month={store.month} onChange={(e: any) => store.month = e.target.value} />
            </dd>
          </dl>
            <dl className="autowidth">
              <dt>전기</dt>
              <dd>
                <input type="text" className="form_control col_md"
                  value={store.electric}
                  onChange={(e: any) => {store.electric = Number(getEscapeString(e.target.value, INPUT_TYPE['phoneNum']))}}
                  maxLength={6}
                />
              </dd>
            </dl>
            <dl className="autowidth">
              <dt>수도</dt>
              <dd>
                <input type="text" className="form_control col_md"
                  value={store.water}
                  onChange={(e: any) => {store.water = Number(getEscapeString(e.target.value, INPUT_TYPE['phoneNum']))}}
                  maxLength={6}
                />
              </dd>
            </dl>
            <dl className="autowidth">
              <dt>가스</dt>
              <dd>
                <input type="text" className="form_control col_md"
                  value={store.gas}
                  onChange={(e: any) => {store.gas = Number(getEscapeString(e.target.value, INPUT_TYPE['phoneNum']))}}
                  maxLength={6}
                />
                {headerStore.writeRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') && !isApply() ? 
                  <button className="btn btn_sm btn_gray ml10" onClick={onApply}>적용</button>
                  : <></>
                }
              </dd>
            </dl>
        </div>

        <h4>당월공제 리스트
          <div className="right_area">
            <button type="button" className="btn btn_md btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button>
          </div>
        </h4>
        <div className="table_normal_list">
          <div className="ag-theme-alpine" style={{height: '500px'}}>
            <AgGridReact
              modules={AllCommunityModules}
              defaultColDef={{
                width: 100,
                wrapText: true,
                resizable: true,
                cellStyle: { textAlign: 'center', fontSize: '13px' },
                headerClass: 'text-center',
                editable: false,
              }}
              suppressRowTransform={true}
              suppressRowClickSelection={true}
              onGridReady={onSourceGrid1Ready}
              multiSortKey={'ctrl'}
              rowSelection={'multiple'}
              rowData={store.items}
            >
              <AgGridColumn headerName="기준년월" field="apply_date" width={120} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="신청지역" field="worksite" width={200} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="숙소구분" field="roomtype" width={200} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="건물" field="building_name" width={150} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="동(이름)" field="dong" width={120} unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="층(이름)" field="floor" width={120} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="호수(이름)" field="ho" width={130} unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="정원" field="quota" width={110} cellStyle={{ justifyContent: 'flex-end' }} unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="사용료" field="room_charge" width={120} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.room_charge)} unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="유틸리티" field="util_charge" width={120} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.util_charge)} unSortIcon={true} sortable={true}/>
              {/*<AgGridColumn headerName="구분" field="classify" width={100} />
              <AgGridColumn headerName="신축구분" field="new_div" width={100} />
              <AgGridColumn headerName="등록일자" field="regist_date" width={100} />
            <AgGridColumn headerName="폐쇄일자" field="closing_date" width={100} />*/}
            </AgGridReact>
          </div>
        </div>

        <div className="submit ac">
          {headerStore.writeRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') ?
            <>
              {isApply() ? <></> : <button type="button" className="btn btn_lg btn_darkblue" onClick={onSave}>저장</button>}
              <button type="button" className="btn btn_lg btn_darkblue" onClick={onNext}>당월공제내역작성</button>
            </>
            :<></>
          }
        </div>

        {/* 다운로드 사유 팝업 */}
      <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
          <div className="pop_wrap">
              <div className="title_wrap">
              <h3>다운로드 사유</h3>
              <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
              </div>
              <div className="contents_wrap">
              <div className="process_list">
                  <dl>
                  <dt>사유</dt>
                  <dd>
                      <textarea className="form_control"
                      value={downloadText}
                      onChange={(e: any) => setDownloadText(e.target.value)}
                      >
                      </textarea>
                  </dd>
                  </dl>
              </div>
              </div>
              <div className="button_wrap btn_length_1">
              <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
              </div>
          </div>
      </div>
      </>
    );
  });

  export default UlsanInit;
/*
  헤더 한글/영어 변환
*/

export const getSelectedLang = (): string =>
  window.localStorage.getItem('lang') || 'kr';

export const setSelectedLang = (lang: string) =>
  window.localStorage.setItem('lang', lang);

export const getToggleLang = (): string =>
  getSelectedLang() === 'kr' ? 'en' : 'kr';

import { action, makeObservable, observable } from 'mobx';

import * as apis from '../../apis';
import Lodge from '../../apis/lodge';

import { getTodayDate } from '../../utils/date';
import {
  DELAY_COUNT_CODE,
  LODGE_MOVE_CODE,
  LODGE_STATUS_CODE,
  MOVE_STATUS_CODE,
  ROOM_TYPE_CODE,
} from '../../constants';
import { isNull } from 'lodash';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

// 모바일용 store
export class MobileTotalStore {
  //paging
  limit: number = 10;
  offset: number = 0;
  totalPage: number = 0;
  totalCount: number = 0;

  //리스트 또는 단일 객체
  list: any[] = [];
  item: any = {};

  paramAuthBuildingRole: any = [];

  //검색조건
  option: string = 'guest_name';
  text: string = '';
  status: string = '';
  selectWorkSite: number = 0;
  selectRoomType: number = 0;
  selectBuilding: string = '';
  selectDong: string = '';
  selectGubun: string = '';
  selectStatus: string = '';
  selectCategory: string = '';
  buildingList: any = [];
  dongList: any = [];

  // 선택한 데이터
  selectData: any = [];

  // 입숙처리용 데이터
  keyoutAt: string = getTodayDate()[0];
  bedding: number = 0;
  files: any = [];

  // 퇴숙 신청시 전송할 데이터 // 이숙신청시 팝업 전송할 데이터
  managedAt: string = getTodayDate()[0];
  isKeyReturn: boolean = true;
  keySelect: string = '열쇠 분실';
  keyDescription: string = '';
  isEquipment: boolean = false;
  equipmentDescription: string = '';
  images: any = [];
  mealTicket: number = 0;
  memo: string = '';

  // 이숙처리용 데이터
  status_description: string = '';
  roomId: number = 0;
  tempCheckChangeDetailItem: any = [];
  checkChangeDetailItem: any = [];

  //guest_party
  guest_party: string = '';

  roomtype: any;
  building: any;
  work_site: any;
  auth_Menu: any;
  authBuilding: any;
  employee_number: any;

  // 유지운영 detail
  vocData: any;
  category1: any[] = [];
  category2: any[] = [];
  category3: any[] = [];
  category4: any[] = [];
  comment: string = '';
  username: string = '';
  answerImages: any = [];

  //loading
  headerStore: HeaderStore;

  constructor(root: any) {
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      limit: observable,
      offset: observable,
      totalPage: observable,
      totalCount: observable,
      list: observable,
      item: observable,
      paramAuthBuildingRole: observable,
      status: observable,
      option: observable,
      selectWorkSite: observable,
      selectRoomType: observable,
      selectBuilding: observable,
      selectDong: observable,
      selectGubun: observable,
      selectStatus: observable,
      selectCategory: observable,
      buildingList: observable,
      dongList: observable,
      text: observable,
      keyoutAt: observable,
      bedding: observable,
      files: observable,
      managedAt: observable,
      isKeyReturn: observable,
      keySelect: observable,
      keyDescription: observable,
      isEquipment: observable,
      equipmentDescription: observable,
      images: observable,
      mealTicket: observable,
      selectData: observable,
      memo: observable,
      status_description: observable,
      roomId: observable,
      work_site: observable,
      auth_Menu: observable,
      authBuilding: observable,
      employee_number: observable,
      tempCheckChangeDetailItem: observable,
      checkChangeDetailItem: observable,
      guest_party: observable,
      roomtype: observable,
      building: observable,
      vocData: observable,
      category1: observable,
      category2: observable,
      category3: observable,
      category4: observable,
      comment: observable,
      username: observable,
      answerImages: observable,

      init: action,
      getCheckInList: action,
      getBuildingList: action,
      getBuildingDongList: action,
    });
  }

  //초기화
  init() {
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.totalCount = 0;
    this.list = [];
    this.item = {};
    this.option = '';
    this.text = '';
    this.building = '';
    this.selectData = [];
    this.vocData = [];
    this.selectWorkSite = 0;
    this.selectRoomType = 0;
    this.selectBuilding = '';
    this.selectDong = '';
    this.selectGubun = '';
    this.selectStatus = '';
    this.selectCategory = '';
    this.buildingList = [];
    this.dongList = [];
  }

  // 건물명 select 가져오기
  async getBuildingList() {
    const payload: any = {
      room_type: this.selectRoomType,
      work_site: this.selectWorkSite,
    };

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBuildings(payload);
      this.buildingList = data;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 건물 관련 동 select 가져오기
  async getBuildingDongList() {
    const payload: any = {
      room_type: this.selectRoomType,
      work_site: this.selectWorkSite,
      name: this.selectBuilding,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBuildingDongs(payload);
      this.dongList = data;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 입숙처리 가능 리스트 가져오기
  async getCheckInList() {
    const payload: any = {
      //  status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(','),
      offset: this.offset,
      limit: this.limit,
      guest_status: LODGE_STATUS_CODE.waiting,
      roomtype: this.roomtype,
      worksite: this.work_site,
    };

    if (this.selectRoomType != 0) {
      payload.roomtype = this.selectRoomType;
    }
    if (this.selectBuilding != '') {
      payload.building_name = this.selectBuilding;
    }
    if (this.selectDong != '') {
      payload.dong = this.selectDong;
    }
    if (this.text != '') {
      payload.guest_name = this.text;
    }

    if (Number(this.work_site) === 2) {
      if (
        !isNull(this.paramAuthBuildingRole) &&
        this.paramAuthBuildingRole !== undefined &&
        this.paramAuthBuildingRole.length > 0
      ) {
        payload.authBuilding = this.paramAuthBuildingRole;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBookings(payload);
      this.totalCount = data.count;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //입숙 현황 리스트 가져오기
  async getTotalList() {
    this.status = [LODGE_STATUS_CODE.waiting, LODGE_STATUS_CODE.checkin].join(',');

    const payload: any = {
      status: this.status,
      offset: this.offset,
      limit: this.limit,
      worksite: this.work_site,
      roomtype: this.roomtype,
    };

    if (this.selectRoomType != 0) {
      payload.roomtype = this.selectRoomType;
    }
    if (this.selectBuilding != '') {
      payload.building_name = this.selectBuilding;
    }
    if (this.selectDong != '') {
      payload.dong = this.selectDong;
    }
    if (this.text != '') {
      payload.guest_name = this.text;
    }

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.lodgeGuestsCheckin(payload);
      // console.log(data);
      this.totalCount = data.count;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //퇴숙 리스트 가져오기
  async getCheckOutList() {
    this.status = LODGE_STATUS_CODE.checkin + '';
    const payload: any = {
      status: this.status,
      offset: this.offset,
      limit: this.limit,
      work_site: this.work_site,
      roomtype: this.roomtype,
    };

    if (this.selectRoomType != 0) {
      payload.roomtype = this.selectRoomType;
    }
    if (this.selectBuilding != '') {
      payload.building_name = this.selectBuilding;
    }
    if (this.selectDong != '') {
      payload.dong = this.selectDong;
    }
    if (this.text != '') {
      payload.guest_name = this.text;
    }

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.lodgeGuestsCheckout(payload);
      // console.log(data);
      this.totalCount = data.count;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //이숙신청 리스트 가져오기
  async getGuestMoveList() {
    const payload: any = {
      offset: this.offset,
      limit: this.limit,
      worksite: this.work_site,
      roomtype: this.roomtype,
      move_status: MOVE_STATUS_CODE.approve,
    };

    if (this.selectRoomType != 0) {
      payload.roomtype = this.selectRoomType;
    }
    if (this.selectBuilding != '') {
      payload.building_name = this.selectBuilding;
    }
    if (this.selectDong != '') {
      payload.dong = this.selectDong;
    }
    if (this.text != '') {
      payload.guest_name = this.text;
    }

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.getGuestMoveList(payload);
      // console.log(data);
      this.totalCount = data.count;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 팝업 초기화
  popInit() {
    this.selectData = [];
    this.managedAt = getTodayDate()[0];
    this.keyoutAt = getTodayDate()[0];
    this.mealTicket = 0;
    this.bedding = 0;
    this.memo = '';
    this.files = [];
    this.isKeyReturn = true;
    this.keyDescription = '';
    this.keySelect = '열쇠 분실';
    this.isEquipment = false;
    this.equipmentDescription = '';
    this.vocData = [];
  }

  // 입숙처리
  async checkIn() {
    let fileIds: any = this.files.map((obj: any) => {
      return obj.id;
    });

    const payload: any = {
      managed_at: this.managedAt,
      keyout_at: this.keyoutAt,
      meal_ticket: this.mealTicket,
      bedding: this.bedding,
      memo: this.memo,
      files: fileIds,
    };

    try {
      this.headerStore.isLoading = true;
      let { data } = await apis.lodge.checkin(this.selectData.id, payload);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 퇴숙신청
  async checkOut() {
    let imgIds: any = this.files.map((obj: any) => {
      return obj.id;
    });

    const payload: any = {
      managed_at: this.managedAt,
      is_key_return: this.isKeyReturn,
      key_description: this.keyDescription,
      key_select: this.keySelect,
      is_equipment_ok: this.isEquipment,
      equipment_description: this.equipmentDescription,
      images: imgIds,
      meal_ticket: this.mealTicket,
      //'memo':''
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuestCheckoutApplicant(this.selectData.booking.id, payload);

      // 문자 발송
      let smsPayload: any = {
        cellphone: this.selectData.guest_cellphone,
        message:
          '퇴숙신청이 확정되었습니다. \n퇴숙일 : ' + this.managedAt + '\n사용료는 익월 급여공제예정(일할계산)입니다.',
      };
      console.log('mobileTotalStore');
      console.log(this);
      if (this.selectData.room_type !== ROOM_TYPE_CODE.vip_room && this.selectData.room_type !== ROOM_TYPE_CODE.family_room) {
        const { data: sms } = await Lodge.smsSendAPI(smsPayload);
      }
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //이숙처리
  async moveAccess() {
    let imgIds: any = this.files.map((obj: any) => {
      return obj.id;
    });

    const payload: any = {
      //'room': this.roomId,
      managed_at: this.managedAt,
      is_key_return: this.isKeyReturn,
      key_description: this.keyDescription,
      key_select: this.keySelect,
      is_equipment_ok: this.isEquipment,
      equipment_description: this.equipmentDescription,
      images: imgIds,
      memo: this.memo,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.moveApplicate(this.selectData.id, payload);

      // 이숙 1년에 1회만 가능하다는 데이터 담기
      let moveStatus: number = 0;
      if (this.selectData.category === LODGE_MOVE_CODE.alway) {
        moveStatus = DELAY_COUNT_CODE.normal;
      } else if (this.selectData.category === LODGE_MOVE_CODE.fixed) {
        moveStatus = DELAY_COUNT_CODE.regular;
      }
      const moveData: any = {
        classify: this.selectData.guest.guest_employee_number,
        status: moveStatus,
        booking: this.selectData.guest.booking_id,
      };

      const { data: move } = await Lodge.lodgeMoveCheck(
        this.selectData.guest.guest_employee_number,
        moveStatus,
        moveData,
      );
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 1:1 이숙처리
  async oneToOneMoveAccess() {
    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.oneToOneMoveAccess(this.selectData.id);

      // 이숙 1년에 1회만 가능하다는 데이터 담기
      const moveData: any = {
        classify: this.selectData.guest.guest_employee_number,
        status: DELAY_COUNT_CODE.oneOne,
        booking: this.selectData.guest.booking_id,
      };

      const { data: move } = await Lodge.lodgeMoveCheck(
        this.selectData.guest.guest_employee_number,
        DELAY_COUNT_CODE.oneOne,
        moveData,
      );
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // voc 유지운영 리스트
  async getVocList() {
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      work_site: this.work_site,
      //roomtype : this.roomtype,
      auth_Menu: this.auth_Menu,
      employee_number: this.employee_number,
    };

    if (this.selectRoomType != 0) {
      filter.roomtype = this.selectRoomType;
    }
    if (this.selectBuilding != '') {
      filter.building_name = this.selectBuilding;
    }
    if (this.selectDong != '') {
      filter.dong = this.selectDong;
    }
    if (this.selectCategory != '') {
      filter.category1 = this.selectCategory;
    }
    // if(this.selectCategory != ''){
    //   filter.category1 = this.selectGubun;
    // }
    // if(this.selectCategory != ''){
    //   filter.category1 = this.selectStatus;
    // }

    if (Number(this.work_site) === 2) {
      if (
        !isNull(this.authBuilding) &&
        this.authBuilding !== undefined &&
        this.authBuilding.length > 0 &&
        this.authBuilding !== 'null'
      ) {
        filter.authBuilding = this.authBuilding;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getVocs(filter);
      this.totalCount = data.count;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 유지운영 상세
  async getVocData(id: string) {
    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getVoc(id);
      if (data) {
        this.vocData = data;
      }
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async getCategories4() {
    if (!this.item) return;

    const filter = {
      category1: this.vocData.category1,
      category2: this.vocData.category2,
      category3: this.vocData.category3,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getCategories(filter);
      this.category4 = data.map((item: any) => ({ name: item.category4 }));
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async saveComment() {
    const comment: any = {
      voc: this.vocData.id,
      contents: this.comment,
      author: this.username,
      created_at: getTodayDate()[0],
    };
    comment.images = this.answerImages.map((image: any) => image.id);

    try {
      this.headerStore.isLoading = true;
      this.comment = '';
      await apis.voc.addComment(comment);
      this.answerImages = [];
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async saveOnlyComment() {
    let payload: any = {
      voc: this.vocData.id,
      contents: this.comment,
      author: this.username,
      created_at: getTodayDate()[0],
    };

    payload.images = this.answerImages.map((image: any) => image.id);
    // delete comment.author;
    // delete comment.created_at;
    try {
      this.headerStore.isLoading = true;
      await apis.voc.addOnlyComment(payload);
      payload = null;
      //this.contents = '';
      this.headerStore.isLoading = false;
      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async statusChage() {
    // const comment = { ...this.comment };
    let payload = {
      id: this.vocData.id,
      is_complete: !this.vocData.is_complete,
    };

    try {
      this.headerStore.isLoading = true;
      await apis.voc.statusChagne(payload);

      this.headerStore.isLoading = false;
      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }
}

import React from 'react';
import Room from './room';

interface props {
  floors: any;
  selectRoom: any;

  onRoomClickHandlerToSelect: Function
  onRoomClickHandlerToUnSelect: Function
  isUsable: Function
  isDisable: Function
  isSelect: Function

  disableText?: Function

  selectFloor: number;
  isDetail?: boolean;
  isGuide?:boolean
}

//배방 네모칸 한층
const Floor = ({
  floors,
  selectRoom,
  selectFloor,
  isDetail,
  disableText,
  onRoomClickHandlerToSelect,
  onRoomClickHandlerToUnSelect,
  isGuide,
  isUsable,
  isDisable,
  isSelect
}: props) => {
  const isAllSelectFloor = (selectFloor === -1) || (selectFloor > (floors.length - 1) );
  //const isAllSelectFloor = (selectFloor === -1);
  floors = isAllSelectFloor ? floors : [floors[selectFloor]];

  const detailText = (floor: any) =>
    isDetail
      ? `${floor.building_name} ${floor.building_number} ${floor.step}`
      : floor.step;
  
  return floors.map((floor: any, idx: number) =>
    floor.rooms.length ? (
      <React.Fragment key={idx}>
        <dt className="dongho">
          {detailText(floor)}F
          {isGuide && !idx
            ? (
              <ul className="lst_info clearfix" >
                <li>이용 가능</li>
                <li>이용 불가능</li>
              </ul>
            )
            : <></>
          }
        </dt>
        <dd>
          <ul className="lst_room clearfix">
            {floor.rooms.map((room: any, idx: number) => (
              <Room
                room={room}
                onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                selectRoom={selectRoom}
                isDetail={isDetail}
                key={idx}
                isUsable={isUsable}
                isDisable={isDisable}
                isSelect={isSelect}
                disableText={disableText ? disableText : () => ""}
              />
            ))}
          </ul>
        </dd>
      </React.Fragment>
    ) : (
        <></>
      ),
  );
};

export default Floor;

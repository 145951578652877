import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Build {
  static URL = `${IP}`;

  //건물정보 조회
  @apiHandler()
  static async getBuilds(payload: any) {
    const PATH = '/admin/building/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /*
   * today: true로 전달해야 오늘 기준으로 조회됨
   */
  @apiHandler()
  static async getFloors(payload: any) {
    const PATH = '/admin/building/floor/guest/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // gender_type 안보는 floor lists
  @apiHandler()
  static async getFloors2(payload: any) {
    const PATH = '/admin/building/floor/guest2/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getRoomsList(payload: any) {
    const PATH = '/admin/building/building_floor/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //동별유지운영내역 사업장유형별 건물 조회
  @apiHandler()
  static async getFloorsVoc(payload: any) {
    const PATH = '/admin/building/floor/voc/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용신청관리-이용관리조회(퇴숙신청)
  @apiHandler()
  static async getGuest(payload: any) {
    const PATH = '/admin/lodge/guest/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용신청관리-입숙신청관리!
  @apiHandler()
  static async getBookings(payload: any) {
    const PATH = '/admin/lodge/booking/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용신청관리-입숙신청관리!
  @apiHandler()
  static async getBooking(bookingId: number, payload: any) {
    const PATH = `/admin/lodge/booking/${bookingId}/`;
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용신청관리-입숙신청관리 (tab = 기숙사, 사택인 경우)
  @apiHandler()
  static async getBookingRanks(payload: any) {
    const PATH = '/admin/lodge/bookingrank/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async createBuilding(payload: any) {
    const PATH = `/admin/building/`;

    return await axios.post(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async updateBuilding(id: number, payload: any) {
    const PATH = `/admin/building/${id}/`;

    return await axios.patch(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async deleteBuilding(id: number) {
    const PATH = `/admin/building/${id}/`;

    return await axios.delete(`${Build.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async setRoomAvailable(id: string, payload: any) {
    const PATH = `/admin/building/room/${id}/`;

    return await axios.patch(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  

  //숙소관리 > 건물 > 객실정보 가져오기
  @apiHandler()
  static async getFloorRooms(payload: any) {
    const PATH = `/admin/building/floor/`;

    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //건물 층 추가
  @apiHandler()
  static async addFloor(payload: any) {
    const PATH = `/admin/building/floor/`;

    return await axios.post(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async setFloor(id: number, payload: any) {
    const PATH = `/admin/building/floor/${id}/`;

    return await axios.patch(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //건물 층 삭제
  @apiHandler()
  static async deleteFloor(id: number) {
    const PATH = `/admin/building/floor/${id}/`;

    return await axios.delete(`${Build.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //객실추가
  @apiHandler()
  static async addRoom(payload: any) {
    const PATH = `/admin/building/room/`;

    return await axios.post(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //객실수정
  @apiHandler()
  static async setRoom(id: number, payload: any) {
    const PATH = `/admin/building/room/${id}/`;

    return await axios.patch(`${Build.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //객실삭제
  @apiHandler()
  static async deleteRoom(id: number) {
    const PATH = `/admin/building/room/${id}/`;

    return await axios.delete(`${Build.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 숙소유형, 사업장별 건물명 가져오기
  @apiHandler()
  static async getBuildings(payload: any) {
    const PATH = `/admin/building/mselect/roomtype/`;

    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 숙소유형, 사업장, 건물명으로 동정보 가져오기
  @apiHandler()
  static async getBuildingDongs(payload: any) {
    const PATH = `/admin/building/mselect/`;

    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Build;

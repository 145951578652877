import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { build as api } from '../../../apis';
import _, { isNull, map } from 'lodash';
import { MessageContext, MessageImpl } from '../../../routes';
import { getTodayDate } from '../../../utils/date';
import '../mb_common.scss';
import { ROOM_TYPE, ROOM_TYPE_CODE, VOC_ETC_CATEGORY } from '../../../constants';
import { useStores } from '../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const MobilePlace = observer ((props: any) => {
  const { headerStore: headerStore } = useStores();
  const etcIndex = props.places.findIndex((place: any) => place.building_name === '기타(지도)');
  const [room_types, setRoom_types] = useState<any[]>([]);
  const [buildings, setBuildings] = useState<any[]>([]);
  const [floors, setFloors] = useState<any[]>([]);
  const [room_type, setRoom_type] = useState<number>(-1);
  const [building, setBuilding] = useState<number>(-1);
  const [places, setPlaces] = useState<any[]>(props.places);
  const [etcImageUrl, setEtcImageUrl] = useState<string>('');
  const [etcText, setEtcText] = useState<string>('내부');
  const [etcCategory, setEtcCategory] = useState<any>([]);
  const [etcText2, setEtcText2] = useState<string>('');
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  //권한 가져오기
  useEffect(() => {
    headerStore.getAuthRoomRole();
    headerStore.getAuthWorkSiteRole();
    headerStore.getAuthBuilding();
  }, []);

  useEffect(() => {
    /* const items = [{ value: -1, label: '숙소유형 선택'}];
    Object.keys(ROOM_TYPE)
      .forEach(key => items.push({ value: Number(key), label: ROOM_TYPE[key] }));
    setRoom_types(items); */

    let options: any[] = [{ value: -1, label: '숙소유형 선택' }];
    for (let i = 0; i < headerStore.paramAuthRoomTypeRole?.split(",").length; i++) {
      options.push({ value: headerStore.paramAuthRoomTypeRole.split(",")[i], label: ROOM_TYPE[headerStore.paramAuthRoomTypeRole.split(",")[i]] })
    }
    options.push({ value: 7, label: '기타' });
    setRoom_types(options);
  }, []);

  useEffect(() => {
    (async () => {
      const init = () => setBuildings([{ id: -1, name: '건물명 선택' }]);
      if (room_type < 0) {
        init();
      } else if(Number(room_type) === 7){
        // 발생장소 기타 선택한 경우 selectbox
        let tmpList:any = VOC_ETC_CATEGORY.find(c => c.name === etcText);
        setEtcCategory([
          ...tmpList.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
        ]);
        setEtcText2(tmpList.child[0].name);
      } else {
        let building = '';
        if (Number(headerStore.workSite) === 2) {
          if (!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== '' && headerStore.paramAuthBuildingRole.length > 0) {
            //setRoom_types(headerStore.paramAuthBuildingRole);
            building = headerStore.paramAuthBuildingRole;
          }
        }

        let payload: any = {
          work_site: headerStore.workSite,
          room_type: room_type
        };

        if (building != '') {  // 남양일 경우, 건물명
          payload['auth_Building'] = building;
        }

        try{

          headerStore.isLoading = true;
          const { data } = await api.getBuilds(payload);
          if (data.length > 0) {
            setBuildings([{ id: -1, name: '건물명 선택' }, ...data]);
          } else {
            init();
          }
          headerStore.isLoading = false;
        }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          info("문제가 발생했습니다");
          return;
        }
      }
    })();
  }, [room_type]);

  useEffect(() => {
    if (buildings.length > 1) {
      setBuilding(buildings[1].id);
    } else {
      setBuilding(-1);
    }
    setFloors([]);
  }, [buildings]);

  useEffect(() => {
    const init = () => setFloors([]);
    (async () => {
      if (building < 0) {
        init();
      } else {
        try{
          headerStore.isLoading = true;
          const today = getTodayDate()[0];
          const { data } = await api.getFloors2({
            building,
            //stay_after: today,
            //stay_before: today,
          });
          if (data.length > 0) {
            if (room_type !== 7) {
              let d = _.cloneDeep(_.orderBy(data, 'step', 'desc'))
              setFloors(data);
            } else {
              setFloors(data);
            }
          } else {
            init();
          }
          headerStore.isLoading = false;
        }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          info("문제가 발생했습니다");
          return;
        }
      }
      selectedEtcObject(building);
    })();
  }, [building]);

  useEffect(() => {
    if (props.isOpen && etcIndex > -1) {
      setRoom_type(7);
    }
    // console.log('props.isOpen:', props.isOpen);
  }, [props.isOpen]);

  const close = async () => {
    setBuildings([]);
    setFloors([]);
    setRoom_type(-1);
    setBuilding(-1);
    setPlaces([]);
    props.setIsOpen(false);
  };

  const onApply = () => {
    // 숙소유형을 기타로 선택한 경우
    if(Number(room_type) === 7){
      if(!etcText || etcText === '' || !etcText2 || etcText2 === '') {
        info('기타인 경우 분류를 선택해주세요');
        return;
      }

      props.onApply([], room_type, etcText, etcText2);
      close();
    }else {
      if (!props.isView) {
        if (places.length === 0) {
          info('선택된 장소가 없습니다.');
          return;
        }
        props.onApply(places, room_type, '', '');
      }
      close();
    }
  };

  const onRoomTypeChnage = async (e: any) => {
    const id = Number(e.target.value);
    setRoom_type(id);
  };

  const onBuildingChnage = async (e: any) => {
    const id = Number(e.target.value);
    setBuilding(id);
  };

  const onSelectedRoom = (room: any, floor: any = null) => {
    const _places = [...places];
    const index = _places.findIndex(place => place.id === room.id);
    if (index > -1) {
      _places.splice(index, 1);
    } else {
      const item = buildings.find(bd => bd.id === building);
      _places.push({
        id: room.id,
        room_type : room.room_type,
        building_name: item.name,
        building_dong: item.number,
        floor_name: floor.step,
        room_name: room.name,
      });
    }
    setPlaces(_places);
  };

  const selectedEtcObject = (buildingId: number): any | null => {
    if (room_type === 7) {
      const buildingItem = buildings.find(item => item.id === buildingId);
      if (buildingItem) {
        setEtcImageUrl(`${process.env.REACT_APP_MEDIA}/${buildingItem.map_image}`);
      }
    }
  };

  const GroupSelectLocation = () => {
    const buildingName = () => {
      const buildingItem = buildings.find(bd => bd.id === building);
      return buildingItem ? buildingItem.name : '';
    }

    let typeNum: string = '1';
    if (room_type === ROOM_TYPE_CODE.dormitory_room) {
      typeNum = '1';
    } else if (room_type === ROOM_TYPE_CODE.company_room) {
      typeNum = '2';
    } else if (room_type === ROOM_TYPE_CODE.business_room || room_type === ROOM_TYPE_CODE.business_room_group) {
      typeNum = '3';
    } else if (room_type === ROOM_TYPE_CODE.vip_room) {
      typeNum = '4';
    } else if (room_type === ROOM_TYPE_CODE.family_room) {
      typeNum = '5';
    }

    return floors.length > 0 ? (
      <>
        <div className="type_area">
          {floors.map((floor: any, index: number) => (
            <ul key={index} className={"lst_floortype type" + typeNum}>
              {floor.rooms.map((room: any) => (
                <li
                  key={room.id}
                  onClick={() => onSelectedRoom(room, floor)}>
                  <a
                    href="javascript:void(0);"
                    className={[places.findIndex(place => place.id === room.id) > -1 ? 'on' : ''].join(' ')}>
                    {room.name}호
                  </a>
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="txt_current">{buildingName()}</div>
      </>
    ) : (<></>);
  };

  const GroupCoordinate = () => {
    return floors.length > 0 ? (
      <div id="pop_select_location">
        <div className="group_coordinate">
          <ul className="lst_top">
            {floors[0].rooms.map((room: any, index: number) => (
              <li key={index}><span>{room.name}</span></li>
            ))}
          </ul>
          <ul className="lst_left">
            {floors.map((bd, index) => (
              <li key={index}><span>{index + 1}</span></li>
            ))}
          </ul>
          <div className="img_area">
            {etcImageUrl ? <img src={`${etcImageUrl}`} alt="" /> : <></>}
            <ul className="lst_area">
              {floors.map((floor: any, fIndex: number) =>
                floor.rooms.map((room: any, rIndex: number) => (
                  <li key={room.id} onClick={() => onSelectedRoom(room, floor)}>
                    <a href="javascript:void(0);"
                      className={[places.findIndex(place => place.id === room.id) > -1 ? 'active' : ''].join(' ')}>
                    </a>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
    ) : (<></>);
  };

  const onDeletePlace = (_place: any) => {
    const _places = places.filter(p => p.id !== _place.id);
    setPlaces(_places);
  };

  return (
    <div
      id="pop_select_spot"
      className="pop_modal"
      style={{ display: props.isOpen ? 'block' : 'none' }}>
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>숙소 내 장소보기</h3>
          <button
            className="pop_btn_close pop_close"
            onClick={() => props.setIsOpen(false)}>
            닫기
          </button>
        </div>
        <div className="contents_wrap">
          {props.isView ? <></> : (
            <div className="form_inline">
              <select
                name=""
                id=""
                className="form_control"
                value={room_type}
                onChange={onRoomTypeChnage}>
                {room_types.map((item, index) => (
                  <option key={index} value={item.value}>{item.label}</option>
                ))}
              </select>
              {Number(room_type) !== 7 ? (
                <select
                  name=""
                  id=""
                  className="form_control"
                  value={building}
                  onChange={onBuildingChnage}
                  style={{marginLeft: '3px'}}
                >
                  {/* <option value="-1">건물명 선택</option> */}
                  {buildings.map((item: any, index: number) => (
                    <option key={`${room_type}_${index}`} value={item.id}>{item.name} {item.dong}</option>
                  ))}
                </select>
              ):(
                <>
                  <div className="form_inline mt10">
                    <select
                      name=""
                      id=""
                      className="form_control"
                      value={etcText}
                      onChange={(e:any)=>{
                        setEtcText(e.target.value);
                        let tmpList:any = VOC_ETC_CATEGORY.find(c => c.name === e.target.value);
                        setEtcCategory([
                          ...tmpList.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                        ]);
                        setEtcText2(tmpList.child[0].name);
                      }}
                    >
                      {VOC_ETC_CATEGORY.map((category: any, index: number) => (
                        <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                    <select
                      name=""
                      id=""
                      className="form_control"
                      value={etcText2}
                      style={{marginLeft: '3px'}}
                      onChange={(e:any)=>{setEtcText2(e.target.value)}}
                    >
                      {etcCategory.map((category: any, index: number) => (
                        <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>
          )}

          {Number(room_type) !== 7 ? ( 
            <>
            <GroupSelectLocation />
          
            <div className="group_title">
              <h4 className="title_contents">선택 지역</h4>
              <div className="right_area">
                {!props.isView ? (
                  <button className="btn btn_sm btn_reset" onClick={() => setPlaces([])}>초기화</button>
                ) : ''}
              </div>
            </div>
            <div className="items_area">
              {places.map((_place, index) => (
                <a
                  key={index}
                  href="javascript:void(0);"
                  className={props.isView ? "item" : "item del"}
                  onClick={() => { if (!props.isView) onDeletePlace(_place) }}
                >
                  {
                    _place.building_name === '기타'
                      ? `${_place.building_name} > ${_place.room_name}-${_place.floor_name}`
                      : `${ROOM_TYPE[_place.room_type]} > ${_place.building_name} > ${_place.building_dong ? _place.building_dong + '>' : ''} ${_place.room_name}`
                  }
                </a>
              ))}
            </div>
            </>
          ) : (
            <>
            {/* <GroupCoordinate /> */}
            </>
          )}

        </div>

        <div className="button_wrap">
          {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
          <button type="button" className="btn btn_darkblue pop_close" onClick={onApply}>
            {props.isView ? '확인' : '적용'}
          </button>
        </div>
      </div>
    </div>
  );
});

export default MobilePlace;


// 이름 마스킹
export const maskingName = function(strName : string) {
    if (strName.length > 2) {
      let originName = strName.split('');
      originName.forEach(function(name, i) {
        if (i === 0 || i === originName.length - 1) return;
        originName[i] = '*';
      });
      let joinName = originName.join();
      return joinName.replace(/,/g, '');
    } else {
      let pattern = /.$/; // 정규식
      return strName.replace(pattern, '*');
    }
  };


// 핸드폰번호 마스킹
export const maskingPhone = function(str: string){
    if(str.length < 11) {
    // 1.1) 0110000000
        str = str.toString().replace(str, str.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
    } else {
    // 1.2) 01000000000
        str = str.toString().replace(str, str.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
    }
    return str; 
}
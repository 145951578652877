import React from 'react';

import SelectBad from '../../atoms/square/select';
import DisableBad from '../../atoms/square/disable';


//배방 네모칸
const Bad = (props: any) => {
  if (props.isDisable) {
    return <DisableBad> {props.disableText()} </DisableBad>;
  }
  if (props.selected) {
    return (
      <SelectBad
        selected={props.selected}
        onClick={() => { props.onRoomClickHandlerToUnSelect(props.room)}}
      >
        {props.isDetail ? props.room.guests[props.guestIdx]?.guest_name : ''}
      </SelectBad>
    )
  }

  return (
    <SelectBad
      // onClick={() => props.onClick(props.room)}
      onClick={() => { props.onRoomClickHandlerToSelect(props.room, props.guestIdx)}}
      selected={false}
    />
  )
  
};

export default Bad;

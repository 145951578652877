import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as apis from '../../../apis'
import Worksite from '../../atoms/select/worksite';
import Pagination from '../../organismas/pagination';
import _ from 'lodash';
import { formatByString } from '../../../utils/date';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import LimitSelect from '../../atoms/select/limit';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { WORK_SITE } from '../../../constants';
import ReactDOM from 'react-dom';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const Floor = observer(({ index, store, building, onDelete }: { index: number, store: any, building: number, onDelete: Function }) => {
  const item = store.item.floors[index];
  const {headerStore: headerStore} = useStores();
  const [ orgItem, setOrgItem ] = useState<string>(JSON.stringify(item));
  const [ id, setId ] = useState<number>(item.id);
  const [ name, setName ] = useState<string>(item.name);
  const [ total_room, setTotal_room ] = useState<number>(item.total_room);
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [worksite, setWorksite] = useState<any>(headerStore.workSite);

  useEffect(() => {
    headerStore.getAuthMenuRole('3-1');
  }, []);

  const saveFloorHandler = async () => {
    // const chnageFloor = store.changeFloors[]
    try {
      if (id < 0) {
        headerStore.isLoading = true;
        const { data } = await apis.build.addFloor({
          building,
          name,
          total_room,
          worksite : worksite,
        });
        setId(data.id);
        item.id = data.id;
        await store.getFloorRooms();
        headerStore.isLoading = false;
      } else {
        headerStore.isLoading = true;
        await apis.build.setFloor(id, {
          name,
          total_room,
        });
      }
      delete store.changeFloors[id];
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if(status === 400) {
        if(data === "중복된 정보입력"){
          info('이미 등록된 정보가 있습니다');
          return;
        }
      }else if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        //errorHandler({ status, data }, '저장 중 오류발생');
        info("문제가 발생했습니다");
        return;
      }
    }
  };

  useEffect(() => {
    changeCheck();
  }, [name, total_room, orgItem]);

  useEffect(() => {
    store.workSite = headerStore.workSite;
  }, []);

  //층삭제
  const deleteFloorHandler = async () => {
    // console.log('deleteFloorHandler : id >>>>>>>>>' , id);
    if (id > -1) {
      try {
        headerStore.isLoading = true;
        const { data } = await apis.build.deleteFloor(id);
        let data_t : any = await store.getFloorRooms();
        headerStore.isLoading = false;
        
        setTimeout(function () {
          if(data_t.save) {
            setId(data.id);
            item.id = data.id;
    
            // console.log('deleteFloorHandler : id2 >>>>>>>>>' , id);
            onDelete(id);
            return;
          }else {
            return;
          }
        }, 250);
      }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        
        info("문제가 발생했습니다.");
        return;
      }
      
    } else {
      // console.log('삭제실패');
    }
    
  };

  const changeCheck = () => {
    const isChange = orgItem !== JSON.stringify({ id, name, total_room });
    if (isChange) {
      store.changeFloors[item.id] = { id, name, total_room };
    }
    // setIsChangeValue(isChange);
  };

  const onChangeName = (e: any) => {
    e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum)
    setName(e.target.value);
    item.name = e.target.value;
  }

  const onChangeTotal_room = (e: any) => {
    const val = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    if (val) {
      setTotal_room(Number(val));
      item.total_room = Number(e.target.value);
    }
  }

  return (
    <li>
      <div className="first">
        <label>층</label>
        <input type="text" id="" className="form_control" placeholder="층수를 입력해주세요" value={name} onChange={onChangeName}/>
      </div>
      <div className="second">
        <label>세대수</label>
        <input
          type="text" id=""
          className="form_control"
          placeholder="세대수를 입력해주세요"
          value={total_room}
          onFocus={(e) => e.target.select()}
          onChange={onChangeTotal_room}/>
      </div>
      <div className="function">
        <>
        {headerStore.deleteRoleFlag ? 
          <button className="btn btn_sm btn_outline" onClick={() => deleteFloorHandler()}>삭제</button> : <></>
        }
        {store.changeFloors[item.id] ? (
          <>
          {headerStore.writeRoleFlag ?
            <button className="btn btn_sm btn_gray" onClick={() => saveFloorHandler()}>저장</button> : <></>
          }
          </>
        ) : (<></>)}
        </>
      </div>
    </li>
  );
});

const Building = observer((props: any) => {
  const {buildingStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const item = store.item;
  const [workList, setWorkList] = useState<any>([]);

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const floorView = useRef(null);

  useEffect(() => {
    headerStore.getAuthMenuRole('3-1');
    store.work_site= headerStore.workSite;
    if (floorView.current) {
      (floorView.current as any).scrollTop = (floorView.current as any).scrollHeight;// + 50;
    }
    setWorkList(headerStore.authWorkSiteLen[0]);
  }, [store.floorCount, headerStore.workSite]);

  const newBuilding = () => {
    store.work_site= headerStore.workSite;
    store.newItem()
  };

  const newFloor = () => {
    const found = store.item.floors.find((floor: any) => floor.id < 0);
    if (found) {
      warning(`새로 추가한 층을 저장 또는 삭제 후 추가가능합니다.`);
      return;
    }
    store.newFloor();
  };

  const selectedItem = (item: any) => {
    store.selectedItem(item);
  };

  const saveBuildingHandler = async () => {
    if (!store.isChange) {
      info('변경된 항목이 없습니다.');
      return;
    }

    try {
      let msg = '등록 완료했습니다.';
      if (store.item.id > -1) {
        msg = '수정 완료했습니다.';
      }

      let data : any = await store.saveItem();
      setTimeout(function () {
        if(data.save) {
          info(msg);
          return;
        }else {
          info("문제가 발생했습니다");
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      //errorHandler({ status, data }, '저장 중 오류발생');
    }
  };

  const onDeleteFloor = (id: number) => {
    const index = store.item.floors.findIndex((floor: any) => floor.id === id);
    store.item.floors.splice(index, 0);
    //console.log('store.item>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' , store.item);
  };

  const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

  const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);

  const onDeleteBuildings = async () => {
    const selected = Object.keys(store.checkeds);
    if (selected.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }
    try {
      let data : any = await store.deleteItems();
      // setTimeout(function () {
        if(data.save) {
          info('선택된 내역을 삭제했습니다.');
          await store.getItems();
          return;
        }else {
          info("문제가 발생했습니다");
          return;
        }
    // }, 250);
    } catch ({ status, data }) {
      //errorHandler({ status, data }, '삭제 중 오류발생');
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }
    }
  };

  return (
    <>
      <div className={['tab_contents', store.tabIndex === 0 ? 'on' : ''].join(' ')}>
        {item ? (
          <>
            <h4>건물 기본정보 입력</h4>
            <div className="table_normal_list table_write">
              <table>
                <caption className="sr_only">건물 기본정보 입력</caption>
                <colgroup>
                  <col width="200"/>
                  <col width="*"/>
                  <col width="200"/>
                  <col width="*"/>
                  <col width="200"/>
                  <col width="*"/>
                </colgroup>
                <tbody>
                <tr>
                  <th className="al">사업장</th>
                  <td>
                    {/* <Worksite value={store.work_site} onChange={(_value: number) => store.work_site = _value} /> */}
                    <select className="form_control"
                        value={store.work_site} onChange={(e:any) => {headerStore.setSelectWorkSite(e.target.value); headerStore.setWorkSite(e.target.value); store.work_site = e.target.value;}}
                    >
                      {workList && workList.map((w: any, idx: number) => {
                          return (
                              <option key={idx} value={w}>
                                  {WORK_SITE[Number(w)]}
                              </option>
                          )
                      })}
                    </select>
                  </td>
                  <th className="al">건물명</th>
                  <td><input type="text" className="form_control" value={item.name} onChange={(e) => item.name = e.target.value} /></td>
                  <th className="al">동</th>
                  <td><input type="text" className="form_control" value={item.dong} onChange={(e) => item.dong = e.target.value} /></td>
                </tr>
                <tr>
                  <th className="al">총 층 수</th>
                  <td><input type="text" className="form_control" value={item.total_floor} onChange={(e) => item.total_floor = e.target.value} /></td>
                  <th className="al">연면적</th>
                  <td><input type="text" className="form_control" value={item.total_area} onChange={(e) => item.total_area = e.target.value} /></td>
                  <th className="al">대지면적</th>
                  <td><input type="text" className="form_control" value={item.site_area} onChange={(e) => item.site_area = e.target.value} /></td>
                </tr>
                </tbody>
              </table>
            </div>  {/*table*/}

            <div className="submit ac">
              {store.isChange ? (
                <>
                {headerStore.writeRoleFlag ? 
                  <button type="button" className="btn_lg btn_darkblue" onClick={saveBuildingHandler}>저장</button> : <></>
                }
                </>
              ) : (<></>)}
            </div>

            {item.id > -1 ? <h4>건물 층정보 입력</h4> : <></>}
            {item.id > -1 && item.floors.length > 0 ? (
              <>
                <div className="lst_write_add" ref={floorView}>
                  <ul>
                    {item.floors.map((floor: any, index: number) => (
                      <Floor
                        key={floor.id}
                        index={index}
                        building={item.id}
                        store={store}
                        onDelete={onDeleteFloor}
                      />
                    ))}
                  </ul>
                </div>

              </>
            ) : (<></>)}
            {item.id > -1 ? (
              <div className="btn_area pt10 pb0 bd0">
                <div className="fr">
                  {headerStore.writeRoleFlag ? 
                    <button type="button" className="btn btn_sm btn_gray2 btn_outline btn_addline" onClick={newFloor}>층 추가</button> : <></>
                  }
                </div>
              </div>
            ) : <></>}
          </>
        ) : (<></>)}

        <h4>등록된 건물 목록

          <div className="right_area">
            <LimitSelect
              className="form_control"
              limit={store.limit}
              onChange={(limit: number) => {
                store.limit = limit;
                store.offset = 0;
                store.getItems();
              }}
            />
          </div>
        </h4>
        <div className="table_normal_list event_on box_scroll_x">
          <table>
            <caption className="sr_only">등록된 건물 목록</caption>
            <colgroup>
              {/*<col width="4%"/>*/}
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <thead>
            <tr>
              {/*<th>
                <label className="input_check pop_notext">
                  <input type="checkbox" id="" name="check_notice" onChange={onCheckedByAll} />
                  <span className="label_text"></span>
                </label>
              </th>*/}
              <th>건물명</th>
              <th>동</th>
              <th>층</th>
              <th>세대수</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>수정일</th>
            </tr>
            </thead>
            <tbody>
            {store.items.length === 0 ? (
              <tr>
                <td colSpan={7} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((_item: any, index: number) => (
                <tr key={index} style={{ backgroundColor: item && _item.id === item.id ? '#f7faffff' : '#f7faff00', cursor: 'pointer'}} onClick={() => selectedItem(_item)}>
                  {/*td>
                    <label className="input_check pop_notext">
                      <input
                        type="checkbox"
                        checked={store.checkeds[_item.id] ? true : false}
                        onChange={(e: any) => onCheckedHandler(e, _item)}
                      />
                      <span className="label_text"></span>
                    </label>
                  </td>*/}
                  <td>{_item.name}</td>
                  <td><a href="javascript:void(0);" onClick={() => selectedItem(_item)}>{_item.dong}</a></td>
                  <td><a href="javascript:void(0);" onClick={() => selectedItem(_item)}>총 {_item.total_floor} 층</a></td>
                  <td><a href="javascript:void(0);" onClick={() => selectedItem(_item)}>{_.sumBy(_item.floors, 'total_room')} 세대</a></td>
                  <td><a href="javascript:void(0);" onClick={() => selectedItem(_item)}>{_item.author}</a></td>
                  <td><a href="javascript:void(0);" onClick={() => selectedItem(_item)}>{formatByString(_item.created_at, 'yyyy.MM.dd')}</a></td>
                  <td><a href="javascript:void(0);" onClick={() => selectedItem(_item)}>{formatByString(_item.modified_at, 'yyyy.MM.dd')}</a></td>
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>  {/*table*/}

        <div className="board_paging">
          <ul>
            <Pagination
              offset={store.offset}
              limit={store.limit}
              totalPage={store.totalPage}
              setOffset={(offset: number) => store.setOffset(offset)} />
          </ul>
        </div>   {/*board_paging*/}

        <div className="submit al">
          {headerStore.writeRoleFlag? 
          <button type="button" className="btn_lg btn_darkblue" onClick={newBuilding}>신규등록</button>
          : <></>
          }
          {/*<button className="btn btn_lg btn_outline" onClick={onDeleteBuildings}>삭제</button>*/}
        </div>

      </div> {/* tab_content */}
    </>
  );
});

export default Building;

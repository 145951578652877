import React, { useContext, useEffect, useState } from 'react';
import DefaultTemplate from '../../components/templetes/default';
import { VocList, VocBuilding, VocCategories } from '../../components/templetes/voc';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';
import { worksite } from '../../apis';
import { isNull } from 'lodash';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../routes';

const VocPage = observer((props: any) => {
  const {vocStore: store} = useStores();
  const {headerStore : headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const baseUrl = '/lodged/maintenance';

  useEffect(() => {
    headerStore.getAuthBuilding();
    headerStore.getAuthMenuRole('3-2');
    headerStore.getAuthRoomRole();
    headerStore.getAuthBuilding();

    store.work_site = headerStore.workSite;
    store.roomtype = headerStore.paramAuthRoomTypeRole;
    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
        store.authBuilding = headerStore.paramAuthBuildingRole;
    }
    store.limit = 10;

    if (store.items.length === 0) {
      (async () => {
        try {
          headerStore.isLoading = true;
          await store.getItems();
          headerStore.isLoading = false;
        }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          info("문제가 발생했습니다");
          return;
        }
      })();
    }
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, []);

  // 탭 선택 시 초기화
  useEffect(() => {
    store.items = [];
  }, [store.tabIndex])

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <div className="inner">
        <div className="group_tab mt30">
          <ul className="tab_lg tabs clearfix">
            <li><a href="javascript:void(0);" className={store.tabIndex === 0 ? 'on' : ''} onClick={() => store.tabIndex = 0}>유지운영내역</a></li>
            <li><a href="javascript:void(0);" className={store.tabIndex === 1 ? 'on' : ''} onClick={() => store.tabIndex = 1}>동별유지운영내역</a></li>
            <li><a href="javascript:void(0);" className={store.tabIndex === 2 ? 'on' : ''} onClick={() => store.tabIndex = 2}>4차분류설정</a></li>
          </ul>
        </div>
        {store.tabIndex === 0 ? <VocList {...props} /> : <></>}
        {store.tabIndex === 1 ? <VocBuilding {...props} /> : <></>}
        {store.tabIndex === 2 ? <VocCategories {...props} /> : <></>}
      </div>
    </DefaultTemplate>
  );
});

export default VocPage;

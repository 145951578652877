import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Guide {
  static URL = `${IP}`;

  // 이용안내 조회
  @apiHandler()
  static async getGuides(params: {work_site: any, room_type: any, limit?: number, offset?: number}) {
    const PATH = `/admin/worksite/lodge/guide/`;

    return await axios.get(`${Guide.URL}${PATH}`, {
      params,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 이용안내 파일, 상단 텍스트 수정
  @apiHandler()
  static async patchFileGuide(id: number, payload: any) {
    const PATH = `/admin/worksite/lodge/guide/${id}/`;

    return await axios.patch(`${Guide.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용안내 내역 조회(history)
  @apiHandler()
  static async getGuidesByHistory(params: {work_site: any, room_type: any, offset: number, limit: number}) {
    const PATH = `/admin/worksite/lodge/guide/history/`;

    return await axios.get(`${Guide.URL}${PATH}`, {
      params,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
 
  // 이용안내 등록
  @apiHandler()
  static async createGuide(payload: any) {
    //console.log('payload>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'  , payload);
    const PATH = `/admin/worksite/lodge/guide/`;

    return await axios.post(`${Guide.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용안내 수정
  @apiHandler()
  static async patchGuide(id: number, payload: any) {
    const PATH = `/admin/worksite/lodge/guide/${id}/`;

    return await axios.put(`${Guide.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용안내 순서변경
  @apiHandler()
  static async patchGuideOrder(id: number, payload: {id: number, order: number}[]) {
    const PATH = `/admin/worksite/lodge/guide/order/`;

    return await axios.post(`${Guide.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // // 이용안내 삭제
  // @apiHandler()
  // static async deleteGuide(id: number, payload: {}) {
  //   const PATH = `/admin/worksite/lodge/guide/${id}/`;

  //   return await axios.delete(`${Guide.URL}${PATH}`, {
  //     headers: {
  //       Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
  //     },
  //   });
  // }
}

export default Guide;

import React from 'react';

import DefaultTemplate from '../../components/templetes/default';
import CheckPannel from '../../components/organismas/checkPannel';

import useAuthorize from '../../hooks/useAuthorize';

import { AdminInterface } from '../../hooks/useAdminManager';

import { ROOM_TYPE, WORK_SITE, MENU, BUILDINGG } from '../../constants';

import './index.scss';
import { useState } from 'react';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';
const AdminAuthManageDetail = observer((props: any) => {
  if (!props?.location?.state) {
    props.history.replace('/admin/manage_auth');
  };
  
  const {headerStore: headerStore} = useStores();
  const profile: AdminInterface = props.location.state;
  const {
    worksite, roomtype, menu, building,
    setWorksite, setRoomtype, setMenu, setBuilding,
    submit
  } = useAuthorize(props.match.params.id);

  const submitHandler = async () => {
    headerStore.isLoading = true;
    let isFail = await submit();
    if (isFail) {
      alert("변경중 문제발생");
      headerStore.isLoading = false;
      return
    }
    
    props.history.replace("/admin/manage_auth");
    alert("성공");
    headerStore.isLoading = false;
  } 

  return (
    <DefaultTemplate
      id='wrap'
      className=''
      isLoading={headerStore.isLoading}
      {...props}
    >
      <div className="tab_contents on">
        <h4>관리자정보</h4>
        <div className="table_modify_list">
          <dl>
            <dt>관리번호</dt>
            <dd>{profile?.employee_number}</dd>
          </dl>
          <dl>
            <dt>성명</dt>
            <dd>{profile?.name}</dd>
          </dl>
          <dl>
            <dt>근무지</dt>
            <dd>{profile?.worksite}</dd>
          </dl>
          <dl>
            <dt>소속</dt>
            <dd>{profile?.company}</dd>
          </dl>
        </div>

        <h4>담당자 선택</h4>
        <div className="lst_charge">
          <CheckPannel
            allocations={worksite}
            free={Object.keys(WORK_SITE).sort().map(item => parseInt(item))}
            allocationTitle="부여된 사업장 권한"
            freeTitle="사업장권한목록"
            onChange={setWorksite}
            mapper={WORK_SITE}
          />

          <CheckPannel
            allocations={roomtype}
            free={Object.keys(ROOM_TYPE).sort().map(item => parseInt(item)).filter(item => item > 0 && item < Object.keys(ROOM_TYPE).length-1)}
            allocationTitle="부여된 숙소 권한"
            freeTitle="숙소권한목록"
            onChange={setRoomtype}
            mapper={ROOM_TYPE}
          />

          <CheckPannel
            allocations={menu}
            free={Object.keys(MENU).sort().map(item => parseInt(item)).filter(item => item > 0 && item < Object.keys(ROOM_TYPE).length-1)}
            allocationTitle="부여된 메뉴 권한"
            freeTitle="메뉴권한목록"
            onChange={setMenu}
            mapper={MENU}
          />

          <CheckPannel
            allocations={building}
            free={Object.keys(BUILDINGG).sort().map(item => parseInt(item)).filter(item => item > 0 && item < Object.keys(ROOM_TYPE).length-1)}
            allocationTitle="부여된 건물 권한"
            freeTitle="건물권한목록"
            onChange={setBuilding}
            mapper={BUILDINGG}
          />

        </div>  {/*lst_charge*/}

        <div className="submit">
          <div className="fl">
            <button className="btn btn_lg btn_gray" onClick={ () => props.history.replace('/admin/manage_auth') }>목록</button>
          </div>
          <div className="fr">
            <button className="btn btn_lg btn_outline">취소</button>
            <button className="btn btn_lg btn_darkblue" onClick={submitHandler}>저장</button>
          </div>
        </div>
      </div> {/* tab_content */}
    </DefaultTemplate>
  );
});

export default AdminAuthManageDetail;

import React from 'react';
import { BAD_DISABLE_COLOR, BAD_SELECT_COLOR, BAD_UNSELECT_COLOR } from '../../../color';
import Bad from '../bad';

// 방 X
const DisableBad = (props: any) => {
  const backgroundColor = BAD_DISABLE_COLOR;
  return (
    <li className="room disabled" style={{ backgroundColor }}>
      {/* <a className="room disabled" style={{ backgroundColor }}> */}
        {props.children}
      {/* </a> */}
    </li>
  );
};


// 방 선택
const SelectBad = (props: any) => {
  const selectedYN = props.selected;

  const backgroundColor = props.selected
    ? BAD_SELECT_COLOR
    : BAD_UNSELECT_COLOR;

  return (
    <li>
    <a className="room" style={{ backgroundColor, cursor:'pointer'}} onClick={props.onClick} 
        onMouseOver={e => (selectedYN? '' : e.currentTarget.style.backgroundColor = BAD_SELECT_COLOR)}
        onMouseOut={e => (selectedYN? '' : e.currentTarget.style.backgroundColor = BAD_UNSELECT_COLOR)} >
      {props.children}
    </a>
    </li>
  );
};

const DorimtoryBad = (props: any) => {
  if (props.isDisable) {
    return <DisableBad> {props.disableText()} </DisableBad>;
  }
  if (props.selected) {
    return (
      <SelectBad
        selected={props.selected}
        onClick={() => { props.onRoomClickHandlerToUnSelect(props.room)}}
      >
        <p>{props.isDetail ? props.room.guests[props.guestIdx]?.guest_name : ''}</p>
      </SelectBad>
    )
  }

  return (
    <SelectBad
      // onClick={() => props.onClick(props.room)}
      onClick={() => { props.onRoomClickHandlerToSelect(props.room, props.guestIdx)}}
      selected={false}
    />
  )
  
};

const DormitoryRoom = (props: any) => {
  // const onClickHandler = (e: any) => props.onClick(props.room);
  const getBad = () => {
    const temp: any = [];
    for (let i = 0; i < props.room.quota; ++i) {
      temp.push(
        <DorimtoryBad
          key={i}
          // onClick={onClickHandler}
          onRoomClickHandlerToSelect={props.onRoomClickHandlerToSelect}
          onRoomClickHandlerToUnSelect={props.onRoomClickHandlerToUnSelect}
          room={props.room}
          guestIdx={i}
          selectRoom={props.selectRoom}
          selected={props.isSelect(props.room, i)}
          isUseable={props.isUsable(props.room)}
          isDisable={props.isDisable(props.room, i)}
          isDetail={props.isDetail && props.room?.guests[i]?.id}
          disableText={() => props.disableText(props.room, i)}
        />
      );
    }

    return temp;
  };
  //const g: number = props.room?.gender_type? props.room?.gender_type : 1;
  return (
    <ul className="lst_ho">
      <li key={props.key}>
        {props.room.name} 
        {props.room?.gender_type === 1? '(남)' : ''}
        {props.room?.gender_type === 2? '(여)' : ''}
      </li>
      { getBad() }
    </ul>
  );
};

export default DormitoryRoom;

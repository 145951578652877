import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../organismas/pagination';
import LimitSelect from '../../atoms/select/limit';
import { FAQ_CATEGORY } from '../../../constants';
import { getViewPageIdx } from '../../../utils/common';
import ConfirmBtn from '../../atoms/button/confirm';
import { formatByString } from '../../../utils/date';
import { useStores } from '../../../store/mobx';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import { observer } from 'mobx-react';

const FaqList = observer((props: any) => {
  const dispatch = useDispatch();
  const {faqStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/manage/faq';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const [sortConfig, setSortConfig] = useState<{ key: string, direction: string }>({ key: "", direction: "" });

  useEffect(() => {
    (async () => {
      headerStore.getAuthMenuRole('4-4');
      headerStore.getAuthWorkSiteRole();

      store.work_site = headerStore.workSite;
      await store.getItems();
    })();
    return () => {
      // console.log(store.findTabs(baseUrl));
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, [headerStore.workSite, store.limit, store.offset]);

  // 소팅 class
  const getClassNamesFor = (name: string) => {
      if (!sortConfig) {
          return;
      }
      return sortConfig.key === name ? sortConfig.direction : '';
  };

  // 소팅
  const requestSort = (key: string) => {
      let direction = 'asc';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
      }

      setSortConfig({ key, direction });
      if (direction === 'asc') {
          store.ordering = '-' + key;
      } else {
          store.ordering = key;
      }

      store.getItems();
  }
  
  const onFaqFilter = (_category: number) => {
    // console.log(_category);
    store.setCategory(_category);
  };

  const onCheckedByAll = (e: any) => {
    store.checkAll(e.target.checked);
  };

  const onCheckedHandler = (e: any, item: any) => {
    store.checkItem(e.target.checked, item);
  };

  const onEditHandler = () => {
    const selectedItems = Object.keys(store.checkeds);
    if (!selectedItems || selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }
    if (selectedItems.length > 1) {
      info('다건의 항목이 선택되어 있습니다.');
      return;
    }
    const item = store.checkeds[selectedItems[0]];
    store.selectedItem(item);
    props.history.replace(`${baseUrl}/${item.id}/update`);
  };

  const onDeletehandler = async () => {
    const selectedItems = Object.keys(store.checkeds);
    if (!selectedItems || selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;

            let data : any = await store.deleteItems()
            // const actions = Object.keys(faqStore.checkeds).map(checkKey => faq.deleteFaq(checkKey));
            // await Promise.all(actions);
            headerStore.isLoading = false;
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
                return;
              }else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
              info("삭제 중 문제가 발생했습니다");
              return;
            }
          }
        },
      }
    });
  };
  
  return (
    <>
      <div className="group_tab">
        <ul className="tab_lg tabs clearfix mt40">
          <li onClick={() => onFaqFilter(-1)}>
            <a href="javascript:void(0);" className={store.category === -1 ? 'on' : ''}>
              전체
            </a>
          </li>
          {Object.keys(FAQ_CATEGORY).map((key: string, index: number) => (
            <li key={index} onClick={() => onFaqFilter(Number(key))}>
              <a href="javascript:void(0);" className={store.category === Number(key) ? 'on' : ''}>
                {FAQ_CATEGORY[Number(key)]}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <h4>FAQ 리스트
        <div className="right_area">
          <LimitSelect
            className="form_control"
            limit={store.limit}
            onChange={(limit: number) => {
              store.limit = limit
              store.offset = 0
              // setLimit(limit);
              // setOffset(0);
            }}
          />
        </div>
      </h4>

      <div className="table_normal_list event_on box_scroll_x">
        <table>
          <caption className="sr_only">FAQ 리스트</caption>
          <colgroup>
            {/*<col width="4%"/>*/}
            <col width="5%"/>
            <col width="10%"/>
            <col width="*"/>
            <col width="8%"/>
            <col width="8%"/>
            <col width="10%"/>
            <col width="10%"/>
          </colgroup>
          <thead>
          <tr>
            {/*<th>
              <label className="input_check pop_notext">
                <input type="checkbox" id="" name="check_notice" checked={store.allCheckFlg} onChange={onCheckedByAll} />
                <span className="label_text"></span>
              </label>
            </th>*/}
            <th>NO</th>
            <th><button type="button" onClick={() => requestSort('category')} className={`table_sortBtn ${getClassNamesFor('category')}`}>유형</button></th>
            <th>제목</th>
            <th>이미지</th>
            <th>첨부파일</th>
            <th>작성자</th>
            <th>작성일</th>
          </tr>
          </thead>
          <tbody>

          {!store.items.length ? (
              <tr>
                <td colSpan={7} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((item, index) => (
              <tr key={index} onClick={() => { store.selectedItem(item); props.history.replace(`${baseUrl}/${item.id}`); }} style={{ cursor: 'pointer' }}>
                {/*<td>
                  <label className="input_check pop_notext">
                    <input
                      type="checkbox"
                      checked={store.checkeds[item.id] ? true : false}
                      onChange={(e: any) => onCheckedHandler(e, item)}
                    />
                    <span className="label_text"></span>
                  </label>
                </td>*/}
                <td>{getViewPageIdx(store.offset, store.limit, index)}</td>
                <td>{FAQ_CATEGORY[item.category]}</td>
                <td className="al" onClick={() => { store.selectedItem(item); props.history.replace(`${baseUrl}/${item.id}`); }} style={{ cursor: 'pointer' }}>
                  <a href="javascript:void(0);" className="btn_link">{item.title}</a>
                </td>
                <td style={{cursor:'default'}}><a className={ item.images.length > 0 ? "btn_image" : "btn_image blank" }></a></td>
                <td style={{cursor:'default'}}><a className={ item.files.length > 0 ? "btn_file" : "btn_file blank" }></a></td>
                <td>{item.author_name}</td>
                <td>{formatByString(item.created_at, 'yyyy.MM.dd')}</td>
              </tr>
            )))}
          </tbody>
        </table>
      </div>  {/*table*/}
      <div className="board_paging">
        <ul>
          <Pagination
            offset={store.offset}
            limit={store.limit}
            totalPage={store.totalPage}
            setOffset={(offset: number) => store.setOffset(offset)} />
        </ul>
      </div>
      {/*board_paging*/}
      <div className="submit">
        <div className="fl">
          {headerStore.writeRoleFlag ? 
            <button type="button" className="btn btn_lg btn_darkblue" onClick={() => { store.newItem(); props.history.replace(`${baseUrl}/create`);}} style={{ cursor: 'pointer' }} > 등록 </button> 
            :<></>
          } 
        </div>
        <div className="fr">
          {/* <button type="button" className="btn_md btn_darkblue btn_outline" onClick={onEditHandler}>
            수정
          </button> */}
          {/* <button type="button" className="btn_md btn_darkgray">
            삭제
          </button> */}
          {/*<ConfirmBtn
            className="btn_md btn_darkgray"
            action={onDeletehandler}
            label="삭제 처리"
            title="삭제확인"
            msg="정말로 삭제하시겠습니까?"
          />*/}
        </div>
      </div>
    </>
  );
});

export default FaqList;

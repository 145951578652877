import React from 'react';

import styled from 'styled-components';

const map: any = {
  elecManage: '#324A61',
  cleanManage: '#4D395A',
};

const _Task = styled.div.attrs((props: any) => ({}))<any>`
  width: 100%;
  background-color: ${(props: any) => map[props.type]};
  clear: both;
  margin-top: 3px;
  color: #d7d7d7;
  opacity: ${(props: any) => (props.isVisible ? '1' : '0')};
`;

const Task = (props: any) => {
  return (
    <div>
      {props.tasks.map((task: any, idx: number) => (
        <_Task isVisible={task.id || 0} key={idx} type={task.type}>
          {task.title || 0}
        </_Task>
      ))}
    </div>
  );
};

export default Task;

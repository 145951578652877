import React from 'react';



import Header from '../../organismas/header';
import Footer from '../../organismas/footer';
import Lnb from "../../organismas/lnb";
import Tab from "../../organismas/tab";

interface Props {
  id: string;
  className: string;
  children: React.ReactNode;
  isNotLoginRequire?: boolean;
  history: any;
  isLoading?: boolean;
  isMainClass?: string;
  
}

const DefaultTemplete = (props: Props) => {
  let propsClassName = props.className;

  if(props.isLoading) {
    propsClassName += " loading";
  }

  return (
    <>
      <div id={props.id} className={propsClassName}>
        <Header {...props} history={props.history} isNotLoginRequire={props.isNotLoginRequire? true : false}/>
        {!props.isNotLoginRequire && (
          <Lnb {...props} isClickActive={props.isNotLoginRequire ? false : true} />
        )}
        
        <div id="container">
          
          {/* <div className="row clearfix"> */}
            <div id="contents" className={props.isMainClass? props.isMainClass :"col col_right"}>
              <div className="inner" >
                {!props.isNotLoginRequire && <Tab {...props} />}
                {props.children}
              </div>
            </div>
          {/* </div> */}
        </div>
        
        <Footer />
      </div>
    
      {/* <div
        id="pop_request_group"
        className="pop_dim_wrap pop_modal pop_lg"
        style={{display: props.isLoading ? "block" : "none"}}
      >
        <div style={{position: "absolute", left: "37%", top: "30%"}}>
          <Loader
            type="ThreeDots"
            color="#eeeeee"
            height={500}
            width={500}
            // timeout={10000} //3 secs
          />
        </div>
      </div> */}
    </>
  );
};

export default DefaultTemplete;
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import React, { useState, useEffect, useContext } from 'react';
import DefaultTemplate from '../../../components/templetes/default';
import ChoiceSmsGuest from '../../../components/organismas/popup/choiceSmsGuest';
import { formatByString_YYYYMMDD, format_YYYYMMDD, format_YYYYMMDDHH, format_YYYYMMDDhhmm, format_YYYYMMDDhhmm2, getTodayDate } from '../../../utils/date';
import * as apis from '../../../apis';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../../routes';
import { useSelector } from 'react-redux';
import CustomCalendar from '../../../components/organismas/customCalendar';

const SmsDetailPage = observer((props: any) => {
    const [isChoiceGuestsOpen, setIsChoiceGuestsOpen] = useState<boolean>(false);
    const {headerStore: headerStore} = useStores();
    const { smsStore : store } = useStores();
    const [isUpDateMode, setIsUpDateMode] = useState<boolean>(false);
    const [contents, setContents] = useState<string>(store.smsSendDetail.message);
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [checking, setChecking] = useState<boolean>(false);
    const [sendingPeople, setSendingPeople] = useState<any>(); 

    

    useEffect(() => {
        if(!store.smsSendDetail || store.smsSendDetail.length === 0) {
            props.history.replace(`/manage/sms`);
            return;
        }

        (async () => {
            try{
                headerStore.isLoading = true;
                store.work_site = headerStore.workSite;
                await store.getUsers();
                headerStore.isLoading = false;
                // console.log('회원현황 리스트=======>',store.users);
            }catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[headerStore.workSite]);

    useEffect(() => {
        store.work_site = headerStore.workSite;
        // console.log('넘어온 리스트=======>',store.smsSendDetail);
    },[headerStore.workSite]);

    const onShowChoiceGuestsPopupHandler = (isOpen: boolean) => {
        setIsChoiceGuestsOpen(isOpen);
    };

    const onUpdateGuests = (selectedGuests: any) => {
        store.guest = selectedGuests;
    };

    //수정 완료 버튼
    const save = async() => {
        // if(!store.guest || store.guest.length <= 0) {
        //     info('발송 대상자를 선택해주세요');
        //     return;
        // }else 
        if(!contents || contents === '') {
            info('메세지 내용을 입력해주세요');
            return;
        }else if(!checking && format_YYYYMMDDhhmm2() < store.send_date.replace(/-/g,"")+store.getHour+store.getMinute === false){
            info('예약 발송은 현재시간보다 빠르게 설정을 해야합니다.');
            return;
        }
        
        let cellMap =store.guest.map((item:any, index:number) => {
            return item.cellphone
        }).join(";");

        let cell2Map = store.smsSendDetail.cellphones

        const id = store.smsSendDetail.id;

        const payload : any = {
            cellphones : store.guest? ''+cellMap : ''+cell2Map,
            message : contents,
        }

        if(checking === true){
            payload['send_date'] = format_YYYYMMDDhhmm();
            payload['send_status'] = "발송완료";
        }else{
            payload['send_date'] = store.send_date+" "+store.getHour+":"+store.getMinute;
            payload['send_status'] = "발송예정";
        }

        try{
            headerStore.isLoading = true;
            let data : any = await apis.sms.smsSendUpDate(id, payload);
            headerStore.isLoading = false;

            info('수정되었습니다.');
            setIsUpDateMode(false);
            if(checking === true){
                props.history.replace('/manage/sms');
            }
            // setTimeout(function () {
            //     if(data.save) {
            //     }else {
            //       return;
            //     }
            // }, 250);
        }catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }

            info("문제가 발생했습니다");
            return;
        }
    }


    //삭제 버튼
    const smsDelete = async() => {
        const id = store.smsSendDetail.id;
        try{
            headerStore.isLoading = true;
            let data : any = await apis.sms.smsSendDelete(id);
            headerStore.isLoading = false;
            info('삭제되었습니다');
            goBack();
            // setTimeout(function () {
            //     if(data.save) {
            //     }else {
            //       return;
            //     }
            // }, 250);
        }catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }

            info("문제가 발생했습니다");
            return;
        }
    }

    const goBack = () => {
        props.history.replace(`/manage/sms`);
    }

    if(!store.smsSendDetail || store.smsSendDetail.length === 0) {return <></>;}

    return(
        <DefaultTemplate
            id="wrap"
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
        <h4>문자발송 상세</h4>
            <div className="table_normal_list table_write">
                <table>
                    <caption className="sr_only">문자발송 상세</caption>
                    <colgroup>
                        <col width="130"/>
                        <col width="*"/>
                        <col width="130"/>
                        <col width="*"/>
                    </colgroup>
                    {isUpDateMode === true ? (
                    <tbody>
                    <tr>
                         <th>발송 대상자</th>
                         <td colSpan={4}>
                            <button type="button" className="btn_sm btn_darkblue btn_outline btn_srch" onClick={()=> onShowChoiceGuestsPopupHandler(true)}>검색</button>
                            <div className="items_area">
                                <a className="item">{store.guest.length === 0 ? `${store.smsSendDetail.cellphones.split(',').length} 명` : `${store.guest.length} 명`}</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>메시지 내용</th>
                        <td colSpan={4}>
                            <div className="contents_area">
                                <textarea className="form_control" value={contents} onChange={(e: any) => setContents(e.target.value)}></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th><span className="emp_red">발송 날짜 선택 및<br/>예약 발송 설정</span></th>
                        <td colSpan={4} className="form_inline">
                            <label className="input_check">
                                <input type="checkbox" id="" name="check_notice" onChange={(e: any) => setChecking(e.target.checked)}/>
                                <span className="label_text">즉시발송</span>
                            </label>
                            <div className="ml20">
                                
                            {/* <DatePicker
                                value={store.send_date}
                                width={250}
                                onChange={(date: string) => (store.send_date = date)}
                                isPrevMonthView={false}
                                min={getTodayDate()[0]}
                            /> */}
                            <CustomCalendar
                                value={store.send_date}
                                onChange={(date:string) => (store.send_date = date)}
                                min={getTodayDate()[0]}
                            ></CustomCalendar>

                            </div>

                            <select className="form_control month" value={store.getHour} 
                                onChange={(e:any) => {store.getHour = e.target.value}}>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                                <option value={24}>24</option>
                            </select>
                            <select className="form_control month" value={store.getMinute} 
                                onChange={(e:any) => {store.getMinute = e.target.value}}>
                                <option value="00">0</option>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                                <option value={24}>24</option>
                                <option value={25}>25</option>
                                <option value={26}>26</option>
                                <option value={27}>27</option>
                                <option value={28}>28</option>
                                <option value={29}>29</option>
                                <option value={30}>30</option>
                                <option value={31}>31</option>
                                <option value={32}>32</option>
                                <option value={33}>33</option>
                                <option value={34}>34</option>
                                <option value={35}>35</option>
                                <option value={36}>36</option>
                                <option value={37}>37</option>
                                <option value={38}>38</option>
                                <option value={39}>39</option>
                                <option value={40}>40</option>
                                <option value={41}>41</option>
                                <option value={42}>42</option>
                                <option value={43}>43</option>
                                <option value={44}>44</option>
                                <option value={45}>45</option>
                                <option value={46}>47</option>
                                <option value={48}>49</option>
                                <option value={50}>50</option>
                                <option value={51}>51</option>
                                <option value={52}>52</option>
                                <option value={53}>53</option>
                                <option value={54}>54</option>
                                <option value={55}>55</option>
                                <option value={56}>56</option>
                                <option value={57}>57</option>
                                <option value={58}>58</option>
                                <option value={59}>59</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>등록자 ID</th>
                        <td>{store.smsSendDetail.employee_number}</td>
                        <th>등록일시</th>
                        <td>{formatByString_YYYYMMDD(store.smsSendDetail.created_at)}</td>
                    </tr>
                    </tbody>
                    ) : 
                    <tbody>
                    <tr>
                        <th>발송 대상자</th>
                        <td colSpan={3}>
                            <span className="item">{store.guest.length === 0 ? `${store.smsSendDetail.cellphones.split(',').length} 명` : `${store.guest.length} 명`}</span>
                        </td>
                    </tr>

                    <tr>
                        <th>메시지 내용</th>
                        <td colSpan={3}>
                            <div className="contents_area">
                                {store.smsSendDetail.message}
                            </div>
                        </td>
                    </tr>
                    
                    <tr>
                        <th>등록자 ID</th>
                        <td>{store.smsSendDetail.employee_number}</td>
                        <th>등록일시</th>
                        <td>{formatByString_YYYYMMDD(store.smsSendDetail.created_at)}</td>
                    </tr>
                    </tbody>
                    }

                </table>
            </div>  {/*table*/}

            <div className="submit">
                <div className="fl">
                    <button className="btn btn_lg btn_gray" onClick={() => props.history.replace(`/manage/sms`)}>목록</button>
                </div>
                {isUpDateMode === true ? (
                <div className="fr">
                    <button className="btn btn_lg btn_outline" onClick={()=> {save();}}>완료</button>
                    <button className="btn btn_lg btn_darkblue" onClick={()=> setIsUpDateMode(false)}>취소</button>
                </div>
                ) :
                <>
                {headerStore.writeRoleFlag ? 
                    <div className="fr">
                        <button className="btn btn_lg btn_outline" onClick={()=> {setIsUpDateMode(true);}}>수정</button>
                        <button className="btn btn_lg btn_darkblue" onClick={()=> {smsDelete();}}>삭제</button>
                    </div>
                    :<></>
                }
                </>
                }
            </div>
            {isChoiceGuestsOpen ? (
            <ChoiceSmsGuest
                isOpen={isChoiceGuestsOpen}
                guests={store.guest}
                setIsOpen={setIsChoiceGuestsOpen}
                onApply={onUpdateGuests}
                setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
            />
      ) : (<></>)}
        </DefaultTemplate>
    );
});
export default SmsDetailPage;
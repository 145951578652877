import React, { useState } from 'react';


//건물명 및 동 select
const SelectBuilding = (props: any) => {
  return (
    <>
    {/* <div className="col col_building"> */}
      {/* <label htmlFor="select_building" className="title_form">
        건물명 및 동
      </label> */}
      <select
        name=""
        id="select_building"
        className="form_control form_sm"
        onChange={e => {props.changeBuildHandler(e)}}
      >
        {props.buildings.map((build: any, idx: number) => (
          <option
            value={idx}
            key={idx}
            selected={props.building ? props.building.id === build.id : !idx}
            label={`${build.name} ${build.dong}`}
          />
        ))}
      </select>
      {/* <select
        name=""
        id="select_floor"
        className="form_control form_sm"
        onChange={e => props.changeBuildHandler(e)}
      >
        {props.dongs.map((d: any, idx: number) => (
          <option
            value={d.id}
            key={idx}
            selected={!idx}
            label={`${d.name}`}
          />
        ))}
      </select>
      </div> */}
    </>
  );
};

export default SelectBuilding;
import React from 'react';

interface Props {
  name?: string
  id?: string
  className?: string
  onChange: Function
  value: number
  onlyData?: boolean
}


//필수 여부 select
const Visable = (props: Props) => {
  return (
    <select name={props.name} id={props.id} className={props.className || 'form_control pc'} value={props.value} onChange={(e: any) => props.onChange(parseInt(e.target.value))}>
      {!props.onlyData && (<option value={-1}>전체</option>)}
      <option value={1}>필수</option>
      <option value={0}>옵션</option>
    </select>
  );
};

export default Visable;

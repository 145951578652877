import React from 'react';
import DormitoryRoom from './room';

interface props {
  floors: any;
  selectRoom: any;

  onRoomClickHandlerToSelect: Function
  onRoomClickHandlerToUnSelect: Function
  isUsable: Function
  isDisable: Function
  isSelect: Function

  disableText?: Function

  selectFloor: number;
  isDetail?: boolean;
  isGuide?:boolean
}


//기숙사 배방 네모칸
const DormitoryFloor = ({
  floors,
  selectRoom,
  selectFloor,
  isDetail,
  disableText,
  onRoomClickHandlerToSelect,
  onRoomClickHandlerToUnSelect,
  isGuide,
  isUsable,
  isDisable,
  isSelect
}: props) => {
  const isAllSelectFloor = (selectFloor === -1) || (selectFloor > (floors.length - 1) );
  floors = isAllSelectFloor ? floors : [floors[selectFloor]];

  return floors.map((floor: any, idx: number) =>
    floor.rooms.length ? (
      <React.Fragment key={idx}>
        
          {floor.rooms.map((room: any, idx: number) => (
            <DormitoryRoom
              room={room}
              onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
              onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
              selectRoom={selectRoom}
              isDetail={isDetail}
              key={idx}
              isUsable={isUsable}
              isDisable={isDisable}
              isSelect={isSelect}
              disableText={disableText ? disableText : () => ""}
            />
          ))}
      </React.Fragment>
    ) : (
        <></>
      ),
  );
};

export default DormitoryFloor;

import { range } from 'lodash';
import React from 'react';

//공제년월 ; 월 selectbox
const MonthSelect = (props: any) => {
  return (
    <>
      <select name="" id="" className="form_control col_xs ml20"
        value={props.month}
        onChange={props.onChange}
      >
        {range(0, 12, 1).map((item:any, index:number) => (
          <option key={index} value={`${item + 1}`.padStart(2, '0')}>{`${item + 1}`.padStart(2, '0')}</option>
        ))}
      </select>
      <span className="pl10">월</span>
    </>
  );
};

export default MonthSelect;

import { action, makeObservable, observable } from 'mobx';
import * as XLSX from 'xlsx';

import * as apis from '../../apis';
import Lodge from '../../apis/lodge';
import Core from '../../apis/core';

import {
  getDate,
  getMonth,
  getRangeDates,
  getIntervalMonthDate,
  getYear,
  format_YYYYMMDDHH,
  getTwoLatersMonthDate,
} from '../../utils/date';
import {
  LODGE_MOVE_CODE,
  LODGE_STATUS_CODE,
  MOVE_STATUS_CODE,
  DELAY_COUNT_CODE,
  CHECKOUT_STATUS_CODE,
  ROOM_TYPE_CODE,
} from '../../constants';
import { isNull } from 'lodash';
import { getWorkSitePhoneNum } from '../../utils/common';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

//입숙, 이숙, 퇴숙 현황에서 사용할 store
export class LoadgedTotalStore {
  rootStore: any;

  //paging
  limit: number = 10;
  offset: number = 0;
  totalPage: number = 0;
  totalCount: number = 0;

  //리스트 또는 단일 객체
  list: any[] = [];
  item: any = {};
  occuItem: any;
  mngStatus: any;
  //검색조건
  selectRoomType: number = 0;
  status: string = '';
  dateAfter: string = '';
  dateBefore: string = '';
  currentYear: number = 0;
  currentMonth: number = 0;
  option: string = 'guest_name';
  text: string = '';
  checkout_after: string = getIntervalMonthDate(2)[1];
  checkout_before: string = getTwoLatersMonthDate(2)[1];
  date_after: string = '';
  date_before: string = '';

  category: number = 0; //이숙상태

  //check한 object
  allCheckFlg: boolean = false;
  checkeds: any = {};

  checkoutData: any = []; // 퇴숙 신청할 데이터 정보
  moveAppData: any = [];

  checkoutStatus: number = 4;
  // 퇴숙 신청시 전송할 데이터 // 이숙신청시 팝업 전송할 데이터
  manageYear: string = getYear();
  manageMonth: string = getMonth();
  manageDay: string = getDate();
  managedAt: string = '';
  isKeyReturn: boolean = true;
  keySelect: string = '열쇠 분실';
  keyDescription: string = '';
  isEquipment: boolean = false;
  equipmentDescription: string = '';
  images: any = [];
  mealTicket: number = 0;
  memo: string = '';

  // 이숙처리용 데이터
  keyoutYear: string = getYear();
  keyoutMonth: string = getMonth();
  keyoutDay: string = getDate();
  keyoutAt: string = '';
  status_description: string = '';
  roomId: number = 0;
  roomInfo: string = '';

  tempCheckChangeDetailItem: any = [];
  checkChangeDetailItem: any = [];

  //퇴숙 상세보기용 데이터
  checkOutDetailItem: any = [];

  // 정기이숙 설정 데이터
  start_date: string = getIntervalMonthDate(2)[1];
  end_date: string = getIntervalMonthDate(2)[0];
  start_hour: string = '00';
  end_hour: string = '00';
  title: string = '';
  employee_number: string = '';
  name: string = '';
  work_site: any;
  reason: string = ''; //업무이관 사유
  auth_building: string = ''; //남양에서 업무이관 할 뉴타운 또는 본동

  //guest_party
  guest_party: string = '';

  roomtype: any;
  building: any;

  //월별 입숙, 이숙, 퇴숙 현황
  new_move: number = 0;
  onetoon: number = 0;
  normal: number = 0;
  regular: number = 0;
  checkout: number = 0;

  //loading
  headerStore: HeaderStore;

  constructor(root: any) {
    this.rootStore = root;
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      selectRoomType: observable,
      mngStatus: observable,
      occuItem: observable,
      new_move: observable,
      onetoon: observable,
      normal: observable,
      regular: observable,
      checkout: observable,
      checkOutDetailItem: observable,
      limit: observable,
      offset: observable,
      totalPage: observable,
      totalCount: observable,
      list: observable,
      item: observable,
      status: observable,
      dateAfter: observable,
      dateBefore: observable,
      currentYear: observable,
      currentMonth: observable,
      option: observable,
      text: observable,
      checkout_after: observable,
      checkout_before: observable,
      allCheckFlg: observable,
      checkeds: observable,
      checkoutData: observable,
      checkoutStatus: observable,
      manageYear: observable,
      manageMonth: observable,
      manageDay: observable,
      managedAt: observable,
      isKeyReturn: observable,
      keySelect: observable,
      keyDescription: observable,
      isEquipment: observable,
      equipmentDescription: observable,
      images: observable,
      mealTicket: observable,
      category: observable,
      moveAppData: observable,
      memo: observable,
      keyoutYear: observable,
      keyoutMonth: observable,
      keyoutDay: observable,
      keyoutAt: observable,
      status_description: observable,
      roomId: observable,
      roomInfo: observable,
      start_date: observable,
      end_date: observable,
      start_hour: observable,
      end_hour: observable,
      title: observable,
      employee_number: observable,
      name: observable,
      work_site: observable,
      tempCheckChangeDetailItem: observable,
      checkChangeDetailItem: observable,
      guest_party: observable,
      roomtype: observable,
      building: observable,
      reason: observable,
      auth_building: observable,

      init: action,
      getCurrentDateRange: action,
      checkAll: action,
      checkItem: action,
      findTabs: action,
      getCheckInList: action,
      setOffset: action,
      downloadCheckIn: action,
      setmoveAppData: action,
    });
  }

  //초기화
  init() {
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.totalCount = 0;

    this.list = [];
    this.item = {};

    this.status = '';
    this.dateAfter = '';
    this.dateBefore = '';
    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth() + 1;

    this.checkeds = {};
    this.option = '';
    this.text = '';

    this.building = '';
    this.selectRoomType = 0;
  }

  //현재 n월의 start일~end일
  getCurrentDateRange() {
    let date = new Date();
    if (this.currentYear === 0) {
      this.currentYear = date.getFullYear();
    }
    if (this.currentMonth === 0) {
      this.currentMonth = date.getMonth() + 1;
    }

    let nextMonth = this.currentMonth + 1;

    let currentDate = this.currentYear + '-' + this.currentMonth + '-01';
    let currentDate2 = this.currentYear + '-' + this.currentMonth + '-01';

    // 12월을 초과하는 경우, 년도 증가하고 1월로 세팅
    let nextDate = nextMonth > 12 ? this.currentYear + 1 + '-1-01' : this.currentYear + '-' + nextMonth + '-01';

    let tempRangeDate = getRangeDates(currentDate, nextDate);

    this.dateAfter = currentDate;
    this.date_after = currentDate2;
    this.dateBefore = this.currentYear + '-' + this.currentMonth + '-' + (tempRangeDate.length - 1);
    this.date_before = this.currentYear + '-' + this.currentMonth + '-' + (tempRangeDate.length - 1);
  }

  //전체 체크
  checkAll(checked: boolean) {
    const temp: any = { ...this.checkeds };
    this.list.map((item: any, index: number) => {
      if (checked) {
        temp[item.id] = item;
        this.allCheckFlg = true;
      } else {
        delete temp[item.id];
        this.allCheckFlg = false;
      }
    });
    this.checkeds = temp;
  }

  //단일체크
  checkItem(checked: boolean, item: any) {
    const temp: any = { ...this.checkeds };
    if (checked) {
      if (!temp[item.id]) {
        temp[item.id] = item;
      }
    } else {
      delete temp[item.id];
    }
    this.checkeds = temp;

    if (Object.keys(temp).length === this.list.length) {
      this.allCheckFlg = true;
    } else {
      this.allCheckFlg = false;
    }
  }

  findTabs(name: string) {
    let result = -1;
    if ((window as any).store) {
      result = (window as any).store.getState().base.tab.opened.find((item: string) => item.indexOf(name) > -1);
    }
    return result;
  }

  //입숙 리스트 가져오기
  async getCheckInList() {
    this.getCurrentDateRange();
    this.status = [LODGE_STATUS_CODE.waiting, LODGE_STATUS_CODE.checkin].join(',');

    const payload: any = {
      status: this.status,
      offset: this.offset,
      limit: this.limit,
      checkin_after: this.dateAfter,
      checkin_before: this.dateBefore,
      worksite: this.work_site,
      roomtype: this.roomtype,
    };
    if (this.selectRoomType != 0) {
      payload.roomtype = this.selectRoomType;
    }

    if (this.option && this.text) {
      payload[this.option] = this.text;
    }

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.lodgeGuestsCheckin(payload);
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async setOffset(offset: number) {
    this.offset = offset;
    this.getCheckInList();
  }

  async downloadCheckIn() {
    //console.log("download in");

    try {
      this.headerStore.isLoading = true;
      let ids: any = Object.keys(this.checkeds).map((id: any) => this.checkeds[id].id);
      let { data, headers } = await Core.downloadByBookingIds(ids.join(','));

      let csv = XLSX.read(data, { type: 'string' });
      XLSX.writeFile(csv, 'CheckInList.xlsx');

      this.checkeds = {};
      this.allCheckFlg = false;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //퇴숙 리스트 가져오기
  async getCheckOutList() {
    this.status = [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(',');
    const payload: any = {
      status: this.status,
      offset: this.offset,
      limit: this.limit,
      checkout_after: this.checkout_after,
      checkout_before: this.checkout_before,
      work_site: this.work_site,
      roomtype: this.roomtype,
    };

    if (this.selectRoomType != 0) {
      payload.roomtype = this.selectRoomType;
    }
    if (this.option && this.text) {
      payload[this.option] = this.text;
    }
    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.lodgeGuestsCheckout(payload);
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //이숙신청 리스트 가져오기
  async getGuestMoveList() {
    const payload: any = {
      offset: this.offset,
      limit: this.limit,
      moved_after: this.checkout_after,
      moved_before: this.checkout_before,
      worksite: this.work_site,
      roomtype: this.selectRoomType,
    };

    if (this.option && this.text) {
      payload[this.option] = this.text;
    }
    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.getGuestMoveList(payload);
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 이숙 상세
  async getGuestMoveDetail() {
    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.getGuestMoveDetail(this.tempCheckChangeDetailItem.id);
      this.checkChangeDetailItem = data;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // //이숙승인
  // async moveApplicate(guestId: number, p){
  //   const data = await Lodge.moveApplicate(guestId);
  // }

  async downloadCheckOut() {
    //console.log("download in");

    try {
      this.headerStore.isLoading = true;
      let ids: any = Object.keys(this.checkeds).map((id: any) => this.checkeds[id].id);

      let { data, headers } = await Core.downloadByBookingIds(ids.join(','));

      let csv = XLSX.read(data, { type: 'string' });
      XLSX.writeFile(csv, 'CheckOutList.xlsx');

      this.checkeds = {};
      this.allCheckFlg = false;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  setCheckoutData(data: any) {
    this.checkoutData = data;
  }
  setmoveAppData(data: any) {
    this.moveAppData = data;
  }

  // 승인
  async applySubmit() {
    const payload: any = {
      guest_id: this.checkoutData.id,
      status: CHECKOUT_STATUS_CODE.approve,
      status_description: '',
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuestCheckoutUpdate(payload);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 거절
  async rejectSubmit() {
    const payload: any = {
      guest_id: this.checkoutData.id,
      status: CHECKOUT_STATUS_CODE.reject,
      status_description: this.status_description,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuestCheckoutUpdate(payload);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 재요청
  async terminateSubmit() {
    const payload: any = {
      guest_id: this.checkoutData.id,
      status: CHECKOUT_STATUS_CODE.terminate,
      status_description: this.status_description,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuestCheckoutUpdate(payload);
      this.headerStore.isLoading = false;
      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 퇴숙 상세
  async getGuestCheckoutDetail() {
    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.getGuestCheckoutDetail(this.checkOutDetailItem.id);
      this.checkOutDetailItem = data[0];
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 퇴숙신청
  async checkOut() {
    let imgIds: any = this.images.map((obj: any) => {
      return obj.id;
    });

    const payload: any = {
      managed_at: this.manageYear + '-' + this.manageMonth + '-' + this.manageDay,
      is_key_return: this.isKeyReturn,
      key_description: this.keyDescription,
      key_select: this.keySelect,
      is_equipment_ok: this.isEquipment,
      equipment_description: this.equipmentDescription,
      images: imgIds,
      meal_ticket: this.mealTicket,
      memo: '',
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuestCheckoutApplicant(this.checkoutData.booking.id, payload);

      // 문자 발송
      let smsPayload: any = {
        cellphone: this.checkoutData.guest_cellphone,
        message:
          '퇴숙신청이 확정되었습니다. \n퇴숙일 : ' +
          this.manageYear +
          '-' +
          this.manageMonth +
          '-' +
          this.manageDay +
          '\n사용료는 익월 급여공제예정(일할계산)입니다.',
      };

      if (this.checkoutData.room_type !== ROOM_TYPE_CODE.vip_room && this.checkoutData.room_type !== ROOM_TYPE_CODE.family_room) {
        const { data: sms } = await Lodge.smsSendAPI(smsPayload);
      }

      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //이숙처리
  async moveAccess() {
    let imgIds: any = this.images.map((obj: any) => {
      return obj.id;
    });

    const payload: any = {
      //'room': this.roomId,
      managed_at: this.manageYear + '-' + this.manageMonth + '-' + this.manageDay,
      is_key_return: this.isKeyReturn,
      key_description: this.keyDescription,
      key_select: this.keySelect,
      is_equipment_ok: this.isEquipment,
      equipment_description: this.equipmentDescription,
      images: imgIds,
      memo: this.memo,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.moveApplicate(this.moveAppData.id, payload);

      // 이숙 1년에 1회만 가능하다는 데이터 담기
      let moveStatus: number = 0;
      if (this.moveAppData.category === LODGE_MOVE_CODE.alway) {
        moveStatus = DELAY_COUNT_CODE.normal;
      } else if (this.moveAppData.category === LODGE_MOVE_CODE.fixed) {
        moveStatus = DELAY_COUNT_CODE.regular;
      }
      const moveData: any = {
        classify: this.moveAppData.guest.guest_employee_number,
        status: moveStatus,
        booking: this.moveAppData.guest.booking_id,
      };

      const { data: move } = await Lodge.lodgeMoveCheck(
        this.moveAppData.guest.guest_employee_number,
        moveStatus,
        moveData,
      );
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //이숙거절
  async moveReject() {
    const payload: any = {
      status_description: this.status_description,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.moveReject(this.moveAppData.id, payload);

      if (this.moveAppData.status === MOVE_STATUS_CODE.apply && this.moveAppData.category === LODGE_MOVE_CODE.one) {
        // 1:1 문자 발송
        let smsPayload: any = {
          cellphone: [this.moveAppData.guest.guest_cellphone, this.moveAppData.other_guest.guest_cellphone].join(','),
          message:
            '관리자가 1:1 이숙신청을 거절 되었습니다. \n거절 사유 : ' +
            this.status_description +
            '\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
            getWorkSitePhoneNum(this.moveAppData.guest.guest_work_site_id, this.moveAppData.guest.room_type),
        };
        const { data: sms } = await Lodge.smsSendAPI(smsPayload);
      } else {
        // 정기, 상시 문자 발송
        let smsPayload: any = {
          cellphone: this.moveAppData.guest.guest_cellphone,
          message:
            '이숙신청이 반려되었습니다. \n반려 사유 : ' +
            this.status_description +
            '\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
            getWorkSitePhoneNum(this.moveAppData.guest.guest_work_site_id, this.moveAppData.guest.room_type),
        };
        const { data: sms } = await Lodge.smsSendAPI(smsPayload);
      }
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //이숙 승인
  async moveApprove() {
    if (this.moveAppData.status === MOVE_STATUS_CODE.apply && this.moveAppData.category !== LODGE_MOVE_CODE.one) {
      const payload: any = {
        room_id: this.roomId,
      };

      try {
        this.headerStore.isLoading = true;
        const { data } = await Lodge.moveApprove(this.moveAppData.id, payload);

        if (this.moveAppData.category === LODGE_MOVE_CODE.alway) {
          // 문자 발송 - 상시
          let smsPayload: any = {
            cellphone: this.moveAppData.guest.guest_cellphone,
            message:
              '이숙신청이 승인되었습니다.  \n호실 : ' +
              this.moveAppData.guest.room_building_name +
              ' ' +
              this.moveAppData.guest.room_building_dong +
              ' ' +
              this.moveAppData.guest.room_name +
              '호 -> ' +
              this.roomInfo +
              //+ '\n이숙일 : 00.00.00'
              '\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
              getWorkSitePhoneNum(this.moveAppData.guest.guest_work_site_id, this.moveAppData.guest.room_type),
          };
          const { data: sms } = await Lodge.smsSendAPI(smsPayload);
        } else if (this.moveAppData.category === LODGE_MOVE_CODE.fixed) {
          // 문자 발송 - 정기
          let smsPayload: any = {
            cellphone: this.moveAppData.guest.guest_cellphone,
            message:
              '이숙신청이 확정 되었습니다. \n신청자 : ' +
              this.moveAppData.guest.guest_name +
              '\n신청자 기존 호실 : ' +
              this.moveAppData.guest.room_building_name +
              ' ' +
              this.moveAppData.guest.room_building_dong +
              ' ' +
              this.moveAppData.guest.room_name +
              '\n신청자 변경 호실 : ' +
              this.roomInfo +
              '\n이숙요청일 : ' +
              this.moveAppData.moved_at +
              '\n중요 : 현 세대의 공용부분 포함하여  필요시 청소 및 정리정돈 이후 이숙 요청드립니다.' +
              '\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
              getWorkSitePhoneNum(this.moveAppData.guest.guest_work_site_id, this.moveAppData.guest.room_type),
          };
          const { data: sms } = await Lodge.smsSendAPI(smsPayload);
        }
        this.headerStore.isLoading = false;

        return {
          save: true,
          status: 200,
          data: '',
        };
      } catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return {
            save: false,
            status: status,
            data: data,
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            save: false,
            status: status,
            data: data,
          };
        } else if (status === 500) {
          window.location.href = '/500';
          return {
            save: false,
            status: status,
            data: data,
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      }
    } else {
      try {
        this.headerStore.isLoading = true;
        const { data } = await Lodge.moveApprove(this.moveAppData.id, {});

        // 문자 발송 - 1:1
        let smsPayload: any = {
          cellphone: [this.moveAppData.guest.guest_cellphone, this.moveAppData.other_guest.guest_cellphone].join(','),
          message:
            '관리자가 1:1 이숙신청을 승인 되었습니다. \n신청자 : ' +
            this.moveAppData.guest.guest_name +
            '\n신청자 호실 : ' +
            this.moveAppData.guest.room_building_dong +
            ' ' +
            this.moveAppData.guest.room_name +
            '호' +
            '\n승인자 호실 : ' +
            this.moveAppData.other_guest.room_building_dong +
            ' ' +
            this.moveAppData.other_guest.room_name +
            '호' +
            '\n이숙요청일 : ' +
            this.moveAppData.moved_at +
            '\n실제 근무조 : ' +
            this.moveAppData.guest.guest_party +
            '\n요청사항 : ' +
            this.moveAppData.claim +
            '\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
            getWorkSitePhoneNum(this.moveAppData.guest.guest_work_site_id, this.moveAppData.guest.room_type),
        };
        const { data: sms } = await Lodge.smsSendAPI(smsPayload);
        this.headerStore.isLoading = false;

        return {
          save: true,
          status: 200,
          data: '',
        };
      } catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return {
            save: false,
            status: status,
            data: data,
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            save: false,
            status: status,
            data: data,
          };
        } else if (status === 500) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      }
    }
  }

  // 1:1 이숙처리
  async oneToOneMoveAccess() {
    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.oneToOneMoveAccess(this.moveAppData.id);

      // 이숙 1년에 1회만 가능하다는 데이터 담기
      const moveData: any = {
        classify: this.moveAppData.guest.guest_employee_number,
        status: DELAY_COUNT_CODE.oneOne,
        booking: this.moveAppData.guest.booking_id,
      };

      const { data: move } = await Lodge.lodgeMoveCheck(
        this.moveAppData.guest.guest_employee_number,
        DELAY_COUNT_CODE.oneOne,
        moveData,
      );
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 정기 이숙 설정 리스트
  async getFixedList() {
    const payload: any = {
      offset: this.offset,
      limit: this.limit,
      work_site: this.work_site,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.getFixedList(payload);

      this.totalCount = parseInt(data.count);
      this.totalPage = Math.ceil(data.count / this.limit);
      this.list = data.results;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 정기 이숙 설정 추가
  async setFixedData() {
    const payload: any = {
      work_site: this.work_site,
      title: this.title,
      start_date: format_YYYYMMDDHH(this.start_date, this.start_hour),
      end_date: format_YYYYMMDDHH(this.end_date, this.end_hour),
      employee_number: this.employee_number,
      name: this.name,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.setFixedData(payload);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // on, off 변경
  async fixedDataOnOff(id: number, onoff: boolean) {
    const payload: any = {
      is_use: onoff,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.fixedDataOnOff(id, payload);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //입숙, 이숙, 퇴숙 현황 > 현황 및 설정 > 월별 입숙, 이숙, 퇴숙 현황
  async occupantStatiStics() {
    this.getCurrentDateRange();
    const payload: any = {
      work_site: this.work_site,
      roomtype: this.roomtype,
      date_after: this.date_after,
      date_before: this.date_before,
    };

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.occupantStatiStics(payload);
      this.occuItem = data;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  //입숙, 이숙, 퇴숙 현황 > 현황 및 설정 > 관리 현황
  async managementStatus() {
    const payload: any = {
      work_site: this.work_site,
      roomtype: this.roomtype,
    };

    if (Number(this.work_site) === 2) {
      if (!isNull(this.building) && this.building !== undefined && this.building.length > 0) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.managementStatus(payload);
      this.mngStatus = data;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  // 업무 이관처리
  async escalationAccess() {
    const payload: any = {
      employee_number: this.employee_number, //이관 관리자 employee_number
      reason: this.reason, //업무이관 사유
      auth_building: this.auth_building, //남양에서 업무이관 할 뉴타운 또는 본동
      guest: this.moveAppData.guest.id,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await Lodge.escalationAccess(payload);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }
}

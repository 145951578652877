import getAccessToken from './commons/getAccessToken';

import { SHOW_ALERT_MODAL } from '../store/modules/modal'

function apiHandler(rules = { ERROR_CODE_CHECK: 'code' }): any {
  return function (target: any, property: any, descriptor: any): any {
    const originMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      let res = {
        status: 200,
        data: {},
        errorCode: false,
        headers: {}
      };
      try {
        const { status, data, headers } = await originMethod.apply(target, args);

        res = {
          status,
          data,
          errorCode: data[rules.ERROR_CODE_CHECK],
          headers
        };

        // 정상응답은 여기서 반환
        return res;
      } catch (err) {
        // 4xx, 5xx는 exception 처리된다.

        /*
         * 400: data validation
         * 401: accessToken, refreshToken 만료
         * accessToken 만료는 내부적으로 처리하나 refreshToken이 만료되면 외부에서 처리한다.
         * 403:
         */

        const { status, data, headers } = err.response;
        res = {
          status,
          data,
          errorCode: data[rules.ERROR_CODE_CHECK],
          headers
        };
        if ( status === 500 ) { // 500 error
          if(window.location.pathname === '/guest/deductible/management') {
            //alert("error");
            return;
          } else {
            // window.location.href = '/500';
            // return res;
          }
        }

        /* if ( status === 401 ) {
          //console.log('accessToken 만료');
          // alert("보안을 위하여 다시 로그인해 주시기 바랍니다.")
          
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
         
          return res;
        } else if (status === 403) {
          alert("접근 권한이 없습니다.");
          // (window as any).store.dispatch({
          //   type: SHOW_ALERT_MODAL,
          //   payload: {
          //     title: '권한오류',
          //     msg: '접근권한이 없습니다.',
          //     redirectPath: false,
          //     statusCode: err.response.status,
          //     errorCode: '',
          //     type: 'error',
          //     isModalShow: true,
          //   }
          // })
        } */
        // else if ( status === 500 ) { // 500 error
        //   window.location.href = '/500';
        //   return res;
        // }

        throw {
          ...res,
          error: new Error('Error Force Raise By StatusCode'),
        };
      }
    };
  };
}

export default apiHandler;

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultTemplate from '../../../components/templetes/default';
import { GENDER_CODE, WORK_SITE } from '../../../constants';
import { onShowMsg, SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { auth } from '../../../apis';
import { UpdateUserPayload } from '../../../apis/auth';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { MessageContext, MessageImpl } from '../../../routes';

const MyPageInfoUpdate = observer ((props: any) => {
    if (!props.location.state) {
        props.history.replace('/mypage/info');
    }
    const { user } = useSelector((state: any) => ({
        user: state.user
    }))
    const {headerStore: headerStore} = useStores();

    const [pw1, setPw1] = useState<string>('');
    const [pw2, setPw2] = useState<string>('');
    const [cellphone, setCellphone] = useState<string>("");
    const [assignedTask, setAssignedTask] = useState<string>(user.assigned_task);
    const [verifyCode, setVerifyCode] = useState<string>("");
    const [email, setEmail] = useState<any>(user.email);

    const [token, setToken] = useState<string>("");
    const [isVerify, setIsVerify] = useState<boolean>(false);
    let [isVerifyCompleted, setIsVerifyCompleted] = useState<boolean>(false);

    const { headerStore: hstore } = useStores();
    let [expire, setExpire] = useState<number>(0); // 만료시간

    const dispatch = useDispatch();

    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    

    useEffect(() => {
        let interval: any;
        hstore.init();
        if (isVerify) {
            interval = setInterval(() => {
                setExpire(expire - 1);
            }, 1000)
            if (expire <= 0) {
                clearInterval(interval);
                //setToken("")
                setIsVerify(false);
                setIsVerifyCompleted(false);
                dispatch(onShowMsg({ msg: "인증 시간이 만료되었습니다." }));
            }
        }
        return () => interval && clearInterval(interval);
    }, [expire]);

    // 휴대폰 번호로 인증번호 요청
    const onAuth = async () => {
        try {
            headerStore.isLoading = true;
            let { data } = await auth.signupAuth({ cellphone })
            setToken(data.token);

            dispatch(onShowMsg({ msg: '인증번호 전송을 완료했습니다.' }));
            setIsVerify(true);
            setExpire(180);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return;
            } else if (status === 403) {
                (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                    },
                });
                return;
            } else if (status === 500) {
                window.location.href = '/500';
                return;
            }

            setIsVerify(false);
            dispatch(
                onShowMsg({ msg: '인증요청 중 문제가 발생하였습니다' })
            );
            return;
        }
    }
    // 인증번호 검증
    const onVerify = async () => {
        try {
            headerStore.isLoading = true;
            let { data } = await auth.signupVerify({
                number: verifyCode,
                cellphone,
                token,
            })

            dispatch(onShowMsg({ msg: '인증을 완료했습니다.' }));
            setIsVerify(true);
            setIsVerifyCompleted(true);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            // console.log(err);
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return;
            } else if (status === 403) {
                (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                    },
                });
                return;
            } else if (status === 500) {
                window.location.href = '/500';
                return;
            }

            dispatch(
                onShowMsg({ msg: '인증 중 문제가 발생하였습니다' })
            );
            return;
        }
    }


    const updateSubmit = async () => {
        const [isValidate, msg] = validate()
        if (isValidate) {
            dispatch(onShowMsg({ msg: msg }));
            return
        }


        if (!isVerifyCompleted) {
            dispatch(onShowMsg({ msg: "인증을 진행해주세요" }));
            return;
        }

        let payload: UpdateUserPayload = {
            new_password: pw1,
            new_password2: pw2,
            assigned_task: assignedTask,
            cellphone,
            email
        }

        try {
            headerStore.isLoading = true;
            let data = await auth.update(user.employeeNumber, payload);
            dispatch(onShowMsg({ msg: '수정이 완료되었습니다.' }));
            //props.history.replace('/mypage/info');
            headerStore.isLoading = false;
            props.history.replace('/mypage/info');
        } catch ({ status, data }) {
            // console.log(err);
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return;
            } else if (status === 403) {
                (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                    },
                });
                return;
            } else if (status === 500) {
                window.location.href = '/500';
                return;
            }


            dispatch(onShowMsg({ msg: '수정중 문제가 발생하였습니다.' }));
            return;
        }
    }

    const validate = (): [boolean, string] => {
        let isValidate: boolean = false;
        let msg: string = '';

        if (!assignedTask) {
            isValidate = true;
            msg = '담당업무를(을) 입력하지 않았습니다.'
        } else if (!pw1.length || !pw2.length || pw1 !== pw2) {
            isValidate = true;
            msg = '비밀번호를 입력하지 않았거나 비밀번호가 일치하지 않습니다.'
        } else if ((pw1.length < 8)) {
            isValidate = true;
            msg = '비밀번호는 최소 8자리 입니다.'
        } else if (!isVerify) {
            isValidate = true;
            msg = '인증을 진행하지 않았습니다.'
        } else if (!email.length) {
            isValidate = true;
            msg = '이메일을 입력하지 않았습니다.'
        }

        return [isValidate, msg]
    }

    if (!props.location.state) {
        return <></>;
    }

    return (
        <>
            <DefaultTemplate
                id="wrap"
                className=""
                isLoading={headerStore.isLoading}
                {...props}
            >
                <div className="tab_contents on">
                    <h4>
                        기본 정보 입력
                        <div className="right_area">
                            <ul className="lst_help">
                                <li>모든 입력사항 필수 입력 입니다</li>
                            </ul>
                        </div>
                    </h4>
                    <div className="table_normal_list table_write">
                        <table>
                            <caption className="sr_only">관리내역</caption>
                            <colgroup>
                                <col width="15%" />
                                <col width="*" />
                                <col width="15%" />
                                <col width="*" />
                                <col width="15%" />
                                <col width="*" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>관리번호(아이디)</th>
                                    <td><input type="text" className="form_control" disabled={true} value={user.employeeNumber} /></td>
                                    <th>성명</th>
                                    <td><input type="text" className="form_control" disabled={true} value={user.username} /></td>
                                    <th>생년월일</th>
                                    <td><input type="text" className="form_control" value={user.birthdate} disabled /></td>
                                </tr>
                                <tr>
                                    <th>성별</th>
                                    <td>
                                        <label className="input_radio" htmlFor="sex_user_choice1">
                                            <input type="radio" id="sex_user_choice1" name="sex" checked={user.gender === GENDER_CODE.male} />
                                            <span className="label_text">남자</span>
                                        </label>
                                        <label className="input_radio" htmlFor="sex_user_choice2">
                                            <input type="radio" id="sex_user_choice2" name="sex" checked={user.gender === GENDER_CODE.female} />
                                            <span className="label_text">여자</span>
                                        </label>
                                    </td>
                                    <th>휴대폰번호</th>
                                    <td><input type="text" className="form_control" value={user.phone} disabled /></td>
                                    <th>소속회사</th>
                                    <td><input type="text" className="form_control" disabled={true} value={user.company} /></td>
                                </tr>
                                <tr>
                                    <th>근무지</th>
                                    <td><input type="text" className="form_control" disabled={true} value={WORK_SITE[user.work_site]} /></td>
                                    <th>담당업무</th>
                                    <td className="br0"><input type="text" className="form_control" value={assignedTask} onChange={(e: any) => setAssignedTask(e.target.value)} /></td>
                                    <th>이메일</th>
                                    <td ><input type="text" className="form_control" value={email} onChange={(e: any) => setEmail(e.target.value)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>  {/*table*/}

                    <h4>추가 정보 입력</h4>
                    <div className="form_write clearfix">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="input_pw" className="title_form">비밀번호<span className="emp_red"></span></label>
                                <input type="password" id="input_pw" className="form_control form_sm" placeholder="비밀번호를 입력해주세요" value={pw1} onChange={(e: any) => setPw1(e.target.value)} />
                            </div>
                            <div className="col">
                                <label htmlFor="input_pw2" className="title_form">비밀번호 확인<span className="emp_red"></span></label>
                                <input type="password" id="input_pw2" className="form_control form_sm" placeholder="비밀번호를 다시 한번 입력해주세요" value={pw2} onChange={(e: any) => setPw2(e.target.value)} />
                            </div>
                        </div>

                        <ul className="lst_warning">
                            <li>비밀번호는 8~20자로 영문 , 특수문자, 숫자 중 세 가지를 조합하여 입력해주세요. 영문 대문자와 소문자를 구별해주세요</li>
                        </ul>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="input_phonenumber" className="title_form">휴대폰 번호</label>
                                <div className="">
                                    <input type="text" id="input_phonenumber" className="form_control form_sm" placeholder="숫자만 입력해주세요" value={cellphone} onChange={(e: any) => setCellphone(e.target.value)} />
                                    {/*<button className="btn btn_lg btn_gray">인증하기</button>*/}
                                </div>
                            </div>
                        </div>

                        <ul className="lst_warning">
                            <li>핸드폰 번호 또는 이메일이 다를 경우, HMC 인사 DB에서 해당 정보를 변경하시면 본 플랫폼에서도 자동으로 변경됩니다</li>
                        </ul>
                    </div>

                    <h4>인증하기</h4>

                    <div className="form_write clearfix">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="input_phonenumber2" className="title_form">휴대폰 번호 인증</label>
                                <p className="form_phone">
                                    <input type="text" id="input_phonenumber2" className="form_control form_sm" placeholder="숫자만 입력해주세요" value={cellphone} disabled />
                                    <button className="btn btn_sm btn_outline" onClick={onAuth}>인증하기</button>
                                    {/*<button className="btn btn_sm btn_outline">재전송</button>*/}
                                </p>
                                <p className="form_phone mt10">
                                    <input type="text" id="input_phonenumber2" className="form_control form_sm" placeholder="인증번호를 입력해주세요" value={verifyCode} onChange={(e: any) => setVerifyCode(e.target.value)} />
                                    <span className="time">{expire && Math.floor(expire / 60)}:{expire && expire % 60}</span>
                                    <button className="btn btn_sm btn_outline" onClick={onVerify}>확인</button>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="submit ac">
                        <button className="btn btn_lg btn_outline" onClick={() => props.history.replace('/mypage/info')}>취소</button>
                        <button className="btn btn_lg btn_darkblue" onClick={() => updateSubmit()}>완료</button>
                    </div>

                </div>
            </DefaultTemplate>
        </>
    );
});

export default MyPageInfoUpdate;

import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import DefaultTemplete from '../../components/templetes/default';
import LocusTotalTemplete from '../locusTotal';

import RoomTypeSelect from '../../components/atoms/select/roomTypeSelectBox';
import GuestType from '../../components/atoms/select/guestType';
import VisableType from '../../components/atoms/select/visibleType';
import Require from '../../components/atoms/select/require';

import Button from '../../components/atoms/button';
import ReactDOM from 'react-dom';
import { worksite } from '../../apis';
import { useStores } from '../../store/mobx';
import { WORK_SITE } from '../../constants';
import { observer } from 'mobx-react';
import { info } from 'console';
import { MessageContext, MessageImpl } from '../../routes';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

// 이용자 범위설정
const LocusDocument = observer((props: any) => {
  const { userInfo = {}, worksites = [], worksiteMap = {} } = useSelector((state: any) => ({
    worksites: state.base.worksites,
    worksiteMap: state.base.worksiteMap,
    userInfo: state.user
  }))

  const {headerStore: headerStore} = useStores();
  const [name, setName] = useState<string>("");
  const [roomType, setRoomType] = useState<number>(1);
  const [guestType, setGuestType] = useState<number>(1);
  const [visibleType, setVisibleType] = useState<number>(1);
  const [isRequire, setIsRequire] = useState<number>(1);
  const [workSite, setWorkSite] = useState<number>();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  useEffect(() => { 
    setWorkSite(headerStore.workSite);
  },[headerStore.selectWorkSite, headerStore.workSite]);

  const onSaveHandler = async () => {
    // console.log('룸타입',roomType);
    // console.log('비지블타입',visibleType);
    // console.log('헤더스토어',headerStore.workSite);

    if(roomType !== 3 && visibleType === 2){
        info('배방시 입숙서류는 사택만 등록 하실 수 있습니다.');
        return
    }

    if (!name) {
      info("빈 항목이 존재합니다.");
      return
    }
    const payload: any = {
      work_site: headerStore.workSite,
      room_type: roomType,
      guest_type: guestType,
      visible_type: visibleType,
      is_required: isRequire? 1 : 0,
      name,
    }

    try {
      headerStore.isLoading = true;
      // console.log(payload)
      let { data } = await worksite.addDocument(payload);
      info("등록이 완료 되었습니다.");
      props.history.replace('/locus/guest/document');
      headerStore.isLoading = false;
    } catch ({ data, status }) {
      headerStore.isLoading = false;
      // console.log(data);
      // console.log(status);
      if (status === 400) {
        info('이미 등록되어 있습니다.')
      }else if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
        info('이미 등록되어 있습니다.');
        return;
    }
  }

  return (
    <LocusTotalTemplete
      className=""
      id="wrap"
      isLoading={headerStore.isLoading}
      {...props}
    >
      <h3>입숙서류 등록</h3>

      {/* table start */}
      <div className="tab_contents on">
        <div className="table_normal_list mt30" style={{ overflowX: 'auto' }}>
          <table>
            <caption className="sr_only">입숙서류 등록</caption>
            <colgroup>
              <col width="15%"></col>
              <col width="35%"></col>
              <col width="15%"></col>
              <col width="35%"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>사업장</th>
                <td>{WORK_SITE[Number(workSite)]}</td>
                <th>숙소유형</th>
                <td>
                  <RoomTypeSelect value={roomType} onChange={(roomType: number) => setRoomType(roomType)} />
                </td>
              </tr>
              <tr>
                <th>이용자 타입</th>
                <td>
                  <GuestType onlyData value={guestType} onChange={(guestType: number) => setGuestType(guestType)} />
                </td>
                <th>표시여부</th>
                <td>
                  <VisableType onlyData value={visibleType} onChange={(isVisable: number) => setVisibleType(isVisable)} />
                </td>
              </tr>
              <tr>
                <th>필수여부</th>
                <td>
                  <Require onlyData value={isRequire} onChange={(isRequire: number) => setIsRequire(isRequire)} />
                </td>
                <th >분류</th>
                <td >
                  <input type="text" className="form_control" value={name} maxLength={100} onChange={(e: any) => setName(e.target.value)} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* 하단버튼 */}
      <div className="group_tab mt40">
        <Button large background onClick={() => props.history.push('/locus/guest/document')}>목록</Button>

        <div className="right_area">
          <Button large background onClick={onSaveHandler}>등록</Button>
        </div>
      </div>
    </LocusTotalTemplete>
  );
});

export default LocusDocument;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../apis';
import { ERROR_CODE } from '../../../constants/error';
import { useStores } from '../../../store/mobx';
import { LOGIN_REQUEST, LOGOUT_REQUEST } from '../../../store/modules/auth';
import { CLEAN_TAB } from '../../../store/modules/base';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import './index.scss';
import MobileSignUp from './signUpIndex';
import MobileSearchId from './mobileSearchId';
import MobileSearchPw from './mobileSearchPw';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import { observer } from 'mobx-react';

const MobileSignIn = observer((props: any) => {
    const { headerStore: hstore } = useStores();
    let { authInfo = {}, userInfo = {} } = useSelector((state: any) => ({
        authInfo: state.auth,
        userInfo: state.user
    }))
    let [employee_number, setEmployeeNumber] = useState<string>(window.localStorage.getItem('employee_number') || '');
    let [password, setPassword] = useState<string>("");
    let [cellPhone, setCellPhone] = useState<string>("");
    let [number, setNumber] = useState<string>("");
    const [ipAddress, setIpAddress] = useState<string>('');

    let [isIdSave, setIsIdSave] = useState<boolean>(window.localStorage.getItem('employee_number') ? true : false);
    let [expire, setExpire] = useState<number>(0); // 만료시간
    //let [token, setToken] = useState<string>("");
    let [isVerify, setIsVerify] = useState<boolean>(false);
    let [isVerifyCompleted, setIsVerifyCompleted] = useState<boolean>(false);
    //let [isLogin, setIsLogin] = useState<boolean>(false);
    const [verifyCount, setVerifycount] = useState<number>(0);
    const [isShowSearchId, setIsShowSearchId] = useState<boolean>(false);
    const [isShowSearchPassword, setIsShowSearchPassword] = useState<boolean>(false);
    const [isShowSingUp, setIsShowSingUp] = useState<boolean>(false);

    const onShowSearchIdPopupHandler = () => {
        setIsShowSearchId(true);
    }; // 아이디 찾기 열기
    const onCloseSearchIdPopupHandler = () => {
        setIsShowSearchId(false);
    }; // 아이디 찾기 닫기

    const onShowSearchPasswordPopupHandler = () => {
        setIsShowSearchPassword(true);
    }; // 비밀번호 찾기 열기
    const onCloseSearchPasswordPopupHandler = () => {
        setIsShowSearchPassword(false);
    }; // 비밀번호 찾기 닫기

    let dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const publicIp = require('public-ip');
            setIpAddress(await publicIp.v4());
        })()
    }, []);

    useEffect(() => {
        let interval: any;
        hstore.init();
        if (isVerify) {
            interval = setInterval(() => {
                setExpire(expire - 1);
            }, 1000)
            if (expire <= 0) {
                clearInterval(interval);
                //setToken("")
                setIsVerify(false);
                setIsVerifyCompleted(false);
                showMsg("인증 시간이 만료되었습니다.");
            }
        }
        return () => interval && clearInterval(interval);
    }, [expire])

    useEffect(() => {
        hstore.init();
        // decorator에서 token 만료(401) 발생 시 isRetryLogin 생성
        if (window.localStorage.getItem("isRetryLogin")) {
            dispatch({ type: LOGOUT_REQUEST, history: props.history, msg: "로그아웃 되었습니다. 보안을 위해 다시 로그인해 주시기 바랍니다." });
        }
        dispatch({ type: CLEAN_TAB });
        window.localStorage.clear();
    }, [])

    useEffect(() => {
        hstore.userInfo = authInfo.userInfo;
        hstore.init();

        if (authInfo.isLogin) {
            //setIsLogin(true);
        }
    }, [authInfo.isLogin])

    // 로그인
    const onSubmit = (e: any) => {
        hstore.init();
        // 나중에 지울 코드!!!!!!!!
        if (process.env.REACT_APP_MODE === 'dev') {
            let payload = {
                employee_number,
                password,
                ip_address: ipAddress,
                smsauth : number
            };

            if (isIdSave) window.localStorage.setItem('employee_number', employee_number);
            dispatch({ type: LOGIN_REQUEST, payload, history: props.history });
        }

        if (!isVerifyCompleted) {
            showMsg("인증을 진행해주세요");
            return
        }

        if(isVerifyCompleted){
            let payload = {
                employee_number,
                password,
                ip_address: ipAddress,
                smsauth : number
            };

            if (isIdSave) window.localStorage.setItem('employee_number', employee_number);
            dispatch({ type: LOGIN_REQUEST, payload, history: props.history });
        }
    }

    // 인증번호 발송0
    const onSigninAuthHandler = async () => {
        if(!employee_number || employee_number === '') {
            showMsg("아이디를 입력해주세요");
            return false;
        }else if(!password || password === '') {
            showMsg("비밀번호를 입력해주세요");
            return false;
        }else if(!cellPhone || cellPhone === '') {
            showMsg("휴대폰 번호를 입력해주세요");
            return false;
        }

        try {
            // 인증번호 발송
            let payload = {
            employee_number : employee_number,
            password : password,
            cellphone : cellPhone
            };
    
            hstore.isLoading = true;
            const { data } = await auth.signinManagerAuth(payload);
            showMsg("입력하신 정보로 인증번호를 발송하였습니다");
            setNumber('');
            setIsVerifyCompleted(false);
            setIsVerify(true);
            setVerifycount(verifyCount + 1);
            //setToken(data.token);
            setExpire(180);
            hstore.isLoading = false;
        } catch ({ status, data }) {
            hstore.isLoading = false;
            // console.log(status, data)
            setIsVerify(false);
            if (status === 400) {
                showMsg(data);
                return;
            } else {
                showMsg("인증번호 발송 중 문제가 발생했습니다.");
                return;
            }
        }
    }

    // 인증확인
    const onSigninAuthVerify = async () => {
        if (!isVerify) {
            showMsg("인증을 진행하지 않았습니다.");
            return
        }
        if (number.length !== 6) {
            showMsg("인증번호를 확인해주세요.");
            return
        }

        try {
            hstore.isLoading = true;
            // 인증번호 발송
            let payload = {
                employee_number : employee_number,
                cellphone : cellPhone,
                smsauth : number
            };

            let { data } = await auth.signinSmsAuth(payload);
            //setToken(data.token);
            showMsg("인증을 완료하였습니다.");
            setIsVerifyCompleted(true);
            hstore.isLoading = false;
        } catch ({ status, data }) {
            hstore.isLoading = false;
            // console.log(status, data)
            setIsVerifyCompleted(false);
            if (status === 400) {
                showMsg(data);
                return;
            } else {
                showMsg("인증번호 발송 중 문제가 발생했습니다.");
                return;
            }
        }
    }

    const showMsg = (msg: string) => {
        dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
                title: '',
                msg,
                redirectPath: false,
                statusCode: false,
                errorCode: '',
                type: 'warning',
                isModalShow: true,
            },
        });
    };
    return (
        <>
            <div id="wrap" className="wrap_mobile wrap_login">
                <div id="container">
                    <div className="row">
                        <div className="col">
                            <div className="group_login">
                                <h2 className="title">통합숙소관리시스템</h2>
                                <ul className="form_login clearfix">
                                    <li>
                                        <label htmlFor="input_id">아이디</label>
                                        <p>
                                            <input type="text" 
                                                id="input_id" 
                                                className="form_control form_lg" 
                                                placeholder="아이디를 입력해주세요" 
                                                value={employee_number}
                                                maxLength={7}
                                                onChange={(e: any) => setEmployeeNumber(e.target.value)}
                                            />
                                        </p>
                                        <ul className="lst_warning">
                                            {/*<li>아이디를 입력해주세요</li>*/}
                                        </ul>
                                    </li>
                                    <li>
                                        <label htmlFor="input_pw">패스워드</label>
                                        <p>
                                            <input type="password"
                                                id="input_pw" 
                                                className="form_control form_lg" 
                                                placeholder="비밀번호를 입력해주세요"
                                                value={password}
                                                onChange={(e: any) => setPassword(e.target.value)} 
                                            />
                                        </p>
                                        <ul className="lst_warning">
                                            {/*<li>비밀번호를 입력해주세요</li>*/}
                                        </ul>
                                    </li>
                                    <li className="">
                                        <label htmlFor="input_phone">휴대폰 인증</label>
                                        <p>
                                            <input type="text" 
                                                id="input_phone" 
                                                className="form_control form_lg" 
                                                placeholder="숫자만 입력해주세요" 
                                                maxLength={11}
                                                value={cellPhone} 
                                                onChange={(e:any) => {
                                                  e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                                                  setCellPhone(e.target.value);
                                                }}
                                            />
                                            <button type="button" className="btn btn_xs btn_outline" onClick={onSigninAuthHandler}>{verifyCount === 0? '인증하기' : '재전송'}</button>
                                            {/*<button type="button" className="btn btn_xs btn_outline">재전송</button>*/}
                                        </p>
                                        <ul className="lst_warning">
                                            {/*<li>숫자만 입력해주세요</li>*/}
                                        </ul>
                                    </li>
                                    <li>
                                        <label htmlFor="input_confirm">인증번호</label>
                                        <p>
                                            <input type="text" 
                                                id="input_confirm" 
                                                className="form_control form_lg" 
                                                placeholder="인증번호를 입력해주세요" 
                                                maxLength={6} 
                                                value={number}
                                                onChange={(e: any) => {
                                                    e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                                                    setNumber(e.target.value);
                                                }}
                                            />
                                            <span className="time">{expire && Math.floor(expire / 60)}:{expire && expire % 60}</span>
                                            <button type="button" className="btn btn_xs btn_outline" onClick={onSigninAuthVerify}>확인</button>
                                        </p>
                                        <ul className="lst_warning">
                                            {/*<li>숫자만 입력해주세요</li>*/}
                                        </ul>
                                    </li>
                                </ul>

                                <div className="submit">
                                    <button className="btn btn_lg btn_darkblue" onClick={onSubmit}>로그인</button>
                                    <button className="btn btn_lg btn_outline" onClick={()=>{setIsShowSingUp(true);}}>회원가입</button>

                                    <ul className="lst_srch">
                                        <li><a href="javascript:void(0);" className="btn btn_sm btn_link" onClick={onShowSearchIdPopupHandler}>아이디찾기</a></li>
                                        <li><a href="javascript:void(0);" className="btn btn_sm btn_link" onClick={onShowSearchPasswordPopupHandler}>비밀번호찾기</a></li>
                                    </ul>
                                </div>

                                <h4 className="mt20">보안 정책 및 유의사항</h4>
                                <div className="txt_security">
                                    1. 본 프로그램은 회사의 업무를 지원하기 위해 제공되는 프로그램으로, 이용자는 이를 회사 업무 처리 목적으로만 사용하여야 하며, 개인의 사적인 용도로는 사용할 수 없습니다. <br />
                                    2. 임직원은 당사 모바일 기기 준수 사항에 따라 모바일 기기 보호를 위한 화면 잠금 설정, 임의 개조 금지, 백신 설치 및 시스템 패치 등을 따라야 하며, 타인에게 ID와 Password를 공유해서는 안 됩니다. <br />
                                    3. 이용자는 회사의 보안 규정(모바일 보안 지침 등)에 따라 본 프로그램 상의 제반 정보가 유출되지 않도록 보안을 철저히 유지해야 합니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 아이디 찾기 팝업 */}
                <MobileSearchId 
                    isShowSearchId={isShowSearchId}
                    onCloseSearchIdPopupHandler={() => setIsShowSearchId(false)}
                />

                {/* 비밀번호 찾기 팝업 */}
                <MobileSearchPw 
                    isShowSearchPassword={isShowSearchPassword}
                    onCloseSearchIdPopupHandler={() => setIsShowSearchPassword(false)}
                />

                {/* 회원가입 팝업 */}
                 <MobileSignUp 
                    isShowSingUp={isShowSingUp}
                    popClose={()=>setIsShowSingUp(false)}
                 />
            </div>
        </>
    );
});

export default MobileSignIn;

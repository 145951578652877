import { strictEqual } from 'assert';
import FileSaver from 'file-saver';
import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import xss from 'xss';
import { core, voc } from '../../../apis';
import MobileFileUpload from '../../../components/organismas/form/mobileFile';
import { ROOM_TYPE, ROOM_TYPE_CODE, VOC_CATEGORY } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { StoreContext, useStores } from '../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { parserEscape } from '../../../utils/common';
import { formatByString, formatByString_YYYYMMDD, getTodayDate } from '../../../utils/date';
import MobileHeader from '../header';
import '../mb_common.scss';
import MobileChoiceManager from './choiceMng';
import MobilePlace from './place';
import MobileVocReg from './reg';

const MobileVocDetail = observer((props: any) => {
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const {headerStore: headerStore} = useStores(); //권한
    const { mobileTotalStore : store } = useStores();
    const { vocStore: vocStore } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [isCommentPop, setIsCommentPop] = useState<boolean>(false);
    const [isPlaceOpen, setIsPlaceOpen] = useState<boolean>(false);
    const [isImgPop, setIsImgPop] = useState<boolean>(false);
    const [imgPath, setImgPath] = useState<string>('');
    const [isRegPop, setIsRegPop] = useState<boolean>(false);
    const [isChoiceManagerOpen, setIsChoiceManagerOpen] = useState<boolean>(false);

    const [isOnlyCommentPop, setIsOnlyCommentPop] = useState<boolean>(false);

    useEffect(() => {
        if(store.vocData && isCommentPop){
            const c1: any = VOC_CATEGORY.find((c: any) => c.name === store.vocData.category1);
            const c2: any = c1.child.find((c: any) => c.name === store.vocData.category2);
            const c3: any = (c2 && c2.child.find((c: any) => c.name === store.vocData.category3)) || null;
            c3 && store.getCategories4();
        }
    }, [isCommentPop]);

    const placeName = () => {
        let placeName = '';
        if(store.vocData.places.length > 0) {
          const place = store.vocData.places[0];
          if (store.vocData.places.length === 1) {
            placeName =  `${ROOM_TYPE[place.room_type]} > ${place.building_name} > ${place.building_dong} > ${place.room_name}`;
          } else {
            placeName =  `${ROOM_TYPE[place.room_type]} > ${place.building_name} > ${place.building_dong} > ${place.room_name} 외 ${store.vocData.places.length - 1}곳`;
          }
        }
        return placeName;
    };

    // 이관 설정
    const onUpdateManagers = async (selectedManagers: any, contents: string) => {
        try {
            headerStore.isLoading = true;
            const data = { managers: selectedManagers.map((user: any) => user.id), contents : contents};

            try {
                await voc.updateVoc(vocStore.item.id, data);
            } catch ({ status, data }) {
                // console.log(status, data);
                headerStore.isLoading = false;
                if (status === 401) {
                    if (!window.localStorage.getItem("isRetryLogin")) {
                      window.localStorage.setItem("isRetryLogin", "retry");
                    }
                    window.location.href = '/';
                    return ;
                } else if (status === 403) {
                    (window as any).store.dispatch({
                      type: SHOW_ALERT_MODAL,
                      payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                      },
                    });
                    return ;
                }else if ( status === 500 ) {
                    window.location.href = '/500';
                    return;
                }
            
                info('이관 설정 중 문제가 발생하였습니다');
                return;
            }

            try {
                const result = await voc.getVoc(vocStore.item.id);
                // console.log(result);
            } catch ({ status, data }) {
                // console.log(status, data);
                headerStore.isLoading = false;
                if (status === 401) {
                    if (!window.localStorage.getItem("isRetryLogin")) {
                      window.localStorage.setItem("isRetryLogin", "retry");
                    }
                    window.location.href = '/';
                    return ;
                } else if (status === 403) {
                    (window as any).store.dispatch({
                      type: SHOW_ALERT_MODAL,
                      payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                      },
                    });
                    return ;
                }else if ( status === 500 ) {
                    window.location.href = '/500';
                    return;
                }
            
                info('이관 설정 중 문제가 발생하였습니다');
                return;
            }

            // setItem(result.data);
            headerStore.isLoading = false;
            info('담당자 설정이 완료되었습니다.');
            let resultData : any = store.getVocData(store.vocData.id); 
            setTimeout(function () {
                if(resultData.save) {
                    let resultData2 : any = vocStore.getItem(store.vocData.id);
                    // setTimeout(function () {
                        if(resultData2.save) {
                            return;
                        }else {
                            // info("문제가 발생했습니다.");
                            return;
                        }
                    // }, 250);
                    return;
                }else {
                    // info("문제가 발생했습니다.");
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            // console.log(status, data);
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
        
            info('이관 설정 중 문제가 발생하였습니다');
            return;
        }
    };

    // 저장
    const onSave = async () => {
        if (!store.comment) {
          info('내용을 입력해주세요.');
          return;
        }
    
        try {
            headerStore.isLoading = true;
            store.username = userInfo.username;
            let resultData:any = await store.saveComment();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(resultData.save) {
                    setIsCommentPop(false);
                    info('답변 등록을 완료했습니다.');
                    let resultData2:any = store.getVocData(store.vocData.id);
                    // setTimeout(function () {
                        if(resultData2.save) {
                            return;
                        }else {
                            // info("문제가 발생했습니다.");
                            return;
                        }
                    // }, 250);
                    
                }else {
                    // info("문제가 발생했습니다.");
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
        
            info('답변 저장 중 오류발생');
            return;
        }
    };

    const onSetPlacesHandler = (_places: any[]) => {
        vocStore.item.places = _places;
    };

    const onCommentSave = async () => {
    
        if (!store.comment) {
          info('내용을 입력해주세요.');
          return;
        }
    
        try {
          headerStore.isLoading = true;
          store.username = userInfo.username;
          let data : any = await store.saveOnlyComment();
          if(data.save) {
            info('작성을 완료했습니다.');
            setIsOnlyCommentPop(false)
            store.comment = '';
            let resultData2:any = store.getVocData(store.vocData.id);
            //await store.getItem(item.id);
          }
    
          headerStore.isLoading = false;
            
        } catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }else {
            info("문제가 발생했습니다");
            return;
          }
    
          // errorHandler({ status, data }, '답변 저장 중 오류발생');
        }
      };

    const onSatusChange = async () => {

    try {
        headerStore.isLoading = true;
        let status = '완료';
        if(store.vocData.is_complete) status = '재요청';
        
        let data : any = await store.statusChage();
        if(data.save) {
            info(status+' 처리 되었습니다.');
            await store.getVocData(store.vocData.id);
        }

        headerStore.isLoading = false;
        
    } catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
        } else if (status === 403) {
        (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
            },
        });
        return ;
        }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
        }else {
        info("문제가 발생했습니다");
        return;
        }

        // errorHandler({ status, data }, '답변 저장 중 오류발생');
    }
    };

   
    if(!store.vocData || !store.vocData.places) return <></>;

    return (
        <>
            {/* 상세 팝업 */}
            <div id="" className="pop_modal" style={{display: props.isDetailPop? 'block' : 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <button className="pop_btn_prev" onClick={() => {props.onClose(); store.popInit();}}></button>
                        <h3>{store.vocData.is_maintenance ? '유지보수' : 'VOC'} 상세</h3>
                        <button className="pop_btn_close pop_close" onClick={() => {props.onClose(); store.popInit();}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <ul className="lst_userinfo">
                            <li className="user_area">
                                <span className="img_user"></span>
                                <span className="username">{store.vocData.author_name}</span>
                                <span className="state">{store.vocData.is_complete ? '완료' : '처리중'}</span>
                            </li>
                            <li>
                                <strong>분류</strong>
                                <span>1. {store.vocData.category1} {store.vocData.category2? `> 2. ${store.vocData.category2}`  : ''} {store.vocData.category3? `> 3. ${store.vocData.category3}`  : ''}</span>
                            </li>
                            {store.vocData.places_etc && store.vocData.places_etc != ''? (<></>):(
                                <li>
                                    <strong>동/호수</strong>
                                    <span>{placeName()}</span>
                                </li>
                            )}
                            <li>
                                <strong>발생일</strong>
                                <span>{formatByString(store.vocData.found_at, 'yyyy.MM.dd HH')} 시경</span>
                            </li>
                            {/* <li>
                                <strong>접수시간</strong>
                                <span>21.05.21 12:59</span>
                            </li> */}
                            {store.vocData.places_etc && store.vocData.places_etc != ''? (
                                <li>
                                    <strong>발생장소</strong>
                                    <span>{'기타 > ' + store.vocData.places_etc + ' > ' + store.vocData.places_etc2}</span>
                                </li>
                            ):(
                                <li>
                                    <strong>발생장소</strong>
                                    <span>
                                        <button className="btn btn_xs btn_darkblue" onClick={() => setIsPlaceOpen(true)}>장소보기</button>
                                    </span>
                                </li>
                            )}
                        </ul>

                        <div className="table_normal_list table_write">
                            <table>
                                <caption className="sr_only">답변</caption>
                                <colgroup>
                                    <col width="85"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>제목</th>
                                    <td>
                                        {store.vocData.title}
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td>
                                        <div className="contents_area" id="editor"
                                            dangerouslySetInnerHTML={{ __html: parserEscape(store.vocData.contents) }}
                                        >    
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>이미지</th>
                                    <td>
                                        <div className="form_img">
                                            {store.vocData.images.map((image: any, index: number) => (
                                                <div className="img_area" onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                                    <a key={index}>
                                                        <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>첨부파일</th>
                                    <td>
                                    {store.vocData.files.map((file: any, index: number) => (
                                            <a key={index} 
                                                href={`${process.env.REACT_APP_MEDIA}/${file.path}`} 
                                            className="item file">{file.name}</a>
                                        ))}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* {store.vocData.is_complete? ( */}
                        {store.vocData.is_maintenance ?
                            store.vocData.comments.map((comment: any, index: number) => (
                                <div className="table_normal_list table_write">
                                    <table>
                                        <caption className="sr_only">답변</caption>
                                        <colgroup>
                                            <col width="85"/>
                                            <col width="*"/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>작성일시</th>
                                            <td>{comment.created_at? formatByString(comment.created_at, 'yyyy.MM.dd') : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>작성자</th>
                                            <td>{comment.author}</td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td>
                                                <div className="contents_area">
                                                    {comment.contents}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이미지</th>
                                            <td>
                                                <div className="form_img">
                                                    {comment.images.map((image: any, index: number) => (
                                                        <div className="img_area" onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                                            <a key={index}>
                                                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))
                        :   
                            store.vocData.comments.map((comment: any, index: number) => (index + 1) % 2 === 1? (
                                <div className="table_normal_list table_write">
                                    <table>
                                        <caption className="sr_only">답변</caption>
                                        <colgroup>
                                            <col width="85"/>
                                            <col width="*"/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>작성일시</th>
                                            <td>{comment? formatByString(comment.created_at, 'yyyy.MM.dd') : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td>
                                                <div className="contents_area">
                                                    {comment.contents}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이미지</th>
                                            <td>
                                                <div className="form_img">
                                                    {comment.images.map((image: any, index: number) => (
                                                        <div className="img_area" onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                                            <a key={index}>
                                                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="table_normal_list table_write">
                                    <table>
                                        <caption className="sr_only">추가문의</caption>
                                        <colgroup>
                                            <col width="85"/>
                                            <col width="*"/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>작성일시</th>
                                            <td>{comment? formatByString(comment.created_at, 'yyyy.MM.dd') : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>내용</th>
                                            <td>
                                                <div className="contents_area">
                                                    {comment.contents}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))
                        }

                        <div className="group_title">
                            <h4 className="title_contents">이관 이력</h4>
                            <div className="right_area">
                            {headerStore.writeRoleFlag && ((store.vocData.author_employee_number === userInfo.employeeNumber) 
                             || store.vocData.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0) ?
                                <>
                                    <button type="button" className="btn btn_xs btn_darkblue" onClick={onSatusChange}>
                                        {store.vocData.is_maintenance && store.vocData.is_complete ? '재요청' : '완료'}
                                    </button>
                                    <button type="button" className="btn btn_xs btn_outline" onClick={() => setIsChoiceManagerOpen(true)}>업무이관</button>
                                </>
                            : '' }
                            </div>
                        </div>
                        <div className="table_normal_list table_write mt20">
                            <table>
                                <caption className="sr_only">추가문의</caption>
                                <colgroup>
                                    <col width="*"/>
                                    <col width="*"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>등록자</th>
                                    <th>이관사유</th>
                                    <th>이관날짜</th>
                                </tr>
                                {!store.vocData.transfer || store.vocData.transfer.length <= 0? 
                                    (
                                    <tr>
                                        <td colSpan={3} className="blank_area">
                                            <p className="">이관이력이 없습니다</p>
                                        </td>
                                    </tr>
                                    ) :
                                    <>
                                    {store.vocData.transfer.map((trans : any, index : number) => (
                                        <tr>
                                            <td>{trans.author}</td>
                                            <td>{trans.reason}</td>
                                            <td>{formatByString_YYYYMMDD(trans.created_at)}</td>
                                        </tr>
                                    ))}
                                    </>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                                
                    {/* 본인 & 업무이관 받은 사람이 올린 유지보수만 수정가능 */}
                    {store.vocData.is_maintenance && headerStore.writeRoleFlag && 
                    ((store.vocData.author_employee_number === userInfo.employeeNumber) || store.vocData.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0)? (
                        <div className="button_wrap">
                            {!store.vocData.is_complete ?
                                <>
                                    <button type="button" className="btn btn_outline pop_close w50" onClick={()=>{setIsRegPop(true);}}>수정</button>
                                    <button type="button" className="btn btn_darkblue pop_close w50" 
                                        onClick={()=> {if(store.vocData.is_maintenance) {setIsOnlyCommentPop(true)}else {setIsCommentPop(true)}}}>답변등록</button>
                                </>
                            : 
                                <button type="button" className="btn btn_darkblue pop_close" 
                                    onClick={()=> {if(store.vocData.is_maintenance) {setIsOnlyCommentPop(true)}else {setIsCommentPop(true)}}}>답변등록</button>
                            }
                        </div>
                    ) : 
                    !store.vocData.is_maintenance && !store.vocData.is_complete && headerStore.writeRoleFlag? (
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" 
                                onClick={()=> {if(store.vocData.is_maintenance) {setIsOnlyCommentPop(true) }else {setIsCommentPop(true)}}}>답변등록</button>
                        </div>
                    ):(
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={() => {props.onClose(); store.popInit();}}>확인</button>
                        </div>
                    )}
                </div>
            </div>
            
            {/* 이미지 팝업 */}
            <div id="pop_imageslide" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
                <div className="pop_wrap">
                    <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
                    <div className="contents_wrap">
                        <div className="slide">
                            <ul className="lst_slide">
                                <li>
                                    <img src={imgPath} alt=""/>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* 답변 등록 팝업 */}
            <div id="" className="pop_modal" style={{display: isCommentPop? 'block' : 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <button className="pop_btn_prev" onClick={()=> {setIsCommentPop(false); store.vocData.category4 = '';store.comment='';}}></button>
                        <h3>답변등록</h3>
                        <button className="pop_btn_close pop_close" onClick={()=> {setIsCommentPop(false); store.vocData.category4 = '';store.comment='';}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <div className="form_write">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="input_type1" className="title_form">1차분류</label>
                                    <input type="text" id="input_type1" className="form_control form_sm" placeholder="" value={store.vocData.category1} disabled/>
                                </div>
                                <div className="col">
                                    <label htmlFor="input_type2" className="title_form">2차분류</label>
                                    <input type="text" id="input_type2" className="form_control form_sm" placeholder="" value={store.vocData.category2} disabled/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="input_type3" className="title_form">3차분류</label>
                                    <input type="text" id="input_type3" className="form_control form_sm" placeholder="" value={store.vocData.category3} disabled/>
                                </div>
                                {store.category4.length > 0 ? (
                                    <div className="col">
                                        <label htmlFor="input_type4" className="title_form">4차분류</label>
                                        <select name="" id="input_type4" className="form_control"
                                            disabled={store.vocData.is_complete}
                                            value={store.vocData.category4}
                                            onChange={(e: any) => store.vocData.category4 = e.target.value}
                                        >
                                            <option value="">선택</option>
                                            {store.category4.map((category: any, index: number) => (
                                                <option key={`${index}`} value={category.name}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                ) : <></>}
                            </div>
                        </div>

                        <hr/>

                        <ul className="lst_userinfo">
                            <li className="user_area">
                                <span className="img_user"></span>
                                <span className="username">{store.vocData.author_name}</span>
                                <span className="state">{store.vocData.is_complete ? '완료' : '처리중'}</span>
                            </li>
                            <li>
                                <strong>발생장소</strong>
                                <span>
                                {store.vocData.places_etc && store.vocData.places_etc != ''? (
                                    '기타 > ' + store.vocData.places_etc + ' > ' + store.vocData.places_etc2
                                ):(
                                    placeName()
                                    //<button className="btn btn_xs btn_darkblue">장소추가</button>
                                )}
                                </span>
                            </li>
                            <li>
                                <strong>발생일</strong>
                                <span>{formatByString(store.vocData.found_at, 'yyyy.MM.dd HH')} 시경</span>
                            </li>
                        </ul>

                        <div className="form_write">
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_date" className="title_form">답변일</label>
                                    <input type="text" id="input_date" className="form_control form_sm datepicker" placeholder="" value={getTodayDate()[0]} disabled/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_contents" className="title_form">답변내용</label>
                                    <textarea name="" 
                                        id="input_contents" 
                                        className="form_control" 
                                        placeholder="내용을 입력하세요"
                                        value={store.comment}
                                        onChange={(e: any) => {
                                            store.comment = e.target.value;
                                        }}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="form_write">
                            <MobileFileUpload
                                type="image"
                                fileId="imageFile" 
                                items={store.answerImages} 
                                onSetData={(data: any[]) => store.answerImages = ([...data])} 
                                setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                            />
                        </div>
                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_darkblue pop_close" onClick={onSave}>답변등록</button>
                    </div>
                </div>
            </div>

            {/* 유지운영 답변 등록 팝업 */}
            <div id="" className="pop_modal" style={{display: isOnlyCommentPop? 'block' : 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <button className="pop_btn_prev" onClick={()=> {setIsOnlyCommentPop(false); store.vocData.category4 = '';store.comment='';}}></button>
                        <h3>답변등록</h3>
                        <button className="pop_btn_close pop_close" onClick={()=> {setIsOnlyCommentPop(false); store.vocData.category4 = '';store.comment='';}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        

                        <hr/>

                        <ul className="lst_userinfo">
                            <li className="user_area">
                                <span className="img_user"></span>
                                <span className="username">{store.vocData.author_name}</span>
                                <span className="state">{store.vocData.is_complete ? '완료' : '처리중'}</span>
                            </li>
                            
                            <li>
                                <strong>발생일</strong>
                                <span>{formatByString(store.vocData.found_at, 'yyyy.MM.dd HH')} 시경</span>
                            </li>
                        </ul>

                        <div className="form_write">
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_date" className="title_form">답변일</label>
                                    <input type="text" id="input_date" className="form_control form_sm datepicker" placeholder="" value={getTodayDate()[0]} disabled/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_contents" className="title_form">답변내용</label>
                                    <textarea name="" 
                                        id="input_contents" 
                                        className="form_control" 
                                        placeholder="내용을 입력하세요"
                                        value={store.comment}
                                        onChange={(e: any) => {
                                            store.comment = e.target.value;
                                        }}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="form_write">
                            <MobileFileUpload
                                type="image"
                                fileId="imageFile" 
                                items={store.answerImages} 
                                onSetData={(data: any[]) => store.answerImages = ([...data])} 
                                setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                            />
                        </div>
                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_darkblue pop_close" onClick={onCommentSave}>답변등록</button>
                    </div>
                </div>
            </div>

            {isChoiceManagerOpen ? (
                <MobileChoiceManager
                    isOpen={isChoiceManagerOpen}
                    isView={true}
                    managers={vocStore.item.managers}
                    setIsOpen={setIsChoiceManagerOpen}
                    onUpdateManagers={onUpdateManagers}
                    setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                />
            ) : (<></>)}

            {isPlaceOpen ? (
                <MobilePlace
                    isOpen={isPlaceOpen}
                    isView={true}
                    setIsOpen={setIsPlaceOpen}
                    user={vocStore.user}
                    places={vocStore.item.places}
                    onApply={onSetPlacesHandler}
                    setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                />
            ) : (<></>)}

            {/* 등록/수정 팝업 */}
            {isRegPop? (
                <MobileVocReg
                    isRegPop = {isRegPop}
                    onClose = {() => {setIsRegPop(false); store.getVocData(store.vocData.id); vocStore.getItem(store.vocData.id);}}
                    isUpdate={true}
                    setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                />
            ): ''}
        </>
    );
});

export default MobileVocDetail;

import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { init } from 'react-intl-universal';
import { useSelector } from 'react-redux';

import LodgedTotal from '..';
import DatePicker from '../../../components/organismas/input/datepicker';
import Pagination from '../../../components/organismas/pagination';
import { ROOM_TYPE, WORK_SITE_CODE } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { formatByString_YYYYMMDD, formatByString_YYYYMMDDHH, getIntervalMonthDate, getTodayDate, getYear5 } from '../../../utils/date';
import ReactDOM from 'react-dom';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import CustomCalendar from '../../../components/organismas/customCalendar';

const LodgedState = observer((props: any) => {
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const { loadgedTotalStore : store } = useStores();
    const {headerStore: headerStore} = useStores();
    const { info, warning, error }: MessageImpl = useContext(MessageContext); //메세지 핸들러
    const [isShowList, setIsShowList] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [selectValue, setSelectValue] = useState<any>(headerStore.paramAuthRoomTypeRole);

    useEffect(()=> {
        (async ()=> {
            try{
                isShow();
                headerStore.isLoading = true;
                store.work_site =  headerStore.workSite;
                await store.getFixedList();     // 정기이숙리스트
                headerStore.isLoading = false;
            }catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
        return () => {
            if (store.findTabs('/lodged/type') < 0) {
                store.init();
            }
        }
    },[store.offset, headerStore.workSite, reload]);

    //입숙, 이숙, 퇴숙 현황 > 현황 및 설정 > 월별 입숙, 이숙, 퇴숙 현황
    //년도 월별 사업장별로 데이터불러오기
    useEffect(()=> {
        (async ()=> {
            store.work_site =  headerStore.workSite;
            // store.roomtype
            try{
                headerStore.isLoading = true;
                await store.occupantStatiStics();
                headerStore.isLoading = false;
            }catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[headerStore.workSite, reload]);

    //입숙, 이숙, 퇴숙 현황 > 현황 및 설정 > 관리 현황
    useEffect(()=> {
        (async ()=> {
            store.work_site = headerStore.workSite;
            store.roomtype = selectValue;

            headerStore.getAuthWorkSiteRole();
            headerStore.getAuthRoomRole();

            if(Number(headerStore.workSite) === 2) {
                if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                    store.building = headerStore.paramAuthBuildingRole;
                }
            }

            let options: any[] = [];
            options.push(<option value={headerStore.paramAuthRoomTypeRole}>전체</option>);
            for ( let i = 0; i < headerStore.paramAuthRoomTypeRole?.split(",").length; i++) {
                //console.log(ROOM_TYPE[headerStore.paramAuthRoomTypeRole.split(",")[i]]);
                options.push(<option value={headerStore.paramAuthRoomTypeRole.split(",")[i]}>{ROOM_TYPE[headerStore.paramAuthRoomTypeRole.split(",")[i]]}</option>)
            }

            ReactDOM.render(options, document.getElementById("selectRoom"));

            await store.managementStatus();
        })();
    },[selectValue, headerStore.workSite]);

    // 정기이숙 설정 화면 표시 범위
    const isShow = () => {
        if(headerStore.workSite === "2" || headerStore.workSite === "5"){
            setIsShowList(true);
        }else {
            setIsShowList(false);
        }
    }

    const init = () => {
        store.start_date = getIntervalMonthDate(2)[1];
        store.end_date = getIntervalMonthDate(2)[0];
        store.start_hour = '00';
        store.end_hour = '00';
        store.title = '';
    }

    const setFixedData = async() => {
        if(!store.title) {
            info("관리제목을 작성해주세요");
            return false;
        }else if(!store.start_date || !store.start_hour || !store.end_date || !store.end_hour){
            info("기간을 설정해주세요");
            return false;
        }else if(parseInt(store.start_hour) > 24 || parseInt(store.end_hour) > 24) {
            info("시간을 확인해주세요");
            return false;
        }

        try {
            headerStore.isLoading = true;
            store.work_site = headerStore.workSite;
            store.employee_number = userInfo.employeeNumber;
            store.name = userInfo.username;

            let data : any = await store.setFixedData();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info("정기 이숙 설정이 완료되었습니다");
                    init();
                    store.getFixedList();
                }else {
                    info("설정 중 문제가 발생하였습니다.");
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    // 정기이숙 상태 변환
    const fixedDataOnOff = async(id: number, onoff: boolean) => {
        try {
            headerStore.isLoading = true;
            let resultData : any = await store.fixedDataOnOff(id, onoff);
            headerStore.isLoading = false;
            // setTimeout(function () {
                if(resultData.save) {
                    let resultData2:any = await store.getFixedList();
                    headerStore.isLoading = false;
                    if(resultData2.save) {
                        return;
                    }else {
                        // info("문제가 발생하였습니다");
                        return;    
                    }
                    return;
                }else {
                    // info("문제가 발생하였습니다");
                    return;
                }
            // }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    return (
        <LodgedTotal
            id=""
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
        
        <h4>월별 입숙, 이숙, 퇴숙 현황 <span>{getTodayDate()[0]} 기준</span>
            <div className="right_area">
                <select className="form_control year" value={store.currentYear} 
                    onChange={(e:any) => {store.currentYear = parseInt(e.target.value); store.getCurrentDateRange(); store.occupantStatiStics();}}
                >
                    {getYear5(false).map((year:any, index: number) => (
                        <option key={index} value={year}>{year}</option>
                    ))}
                </select>
                <select className="form_control month" value={store.currentMonth} 
                    onChange={(e:any) => {store.currentMonth = parseInt(e.target.value); store.getCurrentDateRange(); store.occupantStatiStics();}}
                >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                </select>
            </div>
        </h4>
        <div className="table_normal_list">
            <table>
                <caption className="sr_only">월별 입숙, 이숙, 퇴숙 현황</caption>
                <colgroup>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                </colgroup>
                <thead>
                <tr>
                    <th>월</th>
                    <th>신규입숙</th>
                    <th>1:1이숙</th>
                    <th>상시이숙</th>
                    <th>정기이숙</th>
                    <th>퇴숙</th>
                </tr>
                </thead>
                <tbody>
                {/*데이타 없을 시*/}
                {!store.occuItem? (
                <tr>
                    <td colSpan={5} className="blank_area type_white">
                        <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                </tr>
                ) : (
                <tr>
                    <td>{store.currentMonth ? store.currentMonth : 0}</td>
                    <td>{store.occuItem.new_move ? store.occuItem.new_move : 0}</td>
                    <td>{store.occuItem.onetoon ? store.occuItem.onetoon : 0}</td>
                    <td>{store.occuItem.normal ? store.occuItem.normal : 0}</td>
                    <td>{store.occuItem.regular ? store.occuItem.regular : 0}</td>
                    <td>{store.occuItem.checkout ? store.occuItem.checkout : 0}</td>
                </tr>
                )}
                </tbody>
            </table>
        </div>  {/*table*/}

        <h4>관리현황
            <div className="right_area">
                <select id="selectRoom" className="form_control num" value={selectValue} onChange={(e: any)=>{setSelectValue(e.target.value)}}>
                </select>
            </div>
        </h4>
        <div className="table_normal_list">
            <table>
                <caption className="sr_only">관리현황</caption>
                <colgroup>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                </colgroup>
                <thead>
                <tr>
                    <th>신규입숙</th>
                    <th>1:1이숙 신청</th>
                    <th>상시이숙 신청</th>
                    <th>정기이숙 신청</th>
                    <th>퇴숙 신청</th>
                </tr>
                </thead>
                <tbody>
                {/*데이타 없을 시*/}
                {!store.mngStatus? (
                <tr>
                    <td colSpan={5} className="blank_area type_white">
                        <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                </tr>
                ):(
                    <tr>
                        <td>{store.mngStatus.new_move ? store.mngStatus.new_move : 0 }</td>
                        <td>{store.mngStatus.onetoon ? store.mngStatus.onetoon : 0}</td>
                        <td>{store.mngStatus.normal ? store.mngStatus.normal : 0}</td>
                        <td>{store.mngStatus.regular ? store.mngStatus.regular : 0}</td>
                        <td>{store.mngStatus.checkout ? store.mngStatus.checkout : 0}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>  {/*table*/}
        
        {isShowList ? (
            <>
                <h4>정기이숙 설정</h4>
                <div className="table_modify_list">
                    <dl className="title">
                        <dt>관리제목</dt>
                        <dd>
                            <input type="text" className="form_control" value={store.title} onChange={(e:any) => store.title = e.target.value}/>
                        </dd>
                    </dl>
                    <dl className="date">
                        <dt>기간</dt>
                        <dd>
                            <CustomCalendar
                               value={store.start_date}
                               width={250}
                               onChange={(date: string) => store.start_date = date}
                               isPrevMonthView={true}
                               max={store.end_date}
                            ></CustomCalendar>
                            {/* <DatePicker
                                value={store.start_date}
                                width={250}
                                onChange={(date: string) => store.start_date = date}
                                isPrevMonthView={true}
                                max={store.end_date}
                            /> */}
                            <input type="text" 
                                className="form_control time" 
                                value={store.start_hour} 
                                maxLength={2}
                                onChange={(e:any) => {
                                    let regex  = /[^0-9]/g;
                                    let tempStr: string = e.target.value.replace(regex, '');
                                    store.start_hour = tempStr;
                                }}
                            />
                            <span className="dash">-</span>
                            <CustomCalendar
                               value={store.end_date}
                               width={250}
                               onChange={(date: string) => store.end_date = date}
                               isPrevMonthView={true}
                               min={store.start_date}
                            ></CustomCalendar>
                            {/* <DatePicker
                                value={store.end_date}
                                width={250}
                                onChange={(date: string) => store.end_date = date}
                                isPrevMonthView={true}
                                min={store.start_date}
                            /> */}
                            <input type="text" 
                                className="form_control time" 
                                value={store.end_hour} 
                                maxLength={2}
                                onChange={(e:any) => {
                                    let regex  = /[^0-9]/g;
                                    let tempStr: string = e.target.value.replace(regex, '');
                                    store.end_hour = tempStr;
                                }}
                            />
                            <button type="button" className="btn btn_sm btn_gray" onClick={setFixedData}>설정</button>
                        </dd>
                    </dl>
                </div>
                
                <h4>설정 리스트</h4>
                <div className="table_normal_list event_on box_scroll_x">
                    <table>
                        <caption className="sr_only">설정 리스트</caption>
                        <colgroup>
                            <col width="60px"/>
                            <col width="30%"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>NO</th>
                            <th>관리제목</th>
                            <th>시작일</th>
                            <th>마감일</th>
                            <th>작성자</th>
                            <th>작성일</th>
                            <th>상태</th>
                        </tr>
                        </thead>
                        <tbody>
                            {store.list.length <= 0 ? (
                                <tr>
                                    <td colSpan={7} className="blank_area type_white">
                                        <p className="txt_blank">데이터가 없습니다.</p>
                                    </td>
                                </tr>
                            ) : (
                                store.list.map((item: any, idx: number) => (
                                    <tr key={idx}>
                                        <td>{(store.totalCount - idx)}</td>
                                        <td>{item.title}</td>
                                        <td>{item.start_date? formatByString_YYYYMMDDHH(item.start_date) :''}</td>
                                        <td>{item.end_date? formatByString_YYYYMMDDHH(item.end_date) : ''}</td>
                                        <td>{item.name}</td>
                                        <td>{item.created_at? formatByString_YYYYMMDD(item.created_at) : ''}</td>
                                        <td>
                                            <label className="input_radio" onClick={() => fixedDataOnOff(item.id, true)}>
                                                <input type="radio" id="state_on" name={`state1_${idx}`} checked={item.is_use? true: false} /> 
                                                <span className="label_text">ON</span>
                                            </label>
                                            <label className="input_radio" onClick={() => fixedDataOnOff(item.id, false)}>
                                                <input type="radio" id="state_off" name={`state1_${idx}`} checked={!item.is_use? true: false}/> 
                                                <span className="label_text">OFF</span>
                                            </label>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>  {/*table*/}

                <div className="board_paging">
                    <ul>
                    <Pagination
                        offset={store.offset}
                        limit={store.limit}
                        totalPage={store.totalPage}
                        setOffset={(offset: number) => {store.offset = offset; store.getFixedList();}} />
                    </ul>
                </div> 
            </>
        ) :''}
        </LodgedTotal>
    )
});

export default LodgedState;
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { observer } from 'mobx-react';

import DefaultTemplate from '../../components/templetes/default';

import _ from 'lodash';
import { build } from '../../apis';

import { STATUS } from '../../constants';
import Calendar from '../../components/molecules/calendar';
import { getPrevDay, getTodayDate } from '../../utils/date';

import './index.scss';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { useStores } from '../../store/mobx';
import { MessageContext, MessageImpl } from '../../routes';

const _ToolTip = observer((props: any) => {
  return (
    <div className="tooltip">
      <p className="title clearfix">
        <span className="num_room">{props.room.name}</span>
        <span className="state_use">{STATUS[props.guest.status]}</span>
      </p>
      <ul className="lst_desc">
        <li>
          <strong>이용자</strong>
          <span>{props.guest.guest_name} ({props.guest.guest_employee_number})</span>
        </li>
        <li>
          <strong>직급</strong>
          <span>{props.guest.guest_position}</span>
        </li>
        <li>
          <strong>이용기간</strong>
          <span>{props.guest.stay_after} ~ {props.guest.stay_before}</span>
        </li>
        <li>
          <strong>부서</strong>
          <span>{props.guest.guest_department}</span>
        </li>
        <li>
          <strong>회사명</strong>
          <span>{props.guest.guest_company}</span>
        </li>
        <li>
          <strong>관리자 메모</strong>
          <span>{props.guest.memo}</span>
        </li>
      </ul>
    </div>
  )
});

const Persons = observer ((props: any) => {
  let guests = [...props.room.guests] || []
  let empty: any = Array.from(Array(props.room.quota - props.room.guests.length).keys())

  const onMouseEnterHandler = (guestId: number) => {
    props.onClickPersonHandler(-1);
    if (guestId > -1 ) props.onClickPersonHandler(guestId)
  }

  const getGuestType = (guest: any) => {
    if (guest.guest_type === 1) return "사내임직원"
    else if (guest.guest_type === 2) return "계역사 임직원"
    else if (guest.guest_type === 3) return "외부인"
  }

  return (
    <>
      <li>
        <p className="num">{props.room.name}</p>
        {guests.map((guest: any, idx: number) => (
          <React.Fragment key={idx}>
            <a href="javascript:void(0)" className="room" onMouseEnter={() => onMouseEnterHandler(guest.id)} onMouseLeave={() => onMouseEnterHandler(-1)}>
              <span className="name">{guest.guest_name}</span>
              <span>{getGuestType(guest)}</span>
            </a>
            {props.selectGuestId === guest.id ? (<_ToolTip guest={guest} room={props.room} />) : (<></>)}
          </React.Fragment>
        ))}
        {
          empty.map((item: any, idx: number) => (
            <a href="javascript:void(0)" className={`room ${props.room.is_available ? "" : "disabled"}`} key={idx}>
              <span className="name"></span>
              <span></span>
            </a>
          ))
        }
      </li>
    </>
  )
});

const Floors = observer ((props: any) => {

  const onClickHandler = (room: any) => {
    if (room.guests.length < room.quota) {
      props.setAvailableRoomId(room.id);
    }
  }
  // console.log(props)
  return (
    <div className="floor_area">
      {props.floors.map((floor: any, idx: number) => (
        <React.Fragment key={idx}>
          <div className="title" key={idx}>{floor.step}층</div>
          {floor.rooms.map((room: any, idx: number) => (
            <div className="box" key={idx} onClick={() => onClickHandler(room)}>
              <ul className="lst_room clearfix">
                <Persons
                  room={room}
                  selectGuestId={props.selectGuestId}
                  onClickPersonHandler={props.onClickPersonHandler}
                  setAvailableRoomId={props.setAvailableRoomId}
                />
              </ul>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
});

// 통계량(우측표시)
const RoomAnalysis = observer ((props: any) => {
  let rooms = _.flatten(props.floors.map((floors: any, idx: number) => floors.rooms));
  let facility = 0 // 편의시설

  let quotaTotal = 0; // 수용인원
  let use = 0; // 사용중

  rooms = _.flatten(rooms);

  rooms.forEach((room: any, idx: number) => {
    quotaTotal += room.quota
    use += room.guests.length;
  });

  return (
    <div className="col col_total">
      <div className="total">
        <p className="title"><span className="count">{rooms.length}</span> <span className="desc">총객실</span></p>
        <ul className="lst_total clearfix">
          <li>
            <span className="count">{rooms.length - facility}</span>
            <span className="desc">이용배정객실</span>
          </li>
          <li>
            <span className="count">{facility}</span>
            <span className="desc">편의시설배정</span>
          </li>
          <li>
            <span className="count">{quotaTotal}</span>
            <span className="desc">수용인원</span>
          </li>
          <li>
            <span className="count color_warning">{use}</span>
            <span className="desc">사용중</span>
          </li>
          <li>
            <span className="count color_success">{quotaTotal - use}</span>
            <span className="desc">이용가능</span>
          </li>
        </ul>
      </div>
    </div>

  )
})

const RoomAvailableModal = observer ((props: any) => {
  const [isUse, setIsUse] = useState<boolean>(true);
  const [isNotUse, setIsNotUse] = useState<boolean>(false);
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    // console.log(isUse)
  }, [isUse])

  const onCompleteHandler = async (e: any) => {
    try {
      headerStore.isLoading = true;
      let { data } = await build.setRoomAvailable(props.availableRoomId, {
        information: {
          is_available: isUse
        }
      })

      props.setReload(!props.reload)
      props.onClose();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = true;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("방 상태 변경중 문제가 발생했습니다.");
      return;
    }
  }

  if (props.availableRoomId < 0) return <></>;

  return (
    <div id="" className="pop_dim_wrap pop_modal pop_sm">
      <div className="pop_wrap">
        <button className="pop_btn_close" onClick={props.onClose}>닫기</button>
        <div className="title_wrap">사용 여부</div>
        <div className="contents_wrap">
          <div className="table_normal_wirte">
            <table>
              <caption></caption>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <label className="input_radio" htmlFor="radio_abled">
                      <input type="radio" id="radio_abled" name="radio_useable1" checked={isUse} onChange={e => { setIsUse(true); setIsNotUse(false) }} />
                      <span className="label_text">사용 가능</span>
                    </label>
                  </td>
                  <td>
                    <label className="input_radio" htmlFor="radio_disabled">
                      <input type="radio" id="radio_disabled" name="radio_useable2" checked={isNotUse} onChange={e => { setIsNotUse(true); setIsUse(false) }} />
                      <span className="label_text">사용 불가능</span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div className="button_wrap">
          <button type="button" className="btn_lg btn_darkblue" onClick={onCompleteHandler}>완료</button>
        </div>
      </div>
    </div>
  )
});

const _RestRoom = (props: any) => <li><p className="num">화장실</p><a href="#" className="room disabled"></a></li>

const RoomManagePage = observer ((props: any) => {
  const todayDate = getTodayDate();
  const [reload, setReload] = useState<boolean>(false);
  const [roomType, setRoomType] = useState<number>(1); // 출장자 숙소: 1, 영빈관: 2
  const [building, setBuilding] = useState<any>({});
  const [buildings, setBuildings] = useState<any>([]);
  const [floors, setFloors] = useState<any>([]);
  const [selectGuestId, setSelectGuestId] = useState(-1);
  const [availableRoomId, setAvailableRoomId] = useState<number>(-1);
  const [isFilterShow, setIsFilterShow] = useState<boolean>(false);
  const [dateAfter, setDateAfter] = useState<string>(todayDate[0]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    (async () => {
      try {
        let { data: buildings } = await build.getBuilds({ work_site: 2, room_type: roomType });
        setBuildings(buildings);
        setBuilding(buildings.length && buildings[0] || []);
        // console.log(buildings);
        // setFloors(buildings.length && buildings[0].floors || [])
      } catch ({ status, data }) {
        headerStore.isLoading = false;
        // console.log(status);
        // console.log(data);
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
  
        info('수정중 문제발생');
        return;
      }
    })()
  }, [roomType])

  useEffect(() => {
    if (!building.id) return;
    (async () => {
      try{
        headerStore.isLoading = true;
        let { data: floors } = await build.getFloors({
          building: building.id,
          room_type: roomType,
          // today: true
          date: dateAfter
        })
        floors.reverse();
        setFloors(floors);
        headerStore.isLoading = false;
      }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("문제가 발생했습니다");
        return;
      }
    })()
  }, [building, dateAfter, reload])


  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    setDateAfter(day);
  };


  const onChangeBuildsHandler = (e: any) => setBuilding(buildings[e.target.value]);
  const onClickPersonHandler = (guestId: number) => setSelectGuestId(guestId);

  return (
    <>
      <DefaultTemplate
        id="wrap_admin"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
        <div className="option">
          <label>
            <select className="form_control form_lg" onChange={(e: any) => setRoomType(parseInt(e.target.value))}>
              <option value={1}>출장자 숙소</option>
              <option value={2}>영빈관</option>
            </select>
          </label>
          <label>
            <select name="" id="" className="form_control form_lg" onChange={onChangeBuildsHandler}>
              {buildings.map((item: any, idx: number) => (
                <option key={idx} value={idx}> {item.name} {item.number} </option>
              ))}
            </select>
          </label>
          <div className="wrap_datepicker" onClick={() => setIsFilterShow(true)}>
            <input className="form_control form_lg" value={dateAfter} readOnly disabled></input>
            <button type="button" className="btn_setting"></button>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col col_floor">
            <Floors
              floors={floors}
              selectGuestId={selectGuestId}
              onClickPersonHandler={onClickPersonHandler}
              setAvailableRoomId={setAvailableRoomId}
            />
          </div>

          <RoomAnalysis floors={floors} />

        </div>

        <RoomAvailableModal
          setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
          availableRoomId={availableRoomId}
          onClose={() => setAvailableRoomId(-1)}
          setReload={setReload}
          reload={reload}
        />

      </DefaultTemplate>

      <div id="pop_period" className="pop_dim_wrap pop_modal pop_lg" style={{ display: isFilterShow ? "block" : "none" }}>
        <div className="pop_wrap">
          <button className="pop_btn_close" onClick={() => setIsFilterShow(false)}>닫기</button>
          <div className="title_wrap">날짜선택</div>
          <div className="contents_wrap">
            <div className="select_date">
              <div className="datepicker_area">
                <Calendar
                  onClickSelectDay={onClickDateAfterByCalendar}
                  onRangeSelectDay={(e: string) => { }}
                  tasks={false}
                  selectDate={dateAfter}
                  beforeDisablePoint={getPrevDay(todayDate[0])[0]}
                />
              </div>
            </div>
          </div>
          <div className="button_wrap">
            <button type="button" className="btn_lg btn_darkblue" onClick={() => setIsFilterShow(false)}>완료</button>
          </div>
        </div>
      </div>
    </>
  );
});

export default RoomManagePage;

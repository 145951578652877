import React, { useState, useEffect } from 'react';

import {getTodayDate,  getPrevDay } from '../../../utils/date';

import Calendar from '../../molecules/calendar';

interface Props {
  onChange: Function
  value?: string
  min?: string
  max?: string
  width?: number
  disableDates?: any[]
  isPrevMonthView?: boolean
}

const DatePicker = (props: Props) => {
  const [isDatePickerShow, setIsDatePickerShow] = useState<boolean>(false);

  useEffect(() => {
    return () => window.removeEventListener('click', onUnShowHandler)
  }, [])

  useEffect(() => {
    if (isDatePickerShow) window.addEventListener('click', onUnShowHandler);
    return () => window.removeEventListener('click', onUnShowHandler)
  }, [isDatePickerShow])

  const onUnShowHandler = (e: any) => {
    if (
      (e?.target?.name === "CALENDAR_NAV")
      || (e?.target?.name === "CALENDAR_DATE_PICKER")
    ) {
      return
    }
    setIsDatePickerShow(false)
  }

  return (
    <div style={{ position: 'relative' }} >
      <input
        value={props.value}
        //className="form_control date fl"
        className="form_control date_picker"
        name="CALENDAR_DATE_PICKER"
        autoComplete="off"
        onClick={() => setIsDatePickerShow(!isDatePickerShow)}
        //readOnly
      />
      <div
        style={{
          borderRadius: "2px",
          boxShadow: "0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05)",
          position: 'absolute',
          backgroundColor: 'white',
          width: `${props.width}px`,
          top: 0,
          left: 0,
          display: isDatePickerShow? "block" :"none",
          zIndex: 10,
        }}
      >
        <Calendar
          onClickSelectDay={(date: string) => { setIsDatePickerShow(!isDatePickerShow); props.onChange(date)}}
          value={props.value}
          onRangeSelectDay={(e: string) => { }}
          tasks={false}
          selectDate={props.value || getTodayDate()[0]}
          beforeDisablePoint={props.min && getPrevDay(props.min)[0] || ''}
          afterDisablePoint={props.max && props.max || ''}
          disableDates={props.disableDates || []}
          isPrevMonthView={props.isPrevMonthView}
        />
      </div>
    </div>
  );
};

export default DatePicker;

import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { getIntervalMonthDate } from '../../utils/date';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { BoardStore } from './boardStore';
import { HeaderStore } from './headerStore';

export class MemberStore extends BoardStore {
  users: any = [];
  workSite: any = [];
  userData: any = [];
  userList: any = [];
  searchOption: any;
  dateAfter: string = getIntervalMonthDate(3)[1];
  dateBefore: string = getIntervalMonthDate(2)[0];
  selectOption: string = 'employee_number';
  selectText: string = '';
  selectWorkSite: string = 'total';
  searchFlag: boolean = false;
  userOne: string = '';
  ordering: string = '';
  headerWorkSiteId: any = [];
  userId : number = 0;
  memo: string = '';
  
  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      users: observable,
      workSite: observable,
      userData: observable,
      searchOption: observable,
      dateAfter: observable,
      dateBefore: observable,
      selectOption: observable,
      selectText: observable,
      selectWorkSite: observable,
      searchFlag: observable,
      userOne: observable,
      ordering: observable,
      headerWorkSiteId: observable,
      userId: observable,
      userList: observable,
      memo: observable,
      
      getWorkSite: action,
      setUser: action
    });

    this.init();
  }
  
  init() {
    //this.dateAfter = '';
    //this.dateBefore = '';
    this.selectOption = 'employee_number';
    this.selectText = '';
    this.selectWorkSite = 'total';
    //this.userData = [];
  }

  @debounce(500)
  async getItems() {
    const filter: any = {
        limit: this.limit,
        offset: this.offset
    };

    // 날짜 검색 있는 경우
    if(this.dateAfter && this.dateBefore) {
      filter['date_joined_after'] = this.dateAfter;
      filter['date_joined_before'] = this.dateBefore;
    }

    // 근무지
    if(this.selectWorkSite !== 'total'){
      filter['work_site'] = this.selectWorkSite;
    }

    // 검색어 있는경우
    if(this.selectOption) {
      filter[this.selectOption] = this.selectText;
    }

    // 소팅
    if(this.ordering){
      filter['ordering'] = this.ordering;
    }

    // 검색인 경우 페이지 1로
    if(this.searchFlag) {
      this.offset = 0;
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.user.getUsers(filter);
      this.users = data.results;
      this.totalPage = (Math.ceil(data.count / this.limit));
      this.searchFlag = false;
      this.headerStore.isLoading = false;
      // console.log('users DATA================>',this.users);
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  async getWorkSite() {
    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.user.getWorksite();
      // console.log(data);

      let result = data;
      let tempArr: any = [];
      result.map((e: any) => {
        tempArr.push({ value: e.id, label: e.name });
      });
      tempArr.unshift({ value: 'total', label: "전체" });

      this.workSite = tempArr;
      this.headerStore.isLoading = false;
      // console.log('workSite DATA================>',this.workSite);

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async setUser(user: any) {
    this.userData = user;
    // await this.getItems();
  }

  // 회원 현황 상세 하단 회원 상태 히스토리 데이터
  async suspendedGetId() {
    try {
      this.headerStore.isLoading = true;
      const {data} = await apis.user.suspendedGetId(this.userId);
      // console.log('회원현황 DATA====>', data);
      this.userList = data;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  //회원 현황 이용정지 등록
  // async suspendedId(){
  //   const payload : any = {
  //     user: this.userId,
  //     user_status: 'Y',
  //     memo: this.memo,
  //   }
  //   const data = await apis.user.suspendedId(this.userId, payload);
  // }
}
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { worksite } from '../../apis';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

export interface LocusGuestDataType {
  id: number;
  work_site: any;
  room_type: any;
  guest_type: number;
  is_visible: boolean;
}

export interface LocusGuestResType {
  results: LocusGuestDataType[];
  count: number;
}

const useLocusGuestType = (): [LocusGuestResType, number, number, any, number, number, number, Function, Function, Function, Function, Function, any] => {
  const [data, setData] = useState<LocusGuestResType>({
    count: 0,
    results: []
  });

  const [roomType, setRoomType] = useState<any>();
  const [work_site, setWorksite] = useState<any>();
  const [guestType, setGuestType] = useState<number>(-1);
  const [isVisible, setIsVisible] = useState<number>(-1);

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [r, setReload] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      let payload: any = {
        offset, limit
      };
      
      // console.log("기준설정 이용자이용가능 범위 설정 roomType>>", roomType);
      // console.log("기준설정 이용자이용가능 범위 설정 work_site>>", work_site);
      payload.room_type = roomType;
      if (guestType > 0) payload.guest_type = guestType;
      if (isVisible > -1) payload.is_visible = isVisible;
      payload.work_site = work_site;
      if(roomType !== undefined) {
        try {
          let { data } = await worksite.getGuests(payload);
          setTotalPage(Math.ceil(data.count / limit));
          setData(data)
        }catch ({ status, data }) {
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
        }
      }
      
    })();
    
  }, [roomType, guestType, isVisible, offset, limit, r, work_site]);

  const reload = () => setReload(!r);

  return [
    data,
    offset, limit, totalPage,
    roomType, guestType, isVisible,
    setRoomType, setGuestType, setIsVisible,
    setOffset, reload, setWorksite
  ]
};

export default useLocusGuestType;
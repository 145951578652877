import React, {useState} from 'react';

//계정 정지 확인 팝업
const MemberUseYn = (props: any) => {
    const changeMsg = (e:string) => e.split('\n').map((word: any, index: number) => <p key={index}>{word}</p>);

    return (
        <div className="pop_dim_wrap pop_modal pop_alert" style={{ display: props.isShowUseModal ? "block" : "none" }}>
          <div className="pop_wrap">
            <button className="pop_btn_close" onClick={() => props.onCloseModalHandler()}>닫기</button>
            <div className="message_table">
              <div className="messageWrap">
                {props.user.is_active ? changeMsg("해당 계정의 사용을\n정지시키겠습니까?") : changeMsg("해당 계정을 다시\n사용 가능하도록 하시겠습니까?")}
                <p className="al mt10">{props.user.is_active ? "※ 정지사유 입력 :" : "※ 해지사유 입력 :"}</p>
                <input type="text" className="form_control"/>
              </div>
            </div>
            <div className="button_wrap">
              <button type="button" className="btn_pop_default btn_white pop_close w50" onClick={() => props.onCloseModalHandler()}>아니오</button>
              <button type="button" className="btn_pop_default btn_darkblue pop_close w50">예</button>
            </div>
          </div>
        </div> 
    )
}

export default MemberUseYn;
import { includes } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { useEffect } from 'react';
import { WORK_SITE_CODE } from '../../constants';
import { useStores } from '../../store/mobx';

const DeductTab = observer((props: any) => {
  const {meterStore: store} = useStores();
  const {headerStore : headerStore} = useStores();
  const baseUrl = '/guest/deductible';

  useEffect(() => {
    if (`${baseUrl}/management` === props.location.pathname) {
      if('5' === headerStore.workSite || '6' === headerStore.workSite || '7' === headerStore.workSite) {
        
        props.history.replace(`${baseUrl}/meter`);
      }
    }
    if (`${baseUrl}/dormitory` === props.location.pathname && '1' !== headerStore.workSite) {
      if('5' === headerStore.workSite || '6' === headerStore.workSite || '7' === headerStore.workSite) {
        props.history.replace(`${baseUrl}/meter`);
      }else {
        props.history.replace(`${baseUrl}/management`);
      }
    }
    if (`${baseUrl}/deductibleList` === props.location.pathname && '1' !== headerStore.workSite) {
      if('5' === headerStore.workSite || '6' === headerStore.workSite || '7' === headerStore.workSite) {
        props.history.replace(`${baseUrl}/meter`);
      }else {
        props.history.replace(`${baseUrl}/management`);
      }
    }
  }, [headerStore.workSite]);

  return (
    <div className="group_tab mt30">
      <ul className="tab_lg tabs clearfix">
          <li>
            <a href="javascript:void(0);"
              onClick={(e:any) => props.history.replace(`${baseUrl}/meter`)}
              className={props.history.location.pathname.includes(`${baseUrl}/meter`) ? "on" : ""}
            >검침량 설정</a>
          </li>
          { Number(headerStore.workSite) !== WORK_SITE_CODE.마북 
            && Number(headerStore.workSite) !== WORK_SITE_CODE.의왕 
            && Number(headerStore.workSite) !== WORK_SITE_CODE.기타 ?
          <li>
            <a href="javascript:void(0);"
              onClick={(e:any) => props.history.replace(`${baseUrl}/management`)}
              className={props.history.location.pathname.includes(`${baseUrl}/management`) ? "on" : ""}
            >당월공제자관리</a>
          </li>
          : <></>}
          {(includes([WORK_SITE_CODE.울산공장], Number(headerStore.workSite))) ? (
            <>
              <li>
                <a href="javascript:void(0);"
                  onClick={(e:any) => props.history.replace(`${baseUrl}/deductibleList`)}
                  className={props.history.location.pathname.includes(`${baseUrl}/deductibleList`) ? "on" : ""}
                >공제내역 조회</a>
              </li>
              <li>
                <a href="javascript:void(0);"
                  onClick={(e:any) => props.history.replace(`${baseUrl}/dormitory`)}
                  className={props.history.location.pathname.includes(`${baseUrl}/dormitory`) ? "on" : ""}
                >출장자숙소 공제</a>
              </li>
            </>
          ) : <></>}
      </ul>
    </div>
  );
});

export default DeductTab;

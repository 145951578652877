import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

// display guest(이용자범위 설정) - CRUD /admin/worksite/display/guest/1
// document(입숙서류) - CRUD /admin/worksite/lodge/document

class WorkSite {
  static URL = `${IP}`;

  // 이용자 범위
  @apiHandler()
  static async getGuests(payload: {offset: number, limit: number, room_type?: any, guest_type?: any, is_visible?: number, worksite?: any}) {
    const PATH = `/admin/worksite/display/guest/`;

    return await axios.get(`${WorkSite.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getGuest(guestId: number, payload: {}) {
    const PATH = `/admin/worksite/display/guest/${guestId}/`;

    return await axios.get(`${WorkSite.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async addGuest(payload: {work_site: any, room_type: any, guest_type: number, is_visible: number, remain_day: number}) {
    const PATH = `/admin/worksite/display/guest/`;

    return await axios.post(`${WorkSite.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async updateGuest(guestId: number, payload: {work_site: any, room_type: any, guest_type: number, is_visible: number, remain_day: number}) {
    const PATH = `/admin/worksite/display/guest/${guestId}/`;

    return await axios.put(`${WorkSite.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async deleteGuest(guestId: number, payload: {}) {
    const PATH = `/admin/worksite/display/guest/${guestId}/`;

    return await axios.delete(`${WorkSite.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 입숙서류
  @apiHandler()
  static async getDocuments(payload: {offset: number, limit: number, room_type: any, guest_type?: number, is_visible?: number, work_site: any}) {
    const PATH = `/admin/worksite/lodge/document/`;

    return await axios.get(`${WorkSite.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /*기준설정 > 입숙서류 설정 > 등록 */
  @apiHandler()
  static async addDocument(payload: {work_site: any, room_type: any, guest_type: number, is_visible: number, is_required: number, name: string}) {
    const PATH = `/admin/worksite/lodge/document/`;

    return await axios.post(`${WorkSite.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /*기준설정 > 입숙서류 설정 > 상세 조회 */
  @apiHandler()
  static async getDocument(documentId: number, payload: {}) {
    const PATH = `/admin/worksite/lodge/document/${documentId}/`;

    return await axios.get(`${WorkSite.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /*기준설정 > 입숙서류 설정 > 수정 */
  @apiHandler()
  static async updateDocument(documentId: number, payload: {work_site: any, room_type: any, guest_type: number, is_visible: number, is_required: number, name: string}) {
    const PATH = `/admin/worksite/lodge/document/${documentId}/`;

    return await axios.put(`${WorkSite.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async deleteDocument(documentId: number, payload: {}) {
    const PATH = `/admin/worksite/lodge/document/${documentId}/`;

    return await axios.delete(`${WorkSite.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default WorkSite;

import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { formatByString } from '../../../../utils/date';
import ConfirmBtn from '../../../atoms/button/confirm';
import { audit as api, core } from '../../../../apis';
import { EXHIBIT_STATUS } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { observer } from 'mobx-react';
import { useStores } from '../../../../store/mobx';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../../routes';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import Select from '../../../atoms/select';
import RejectPopup from './RejectPopup';
import xss from 'xss';
import { parserEscape } from '../../../../utils/common';
import FileSaver from 'file-saver';

const ViewContent = observer((props: any) => {
  const dispatch = useDispatch();
  const { auditStore: store } = useStores();
  const { headerStore: headerStore } = useStores();
  const item: any | null = store.item;
  const baseUrl = '/guest/audit';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');
  const goBack = () => props.history.replace(baseUrl);
  const [isOpen, setIsOpen] = useState(false);

  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>('');

  const onEditHandler = () => {
    props.history.replace(`${baseUrl}/${item.id}/update`);
  };

  useEffect(() => {
    headerStore.getAuthMenuRole('2-5');
  }, []);

  const onDeletehandler = async () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '현재 항목을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.deleteItem();
            headerStore.isLoading = false;
            setTimeout(function () {
              if (data.save) {
                info('삭제 완료했습니다.');
                goBack();
              } else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            //errorHandler({ status, data }, '삭제중 오류발생');

            if (status === 401) {
              if (!window.localStorage.getItem('isRetryLogin')) {
                window.localStorage.setItem('isRetryLogin', 'retry');
              }
              window.location.href = '/';
              return;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return;
            } else if (status === 500) {
              window.location.href = '/500';
              return;
            } else {
              info('문제가 발생했습니다');
              return;
            }
          }
        },
      },
    });
  };

  const onShowReject = (selectedItem: any) => {
    selectedItem.id = selectedItem.audit_list_id;
    store.selectedGuest = selectedItem;

    // console.log('====================================>',selectedItem);
    setIsOpen(true);
  };

  // 다운로드
  const xlsDownload = async () => {
    try {
      const user = JSON.parse(window.sessionStorage.getItem('user') || '');
      const filter: any = { audit: props.match.params.id, employee_number: user.employeeNumber, reason: downloadText };

      const { data } = await api.fileDownExhibitexcel(filter);

      if (data) {
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}` + data);
      }

      setIsShowDownloadPop(false);
      setDownloadText('');

      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }
    }
  };

  const updateGuestsNote = async (text: string, id: string) => {
    store.updateGuestsNote(text, id);
  }

  const onUpdateNote = async (selectedItem: any) => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '변경 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            selectedItem.id = selectedItem.audit_list_id;
            store.selectedGuest = selectedItem;
            let data: any = await store.updateExhibitNote();
            headerStore.isLoading = false;
            setTimeout(function () {
              if (data.save) {
                info('처리 완료 됐습니다.');
                return;
              } else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem('isRetryLogin')) {
                window.localStorage.setItem('isRetryLogin', 'retry');
              }
              window.location.href = '/';
              return;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return;
            } else if (status === 500) {
              window.location.href = '/500';
              return;
            } else {
              info('문제가 발생했습니다');
              return;
            }
          }
        },
      },
    });
  }

  const onUpdateState = async (selectedItem: any, state: number) => {
    try {
      // 3: 완료
      if (state === 3) {
        dispatch({
          type: SHOW_CONFIRM_MODAL,
          payload: {
            isModalShow: true,
            title: '확인',
            msg: '현재 항목을 승인 하시겠습니까?',
            action: async () => {
              try {
                headerStore.isLoading = true;
                selectedItem.id = selectedItem.audit_list_id;
                store.selectedGuest = selectedItem;
                let data: any = await store.approveGuest();
                headerStore.isLoading = false;
                setTimeout(function () {
                  if (data.save) {
                    info('승인 처리 했습니다.');
                    return;
                  } else {
                    return;
                  }
                }, 250);
              } catch ({ status, data }) {
                headerStore.isLoading = false;
                //errorHandler({ status, data }, '승인 처리중 오류발생');
                if (status === 401) {
                  if (!window.localStorage.getItem('isRetryLogin')) {
                    window.localStorage.setItem('isRetryLogin', 'retry');
                  }
                  window.location.href = '/';
                  return;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return;
                } else if (status === 500) {
                  window.location.href = '/500';
                  return;
                } else {
                  info('문제가 발생했습니다');
                  return;
                }
              }
            },
          },
        });
      }
      // 4: 반려
      if (state === 4) {
        await store.rejectGuest();
      }
      // 2: 반려취소 or 승인취소(등록 상태로 변경)
      if (state === 2) {
        dispatch({
          type: SHOW_CONFIRM_MODAL,
          payload: {
            isModalShow: true,
            title: '확인',
            msg: '현재 항목을 등록 상태로 변경 하시겠습니까?',
            action: async () => {
              try {
                headerStore.isLoading = true;
                selectedItem.id = selectedItem.audit_list_id;
                store.selectedGuest = selectedItem;
                let data: any = await store.rollbackGuest();
                headerStore.isLoading = false;
                setTimeout(function () {
                  if (data.save) {
                    info('처리 완료 됐습니다.');
                    return;
                  } else {
                    return;
                  }
                }, 250);
              } catch ({ status, data }) {
                headerStore.isLoading = false;
                //errorHandler({ status, data }, '승인 처리중 오류발생');
                if (status === 401) {
                  if (!window.localStorage.getItem('isRetryLogin')) {
                    window.localStorage.setItem('isRetryLogin', 'retry');
                  }
                  window.location.href = '/';
                  return;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return;
                } else if (status === 500) {
                  window.location.href = '/500';
                  return;
                } else {
                  info('문제가 발생했습니다');
                  return;
                }
              }
            },
          },
        });
      }
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      //errorHandler({ status, data }, '삭제중 오류발생');
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      } else {
        info('문제가 발생했습니다');
        return;
      }
    }
  };

  const Guests = observer(() => {
    const onCheckedByAll = (e: any) => store.guestCheckAll(e.target.checked);
    const onCheckedHandler = (e: any, item: any) => store.guestCheckItem(e.target.checked, item);
    return (
      <>
        <h4>
          등록현황
          <div className="right_area">
            <Select
              className="form_control"
              options={store.exhibitStatus}
              value={store.status}
              onChange={(e: any) => store.setStatus(Number(e.target.value))}
            />
          </div>
        </h4>
        <div className="table_normal_list mt30">
          <table>
            <caption className="sr_only">등록현황 목록</caption>
            <colgroup>
              <col width="10%" />
              <col width="5%" />
              <col width="6%" />
              <col width="10%" />
              <col width="6%" />
              <col width="20%" />
              <col width="8%" />
              <col width="34%" />
            </colgroup>
            <thead>
              <tr>
                {/* <th>
                <label className="input_check pop_notext">
                  <input type="checkbox" id="" name="check_notice" checked={store.guestAllCheckFlg} onChange={onCheckedByAll} />
                  <span className="label_text"></span>
                </label>
              </th> */}
                <th>숙소</th>
                <th>성명</th>
                <th>사번</th>
                <th>팀명</th>
                <th>진행상황</th>
                <th>첨부파일</th>
                <th>업무처리</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              {store.guests.length === 0 ? (
                <tr>
                  <td colSpan={6} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              ) : (
                store.guests.map((item: any, index: number) => {
                  const StateItem = (props: any) => {
                    switch (item.status) {
                      case 2:
                        return (
                          <span key={index} className="color_blue">
                            {EXHIBIT_STATUS[props.item.status]}
                          </span>
                        );
                      case 3:
                        return (
                          <span key={index} className="color_green">
                            {EXHIBIT_STATUS[props.item.status]}
                          </span>
                        );
                      case 4:
                        return (
                          <span key={index} className="color_red">
                            {EXHIBIT_STATUS[props.item.status]}
                          </span>
                        );
                      case 5:
                        return (
                          <span key={index} className="color_blue">
                            {EXHIBIT_STATUS[props.item.status]}
                          </span>
                        );
                      default:
                        return <>{EXHIBIT_STATUS[props.item.status]}</>;
                    }
                  };

                  const Actions = (props: any) => {
                    switch (props.item.status) {
                      // case 1: case 3: return <></>;
                      case 2:
                      case 5:
                        return (
                          <>
                            {headerStore.mobileRoleFlag ? (
                              <>
                                <button
                                  key={index}
                                  type="button"
                                  className="btn btn_xs btn_outline"
                                  onClick={() => onShowReject(props.item)}
                                >
                                  반려
                                </button>
                                <button
                                  key={index}
                                  type="button"
                                  className="btn btn_xs btn_outline"
                                  onClick={() => onUpdateState(props.item, 3)}
                                >
                                  승인
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      case 4:
                        return (
                          <>
                            <button
                              key={index}
                              type="button"
                              className="btn btn_xs btn_outline"
                              onClick={() => onShowReject(props.item)}
                            >
                              반려사유
                            </button>
                            <button
                              key={index}    
                              type="button"
                              className="btn btn_xs btn_outline"
                              onClick={() => onUpdateState(props.item, 2)}>
                                반려취소
                            </button>
                          </>
                        );
                      case 3:
                        return (
                          <button
                              key={index}    
                              type="button"
                              className="btn btn_xs btn_outline"
                              onClick={() => onUpdateState(props.item, 2)}>
                                승인취소
                          </button>
                        );
                      default:
                        return <></>;
                    }
                  };

                  return (
                    <tr key={index}>
                      {/* <td>
                        <label className="input_check pop_notext">
                          <input
                            type="checkbox"
                            checked={store.guestCheckeds[item.id] ? true : false}
                            onChange={(e: any) => onCheckedHandler(e, item)}
                          />
                          <span className="label_text"></span>
                        </label>
                      </td> */}
                      <td>
                        {item.guest.room_building_name}
                        {item.guest.room_building_dong}
                        {item.guest.room_name}호
                      </td>
                      <td>{item.guest.guest_name}</td>
                      <td>{item.guest.guest_employee_number}</td>
                      <td>{item.guest.guest_department}</td>
                      <td>
                        <StateItem item={item} />
                      </td>
                      <td className="al">
                        {item.files.map((file: any, fileIndex: number) => (
                          <>
                            {headerStore.downRoleFlag ? (
                              <a
                                key={`${index}_${fileIndex}`}
                                className="item file"
                                href={`${process.env.REACT_APP_MEDIA}${file.path}`}
                                target={
                                  `${file.path}`.split('.').pop() &&
                                  ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(
                                    `${file.path}`.split('.').pop() || '',
                                  ) &&
                                  !isMobile
                                    ? '_blank'
                                    : ''
                                }
                              >
                                {' '}
                                {file.name}
                              </a>
                            ) : (
                              <a key={`${index}_${fileIndex}`} className="item file">
                                {file.name}
                              </a>
                            )}
                          </>
                        ))}
                      </td>
                      <td>
                        <Actions item={item} />
                      </td>
                      <td>
                        <input key={index} type="text" name="exhibit_note" maxLength={50} className="note_input" defaultValue={item.note} onChange={(e: any) => updateGuestsNote(e.target.value, item.id)} />
                        <button type="button" className="btn_md btn_darkgray" onClick={() => onUpdateNote(item)}>입력</button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>{' '}
        {/*table*/}
      </>
    );
  });

  if (!item) return <></>;

  return (
    <>
      <h4>정기서류검사 상세</h4>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">정기서류검사 상세</caption>
          <colgroup>
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>작성자</th>
              <td>
                {item.author_name} ({item.author_employee_number})
              </td>
              <th>작성일시</th>
              <td>{formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}</td>
            </tr>

            <tr>
              <th>기간 설정</th>
              {item.upload_after && item.upload_before ? (
                <td colSpan={3}>
                  {formatByString(item.upload_after, 'yyyy-MM-dd')} ~ {formatByString(item.upload_before, 'yyyy-MM-dd')}
                </td>
              ) : (
                <td>&nbsp;</td>
              )}
            </tr>

            <tr>
              <th>제목</th>
              <td colSpan={3}>{item.title}</td>
            </tr>

            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <div className="editor" dangerouslySetInnerHTML={{ __html: parserEscape(item.contents) }}></div>
              </td>
            </tr>

            <tr>
              <th>이미지</th>
              <td colSpan={3}>
                <div className="images_area">
                  {item.images
                    ? item.images.map((image: any, index: number) => (
                        <>
                          {headerStore.downRoleFlag ? (
                            <div
                              key={index}
                              className="img"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`);
                                setIsImgPop(true);
                              }}
                            >
                              <a key={index}>
                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name} />
                              </a>
                            </div>
                          ) : (
                            <div
                              key={index}
                              className="img"
                              onClick={() => {
                                setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`);
                                setIsImgPop(true);
                              }}
                            >
                              <a key={index}>
                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name} />
                              </a>
                            </div>
                          )}
                        </>
                      ))
                    : ''}
                </div>
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan={3}>
                {item.files
                  ? item.files.map((file: any, index: number) => (
                      <>
                        {headerStore.downRoleFlag ? (
                          <a
                            key={index}
                            href={`${process.env.REACT_APP_MEDIA}${file.path}`}
                            className="item file"
                            target={
                              `${file.path}`.split('.').pop() &&
                              ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(
                                `${file.path}`.split('.').pop() || '',
                              ) &&
                              !isMobile
                                ? '_blank'
                                : ''
                            }
                          >
                            {file.name}
                          </a>
                        ) : (
                          <a key={index} className="item file">
                            {file.name}
                          </a>
                        )}
                      </>
                    ))
                  : ''}
              </td>
            </tr>

            <tr>
              <th>문자</th>
              <td colSpan={3}>{item.sms_message}</td>
            </tr>

            <tr>
              <th>메일</th>
              <td colSpan={3}>
                <p className="title fwb mb10">{item.mail_title}</p>
                <div className="editor" dangerouslySetInnerHTML={{ __html: parserEscape(item.mail_message) }}></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>{' '}
      {/*table*/}
      <div className="submit">
        <div className="fl">
          <button type="button" className="btn_md btn_darkgray" onClick={goBack}>
            목록
          </button>
          {headerStore.writeRoleFlag ? (
            <button type="button" className="btn_md btn_darkblue btn_outline" onClick={props.onCopy}>
              복사 생성
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="fr">
          {headerStore.writeRoleFlag ? (
            <button type="button" className="btn_md btn_darkblue btn_outline" onClick={onEditHandler}>
              수정
            </button>
          ) : (
            <></>
          )}
          {headerStore.deleteRoleFlag ? (
            <button type="button" className="btn_md btn_darkblue" onClick={onDeletehandler}>
              삭제
            </button>
          ) : (
            <></>
          )}
          {headerStore.downRoleFlag ? (
            <>
              <button
                type="button"
                className="btn btn_md btn_darkblue"
                onClick={() => {
                  setIsShowDownloadPop(true);
                }}
              >
                다운로드
              </button>
            </>
          ) : (
            <>
              <></>
            </>
          )}
        </div>
      </div>
      <Guests />
      {isOpen ? <RejectPopup isOpen={isOpen} setIsOpen={setIsOpen} onApply={onUpdateState} /> : <></>}
      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{ display: isImgPop ? 'block' : 'none' }}>
        <a href="javascript:void(0);" className="pop_btn_close" onClick={() => setIsImgPop(false)}>
          닫기
        </a>
        <div className="pop_wrap">
          <div className="contents_wrap">
            <div className="slide">
              <p className="img_area">
                <img src={imgPath} alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* 다운로드 사유 팝업 */}
      <div
        id="pop_download"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isShowDownloadPop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                setDownloadText('');
                setIsShowDownloadPop(false);
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>사유</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_lg btn_darkblue pop_close"
              onClick={() => {
                xlsDownload();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default ViewContent;

import { useEffect, useState } from 'react';

import {
  manager, 
} from '../../apis';

import {
  CreatedAdminPayload
} from '../../apis/manager';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

export interface registriedAdminInfoInterface {
  company: string;
  name: string;
  workSite: number;
  cellphone: string;
  employeeNumber: string;
}

const useRegistriedAdminInfo = () => {
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [workSite, setWorkSite] = useState(1);
  const [cellphone, setCellphone] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');

  const submit = async () => {
    let payload: CreatedAdminPayload = {
      company: company,
      name: name,
      work_site: workSite,
      cellphone: cellphone,
      employee_number: employeeNumber,
    };
    // console.log(payload)
    try {
      await manager.createdAdmin(payload)
      init();
    } catch (err) {
      const { status, data } = err;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      // console.log(err);
      // return err;
    }

    return false;
  }

  const init = () => {
    setCompany('');
    setName('');
    setWorkSite(1);
    setCellphone('');
    setEmployeeNumber('');
  }

  return {
    company, name, workSite, cellphone, employeeNumber,
    setCompany, setName, setWorkSite, setCellphone, setEmployeeNumber,
    submit, init
  }
}

export default useRegistriedAdminInfo;
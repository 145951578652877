import React, { useContext, useEffect, useRef, useState } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { includes } from 'lodash';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import YearSelect from '../yearSelect';
import MonthSelect from '../monthSelect';
import { onlyNumberFormatter } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';

const Ulsan = observer((props: any) => {
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const { ulsanStore: store } = useStores();
  const { headerStore: headerStore } = useStores();

  const [grid, setGrid] = useState<any>(null);
  const [isClickRow, setIsClickRow] = useState<any>();

  const baseUrl = '/guest/deductible';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>('');

  useEffect(() => {
    //console.log('Ulsan');
    headerStore.getAuthMenuRole('2-4');
    if (store.items.length === 0) {
      (async () => {
        try {
          store.init();
          headerStore.isLoading = true;
          await store.getUlsanDeductList();
          headerStore.isLoading = false;
          if (!store.isDeductList) {
            try {
              headerStore.isLoading = true;
              await store.getUlsanDeductSelect();
              headerStore.isLoading = false;
            } catch ({ status, data }) {
              headerStore.isLoading = false;
              if (status === 401) {
                if (!window.localStorage.getItem('isRetryLogin')) {
                  window.localStorage.setItem('isRetryLogin', 'retry');
                }
                window.location.href = '/';
                return;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return;
              } else if (status === 500) {
                window.location.href = '/500';
                return;
              }
            }
          }
        } catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem('isRetryLogin')) {
              window.localStorage.setItem('isRetryLogin', 'retry');
            }
            window.location.href = '/';
            return;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return;
          } else if (status === 500) {
            window.location.href = '/500';
            return;
          }
        }
      })();
    }
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    };
  }, [store.year, store.month]);

  // 울산공장: 1, 남양연구소: 2, 전주공장: 3, 아산공장: 4,
  if (!includes([1], Number(headerStore.workSite)) || store.step !== 2) return <></>;

  // 공제년월      : deduct_date
  // 부서명        : guest_department
  // 사번          : guest_employee_number
  // 성명          : guest_name
  // 건물동층호    : dongho
  // 결혼유무      : is_married
  // 공제일수      : deduct_days
  // 이용료        : room_charge
  // 유틸리티      : util_charge
  // 침구대        : bedding_charge
  // 기타          : etc_charge
  // 공제총액      : total_deduct
  // 비고          : memo
  // 처리상태      : status
  // 마감확정일    : close_date
  const onSourceGrid1Ready = (params: any) => {
    setGrid(params.api);
  };

  const onPrev = () => {
    store.items = [];
    store.step = 1;
  };
  // 배방정보
  const cellMarriedRenderer = (data: any) => {
    return data.value ? '기혼' : '미혼';
  };

  const cellOnlyNumberFormatter = (number: any) => {
    var number_string = number.toString();
    var number_parts = number_string.split('.');
    var regexp = /\B(?=(\d{3})+(?!\d))/g;

    if (number) {
      if (number_parts.length > 1) {
        return (
          number_parts[0].replace(regexp, ',').replace(/[^0-9]*$/gi, '') +
          '.' +
          number_parts[1].replace(/[^0-9]*$/gi, '')
        );
      } else {
        return number_string.replace(regexp, ',').replace(/[^0-9]*$/gi, '');
      }
    } else {
      return 0;
    }
  };

  //해당 방이 몇인실인지 표시
  const cellUseRenderer = (data: any) => {
    return data.value + '인실';
  };

  const frameworkComponents = {
    cellMarriedRenderer,
    cellOnlyNumberFormatter,
    cellUseRenderer,
  };

  //공제자 정보 저장
  const onSave = async () => {
    if (store.items.length <= 0) {
      info('생성할 공제자 정보가 없습니다.');
      return;
    }
    try {
      headerStore.isLoading = true;
      let data: any = await store.saveUlsan();
      headerStore.isLoading = false;

      //setTimeout(function () {
      if (data.save) {
        info('당월 공제자 정보를 저장완료 했습니다.');
        await store.getItems();
        return;
      } else {
        info('문제가 발생했습니다');
        return;
      }
      //}, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      errorHandler({ status, data }, '저장 중 오류발생');
    }
  };

  //마감확정
  const onConfirm = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '현재 내역을 마감확정 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.ulsanDeductFinal(store.item.id);
            headerStore.isLoading = false;

            setTimeout(function () {
              if (data.save) {
                store.item.is_finalize = 'Y';
                info('마감확정을 완료했습니다.');
              } else {
                info('문제가 발생했습니다');
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem('isRetryLogin')) {
                window.localStorage.setItem('isRetryLogin', 'retry');
              }
              window.location.href = '/';
              return;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return;
            } else if (status === 500) {
              window.location.href = '/500';
              return;
            } else {
              info('문제가 발생했습니다');
              return;
            }
          }
        },
      },
    });
  };

  //그리드 입력되는 값에 따라 가격 계산 반영
  const onCellValueChanged = (event: any) => {
    if (includes(['room_charge', 'util_charge', 'bedding_charge', 'etc_charge'], event.colDef.field)) {
      //total_deduct 공제 총액

      let room_charge = onlyNumberFormatter(event.data.room_charge); // 이용료
      let util_charge = onlyNumberFormatter(event.data.util_charge); // 유틸
      let bedding_charge = onlyNumberFormatter(event.data.bedding_charge); // 침구대
      let etc_charge = onlyNumberFormatter(event.data.etc_charge); //기타
      let total_deduct = 0; //total

      if (event.colDef.field === 'bedding_charge') {
        room_charge = Number(event.data.room_charge);
        util_charge = Number(event.data.util_charge);
        bedding_charge = Number(event.newValue);
        etc_charge = Number(event.data.etc_charge);
        total_deduct = Number(room_charge) + Number(util_charge) + Number(bedding_charge) + Number(etc_charge);
        event.api.refreshCells({
          force: true,
          rowNodes: [event.data.total_deduct],
        });
      } else if (event.colDef.field === 'etc_charge') {
        room_charge = Number(event.data.room_charge);
        util_charge = Number(event.data.util_charge);
        bedding_charge = Number(event.data.bedding_charge);
        etc_charge = Number(event.newValue);
        total_deduct = Number(room_charge) + Number(util_charge) + Number(bedding_charge) + Number(etc_charge);
        event.api.refreshCells({
          force: true,
          rowNodes: [event.data.total_deduct],
        });
      } else if (event.colDef.field === 'room_charge') {
        room_charge = Number(event.newValue);
        util_charge = Number(event.data.util_charge);
        bedding_charge = Number(event.data.bedding_charge);
        etc_charge = Number(event.data.etc_charge);
        total_deduct = Number(room_charge) + Number(util_charge) + Number(bedding_charge) + Number(etc_charge);
        event.api.refreshCells({
          force: true,
          rowNodes: [event.data.total_deduct],
        });
      } else if (event.colDef.field === 'util_charge') {
        room_charge = Number(event.data.room_charge);
        util_charge = Number(event.newValue);
        bedding_charge = Number(event.data.bedding_charge);
        etc_charge = Number(event.data.etc_charge);
        total_deduct = Number(room_charge) + Number(util_charge) + Number(bedding_charge) + Number(etc_charge);
        event.api.refreshCells({
          force: true,
          rowNodes: [event.data.total_deduct],
        });
      }

      event.data.total_deduct = total_deduct; //Number(room_charge) + Number(util_charge) + Number(bedding_charge) + Number(etc_charge);
    }

    if (isClickRow) {
      isClickRow.api.redrawRows();
      store.saveOneUlsan(isClickRow);
    }
  };

  const onSearch = async () => {
    try {
      headerStore.isLoading = true;
      await store.getUlsanDeductList();
      headerStore.isLoading = false;
      if (store.items.length === 0) {
        try {
          headerStore.isLoading = true;
          await store.getUlsanDeductSelect();
          headerStore.isLoading = false;
        } catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem('isRetryLogin')) {
              window.localStorage.setItem('isRetryLogin', 'retry');
            }
            window.location.href = '/';
            return;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return;
          } else if (status === 500) {
            window.location.href = '/500';
            return;
          } else {
            info('문제가 발생했습니다');
            return;
          }
        }
      }
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      } else {
        info('문제가 발생했습니다');
        return;
      }
    }
  };

  // 다운로드
  const xlsDownload = async () => {
    try {
      headerStore.isLoading = true;
      const payload: any = {
        employee_number: userInfo.employeeNumber,
        worksite: '울산',
        deduct_date: `${store.year}${store.month}`,
        reason: downloadText,
      };

      let { status, data } = await Core.xlsDeductUlsandeductList(payload);

      setIsShowDownloadPop(false);
      setDownloadText('');
      headerStore.isLoading = false;

      if (status === 204) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '데이터가 존재하지 않습니다.',
            msg: '요청한 데이터가 존재하지 않습니다.\n당월공제자관리 정보를 저장 후 다운로드를 진행해주시기 바랍니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      }

      if (data) {
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}` + data);
      }
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }
      // console.log(status, data);
    }
  };

  return (
    <>
      <h4>당월공제자관리 설정</h4>
      <div className="table_modify_list">
        <dl className="autowidth" style={{ display: 'none' }}>
          <dt>공제년월</dt>
          <dd>
            <YearSelect
              year={store.year}
              onChange={(e: any) => {
                headerStore.isLoading = true;
                store.setYear(e.target.value);
                headerStore.isLoading = false;
              }}
            />
          </dd>
        </dl>
        <dl className="autowidth" style={{ display: 'none' }}>
          <dt></dt>
          <dd>
            <MonthSelect
              month={store.month}
              onChange={(e: any) => {
                headerStore.isLoading = true;
                store.setMonth(e.target.value);
                headerStore.isLoading = false;
              }}
            />
          </dd>
        </dl>
        <dl className="autowidth" style={{ display: 'none' }}>
          <dt>회사구분</dt>
          <dd>
            <select name="" id="" className="form_control col_md">
              <option value="">전체</option>
            </select>
          </dd>
        </dl>
        <dl className="autowidth" style={{ display: 'none' }}>
          <dt>부서명</dt>
          <dd>
            <input
              type="text"
              className="form_control col_md"
              value={store.department}
              onChange={(e: any) => (store.department = e.target.value)}
            />
          </dd>
        </dl>
        <dl className="autowidth" style={{ display: 'none' }}>
          <dt>사번</dt>
          <dd>
            <input
              type="text"
              className="form_control col_md"
              value={store.employee_number}
              onChange={(e: any) => (store.employee_number = e.target.value)}
            />
            <button className="btn btn_sm btn_gray ml10" onClick={onSearch}>
              검색
            </button>
          </dd>
        </dl>
      </div>

      <h4>
        당월공제자관리 리스트
        <span className="ml10" style={{ fontSize: '15px' }}>
          (저장 후 수정 가능합니다.)
        </span>
        <div className="right_area">
          <div>
            {headerStore.downRoleFlag ? ( // && store.item && store.item.is_finalize === 'N'
              <>
                <button
                  type="button"
                  className="btn btn_md btn_darkblue"
                  onClick={() => {
                    setIsShowDownloadPop(true);
                  }}
                >
                  다운로드
                </button>
              </>
            ) : (
              <>
                <></>
              </>
            )}
          </div>
        </div>
      </h4>
      <div className="table_normal_list">
        <div className="ag-theme-alpine" style={{ height: '500px' }}>
          <AgGridReact
            modules={AllCommunityModules}
            defaultColDef={{
              width: 100,
              wrapText: true,
              resizable: true,
              headerClass: 'text-center',
              editable: false,
              filter: true,
              floatingFilter: true,
            }}
            suppressRowTransform={true}
            suppressRowClickSelection={true}
            frameworkComponents={frameworkComponents}
            onGridReady={onSourceGrid1Ready}
            multiSortKey={'ctrl'}
            rowSelection={'multiple'}
            rowData={store.items}
          >
            <AgGridColumn
              headerName="공제년월"
              field="deduct_date"
              width={120}
              editable={false}
              filter={false}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="부서명"
              field="guest_department"
              width={180}
              editable={false}
              filter="agTextColumnFilter"
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="사번"
              field="guest_employee_number"
              width={120}
              editable={false}
              filter="agTextColumnFilter"
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="성명"
              field="guest_name"
              width={120}
              editable={false}
              filter="agTextColumnFilter"
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="건물/동/층/호"
              field="dongho"
              width={170}
              editable={false}
              filter={false}
              unSortIcon={true}
              sortable={true}
            />
            {/*<AgGridColumn headerName={store.isDeductList ? '공제기간' : '숙박기간'} field="check_in_out" width={175} editable={false} cellStyle={{ justifyContent: 'flex-start' }} filter={false} />*/}
            <AgGridColumn
              headerName="공제일수"
              field="deduct_days"
              width={110}
              editable={false}
              filter={false}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="인실"
              field="use_count"
              width={110}
              editable={false}
              filter={false}
              cellRenderer="cellUseRenderer"
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="결혼유무"
              field="is_married"
              width={100}
              editable={false}
              filter={false}
              cellRenderer="cellMarriedRenderer"
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="이용료"
              field="room_charge"
              width={100}
              editable={
                store.is_finalize !== 'Y' &&
                headerStore.writeRoleFlag &&
                store.saveBtnFalg &&
                (userInfo.classification === 'HEC' || userInfo.classification === 'HMC')
                  ? true
                  : false
              }
              filter={false}
              cellStyle={{ justifyContent: 'flex-end' }}
              valueFormatter={params => onlyNumberFormatter(params.data.room_charge)}
              onCellValueChanged={onCellValueChanged}
              onCellClicked={e => {
                setIsClickRow(e);
              }}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="유틸리티"
              field="util_charge"
              width={100}
              editable={
                store.is_finalize !== 'Y' &&
                headerStore.writeRoleFlag &&
                store.saveBtnFalg &&
                (userInfo.classification === 'HEC' || userInfo.classification === 'HMC')
                  ? true
                  : false
              }
              filter={false}
              cellStyle={{ justifyContent: 'flex-end' }}
              valueFormatter={params => onlyNumberFormatter(params.data.util_charge)}
              onCellValueChanged={onCellValueChanged}
              onCellClicked={e => {
                setIsClickRow(e);
              }}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="침구대"
              field="bedding_charge"
              width={100}
              editable={
                store.is_finalize !== 'Y' &&
                headerStore.writeRoleFlag &&
                store.saveBtnFalg &&
                (userInfo.classification === 'HEC' || userInfo.classification === 'HMC')
                  ? true
                  : false
              }
              filter={false}
              cellStyle={{ justifyContent: 'flex-end' }}
              valueFormatter={params => onlyNumberFormatter(params.data.bedding_charge)}
              onCellValueChanged={onCellValueChanged}
              onCellClicked={e => {
                setIsClickRow(e);
              }}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="기타"
              field="etc_charge"
              width={100}
              editable={
                store.is_finalize !== 'Y' &&
                headerStore.writeRoleFlag &&
                store.saveBtnFalg &&
                (userInfo.classification === 'HEC' || userInfo.classification === 'HMC')
                  ? true
                  : false
              }
              filter={false}
              cellStyle={{ justifyContent: 'flex-end' }}
              valueFormatter={params => onlyNumberFormatter(params.data.etc_charge)}
              onCellValueChanged={onCellValueChanged}
              onCellClicked={e => {
                setIsClickRow(e);
              }}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="공제총액"
              field="total_deduct"
              width={100}
              editable={false}
              filter={false}
              cellStyle={{ justifyContent: 'flex-end' }}
              valueFormatter={params => onlyNumberFormatter(params.data.total_deduct)}
              onCellClicked={e => {
                setIsClickRow(e);
              }}
              cellRenderer={({ data }: { data: any }) => {
                data.total_deduct =
                  Number(data.room_charge) +
                  Number(data.util_charge) +
                  Number(data.bedding_charge) +
                  Number(data.etc_charge);
                data.total_deduct += '';
                return onlyNumberFormatter(data.total_deduct);
              }}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="비고"
              field="memo"
              width={200}
              editable={true}
              filter={false}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="처리상태"
              field="status"
              width={100}
              editable={false}
              filter={false}
              unSortIcon={true}
              sortable={true}
            />
            <AgGridColumn
              headerName="마감확정일"
              field="close_date"
              width={100}
              editable={false}
              filter={false}
              unSortIcon={true}
              sortable={true}
            />
          </AgGridReact>
        </div>
      </div>

      <div className="submit ac">
        {headerStore.writeRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') ? (
          <button type="button" className="btn btn_lg btn_outline" onClick={onPrev}>
            기준가 세팅
          </button>
        ) : (
          <></>
        )}
        {headerStore.writeRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') ? (
          <>
            {store.is_finalize === '' ? (
              <button type="button" className="btn btn_lg btn_darkblue" onClick={onSave}>
                저장
              </button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {headerStore.writeRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') ? (
          <>
            {store.item && store.item.is_finalize === 'N' ? (
              <button type="button" className="btn btn_lg btn_darkblue" onClick={onConfirm}>
                마감확정
              </button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      {/* 다운로드 사유 팝업 */}
      <div
        id="pop_download"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isShowDownloadPop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                setDownloadText('');
                setIsShowDownloadPop(false);
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>사유</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_lg btn_darkblue pop_close"
              onClick={() => {
                xlsDownload();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default Ulsan;

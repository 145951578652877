import React, { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Core from '../../../apis/core';
import { MessageContext, MessageImpl } from '../../../routes';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';

const MngFileUpload = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext)
  const [fileList, setFileList] = useState<any[]>(props.items);
  const [maxCount, setMaxCount] = useState<number>(3);
  const fileRef = useRef(null);

  useEffect(() => {
    setFileList(props.items);
  },[props])

  const onFileChange = async (e: any) => {
    const {
      target: { files },
    } = e;
    const tmpFileList = [...fileList];
    props.items.splice(0, props.items.length);

    // if ((files.length + tmpFileList.length) > maxCount) {
    //   error(`최대 ${maxCount}개까지 첨부할 수 있습니다.`);
    //   return;
    // }

    const actions: any[] = [];
    files.forEach((file: Blob) => actions.push(addFile(file)));
    try {
      headerStore.isLoading = true;
      const founds = await Promise.all(actions);

      founds.forEach((found: any) => {
        if(found) {
          tmpFileList.push(found.data);
        }
      });

      (fileRef.current as any).value = null;
      setFileList(tmpFileList);
      props.onSetData(tmpFileList);
      headerStore.isLoading = false;
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("파일 업로드 중 문제가 발생했습니다.");
      return;
    }
  };

  const addFile = async(file: Blob) => {
    const form = new FormData();
    form.append('path', file);

    let extList : any = [];
    if(props.type === "image") {
      extList = ['gif', 'jpg', 'jpeg', 'png'];
    }else {
      extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];
    }

    let fileData: any = file;
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (file.size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }else {
      try {
        headerStore.isLoading = true;
        let tmpFile = await Core.fileUpload<{
            id?: number;
            path?: string;
            name?: string;
            category?: string | number;
          }>(form);

        headerStore.isLoading = false;
        return tmpFile;
      } catch (error) {
        headerStore.isLoading = false;
        // console.log(error);
        const { status, data } = error;

        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("파일 업로드 중 문제가 발생했습니다.");
        return;
      }
    }
  };

  const deleteFile = (fileInfo: any) => {
    const { id } = fileInfo;
    const founds = fileList.filter(file => file.id !== Number(id));
    setFileList(founds);
    props.onSetData(founds);
    (fileRef.current as any).value = null;
  };

  const clicking = () => {
    // console.log(process.env.REACT_APP_MEDIA);
    // if (fileList.length > 2) {
    //   error(`최대 ${maxCount}개까지 첨부할 수 있습니다.`);
    //   return;
    // }
    (fileRef.current as any).click();
  }

  const pathString = (fileInfo: any) => {
    const path = fileInfo.path ? fileInfo.path : fileInfo.image;
    return `${process.env.REACT_APP_MEDIA}/${path}`;
  }

  return (
    <>
      <div className="wrap_file w100 mb10">
        <input
          id={'input_' + props.fileId}
          className="upload_name form_control"
          disabled={true}
        />
        <input ref={fileRef} type="file" multiple={true} id={props.fileId} className="upload_hidden" onChange={onFileChange} />
        <label className="btn btn_sm btn_gray" onClick={clicking}>
          파일찾기
        </label>
      </div>
      {fileList.length > 0 ? (
          fileList.map((fileInfo: any, index: number) => (
            <a key={index} href="javascript:void(0);" className="item del" onClick={() => deleteFile(fileInfo)}>
              {fileInfo.name}
            </a>
          ))
        ): ''}
    </>
  );
});

export default MngFileUpload;

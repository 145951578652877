import React, { useEffect, useContext, useState } from 'react';
import { lodge } from '../../../apis';
import SelectBuilding from '../../atoms/select/buildings';
import SelectFloor from '../../atoms/select/floor';
import Floors from '../../molecules/floor';
import { LODGE_STATUS_CODE, ROOM_TYPE_CODE } from '../../../constants';
import _ from 'lodash';
import './businessGroupBackroom.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { MessageContext, MessageImpl } from '../../../routes';
import { rightBig } from '../../../utils/date';
import { getWorkSitePhoneNum } from '../../../utils/common';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

// 출장자 단체 배방
const BusinessGroupBackroom = observer((props: any) => {
  const {applicantJudgeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const { info }: MessageImpl = useContext(MessageContext);
  //const [badIdx, setSelectBadIdx] = useState<number>();
  const [groupList, setGroupList] = useState<any>({});
  const [stayAfter, setStayAfter] = useState<string>('');
  const [stayBefore, setStayBefore] = useState<string>('');
  const [gender, setGender] = useState<number>(0);
  const [clickGuest, setClickGuest] = useState<any>();
  const [guestRoom, setGuestRoom] = useState<any>();
  const [roomList, setRoomList] = useState<any>([]);

  useEffect(() => {
    if (!store.isBusinessGroupPop) return;
    //if (!store.selectRow.id) return;
    // console.log(store.selectRow);

    (async () => {
      store.workSite = store.selectRow.room_work_site;
      
      try {
        headerStore.isLoading = true;
        let list = await setGroupGuests();    // 날짜별로 소팅하는 영역
        headerStore.isLoading = false;
      } catch (error) {
        headerStore.isLoading = false;
        // console.log(error);
        const { status, data } = error;

        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("문제가 발생했습니다.");
        return;
      }

      // 건물, 층 select 정보 가져오기
      //await store.getBuildsInfo();
      //  await store.getFloorsInfo();
    })();
  }, [store.isBusinessGroupPop, store.popReload]);

  // 초기화
  const roomInit = () => {
    setStayAfter('');
    setStayBefore('');
    setClickGuest([]);
    setGuestRoom([])
    setRoomList([]);
    store.selectRow.guestList.map((temp: any) => {
      temp.tempRoom = '';
    });
    //if(store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group){
      store.roomInit();
    //}
  }

  // 같은 입숙예정일, 퇴숙 예정일끼리 그룹화하기 
  const setGroupGuests = () => {
    let group: any = [];
    group = store.selectRow.guestList;
    group.sort((a: any, b: any) => rightBig(b.stay_after, a.stay_after) ? 1 : rightBig(a.stay_after, b.stay_after) ? -1 : 0);

    let temp: any = [];
    let tempList: any = [];

    // 입숙, 퇴숙 예정 일자 별로 그룹 묶기
    group = group.map((groupUnit: any, groupUnitIdx: number) => {
      tempList = [];
      let dateStr = groupUnit.stay_after + '-' + groupUnit.stay_before;

      group.map((groupUnit2: any, groupUnitIdx2: number) => {
        let dateStr2 = groupUnit2.stay_after + '-' + groupUnit2.stay_before;
        if(dateStr === dateStr2){
          tempList[groupUnit2.id] = groupUnit2;
        }
      })

      temp[dateStr] = tempList;
    })

    store.popReload = true;
    //setGroupList(temp);
    setGroupList(group);
    return temp;
  };

  // 배방시 room 칸 클릭
  const onRoomClickHandlerToSelect = (selectedRoom: any, idx: number) => {
    let tempList : any = {...roomList};
    //let tempRoom: any = _.cloneDeep(store.room);
    
    let tempRoom: any = {};
    tempRoom[selectedRoom.id] = selectedRoom;
    tempRoom['room'] = selectedRoom;
    tempRoom[clickGuest.id] = clickGuest;
    tempRoom['guest'] = clickGuest;
    tempRoom['badIdx'] = idx;     // 선택한 방이 2인 이상인 경우때문에 badIdx 담기
    store.room = tempRoom;        // 선택한 방 담기
    tempList[clickGuest.id] = tempRoom;

    store.selectRow.guestList.map((temp: any) => {
      if(temp.id === clickGuest.id){
          temp.tempRoom = selectedRoom.building_name + ' ' + selectedRoom.name;   // 이용자 테이블 영역에 표시할 방 정보
      }
    });

    setRoomList(tempList);
    //setSelectBadIdx(idx);  
  }

  // 선택 풀기
  const onRoomClickHandlerToUnSelect = (room: any) => {
    //store.room = [];
  }

  // 배방 등록
  const onCompleteHandler = async () => {
    if(roomList === null || Object.keys(roomList).length <= 0){
      info('배방정보를 입력해주세요');
      return false;
    }else if(Object.keys(roomList).length !== Object.keys(store.selectRow.guestList).length) {
      info('선택하신 방과 이용자 수가 맞지 않습니다');
      return false;
    }

    /* let pairs:any = Object.keys(roomList).map((room: any, idx: number) => {
      return {
        guest: roomList[room]['guest'].id,
        rooms: roomList[room]['room'].id
      }
    );*/

    try {   
      // 단체 배방 오픈인 경우
      if((store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group) && !store.manageCheckin){
        let room :any = Object.keys(roomList).map((room: any, idx: number) =>  roomList[room]['room'].id );
    
        const payload: any ={
          rooms : room
        }

        try {
          headerStore.isLoading = true;
          let { data } = await lodge.businessGroupAccept(store.selectRow.id, payload);
          //store.roomInit();
          
          // 배방 오픈 문자 발송
          let guestsCellPhone = store.selectRow.guestList.map((item: any) => {
            return item.guest_cellphone;
          });
          
          let smsPayload: any = {
            cellphone : guestsCellPhone.join(','),
            message : '서류승인이 완료되었습니다. 마이페이지 > 신청관리 메뉴에서 신청자가 배방을 완료하시면 관리자가 최종 확인하여 승인 시 예약이 완료됩니다'
                      +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(store.selectRow.room_work_site, store.selectRow.room_type)
          }
          const { data: sms } = await lodge.smsSendAPI(smsPayload);
          
          info('배방이 완료되었습니다.');
          store.isBusinessGroupPop = false;
          roomInit();
          store.getItems();
          headerStore.isLoading = false;
        }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          info("문제가 발생했습니다.");
          return;
        }
      }else if((store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group) && store.manageCheckin) {
        // 관리자가 강제 입숙처리하는 경우 (출장자 단체)
        let pairs:any = {};
        let temp: any = {};
        await Object.keys(roomList).map((room: any, idx: number) => {
          temp[roomList[room]['guest'].id] = roomList[room]['room'];
        });
        
        pairs = temp;
        await props.gridDataUpdate(pairs);
        store.isBusinessGroupPop = false;
        roomInit();
      }else {
         // 관리자가 강제 입숙처리하는 경우 (기숙사 다건)
        let pairs:any = {};
        let temp: any = {};
        await Object.keys(roomList).map((room: any, idx: number) => {
          temp[roomList[room]['guest'].id] = roomList[room]['room'];
        });
        
        pairs = temp;
        await props.gridDataUpdate(pairs);
        store.isBusinessGroupPop = false;
        roomInit();
      }
    } catch ({ data, status }) {
      headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("배방 중 문제가 발생했습니다.");
      return;
    }
  }
  
  // 사용 불가능한 방 조건
  const isDisable = (room: any, idx: number) => {
    let chk = false;
    if(roomList){   // 이용자 테이블에 방 선택한 이용자가 있는 경우
      if(store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group){   
        // 출장자 단체인 경우 : 선택한 방 들 중 해당방에 있는 이용자가 클릭한 이용자와 다르며 방의 베드수가 같을 경우 disable
        Object.keys(roomList).map((listId: any, gidx: number) =>  {
          if(roomList[listId][room.id] && roomList[listId]['guest'].id !== clickGuest.id && 
              // 아래 주석 풀면 선택한 이용자들끼리 겹치는 날짜가 다를 경우도 배방 선택이 가능하게 됨
              //(roomList[listId]['guest'].stay_after === stayAfter || roomList[listId]['guest'].stay_before === stayBefore) 
              //getStayConfirm(roomList[listId]['guest'].stay_after, roomList[listId]['guest'].stay_before, stayAfter, stayBefore) &&
              roomList[listId]['badIdx'] === idx){
            chk = true;
          }
        });
      }else {  
        // 관리자 - 기숙사 단체 배방의 경우
        Object.keys(roomList).map((listId: any, gidx: number) =>  {
          if(roomList[listId][room.id] && roomList[listId]['guest'].id !== clickGuest.id && roomList[listId]['badIdx'] === idx){
            chk = true;
          }
        });
      }
    }

    // 해당 방상태가 이용가능X, 룸 타입X, 성별X, 이미 들어 있는 게스트 수가 해당방의 index보다 클때 disable
    if((room.roomstatus[idx] !== null && room.roomstatus[idx].status !== 1) || room.roomstatus[idx].guest_id || room.room_type !== store.selectRow.room_type 
        || (room.roomstatus[idx].guest && room.roomstatus[idx].guest.status !== LODGE_STATUS_CODE.checkout)
        || (room.gender_type !== 0 && room.gender_type !== clickGuest.guest_gender)){
      chk = true;
    }

    return chk;
  }

  // 선택한 방 표시 조건
  const isSelect = (room: any, idx: number) => {
    // 이용자 테이블에서 선택한 방이 있으며 클릭한 이용자의 id가 선택한 방list에 들어있는 경우
    if(roomList && roomList[clickGuest.id]){
      // clickGuest.id key 값을 가진 데이터 한줄 가져오기
      let temp : any = roomList[clickGuest.id];
      
      // 해당 데이터에 방 id가 있으며 badindex가 동일하고 room id가 동일할 때 선택한 방 조건 true
      return (temp[room.id] && temp['badIdx'] === idx && temp['room']['id'] === room.id);
    }else {
      return false;
    }
    //return (store.room[room.id] && badIdx === idx)
  }

  // 팝업 체크 박스 핸들러
  /*const checkBoxHandler = async(e : any, target: any) => {
    console.log(e, target);
    setStayAfter(target.stay_after);
    setStayBefore(target.stay_before);

    try {
      await store.getBuildsInfo();
      await store.getFloorsInfo(stayAfter, stayBefore);
    } catch (error) {
      console.log(error);
    }
  } */

  // tr 클릭 -> 방 그리기
  const drawRoomHandler = async(guest : any) => {
    setClickGuest(guest);   // 선택한 이용자 담기

    // 선택한 이용자가 roomlist에 들어 있으면 해당 게스트의 룸 정보 담기
    if(roomList && roomList[guest.id]) {
      await setRoomData(guest);
    }

    // 방 그릴때 필요한 데이터 세팅
    setStayAfter(guest.stay_after);
    setStayBefore(guest.stay_before);
    setGender(guest.guest_gender);

    try {
      headerStore.isLoading = true;
      await store.getBuildsInfo();  // 건물 select
      await store.getFloorsInfo(guest.stay_after, guest.stay_before, guest.guest_gender);   // 층, 방 데이터 가져오기
      
      // 기숙사 다건의 경우 우선순위표 표시
      if(store.selectRow.room_type === ROOM_TYPE_CODE.dormitory_room){
        await store.getFirstAssign();
      }
      headerStore.isLoading = false;
    } catch ({ data, status }) {
      headerStore.isLoading = false;
      
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다.");
      return;
    }
  }

  const setRoomData = (guest : any) => {
    let roomData: any = [];
    let listData: any =roomList[guest.id];
    let roomId: string = listData['room'].id;
    roomData[roomId] = listData['room'];
    setGuestRoom(roomData);
  }

  // 배방 오픈 초기화
  const roomListInit = () => {
    setRoomList([]);
    store.selectRow.guestList.map((temp: any) => {
      temp.tempRoom = '';
    });
  }
  
  if (!store.isBusinessGroupPop) return<></>;
  return (
    <div id="pop_open_roomtype" className="pop_dim_wrap pop_modal pop_lg" style={{display: store.isBusinessGroupPop? 'block' : 'none'}}>
        <div className="pop_wrap">
            <div className="title_wrap">
              <h3>배방오픈</h3>
                <button className="pop_btn_close pop_close" onClick={() => {roomInit(); store.isBusinessGroupPop = false}}>닫기</button>
            </div>
            <div className="contents_wrap">
                <div className={store.openRoomInfoPop? '' : "row"}>
                  <div className="col col_list">
                    <div className="title_contents bd0">
                        <h3>이용자정보</h3>
                        <div className="right_area">
                            <span className="txt_sm">총 이용인원 {store.selectRow.guestList.length}명</span>
                        </div>
                    </div>
                      <div className="table_list box_scroll_y">
                        <table>
                          <caption className="sr_only"></caption>
                          <colgroup>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            {store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group? (<col width="*"/>) :''}
                            <col width="*"/>
                          </colgroup>
                          <thead>
                          <tr>
                              <th>성명</th>
                              <th>사번</th>
                              <th>성별</th>
                              <th>입숙일</th>
                              {store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group? (<th>퇴숙일</th>) :''}
                              <th>숙소정보</th>
                          </tr>
                          </thead>
                          <tbody>
                            {Object.keys(store.selectRow.guestList).length > 0 ? store.selectRow.guestList.map((group: any, groupIdx: number) => (   
                              <tr key={groupIdx} 
                                onClick={() => drawRoomHandler(group)} 
                                style={{cursor: 'pointer'}} 
                                className={group.tempRoom? 'selected' : clickGuest && clickGuest.id === group.id? 'active': ''}
                              >
                                  <td>{group.guest_name}</td>
                                  <td>{group.guest_employee_number}</td>
                                  <td>{group.guest_gender === 1? '남' : '여'}</td>
                                  <td>{group.stay_after}</td>
                                  {store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group? (<td>{group.stay_before}</td>) :''}
                                  <td>{!store.openRoomInfoPop? group.tempRoom : 
                                            group.room_building_name + ' ' + group.room_building_dong + ' ' + group.room_name +'호'}</td>
                              </tr>
                            )) : '' }
                          {!store.openRoomInfoPop ? (
                            <>
                              <tr>
                                  <td colSpan={store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group? 6 : 5}>
                                      {roomList? Object.keys(roomList).length : 0}개 호실에 위 {Object.keys(store.selectRow.guestList).length}명 배방 오픈정보
                                  </td>
                              </tr>
                              <tr>
                                  <td colSpan={store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group? 6 : 5}>
                                      <ul className="lst_dong clearfix">
                                        {roomList? (Object.keys(roomList).map((room: any, idx: number) => 
                                          (
                                            <li><span className="dong">{roomList[room]['room'].building_name}</span><span className="ho">{roomList[room]['room'].name}</span></li>
                                          ))
                                        ) : ''}
                                      </ul>
                                  </td>
                              </tr>
                            </>
                          ) : ''}
                          </tbody>
                      </table>
                      </div>
                    </div>

                    {!store.openRoomInfoPop ? (
                      <div className="col col_room">
                          <div className="title_contents bd0">
                              <h3>배방 오픈 정보</h3>
                              <div className="right_area">
                                <button className="btn btn_sm btn_reset" onClick={() => roomListInit()}>초기화</button>
                              </div>
                          </div>

                          <div className="form_case">
                            <ul className="lst_desc">
                                <li style={{color:'#f1615d'}}>왼쪽 리스트에서 배방하실 사람을 클릭하시면 입숙, 퇴숙일에 맞는 방정보가 표시됩니다</li>
                                <li>동과 호수를 선택하면 배방오픈을 할 수 있습니다</li>
                                {stayAfter && stayBefore ? (
                                  <li>입숙기간 : {stayAfter} ~ {stayBefore}</li>
                                ) : ''}

                                {store.buildings.length > 0 && store.floors.length <= 0?<li style={{color:'#f1615d'}}>선택한 건물에 배방가능한 방이 없습니다.</li> :''}
                            </ul>

                            {!store.openRoomInfoPop && store.firstNumList && store.firstNumList.length > 0 ? (
                              <div className="right_area">
                                <h4>배방 우선순위 안내</h4>
                                <div className="lst_case">
                                    <ul>
                                       {store.firstNumList.map((item: any, idx: number) => (
                                          <li>
                                              <span className="num">{(idx + 1)}</span>
                                              <span>{item.dong} {item.room_name}호</span>
                                              <span>{item.stayBefore? item.stayBefore : ''}</span>
                                          </li>
                                        ))}
                                    </ul>
                                </div>
                              </div>
                            ):''}
                          </div>

                          <div className="form_select clearfix">
                            <SelectBuilding
                                changeBuildHandler={(e: any) => {
                                  store.selectFloor = 0;
                                  store.building = store.buildings[parseInt(e.target.value)];
                                  store.getFloorsInfo(stayAfter, stayBefore, gender);
                                  
                                  // 기숙사 다건 우선순위표 표시
                                  if(store.selectRow.room_type === ROOM_TYPE_CODE.dormitory_room){
                                    store.getFirstAssign();
                                  }
                                }}
                                building={store.building}
                                buildings={store.buildings}
                                dongs={store.dongs}
                              />
                              <SelectFloor floors={store.floors} value={store.selectFloor}
                                  onChangeFloor={(e:any) => {
                                      store.selectFloor = e.target.value; //store.room = store.floors[e.target.value]
                                  }} />
                              <ul className="lst_info clearfix">
                                  <li>이용 가능</li>
                                  <li>이용 불가능</li>
                              </ul>
                          </div>

                          <div className="box_scroll_y">
                              <Floors
                                floors={store.floors}
                                //selectRoom={store.room}
                                selectRoom={guestRoom}
                                selectFloor={store.selectFloor}
                                onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                                onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                                isUsable={() => { }}
                                isDisable={isDisable}   // 이용 불가능 표시 
                                isSelect={isSelect}     // 선택한 방 표시 
                              />
                          </div>
                      </div>
                    ):''}
                </div>
            </div>
            <div className="button_wrap">
              {store.openRoomInfoPop ? (
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={() => {roomInit(); store.isBusinessGroupPop = false}}>확인</button>
              ):(
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={onCompleteHandler}>완료</button>
              )}
            </div>
        </div>
    </div>
  )
});

export default BusinessGroupBackroom;

import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Pagination from '../../components/organismas/pagination';
import DefaultTemplate from '../../components/templetes/default';
import { WORK_SITE, ADMIN_TYPE } from '../../constants';
import { useStores } from '../../store/mobx';

const AccountPermission = observer((props: any) => {
    const {accountPermissionStore: store} = useStores();
    const {headerStore: headerStore} = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [reload, setReload] = useState<boolean>(false);
    const [workList, setWorkList] = useState<any>([]);

    useEffect(() => {
        (async () => {
           headerFn();
        })();
        return () => {
            if (store.findTabs('/account/permission') < 0) {
              store.destory();
            }
        }
    }, [reload]);

    const headerFn = async() => {
        headerStore.isLoading = true;
        store.init();
        headerStore.getAuthMenuRole('6-1');
        headerStore.getAuthBuilding();
        headerStore.getAuthRoomRole();
        await headerStore.getAuthWorkSiteRole();
        setWorkList(headerStore.authWorkSiteLen[0]);
        store.paramAuthWorkSiteRole = headerStore.paramAuthWorkSiteRole;
        store.workSite = headerStore.workSite;
        setReload(true);
        headerStore.isLoading = false;
    }

    // 헤더 바뀔 경우
    useEffect(() => {
        (async () => {
            store.workSite = headerStore.workSite;
            //await store.getItems();
        })();
    }, [headerStore.workSite]);

    // 검색 조건 바뀌는 경우
    useEffect(() => {
        (async () => {
            headerStore.isLoading = true;
            store.loginClassification = userInfo.classification;
            if(Number(headerStore.workSite) === 2) {
                if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null') {
                    store.building = headerStore.paramAuthBuildingRole;
                }
            }
            await store.getItems();
            headerStore.isLoading = false;
        })();
        return () => {
            if (store.findTabs('/account/permission') < 0) {
              store.destory();
            }
        }
    }, [store.limit, store.offset, store.workSite, store.gender, store.isActive, store.progress]);

    return (
    <>
        <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
        >
        <div className="tab_contents on">
            <h4>계정 및 권한관리 리스트
                <div className="right_area">
                    <select className="form_control" style={{'width':'120px'}}
                        value={store.workSite} onChange={(e:any) => {store.offset=0; store.workSite = e.target.value;}}
                    >
                        <option value="0">근무지</option>
                        {/* {Object.keys(WORK_SITE).map((key: string, index: number) => {
                            return (
                                <option key={index} value={key}>
                                    {WORK_SITE[Number(key)]}
                                </option>
                            )
                        })} */}
                        {workList && workList.map((w: any, idx: number) => {
                            return (
                                <option key={idx} value={w}>
                                    {WORK_SITE[Number(w)]}
                                </option>
                            )
                        })}
                    </select>
                    <select className="form_control" style={{'width':'120px'}}
                        value={store.gender} onChange={(e:any) => {store.offset=0; store.gender = parseInt(e.target.value);}}
                    >
                        <option value="0">성별</option>
                        <option value="1">남</option>
                        <option value="2">여</option>
                    </select>
                    <select className="form_control" style={{'width':'120px'}}
                        value={store.isActive} onChange={(e:any) => {store.offset=0; store.isActive = parseInt(e.target.value);}}
                    >
                        <option value="0">활성여부</option>
                        <option value="1">활성</option>
                        <option value="2">비활성</option>
                    </select>
                    <select className="form_control" style={{'width':'120px'}}
                        value={store.progress} onChange={(e:any) => {store.offset=0; store.progress = parseInt(e.target.value);}}
                    >
                        <option value="0">상태</option>
                        <option value="1">신청대기</option>
                        <option value="2">승인요청</option>
                        <option value="3">승인</option>
                    </select>
                </div>
            </h4>
            <div className="table_normal_list">
                <table>
                <caption className="sr_only">계정 및 권한관리 리스트</caption>
                <colgroup>
                    {/* <col width="4%"/> */}
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                </colgroup>
                <thead>
                <tr>
                    {/* <th>
                        <label className="input_check pop_notext">
                            <input type="checkbox" id="" name="check_notice"/>
                            <span className="label_text"></span>
                        </label>
                    </th> */}
                    <th>관리번호</th>
                    <th>성명</th>
                    <th>근무지</th>
                    <th>소속</th>
                    <th>생년월일</th>
                    <th>성별</th>
                    <th>휴대폰번호</th>
                    <th>활성여부</th>
                    <th>담당업무</th>
                    <th>상태</th>
                </tr>
                </thead>
                <tbody>
                    {store.list.length <= 0? (
                        <tr>
                            <td colSpan={10} className="blank_area type_white">
                                <p className="txt_blank">데이터가 없습니다.</p>
                            </td>
                        </tr>
                    ): (
                    store.list.map((data: any, idx: number) => (
                        <tr key={idx}
                            style={{'cursor': 'pointer'}}
                            onClick={() => {store.clickData = data; props.history.replace('/account/permission/detail')}}
                        >
                            {/*<td>
                                <label className="input_check pop_notext">
                                    <input type="checkbox" id="" name=""/>
                                    <span className="label_text"></span>
                                </label>
                            </td>*/}
                            <td>{data.employee_number}</td>
                            <td>{data.name}</td>
                            <td>{data.worksite}</td>
                            <td>{data.company}</td>
                            <td>{data.birthdate}</td>
                            <td>{data.gender ===1 ? '남' : data.gender ===2 ?'여' : '무관'}</td>
                            <td>{data.cellphone}</td>
                            <td>
                                {data.is_active? (
                                    <span className="state_type type3">활성</span>
                                ) :(
                                    <span className="state_type type5">비활성</span>
                                )}
                            </td>
                            <td>{data.duty}</td>
                            <td>
                                {data.progress === 1 && (
                                    <span className="point red">신청대기</span>
                                )}
                                {data.progress === 2 && (
                                    <span className="point red">승인요청</span>
                                )}
                                {data.progress === 3 && (
                                    <span className="point red">승인</span>
                                )}
                            </td>
                        </tr>
                        ))
                    )}
                </tbody>
                </table>
            </div>  {/*table*/}
            
            <div className="board_paging">
                <ul>
                    <Pagination
                        offset={store.offset}
                        limit={store.limit}
                        setOffset={(offset: number) => store.offset = offset}
                        totalPage={store.totalPage}
                    />
                </ul>
            </div>   {/*board_paging*/}

            {ADMIN_TYPE[userInfo.classification] && headerStore.writeRoleFlag? (
                <div className="submit ac">
                    <button type="button" className="btn_lg btn_darkblue" onClick={() => {store.init(); props.history.replace('/account/permission/detail')}}>관리자등록</button>
                    {/*<button type="button" className="btn_lg btn_darkblue btn_outline">삭제</button>*/}
                </div>
            ) :''}

        </div>
        </DefaultTemplate>
    </>
    );
});

export default AccountPermission;

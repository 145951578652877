import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import DefaultTemplate from '../../../components/templetes/default';
import { useStores } from '../../../store/mobx';
import { ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE } from '../../../constants';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import XLSX from 'xlsx';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { formatByString, formatByString_YYYYMMDD, getTodayDate, isAfterAndSame, leftBig } from '../../../utils/date';
import BusinessGroupBackroom from '../../../components/organismas/popup/businessGroupBackroom';
import { useDispatch, useSelector } from 'react-redux';
import { includes, isNull } from 'lodash';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import { ADD_TAB, CLICK_TAB } from '../../../store/modules/base';
import Button from '../../../components/molecules/fileUpload/button';
import { core } from '../../../apis';

const BusinessRoomGroupType = observer((props: any) => {
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const {lodgedHistoryStore: store} = useStores();
  const {applicantJudgeStore: roomStore} = useStores();
  const {headerStore : headerStore} = useStores();
  //const {boardStore : boardStore} = useStores();
  const [grid, setGrid] = useState<any>(null);
  const subStore = store.insertStore;
  const sources = subStore.item.guests;
  const fileRef = useRef(null);
  const baseUrl = '/guest/history';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  //권한 가져오기
  useEffect(() => {
    headerStore.getAuthRoomRole();
  }, []);

  useEffect(() => {
    roomStore.workSite = headerStore.workSite;     
    roomStore.selectRow.room_work_site = headerStore.workSite;   
    store.insertStore.workSite = headerStore.workSite;

    if(Number(headerStore.workSite) === 2) {
      if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
        roomStore.paramAuthBuildingRole = headerStore.paramAuthBuildingRole;
      }
    }

    if (sources.length > 0) {
      store.insertStore.items.forEach(item => {
        item.work_site = headerStore.workSite;
        item.room = -1;
        item.room_name = '';
      });
  
      if(grid){
        grid.redrawRows();
      }
    }
  }, [headerStore.workSite]);

  const isGroup = () => ROOM_TYPE_CODE.business_room_group === store.insertRoomType;

  const goBack = () => props.history.replace(baseUrl);

  const onFileChange = async (e: any) => {
    let file = e.target.files[0];
    let splited = file.name.split('.')
    if (splited[splited.length - 1] !== 'xlsx' && splited[splited.length - 1] !== 'xls') {
      let msg = "업로드하신 엑셀파일의 .형태가 맞지 않거나 입력정보가 올바르지 않아서 인원추가에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.";
      info(msg);
      return
    }

    try {
      headerStore.isLoading = true;
      var excel = new FormData();
      excel.append("path", file);

      let { data } = await core.fileUploadBusinessRoomGroup(excel);

      //store.insertStore.items = data.data;
      //console.log('data ---------> ', data);
      data = data.data;

      let rowsData:any = [];
      let idCount = 1;
      data.map((item: any, idx: number) => {
        const newItem: any = {};
        // 배방용 임의로 만든 데이터
        newItem['id'] = idCount;
        idCount ++;

        // 데이터 세팅
        newItem.guest_type = item.guest_type;
        newItem.guest_company = item.company; 
        newItem.guest_department = item.department;
        newItem.guest_employee_number = item.employee_number;
        newItem.guest_name = item.name;
        newItem.guest_gender = item.gender_type;
        newItem.guest_cellphone = item.cellphone; 
        newItem.guest_car_number1 = item.car_number; 
        newItem.stay_after = item.stay_after? formatByString_YYYYMMDD(item.stay_after) : '';
        newItem.stay_before = item.stay_before? formatByString_YYYYMMDD(item.stay_before) : '';
        newItem.is_leader = item.is_leader === "Y"? "여" : item.is_leader === "N"? "부" : item.is_leader;

        // 배방 정보 초기값 세팅
        newItem.room = -1;
        newItem.room_name = '';

        if (newItem.guest_employee_number) {
          rowsData.push(newItem);
        }
      });

      // 배방용 데이터
      const work_site = Number(headerStore.workSite);
      roomStore.workSite = work_site;                    // workSite 후에 header 와 동일하게 수정 필요
      roomStore.selectRow.room_work_site = work_site;    // workSite 후에 header 와 동일하게 수정 필요
      roomStore.selectRow.room_type = ROOM_TYPE_CODE.business_room_group;

      rowsData.map((data: any) => {
        data.applicant_employee_number = userInfo.employeeNumber;
        if(data.guest_gender !== 1 || data.guest_gender !== 2){
          data.guest_gender = data.guest_gender === '남' || data.guest_gender === '남자' ? 1 :  
                              data.guest_gender === '여' || data.guest_gender === '여자' ? 2 : data.guest_gender;
        }
      });

      roomStore.selectRow.guestList = rowsData;

      // setSources(rowsData);
      subStore.item.guests = rowsData;
      (fileRef.current as any).value = null;

      headerStore.isLoading = false;
    }catch ({status, data}) {
      headerStore.isLoading = false;
      (fileRef.current as any).value = null;
      if (status === 401) {
        window.location.href = '/force/signout';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      let msg = "업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 업로드에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.";
      info(msg);
    }

    // const {
    //   target: { files },
    // } = e;
    // const reader = new FileReader();
    // reader.onload = function(event: any) {
    //   const data = new Uint8Array(event.target.result);
    //   const wb: any = XLSX.read(data, { type: 'array' });
    //   const rows = Number(wb.Sheets[wb.SheetNames[0]]['!ref'].split(':')[1].match(/\d+/g)[0]);
    //   let startRow = 2;
    //   let idCount = 1;
    //   // 엑실템플릿 파일을 근거로 재 정의 필요함
    //   const cols: any = {
    //     B: 'guest_type',             // 이용자유형
    //     C: 'guest_company',          // 회사/단체명(이용자)
    //     D: 'guest_department',       // 부서(이용자)
    //     E: 'guest_employee_number',  // 사번(이용자)
    //     F: 'guest_name',             // 성명(이용자)
    //     G: 'guest_gender',           // 성별
    //     H: 'guest_cellphone',        // 휴대폰번호
    //     I: 'guest_car_number1',      // 차량번호
    //     J: 'stay_after',             // 입숙일
    //     K: 'stay_before',            // 퇴숙일
    //     L: 'is_leader',              // 인솔자여부
    //   };

    //   const rowsData = [];
    //   while(startRow < rows) {
    //     const newItem: any = {};

    //     // 배방용 임의로 만든 데이터
    //     newItem['id'] = idCount;
    //     idCount ++;

    //     Object.keys(cols).forEach((col: string) => {
    //       const field: string = cols[col];
    //       const cell = wb.Sheets[wb.SheetNames[0]][`${col}${startRow}`];
    //       if (cell) {
    //         let v = cell.v;
    //         if (col === 'J' || col === 'K') {
    //           v = cell.w.replace(/\//g, '-');
    //         }
    //         if (col === 'L') {
    //           v = v === 'Y' ? '여' : '부';
    //         }
    //         newItem[field] = v;
    //       }
    //     });
    //     newItem.room = -1;
    //     newItem.room_name = '';

    //     if (newItem.guest_employee_number) {
    //       rowsData.push(newItem);
    //     }
    //     startRow++;
    //   }
    //   // setSources(rowsData);

    //   // ----------------배방용 데이터
    //   const work_site = Number(headerStore.workSite);
    //   roomStore.workSite = work_site;                    // workSite 후에 header 와 동일하게 수정 필요
    //   roomStore.selectRow.room_work_site = work_site;    // workSite 후에 header 와 동일하게 수정 필요
    //   roomStore.selectRow.room_type = ROOM_TYPE_CODE.business_room_group;

    //   rowsData.map((data: any) => {
    //     data.applicant_employee_number = userInfo.employeeNumber;
    //     if(data.guest_gender !== 1 || data.guest_gender !== 2){
    //       data.guest_gender = data.guest_gender === '남' || data.guest_gender === '남자' ? 1 :  
    //                           data.guest_gender === '여' || data.guest_gender === '여자' ? 2 : data.guest_gender;
    //     }
    //   });

    //   roomStore.selectRow.guestList = rowsData;

    //   subStore.item.guests = rowsData;

    //   (fileRef.current as any).value = null;
    // }
    // reader.readAsArrayBuffer(files[0]);
  };

  const onSourceGridReady = (params: any) => {
    setGrid(params.api);
  }

  // 배방정보
  const cellRoomRenderer = ({data}: {data: any}) => {
    return (
      <>
        <span className="pr10 dp_ib">{data.room? data.room : ''}</span>
        <button type="button" className="btn btn_xs btn_outline">배방</button>
      </>
    );
  };

  // 배방오픈팝업
  const openRoomPop = () => {
    if(!subStore.item.guests || subStore.item.guests.length <= 0) {
      info("엑셀 업로드를 먼저 해주세요")
      return false;
    }

    let possibleNext : boolean = true;
    for(let idx=0; idx<subStore.item.guests.length; idx++) {
      if (!([subStore.item.guests[idx].guest_name, subStore.item.guests[idx].guest_cellphone, subStore.item.guests[idx].guest_gender, subStore.item.guests[idx].stay_before, subStore.item.guests[idx].stay_after].every((item) => item))) {
        info(idx+1 +'행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)');
        possibleNext = false;
        return;
      }else if(subStore.item.guests[idx].guest_gender !== 1 && subStore.item.guests[idx].guest_gender !== 2 && subStore.item.guests[idx].guest_gender !== '남' && subStore.item.guests[idx].guest_gender !== '여'){
        info(idx+1 + '행에서 성별 항목이 잘못되었습니다.');
        possibleNext = false;
        return;
      }else if(isAfterAndSame(subStore.item.guests[idx].stay_before, subStore.item.guests[idx].stay_after)) {
        info(idx+1 + '행에서 날짜가 잘못되었습니다.');
        possibleNext = false;
        return;
      }else if(leftBig(subStore.item.guests[idx].stay_after, subStore.item.guests[idx].stay_before)) {
        info(idx+1 + '행에서 날짜가 잘못되었습니다.');
        possibleNext = false;
        return;
      }
    }

    let temp:any;
    if(possibleNext){
      // ----------------배방용 데이터
      const work_site = Number(headerStore.workSite);
      roomStore.workSite = work_site;                    // workSite 후에 header 와 동일하게 수정 필요
      roomStore.selectRow.room_work_site = work_site;    // workSite 후에 header 와 동일하게 수정 필요
      roomStore.selectRow.room_type = ROOM_TYPE_CODE.business_room_group;
      temp = subStore.item.guests.map((data: any) => {
        if(data.guest_gender !== 1 || data.guest_gender !== 2){
          data.guest_gender = data.guest_gender === '남' || data.guest_gender === '남자' ? 1 :  
                              data.guest_gender === '여' || data.guest_gender === '여자' ? 2 : data.guest_gender;
        }
        return data;
      })
      // roomStore.selectRow.guestList = store.insertStore.item.guests;
      roomStore.selectRow.guestList = temp;
      roomStore.manageCheckin = true;
      roomStore.isBusinessGroupPop = true;
    }
  }

  // 그리드 데이터 담기
  const gridDataUpdate = (dataList: any) => {
    subStore.item.guests.map((data: any) => {
      if(data.id) {
        data.room = dataList[data.id].id;
        data.room_name = dataList[data.id].building_name + dataList[data.id].name;
      }
    });
  
    grid.redrawRows();
  }

  const frameworkComponents = {
    cellRoomRenderer: cellRoomRenderer,
  };

  const onDelete = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제하시겠습니까?',
        action: () => {
          const indexs: number[] = [];
          Object.keys(grid.selectionController.selectedNodes)
            .forEach(key => {
              const node = grid.selectionController.selectedNodes[key];
              if (node) {
                indexs.push(node.rowIndex);
              }
            });
          const tmps = [...sources];
          const filterData = tmps.filter((item: any, index: number) => !includes
          (indexs, index));
          subStore.item.guests = filterData;
        },
      }
    });
  };

  const onSave = async () => {
    if (sources.length === 0) {
      error('엑셀 업로드를 해주세요');
      return;
    }

    let possibleNext : boolean = true;
    for(let idx=0; idx<subStore.item.guests.length; idx++) {
      if (!([subStore.item.guests[idx].guest_name, subStore.item.guests[idx].guest_cellphone, subStore.item.guests[idx].guest_gender, subStore.item.guests[idx].stay_before, subStore.item.guests[idx].stay_after].every((item) => item))) {
        info(idx+1 +'행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)');
        possibleNext = false;
        return;
      }else if(subStore.item.guests[idx].guest_gender !== 1 && subStore.item.guests[idx].guest_gender !== 2 && subStore.item.guests[idx].guest_gender !== '남' && subStore.item.guests[idx].guest_gender !== '여'){
        info(idx+1 + '행에서 성별 항목이 잘못되었습니다.');
        possibleNext = false;
        return;
      }else if (!/[^0-9]*$/gi.test(subStore.item.guests[idx].guest_cellphone) || !(subStore.item.guests[idx].guest_cellphone.length === 11)) {
        info(idx+1 + '행에서 휴대폰번호 항목이 잘못되었습니다.');
        possibleNext = false;
        return;
      }else if(!/[^0-9]*$/gi.test(subStore.item.guests[idx].guest_employee_number)) {
        info(idx+1 + '행에서 사번이 잘못되었습니다.');
        possibleNext = false;
        return;
      }else if(isAfterAndSame(subStore.item.guests[idx].stay_before, subStore.item.guests[idx].stay_after)) {
        info(idx+1 + '행에서 날짜가 잘못되었습니다.');
        possibleNext = false;
        return;
      }else if(leftBig(subStore.item.guests[idx].stay_after, subStore.item.guests[idx].stay_before)) {
        info(idx+1 + '행에서 날짜가 잘못되었습니다.');
        possibleNext = false;
        return;
      }
    }

    let noRooms = subStore.item.guests.filter((item: any) => item.room === -1);
    if (noRooms.length > 0) {
      error('배방을 진행 해주세요');
      return;
    }
    let isLeader = subStore.item.guests.filter((item: any) => item.is_leader === 'Y' || item.is_leader === '여');
    if (isLeader <= 0) {
      error('이용자 중 인솔자는 반드시\n 1명 이상 선택되어야 합니다.\n 인솔자 여부를 확인해주세요.');
      possibleNext = false;
      return;
    }

    try {
      if(possibleNext) {   
        headerStore.isLoading = true;   
        let temp_data = subStore.item.guests;
        let data : any = await subStore.businessRoomGroupBooked();
        //goBack();
        headerStore.isLoading = false;
        setTimeout(function () {
          if(data.save) {
            info("신규등록이 완료되었습니다");
            subStore.item.guests = [];
            store.init();
            roomStore.init();
            roomStore.roomInit();
            addTabHandler('/applicant/judge');
          }else {
            if (data.data.code === "already_exists") {
              info('이미 이용중인 숙소가 있습니다.');
              return;
            }else if(data.data.code === "cannot_find_guest") {
              info('신청 가능한 사용자가 아닙니다');
              return;
            }
            
            info('등록 중 문제가 발생했습니다');
            subStore.item.guests.forEach((item: any) => {
              item.guest_type = item.guest_type === 1? '사내임직원' : 
                  item.guest_type === 2 ? '계열사임직원' : '외부인';
              item.guest_gender = item.guest_gender === 1 ? '남' : '여';
              item.is_leader = item.is_leader === 'Y'? '여' : '부';
            });
            // console.log(data.status, data.data);
            return;
          }
        }, 250);
      }
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (data.code === "already_exists") {
        info('이미 이용중인 숙소가 있습니다.');
        return;
      }else if(data.code === "cannot_find_guest") {
        info('신청 가능한 사용자가 아닙니다');
        return;
      }
      
      info('등록 중 문제가 발생했습니다');
      return;
    }
    
  }

  const compMenu = (val: string) => {
    const vals = val.split('/');
    if (vals.length > 2) {
      return vals.slice(0, 3).join('/');
    } else {
      return val;
    }
  }

  const m: { [key: string]: number } = {
    '/applicant/judge': 0
  }

  const { opened = [], select = -1 } = useSelector((state: any) => ({
    opened: state.base.tab.opened,
    select: state.base.tab.select,
  }));
  
  const DEFAULT_TAB_PATH = '/home';

  const addTabHandler = (path = DEFAULT_TAB_PATH) => {
    const idx = opened.findIndex((item: string) => compMenu(item) === compMenu(path));
    if (idx < 0) {
      dispatch({
        type: ADD_TAB,
        path,
      });
      // props.history.replace(path);
    } else {
      dispatch({
        type: CLICK_TAB,
        select: idx,
      });
    }
  };

  return (
    <>
      <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
      
      {headerStore.paramAuthRoomTypeRole.indexOf(ROOM_TYPE_CODE.business_room)>=0 && headerStore.paramAuthRoomTypeRole.indexOf(ROOM_TYPE_CODE.business_room_group)>=0 ? (
          <div className="group_tab mt30">
              <ul className="tab_lg tabs clearfix">
              <li>
                <a href="javascript:void(0);" className={isGroup() ? '' : 'on'}
                  onClick={() => {
                    subStore.item.guests = [];
                    props.history.replace(`/guest/history/${ROOM_TYPE_CODE.business_room}/insert`);
                  }}>
                  단건등록
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className={isGroup() ? 'on' : ''}>다건등록</a></li>
              </ul>
          </div>
      ):''}

        <div className="tab_contents on">
          <h4>기본정보</h4>
          <ul className="lst_status">
            <li>
              <strong>신청일자</strong>
              <span>{formatByString(getTodayDate()[0], 'yyyy.MM.dd')}</span>
            </li>
            <li>
              <strong>숙소유형</strong>
              <span>{ROOM_TYPE[store.insertRoomType]}</span>
            </li>
            <li>
              <strong>신청지역</strong>
              <span>{WORK_SITE[headerStore.workSite]}</span>
            </li>
            <li>
              <strong>배방정보</strong>
              <span className="room">
                <button type="button" className="btn btn_xs btn_darkblue" onClick={() => openRoomPop()}>배방</button>
              </span>
            </li>
            {/* <li>
              <strong>진행상태정보</strong>
              <span>신청완료</span>
            </li> */}
          </ul>

          <h4>이용자정보</h4>
          <div className="upload_area">
            <label htmlFor="input_copy">파일 업로드</label>
            <div className="wrap_file">
              <input id="input_copy" className="upload_name form_control" disabled value=""/>
              <input ref={fileRef} type="file" id="input_upload_copy" className="upload_hidden"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                onChange={onFileChange} />
              <label htmlFor="input_upload_copy" className="btn btn_gray">파일찾기</label>
            </div>
            {/* <button className="btn btn_file ml10">양식다운로드</button> */}
            <Button
                src={process.env.REACT_APP_MEDIA + "static/sample_template_group.xlsx"}
                value={"양식다운로드"}
                onlySrc
            />
          </div>

          <div className="table_normal_list light_gray">
            <div className="ag-theme-alpine" style={{height: '400px'}}>
              <AgGridReact
                //components={{datePicker: () => {}}}
                modules={AllCommunityModules}
                defaultColDef={{
                  width: 100,
                  wrapText: true,
                  resizable: true,
                  // autoHeight: true,
                  cellStyle: { textAlign: 'center', fontSize: '13px' },
                  headerClass: 'text-center',
                  editable: true,
                }}
                suppressRowTransform={true}
                suppressRowClickSelection={true}
                frameworkComponents={frameworkComponents}
                onGridReady={onSourceGridReady}
                multiSortKey={'ctrl'}
                rowSelection={'multiple'}
                singleClickEdit={true}
                rowData={sources}
              >
                <AgGridColumn 
                    field=""
                    editable={false}
                    headerCheckboxSelection={true}
                    checkboxSelection={true}
                    width= {Number(50)}
                ></AgGridColumn>
                <AgGridColumn headerName="이용자유형" field="guest_type" width={140} cellEditor="agSelectCellEditor" cellEditorParams={{ values: ['사내임직원', '계열사임직원', '외부인'] }} />
                <AgGridColumn headerName="회사/단체명" field="guest_company" width={140} />
                <AgGridColumn headerName="사번" field="guest_employee_number" width={100} />
                <AgGridColumn headerName="성명" field="guest_name" width={100} />
                <AgGridColumn headerName="성별" field="guest_gender" width={100} cellEditor="agSelectCellEditor" cellRenderer={({data}:{data:any}) => data.guest_gender === 1 ? '남' : data.guest_gender === 2 ?'여' : data.guest_gender} cellEditorParams={{ values: ['남', '여'] }} />
                <AgGridColumn headerName="휴대폰" field="guest_cellphone" width={140} />
                <AgGridColumn headerName="부서" field="guest_department" width={100} />
                <AgGridColumn headerName="인솔자여부" field="is_leader" width={100} cellEditor="agSelectCellEditor" cellEditorParams={{ values: ['여', '부'] }} />
                <AgGridColumn headerName="차량번호" field="guest_car_number1" width={100} />
                <AgGridColumn headerName="입숙예정일" field="stay_after" width={120} />
                <AgGridColumn headerName="퇴숙예정일" field="stay_before" width={120} />
                <AgGridColumn headerName="배방정보" field="room_name" editable={false} width={200} />
              </AgGridReact>
            </div>
          </div>

          <ul className="lst_help mt10">
            <li>출장자숙소 단체 등록 시 양식 다운로드 받아서 파일업로드로 업무 처리를 진행하셔야 합니다</li>
            <li>파일업로드 후 아래 업로드 내용에서 직접 수정이 가능합니다</li>
          </ul>

          <div className="submit">
            <button type="button" className="btn btn_lg btn_gray fl" onClick={() => {
              subStore.item.guests = [];
              props.history.replace('/guest/history');
            }}>목록</button>
            <div className="fr">
              <button type="button" className="btn btn_lg btn_darkblue btn_outline" onClick={onDelete}>삭제</button>
              <button type="button" className="btn btn_lg btn_darkblue" onClick={onSave}>완료</button>
            </div>
          </div>

        </div> {/* tab_content */}

        {/* 단체 배방팝업 */}
        <BusinessGroupBackroom
          //setRoomList={setRoomListData}
          gridDataUpdate={gridDataUpdate}
        />
      </DefaultTemplate>
    </>
  );
});

export default BusinessRoomGroupType;

import {action, makeObservable, observable} from 'mobx';
import { BoardStore } from './boardStore';
import * as apis from '../../apis';
import { ROOM_TYPE_CODE } from '../../constants';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class LodgedHistoryInsertStore extends BoardStore {
  // guestypes: number = 1;
  numberPeople: number = 3;
  guest_types: any[] = [];
  documents: any[] = [];
  roomType: number = -1;
  workSite: number = -1;
  isSave: boolean = false;

  // 로딩
  //loading
  headerStore : HeaderStore;

  // temp: RootStore;
  
  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    // this.temp = new RootStore();
    makeObservable(this, {
      // guest_type: observable,
      numberPeople: observable,
      guest_types: observable,
      documents: observable,
      roomType: observable,
      workSite: observable,
      isSave: observable,
      
      
      // documentFiles: computed,

      newItem: action,
      setGuestType: action,
      getGuestTypes: action,
      newVipRoom: action,
      newDormitory: action,
      newCompanyHouse: action,
      newBusinessRoomSingle: action,
      newBusinessRoomGroup: action,
      newFamilyRoom: action,
      businessRoomSingleBooked: action,
      businessRoomGroupBooked: action,
      vipRoomBooked: action,
      familyRoomBooked: action,
      dormitoryBooked: action,
      companyHouseBooked: action,
    });
  }

  getItems(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  init(): void {
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.checkeds = {};
    this.item = null;
    this.rootStore.isMultiType = false;
  }

  newItem (roomType: number) {
    this.roomType = roomType;
    switch(roomType) {
      case ROOM_TYPE_CODE.family_room: this.newFamilyRoom(this.numberPeople); break;
      case ROOM_TYPE_CODE.dormitory_room: this.newDormitory(); break;
      case ROOM_TYPE_CODE.company_room: this.newCompanyHouse(); break;
      case ROOM_TYPE_CODE.vip_room: this.newVipRoom(); break;
      case ROOM_TYPE_CODE.business_room: this.newBusinessRoomSingle(); break;
      case ROOM_TYPE_CODE.business_room_group: this.newBusinessRoomGroup(); break;
    }
    // console.log('newItem', this.roomType);
    // await this.getGuestTypes(roomType);
  }

  async getGuestTypes (roomType: number) {
    if (this.item && this.item.guest_type) {
      const payload: any = {};
      if (this.user) {
        payload.work_site = this.workSite;
      }
      payload.room_type = roomType;
      payload.guest_type = this.item.guest_type;
      payload.is_visible = 1;
      try {
        this.headerStore.isLoading = true;
        const { data }  = await apis.worksite.getDocuments(payload);
        if (data) {
          this.documents = data;
        }
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  setGuestType(guest_type: number, room_type?: number) {
    this.item.guest_type = guest_type;
    this.getGuestTypes(room_type ? room_type : this.rootStore.roomType);
  }

  newDormitory () {
    this.item = {
      work_site: this.user ? this.user.work_site : -1,
      stay_after: '',
      room: -1,
      files: [],
      guest_type: 1,
      guest_name: '',
      guest_cellphone: '',
      guest_gender: 1,
      guest_company: '',
      guest_employee_number: '',
      guest_department: '',
      guest_car_number1: '',
      guest_party: '',
    };
    // console.log(this.item);
  }

  newVipRoom () {
    this.item = {
      work_site: this.user? this.user.work_site : -1,
      stay_after: '',
      stay_before: '',
      room: -1,
      files: [],
      guest_type: 1,
      guest_name: '',
      guest_cellphone: '',
      guest_gender: 1,
      guest_company: '',
      guest_employee_number: '',
      guest_department: '',
      guest_car_number1: '',
    };
  }

  newCompanyHouse () {
    this.item = {
      work_site: this.user? this.user.work_site : -1,
      stay_after: '',
      room: '',
      guest_employee_number: '',
      guest_car_number1: '',
      guest_car_number2: '',
      guest_car_number3: '',
      is_cc: false,
      is_supported: false,
      files: [],
    };
  }

  newBusinessRoomSingle () {
    this.item = {
      work_site: this.user? this.user.work_site : -1,
      stay_after: '',
      stay_before: '',
      meal_ticket: 0,
      room: -1,
      guest_type: 1,
      guest_name: '',
      guest_cellphone: '',
      guest_gender: 1,
      guest_company: '',
      guest_employee_number: '',
      guest_department: '',
      guest_car_number1: '',
    };
  }

  newBusinessRoomGroup () {
    this.item = {
      work_site: this.user? this.user.work_site : -1,
      files: [],
      meal_ticket: 0,
      guests: [],
    };
    // {
    //   room: -1,
    //   stay_after: '',
    //   stay_before: '',
    //   guest_type: 1,
    //   guest_employee_number: '',
    //   guest_department: '',
    //   guest_name: '',
    //   guest_company: '',
    //   guest_gender: 1,
    //   guest_cellphone: '',
    //   is_leader: false,
    //   guest_car_number1: '',
    // }
  }
  
  newFamilyRoom (numberPeople: number) {
    this.numberPeople = numberPeople;
    const item: any = {
      room: -1,
      stay_after: '',
      stay_before: '',
      guests: [],
    };

    let start = 0;
    const guests = [];
    while(start < numberPeople) {
      guests.push({
        guest_name: '',
        guest_gender: 1,
        guest_cellphone: '',
        guest_relation: '',
        guest_car_number1: '',
      });
      start++;
    }
    item.guests = guests;
    this.item = item;
  };

  async businessRoomSingleBooked() {
    const data = {...this.item};
    data.work_site = this.workSite;
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.businessRoomSingleBooked(data);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async businessRoomGroupBooked() {
    this.item.work_site = this.workSite;
    this.item.guests.forEach((item: any) => {
      item.work_site = this.workSite;
      item.guest_type = item.guest_type === '사내임직원'? 1 : 
          item.guest_type === '계열사임직원' ? 2 : 3;
      //data.guest_gender = data.guest_gender === '남' ? 1 : 2;
      item.guest_gender = item.guest_gender === '남' || item.guest_gender === '남자' ? 1 :  
                  item.guest_gender === '여' || item.guest_gender === '여자' ? 2 : item.guest_gender;
      item.room_type = ROOM_TYPE_CODE.business_room_group;
      item.room_work_site = this.workSite;
      item.is_leader = item.is_leader === '여'? 'Y' : 'N';
    });
    try {
      
      this.headerStore.isLoading = true;
      //this.item.work_site=this.workSite;
      await apis.lodge.businessRoomGroupBooked(this.item);
      this.headerStore.isLoading = false;
      this.headerStore.isSaveCheck = true;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      this.headerStore.isSaveCheck = false;
      
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async vipRoomBooked() {
    const data = {...this.item};
    data.work_site = this.workSite;
    // data.guest_type = data.guest_type === '사내임직원'
    //     ? 1 : data.guest_type === '계열사임직원'
    //     ? 2 : 3;
    //data.guest_gender = data.guest_gender === '남' ? 1 : 2;
    data.files = data.files.map((file: any) => ({file: file.id, document: file.document})).filter((file: any) => file.file !== -1);
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.vipRoomBooked(data);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async familyRoomBooked() {
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.familyRoomBooked(this.item);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async dormitoryBooked() {
    const data = {...this.item};
    if(data.guest_type === 4 || data.guest_type === 1) {
      data.guest_company = "현대자동차";
    }
    data.work_site = this.workSite;
    // data.guest_type = data.guest_type === '사내임직원'
    //     ? 1 : data.guest_type === '계열사임직원'
    //     ? 2 : 3;
    //data.guest_gender = data.guest_gender === '남' ? 1 : 2;
    data.files = data.files.map((file: any) => ({file: file.id, document: file.document})).filter((file: any) => file.file !== -1);
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.dormitoryBooked(data)
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      
      if ( status === 400 ) {

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '오류',
            msg: data? (data as any).code: '오류가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async dormitoryGroupBooked() {
    // const actions: any[] = [];
    this.items.forEach(item => {
      // const data = {...item};
      item.work_site = this.workSite;
      if(typeof(item.guest_type) === "string") {
        item.guest_type = item.guest_type === '사내임직원'? 1 : 
            item.guest_type === '계열사임직원' ? 2 :
            item.guest_type === '외부인' ? 3 : 4;
      }
      //data.guest_gender = data.guest_gender === '남' ? 1 : 2;
      item.guest_gender = item.guest_gender === '남' || item.guest_gender === '남자' ? 1 :  
                          item.guest_gender === '여' || item.guest_gender === '여자' ? 2 : item.guest_gender;
      item.room_type = ROOM_TYPE_CODE.dormitory_room;
      item.room_work_site = this.workSite;
      // actions.push(apis.lodge.dormitoryBooked(data));
    });
    let payload = {
      guests : this.items
    }
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.dormitoryListBooked(payload);
      // await Promise.all(actions);
      this.init();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async companyHouseBooked() {
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.companyHouseBooked(this.item);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}
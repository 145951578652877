import React, { createContext, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import HomePage from '../pages/home';

import SigninPage from '../pages/signin';
import SignupPage from '../pages/signup';

import LodgedApplicant from '../pages/lodgedApplicant';
import LodgedGuest from '../pages/lodgedGuest';
import LodgedApplicantDetail from '../pages/lodgedApplicantDetail';
import RoomManage from '../pages/roomManage';
import LodgedCheckoutPage from '../pages/lodgedCheckout';
import LodgedCheckoutDetailPage from '../pages/lodgedCheckoutDetail';
import AccountListPage from '../pages/accountList';
import AccountDetailPage from '../pages/accountDetail';

import AuditListPage from '../pages/audit';
import AuditDetailPage from '../pages/audit/detail';
import AuditUpdatePage from '../pages/audit/update';

import LocusGuestTypePage from '../pages/locusGuestType';
import LocusGuestTypeWritPage from '../pages/locusGuestTypeWrite';
import LocusGuestTypeDetailPage from '../pages/locusGuestTypeDetail';

import LocusDocumentPage from '../pages/locusDocument';
import LocusDocumentWritPage from '../pages/locusDocumentWrite';
import LocusDocumentDetailPage from '../pages/locusDocumentDetail';

import BuildingPage from '../pages/building';

import NoticeListPage from '../pages/notice';
import NoticeDetailPage from '../pages/notice/detail';
import NoticeUpdatePage from '../pages/notice/update';

import FaqListPage from '../pages/faq';
import FaqDetailPage from '../pages/faq/detail';
import FaqUpdatePage from '../pages/faq/update';

import VocPage from "../pages/voc";
import VocDetailPage from '../pages/voc/detail';
import VocUpdatePage from '../pages/voc/update';

import InfoPage from '../pages/info';
import EstablishmentBasicInfo from '../pages/establishmentBasicInfo';

import Alert from '../components/organismas/modal/alert';
import Confirm from '../components/organismas/modal/confirm';

import UseGuideManagePage from "../pages/useGuideManage";

import LodgedHistory from "../pages/lodgedHistory";

import PledgePage from "../pages/pledgeManage";
import { useDispatch } from "react-redux";
import { SHOW_ALERT_MODAL } from "../store/modules/modal";
import LodgedHistoryDetail from "../pages/lodgedHistoryDetail";
import LodgedBoard from '../pages/lodgedBoard';
import LodgedBoardDetail from '../pages/lodgedBoardDetail';
import LodgedState from '../pages/lodgedTotal/state';
import LodgedCheckIn from '../pages/lodgedTotal/checkin';
import LodgedCheckOut from '../pages/lodgedTotal/checkout';
import LodgedCheckOutDetail from '../pages/lodgedTotal/checkoutDetail';
import LodgedCheckOutHistoryDetail from '../pages/lodgedTotal/checkoutHistoryDetail';
import LodgedCheckChange from '../pages/lodgedTotal/checkchange';


import AdminAuthManageList from '../pages/adminAuthManageList';
import AdminAuthManageDetail from '../pages/adminAuthManageDetail';
import AdminRegisterApplyManageList from '../pages/adminRegisterApplyManageList';
import MyPageInfo from "../pages/mypageInfo";
import MyPageInfoUpdate from "../pages/mypageInfo/update";
import AccountPermission from "../pages/accountPermission";
import AccountPermissionDetail from "../pages/accountPermission/detail";
import AccountApplication from "../pages/accountApplication";
import LodgedInsert from "../pages/lodgedInsert";
import ApplicantJudge from "../pages/applicantJudge";
import ApplicantJudgeDetailPage from "../pages/applicantJudgeDetail";
import DeductMeterPage from "../pages/deduct/meter";
import DeductHistoryPage from "../pages/deduct/history";
import DeductManagementPage from "../pages/deduct/management";
import DeductDormitoryPage from "../pages/deduct/dormitory";
import DeductdeductibleListPage from "../pages/deduct/deductibleList";
import LodgedCheckChangeDetail from "../pages/lodgedTotal/checkchangeDetail";
import MobileMain from "../pages/mobile/main";
import MobileCheckTotal from "../pages/mobile/lodgeTotal/total";
import MobileCheckIn from "../pages/mobile/lodgeTotal/checkin";
import MobileCheckOut from "../pages/mobile/lodgeTotal/checkout";
import MobileCheckChange from "../pages/mobile/lodgeTotal/checkchange";
import MobileVoc from "../pages/mobile/voc";
import SigninSsoPage from "../pages/signinSso";
import SmsPage from "../pages/sms";
import SmsPushPage from "../pages/sms/smsSendCreated";
import SmsDetailPage from "../pages/sms/smsSendDetail/smsSendDetail";
import SsoFail from "../pages/signinSso/ssoFail";
import NotFound from "../pages/notFound";
import ServerError from "../pages/serverError";

export interface MessageImpl {
  info(msg: string, redirectPath?: string): void
  warning(msg: string, redirectPath?: string): void
  error(msg: string, redirectPath?: string): void
}

export const MessageContext = createContext<MessageImpl>({
  info: (msg: string, redirectPath?: string) => { },
  warning: (msg: string, redirectPath?: string) => { },
  error: (msg: string, redirectPath?: string) => { },
});

export interface ApiErrorHandleImpl {
  errorHandler(err: {status: number, data: any}, defaultMsg?: string): void
}

export const ApiErrorHandleContext = createContext<ApiErrorHandleImpl>({
  errorHandler: (err: {status: number, data: any}, defaultMsg: string) => { }
});

const Routes: React.FC = () => {
  const dispatch = useDispatch();

  const info = (msg: string, redirectPath='') => {
    dispatch({
      type: SHOW_ALERT_MODAL,
      payload: {
        title: '',
        msg,
        redirectPath: redirectPath? redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'info',
        isModalShow: true,
      },
    });
  };
  const warning = (msg: string, redirectPath='') => {
    dispatch({
      type: SHOW_ALERT_MODAL,
      payload: {
        title: '',
        msg,
        redirectPath: redirectPath? redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'warning',
        isModalShow: true,
      },
    });
  };
  const error = (msg: string, redirectPath='') => {
    dispatch({
      type: SHOW_ALERT_MODAL,
      payload: {
        title: '',
        msg,
        redirectPath: redirectPath? redirectPath: false ,
        statusCode: false,
        errorCode: '',
        type: 'error',
        isModalShow: true,
      },
    });
  };

  const errorHandler = (err: {status: number, data: any}, defaultMsg="서버에 문제가 있습니다.") => {
    // console.log(err);
    if (err.status === 400) {
      const msg = Object.keys(err.data).map(key => `${err.data[key].join('')}`).join('\n');
      error(msg);
      return;
    }

    error(defaultMsg)
  }

  const messaageContextValue = useMemo(() => ({
    info,
    warning,
    error
  }), [SHOW_ALERT_MODAL]);

  const apiErrorHandlerValue = useMemo(() => ({
    errorHandler
  }), [SHOW_ALERT_MODAL])

  return (
    <MessageContext.Provider value={messaageContextValue} >
      <ApiErrorHandleContext.Provider value={apiErrorHandlerValue}>
        <Switch>
          <Route path="/" exact component={SigninPage}  /> {/* 로그인 페이지 */}
          <Route path="/single/signin" exact component={SigninSsoPage}  /> {/* 로그인 페이지 */}
          <Route path="/single/signin/fail" exact component={SsoFail}  /> {/* 로그인 실패 페이지 */}
          
          <Route path="/user/signup" exact component={SignupPage} /> {/* 회원가입 페이지 */}

          <Route path="/home" exact component={HomePage} />
          {/*<Route path="/lodged/applicant" exact component={LodgedApplicant} />*/}         {/* 신청관리-입숙신청현황 리스트 */}
          <Route path="/lodged/guest" exact component={LodgedGuest} />                 {/* 신청관리-이용내역 리스트 */}
          <Route path="/lodged/checkout" exact component={LodgedCheckoutPage} />                 {/* 신청관리-퇴숙내역 리스트 */}
          {/*<Route path="/lodged/applicant/:bookingId/detail" exact component={LodgedApplicantDetail} />*/}     {/* 신청관리-입숙신청현황 상세 */} 
          <Route path="/lodged/guest/detail" exact component={LodgedApplicantDetail} />         {/* 신청관리-이용내역현황 상세 */}
          {/*<Route path="/lodged/checkout/:guestId/detail" exact component={LodgedCheckoutDetailPage} />*/}       {/* 신청관리-퇴숙확인 상세 */}
          <Route path="/manage/room" exact component={RoomManage} />                   {/* 방 현황 */}

          <Route path="/applicant/judge" exact component={ApplicantJudge} />     {/* 심사 및 입숙처리 */}
          <Route path="/applicant/judge/:bookingId/detail" exact component={ApplicantJudgeDetailPage} />     {/* 심사 및 입숙처리 */}
          
          <Route path="/locus/guest/guide" exact component={UseGuideManagePage} />                   {/* 이용안내 관리 */}
          <Route path="/locus/guest/pledge" exact component={PledgePage} />                   {/* 입숙서약서 관리 */}
          <Route path="/manage/member" exact component={AccountListPage} />                   {/* 고객관리 - 회원현황 */}
          <Route path="/manage/member/detail" exact component={AccountDetailPage} />                   {/* 고객관리 - 회원현황 상세 */}

          <Route path="/locus/guest/type" exact component={LocusGuestTypePage} />                   {/* 현장관리 - 이용자이용가능 범위설정 목록 */}
          <Route path="/locus/guest/type/write" exact component={LocusGuestTypeWritPage} />                   {/* 현장관리 - 이용자이용가능 범위설정 등록 */}
          <Route path="/locus/guest/type/:id/detail" exact component={LocusGuestTypeDetailPage} />                   {/* 현장관리 - 이용자이용가능 범위설정 상세/수정 */}

          <Route path="/locus/guest/document" exact component={LocusDocumentPage} />                   {/* 현장관리 - 이용자이용가능 범위설정 목록 */}
          <Route path="/locus/guest/document/write" exact component={LocusDocumentWritPage} />                   {/* 현장관리 - 이용자이용가능 범위설정 등록 */}
          <Route path="/locus/guest/document/:id/detail" exact component={LocusDocumentDetailPage} />                   {/* 현장관리 - 이용자이용가능 범위설정 상세/수정 */}

          <Route path="/manage/notice" exact component={NoticeListPage} />                   {/* 공지관리 */}
          <Route path="/manage/notice/create" exact component={NoticeUpdatePage} />                   {/* FAQ관리  상세*/}
          <Route path="/manage/notice/:id" exact component={NoticeDetailPage} />                   {/* FAQ관리  상세*/}
          <Route path="/manage/notice/:id/update" exact component={NoticeUpdatePage} />                   {/* FAQ관리  상세*/}

          <Route path="/manage/faq" exact component={FaqListPage} />                   {/* FAQ관리 */}
          <Route path="/manage/faq/create" exact component={FaqUpdatePage} />                   {/* FAQ관리  상세*/}
          <Route path="/manage/faq/:id" exact component={FaqDetailPage} />                   {/* FAQ관리  상세*/}
          <Route path="/manage/faq/:id/update" exact component={FaqUpdatePage} />                   {/* FAQ관리  상세*/}

          <Route path="/guest/deductible/meter" exact component={DeductMeterPage} />                   {/* 검침량 */}
          <Route path="/guest/deductible/history" exact component={DeductHistoryPage} />                   {/* 공제이력 : 사용 X */}
          <Route path="/guest/deductible/management" exact component={DeductManagementPage} />                   {/* 당월공제자관리 */}
          <Route path="/guest/deductible/dormitory" exact component={DeductDormitoryPage} />                   {/* 출장자숙소 공제 */}
          <Route path="/guest/deductible/deductibleList" exact component={DeductdeductibleListPage} />  {/*공제내역 조회 */}
          
          
          <Route path="/guest/audit" exact component={AuditListPage} />                   {/* 정기서류 검사 */}
          <Route path="/guest/audit/create" exact component={AuditUpdatePage} />                   {/* 정기서류  생성*/}
          <Route path="/guest/audit/:id" exact component={AuditDetailPage} />                   {/* 정기서류 상세*/}
          <Route path="/guest/audit/:id/update" exact component={AuditUpdatePage} />                   {/* 정기서류  상세*/}

          <Route path="/lodged/maintenance" exact component={VocPage} />                   {/* VoC관리 */}
          <Route path="/lodged/maintenance/create" exact component={VocUpdatePage} />                   {/* FAQ관리  상세*/}
          <Route path="/lodged/maintenance/:id" exact component={VocDetailPage} />                   {/* FAQ관리  상세*/}
          <Route path="/lodged/maintenance/:id/update" exact component={VocUpdatePage} />                   {/* FAQ관리  상세*/}

          <Route path="/manage/sms" exact component={SmsPage} />                   {/* 문자발송관리 */}
          <Route path="/manage/sms/push" exact component={SmsPushPage} />              {/*문자발송관리*/}
          <Route path="/manage/sms/detail" exact component={SmsDetailPage} />

          <Route path="/lodged/basicinfo" exact component={BuildingPage} />                   {/* 숙소관리 (기본 정보 설정) */}

          <Route path="/my/info" exact component={InfoPage} />                   {/* 개인정보 */}

          <Route path="/establish/bacisinfo" exact component={EstablishmentBasicInfo} /> {/* 사업장관리 - 기본정보 */}

          <Route path="/guest/history" exact component={LodgedHistory} />    {/* 입숙자 관리 - 입숙자 현황 및 이력 */}
          <Route path="/guest/history/:id/detail" exact component={LodgedHistoryDetail} />    {/* 입숙자 관리 - 입숙자 현황 및 이력 상세 */}
          <Route path="/guest/history/:id/insert" exact component={LodgedInsert} />    {/* 입숙자 관리 - 신규등록 단건 */}

          <Route path="/guest/board" exact component={LodgedBoard} /> {/* 입숙자관리 - 입숙자현황표 */}
          <Route path="/guest/board/detail" exact component={LodgedBoardDetail} /> {/* 입숙자관리 - 입숙자현황표 상세*/}
          
          <Route path="/guest/status/setting" exact component={LodgedState} /> {/* 입숙, 퇴숙, 이숙 현황 - 현황 및 설정*/}
          <Route path="/guest/status/checkin" exact component={LodgedCheckIn} /> {/* 입숙, 퇴숙, 이숙 현황 - 입숙 리스트*/}
          <Route path="/guest/status/checkout" exact component={LodgedCheckOut} /> {/* 입숙, 퇴숙, 이숙 현황 - 퇴숙 리스트*/}
          <Route path="/guest/status/checkout/detail" exact component={LodgedCheckOutDetail} /> {/* 입숙, 퇴숙, 이숙 현황 - 퇴숙 상세보기*/}
          <Route path="/guest/status/checkout/history/detail" exact component={LodgedCheckOutHistoryDetail} /> {/* 입숙, 퇴숙, 이숙 현황 - 퇴숙 상세보기*/}
          <Route path="/guest/status/checkchange" exact component={LodgedCheckChange} /> {/* 입숙, 퇴숙, 이숙 현황 - 이숙 리스트*/}
          <Route path="/guest/status/checkchange/detail" exact component={LodgedCheckChangeDetail} /> {/* 입숙, 퇴숙, 이숙 현황 - 이숙 상세*/}
          
          <Route path="/admin/manage_auth" exact component={AdminAuthManageList} /> {/* 관리자관리 - 권한관리*/}
          <Route path="/admin/manage_auth/:id/detail" exact component={AdminAuthManageDetail} /> {/* 관리자관리 - 권한관리*/}

          <Route path="/admin/regist_apply" exact component={AdminRegisterApplyManageList} /> {/* 관리자관리 - 관리자계정관리*/}

          <Route path="/mypage/info" exact component={MyPageInfo} /> {/* 마이페이지 - 개인정보 */}
          <Route path="/mypage/info/update" exact component={MyPageInfoUpdate} /> {/* 마이페이지 - 개인정보수정 */}

          <Route path="/account/permission" exact component={AccountPermission} /> {/* 계정관리 - 계정 및 권한 관리 */}
          <Route path="/account/permission/detail" exact component={AccountPermissionDetail} /> {/* 계정관리 - 계정 및 권한 관리 상세 */}
          <Route path="/account/application" exact component={AccountApplication} /> {/* 계정관리 - 계정 신청 현황 */}

          
          {/* 모바일 */}
          <Route path="/mobile/main" exact component={MobileMain} />    {/* 모바일 - 메인 */}
          <Route path="/mobile/guest/checkTotal" exact component={MobileCheckTotal} />   {/* 모바일 - 입숙현황 */}
          <Route path="/mobile/guest/checkin" exact component={MobileCheckIn} />      {/* 모바일 - 입숙처리 */}
          <Route path="/mobile/guest/checkout" exact component={MobileCheckOut} />    {/* 모바일 - 퇴숙처리 */}
          <Route path="/mobile/guest/checkchange" exact component={MobileCheckChange} />   {/* 모바일 - 이숙처리 */}
          <Route path="/mobile/lodged/maintenance" exact component={MobileVoc} />      {/* 모바일 - 유지운영리스트 */}

          <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={ServerError} />
          <Route exact path="*" component={NotFound} />
          
        </Switch>
        <Alert />
        <Confirm />
      </ApiErrorHandleContext.Provider>
    </MessageContext.Provider>
  );
}
export default Routes;

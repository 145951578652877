import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Sms {
    static URL = `${IP}`;


// 문자 발송 리스트
  @apiHandler()
  static async smsList(payload: any | null){
    const PATH = `/checkin/smslist/`

    return await axios.get(`${Sms.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  @apiHandler()
  static async getUsers(payload: any) {
    const PATH = '/admin/auth/user/';
    
    return await axios.get(`${Sms.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }
  //문자발송
  @apiHandler()
  static async smsSend(payload: any) {
    const PATH = '/checkin/smslist/';

    return await axios.post(`${Sms.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  //문자발송 수정
  @apiHandler()
  static async smsSendUpDate(id:number, payload: any) {
    const PATH = `/checkin/smslist/${id}/`;

    return await axios.put(`${Sms.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }


  //문자발송 삭제
  @apiHandler()
  static async smsSendDelete(id:number) {
    const PATH = `/checkin/smslist/${id}/`;

    return await axios.delete(`${Sms.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  //MAIL발송
  @apiHandler()
  static async mailSend(payload: any) {
    const PATH = '/member/lodge/sendmail/';

    return await axios.post(`${Sms.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }
}

export default Sms;
import React, { useState, useEffect } from 'react';

import { Provider } from 'react-redux';

import intl from 'react-intl-universal';
import locales from './locales';
import { getSelectedLang } from './utils/locales';

import { BrowserRouter } from "react-router-dom"

import store, { root } from './store';
import { RootStore } from './store/mobx/rootStore';

import Routes from "./routes";

const rootStore = new RootStore();
(window as any).store = store

function App() {
  const [initDone, setInitDome] = useState<boolean>(false);

  useEffect(() => {
    intl
      .init({
        currentLocale: getSelectedLang(),
        locales,
      })
      .then(() => {
        setInitDome(true);
      });
  }, []);

  if (!initDone) return <></>;

  return (
    <>
      <root.StoreProvider value={ rootStore }>
        <Provider store={ store }>
          <BrowserRouter>
            <Routes/>
          </BrowserRouter>
        </Provider>
      </root.StoreProvider>
    </>
  );
}

export default App;

import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ConfirmBtn from '../../../components/atoms/button/confirm';
import DefaultTemplate from '../../../components/templetes/default';
import { ADMIN_TYPE, BUILDINGG, ROOM_TYPE, WORK_SITE } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import './index.scss';

const AccountPermissionDetail = observer((props: any) => {
    const {accountPermissionStore: store} = useStores();
    const {headerStore: headerStore} = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const { info, warning, error }: MessageImpl = useContext(MessageContext); //메세지 핸들러
    const [workList, setWorkList] = useState<any>([]);
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            // 권한 부여 영역 데이터 그리기
            store.setAuthList();
        })();
        return () => {
            if (store.findTabs('/account/permission/detail') < 0) {
              store.destory();
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [reload]);

    // 상세 데이터 가져오기
    const fetchData = async() => {
        try {
            headerStore.isLoading = true;
            headerStore.getAuthMenuRole('6-1');
            headerStore.getAuthBuilding();
            headerStore.getAuthRoomRole();
            // worksite 접근가능한 리스트
            await headerStore.getAuthWorkSiteRole();
            setWorkList(headerStore.authWorkSiteLen[0]);

            store.work_site = headerStore.authWorkSiteLen[0][0];

            if(store.clickData.employee_number){
                // 상세 데이터  
                await store.getAdmin(store.clickData.employee_number);
                // 권한 상세 가져와서 뿌리기
                await store.getItem(store.clickData.employee_number);
            }else {
                if(userInfo.classification !== "HEC" && userInfo.classification !== "HMC" && Number(store.work_site) === 2) {
                    // 로그인한 사용자가 남양소속 etc인 경우 : 본인의 건물 권한만 넣기
                    let authBuild: any = headerStore.paramAuthBuildingRole.replace(/\"/gi,"").split(",");
                    const builTemp: any = { };
                    authBuild.map((data: any) => {
                        if(data === BUILDINGG[1]){
                            builTemp[1] = data;
                        }else if(data === BUILDINGG[2]) {
                            builTemp[2] = data;
                        }
                        store.buildingCheckeds = builTemp;
                    });
                    if (Object.keys(builTemp).length === Object.keys(store.buildingList).length) {
                        store.buildingAllCheckFlg = true;
                    } else {
                        store.buildingAllCheckFlg = false;
                    }
                }
            }
            setReload(true);
            headerStore.isLoading = false;
        } catch (error) {
            headerStore.isLoading = false;
            // console.log(error);
            const { status, data } = error;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                    },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
        }
    }

    // 사업장 체크박스 전체
    const workAllCheckControl = (e: any) => {
        store.workCheckAll(e.target.checked);
    }

    // 사업장 체크박스 단건
    const workOnCheckControl = (e: any, work: any) => {
        store.workCheckItem(e.target.checked, work);
    }

    // 숙소 체크박스 전체
    const roomAllCheckControl = (e: any) => {
        store.roomCheckAll(e.target.checked);
    }

    // 숙소 체크박스 단건
    const roomOnCheckControl = (e: any, room: any) => {
        store.roomCheckItem(e.target.checked, room);
    }

    // 메뉴 체크박스 전체
    const menuAllCheckControl = (e: any) => {
        store.menuCheckAll(e.target.checked);
    }

    // 메뉴 체크박스 단건
    const menuOnCheckControl = (e: any, menu: any) => {
        store.menuCheckItem(e.target.checked, menu);
    }

     // 메뉴 active 체크박스 단건
     const subMenuOnCheckControl = (e: any, menu: any, sub: any) => {
        store.menuSubCheckItem(e.target.checked, menu, sub);
    }

    // 건물 체크박스 전체
    const buildAllCheckControl = (e: any) => {
        store.buildCheckAll(e.target.checked);
    }

    // 건물 체크박스 단건
    const buildOnCheckControl = (e: any, build: any) => {
        store.buildCheckItem(e.target.checked, build);
    }

    // 관리자 권한 체크
    const adminCodeCheck = (e: any, key: string) => {
        if(!e.target.checked && store.adminCode === key){
            store.adminCode = '';
        }else if(e.target.checked){
            store.adminCode = key;
        }
    }

    // 중복확인 버튼
    const checkEmployeeNum = async() =>{
        try {
            headerStore.isLoading = true;
            if(store.employeeNum){
                let data : any = await store.checkEmployeeNum(store.employeeNum);

                setTimeout(function () {
                    if(data.save) {
                        if(store.numFlag) {
                            info("사용가능한 관리번호(아이디)입니다");
                        }else {
                            info("이미 사용중인 관리번호(아이디)가 있습니다");
                            store.employeeNum = '';
                        }
                    }else {
                      return;
                    }
                }, 250);
            }
            headerStore.isLoading = false;
        } catch (error) {
            headerStore.isLoading = false;
            // console.log(error)
            const { status, data } = error;

            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
        }
    }

    const compareStr = (str1: any, str2: any) => {
        // console.log(str1, str2)
        // console.log(str1, str2);
        let result = (''+str1) > (''+str2)

        return result;
    }   

    // 저장버튼
    const authSave = async() => {
        try {
            if(!store.employeeNum || !store.name || !store.cellphone) {
                info("관리자 정보를 입력해주세요");
                return false;
            }else if(store.employeeNum && !store.numFlag){
                info("관리번호(아이디) 중복확인 해주세요");
                return false;
            }else if(store.cellphone.length !== 11) {
                info("휴대폰 번호를 확인해주세요");
                return false;
            }
            
            headerStore.isLoading = true;
            if(store.clickData.id) {
               // 수정
               store.mEmployeeNum = userInfo.employeeNumber;
               let data: any = await store.adminUpdate(store.clickData.id);
               headerStore.isLoading = false;
               setTimeout(function () {
                    if(data.save) {
                        info("계정이 수정되었습니다.");
                        props.history.replace('/account/permission');
                        store.init(); 
                    }else {
                        info("관리자 등록 중 문제가 발생하였습니다.");
                        return;
                    }
                }, 250);
            }else { 
                // 등록
                store.mEmployeeNum = userInfo.employeeNumber;
                let data: any = await store.adminSave();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        info("계정이 등록되었습니다.")
                        props.history.replace('/account/permission');
                        store.init(); 
                    }else {
                        info("관리자 등록 중 문제가 발생하였습니다.");
                        return;
                    }
                }, 250);
            }
            headerStore.isLoading = false;
        } catch (error) {
            headerStore.isLoading = false;

            const { status, data } = error;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                    },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
        
            info("관리자 등록 중 문제가 발생하였습니다.")
            return;
        }
    }

    // 삭제
    const adminDelete = async() => {
        try {
            headerStore.isLoading = true;
            let data: any = await store.adminDelete(store.clickData.id);
            headerStore.isLoading = false;

            setTimeout(function () {
                if(data.save) {
                    info("삭제되었습니다.")
                    props.history.replace('/account/permission');
                    store.init();
                }else {
                    info("관리자 삭제 중 문제가 발생하였습니다.");
                    return;
                }
            }, 250);
        } catch (error) {
            headerStore.isLoading = false;
            // console.log(error);
            const { status, data } = error;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }
        
            info("관리자 삭제 중 문제가 발생하였습니다.")
            return;
        }
    }
    
    // 등록, 수정 가능한 사람인지 체크 
    const updateAdminYN = (classif: string) => {
        if(classif === "HEC") {
            return true;
        }else if(classif === "HMC" && store.adminCode !== "HEC") {
            return true;
        }else if(classif === "ETC" && (!store.adminCode || store.adminCode === "ETC")) {
            return true;
        }

        return false;
    }

    return (
    <>
        <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
        >
        <div className="tab_contents on">
        <h4>관리자정보</h4>
        <div className="table_normal_list table_write">
            <table>
                <caption className="sr_only">관리내역</caption>
                <colgroup>
                    <col width="15%"/>
                    <col width="*"/>
                    <col width="15%"/>
                    <col width="*"/>
                    <col width="15%"/>
                    <col width="*"/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>관리번호(아이디)</th>
                        <td>
                            <div className="form_wrap">
                                <input type="text"
                                    className="form_control"
                                    maxLength={7}
                                    value={store.employeeNum}
                                    onChange={(e:any) => {
                                        store.employeeNum = getEscapeString(e.target.value, INPUT_TYPE.employee);
                                    }}
                                    disabled={store.clickData.employee_number? true: false}
                                />
                                {store.clickData.employee_number ? '' :(
                                    <button className="btn_sm btn_gray" onClick={(e:any) => checkEmployeeNum()}>중복확인</button>
                                )}
                            </div>
                        </td>
                        <th>성명</th>
                        <td>
                            <input type="text" className="form_control" maxLength={10} 
                                value={store.name} onChange={(e:any) => store.name = e.target.value} 
                                disabled={store.clickData.employee_number? true: false}
                            />
                        </td>
                        <th>생년월일</th>
                        <td>
                            <input type="text" className="form_control" maxLength={6} 
                                value={store.birthday} 
                                onChange={(e:any) => store.birthday =  getEscapeString(e.target.value, INPUT_TYPE.phoneNum)} 
                                disabled={store.clickData.employee_number? true: false}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>성별</th>
                        <td>
                            <label className="input_radio" htmlFor="sex_user_choice1">
                                <input type="radio" id="sex_user_choice1" name="sex" 
                                    checked={store.gender === 1 ? true : false} 
                                    onChange={(e:any) => store.genderType = 1}
                                    onClick={() => store.gender = 1}
                                    // disabled={store.clickData.employee_number? true: false}
                                />
                                <span className="label_text">남자</span>
                            </label>
                            <label className="input_radio" htmlFor="sex_user_choice2">
                                <input type="radio" id="sex_user_choice2" name="sex" 
                                    checked={store.gender === 2 ? true : false} 
                                    onChange={(e:any) => store.genderType = 2}
                                    onClick={() => store.gender = 2}
                                    // disabled={store.clickData.employee_number? true: false}
                                />
                                <span className="label_text">여자</span>
                            </label>
                        </td>
                        <th>휴대폰번호</th>
                        <td>
                            <input type="text" className="form_control" maxLength={11} 
                                value={store.cellphone} 
                                onChange={(e:any) => store.cellphone = getEscapeString(e.target.value, INPUT_TYPE.phoneNum)}
                            />
                        </td>
                        <th>소속회사</th>
                        <td><input type="text" className="form_control" maxLength={20} 
                            value={store.company} 
                            onChange={(e:any) => store.company = e.target.value}
                            disabled={store.clickData.employee_number? true: false}
                        />
                        </td>
                    </tr>
                    <tr>
                        <th>근무지</th>
                        <td>
                            <select className="form_control" 
                                value={store.work_site} 
                                onChange={(e:any) => store.work_site = e.target.value} 
                                disabled={store.clickData.employee_number? true: false}
                            >
                                {/* {Object.keys(WORK_SITE).map((key: string, index: number) => {
                                    return (
                                        <option key=index} value={key}>
                                            {WORK_SITE[Number(key)]}
                                        </option>)
                                })} */}
                                {workList && workList.map((w: any, idx: number) => {
                                    return (
                                        <option key={idx} value={w}>
                                            {WORK_SITE[Number(w)]}
                                        </option>
                                    )
                                })}
                            </select>
                        </td>
                        <th>담당업무</th>
                        <td className="br0"><input type="text" maxLength={20} className="form_control" value={store.department} onChange={(e:any) => store.department = e.target.value}/></td>
                        <th>활성여부</th>
                        <td className="br0">
                        <select className="form_control" 
                            value={store.activeYN} 
                            onChange={(e:any) => store.activeYN = e.target.value}
                            disabled={store.clickData.employee_number && store.clickData.progress !== 1? false : true}
                        >
                            {/* <option value="">선택</option> */}
                            <option value="1">활성</option>
                            <option value="2">비활성</option>
                        </select>
                        </td>
                    </tr>
                </tbody>
            </table>                
        </div>

        <h4>담당자 권한 부여</h4>
        <div className="allcheck_area">
            {store.clickData.employee_number ? (
                store.clickData.classification !== '' ? (
                    <label className="input_check">
                        <span className="">{ADMIN_TYPE[store.clickData.classification]} 관리자</span>
                    </label>
                ) :
                (
                    <label className="input_check">
                        <span className="">일반 관리자</span>
                    </label>
                )
            ): (
                userInfo.classification === "HEC" ? (
                    Object.keys(ADMIN_TYPE).map((key: string, index: number) => (
                        <label className="input_check" key={index} style={{'display' : !store.clickData.employee_number && key === 'HMC' ? 'none' : ''}}>
                            <input type="checkbox" 
                                onChange={(e:any) => {adminCodeCheck(e, key)}}
                                checked={store.adminCode === key? true: false}
                                // disabled={store.clickData.employee_number && store.adminCode === 'HMC'}
                            />
                            <span className="label_text">{ADMIN_TYPE[key]}</span>
                        </label>
                    ))
                ):(
                    userInfo.classification === "HMC" ? (
                        Object.keys(ADMIN_TYPE).map((key: string, index: number) => 
                            key !== "HEC" ? (
                                <label className="input_check" key={index} style={{'display' : !store.clickData.employee_number && key === 'HMC' ? 'none' : ''}}>
                                    <input type="checkbox" 
                                        onChange={(e:any) => {adminCodeCheck(e, key)}}
                                        checked={store.adminCode === key? true: false}
                                        // disabled={store.clickData.employee_number && store.adminCode === 'HMC'}
                                    />
                                    <span className="label_text">{ADMIN_TYPE[key]}</span>
                                </label>
                            ) : ('')
                        )
                    ) : (
                        ''
                    )
                )
            )}
        </div>
        
        <div className="lst_charge">
            {/* 사업장 관리는 HEC, HMC만 가능 */}
            {(userInfo.classification === "HEC")? (
                <div className="box_outer">
                    <div className="box_inner">
                        <dl className="lst_items">
                            <dt>사업장 권한(관리자관리만)</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <label className="input_check">
                                            <input type="checkbox" onChange={workAllCheckControl} checked={store.workSiteAllCheckFlg}/>
                                            <span className="label_text">전체</span>
                                        </label>
                                    </li>
                                    {store.workSiteList ? Object.keys(store.workSiteList).map((work: any, index: number) => (
                                        <li key={index}>
                                            <label className="input_check">
                                                <input type="checkbox" 
                                                    onChange={(e:any) => {workOnCheckControl(e, work)}}
                                                    checked={store.workSiteCheckds[work]? true: false}
                                                />
                                                <span className="label_text">{store.workSiteList[work]['name']}</span>
                                            </label>
                                        </li>
                                    )) : ''}
                                </ul>
                            </dd>
                        </dl>
                    </div>
                </div>
            ):''}
            <div className="box_outer">
                <div className="box_inner">
                    <dl className="lst_items">
                        <dt>숙소 권한</dt>
                        <dd>
                            <ul>
                                <li>
                                    <label className="input_check">
                                        <input type="checkbox" onChange={roomAllCheckControl} checked={store.roomAllCheckFlg}/>
                                        <span className="label_text">전체</span>
                                    </label>
                                </li>
                                {store.roomList ? Object.keys(store.roomList).map((room: any, index: number) => (
                                    <li key={index}>
                                        <label className="input_check">
                                            <input type="checkbox" 
                                                onChange={(e:any) => {roomOnCheckControl(e, room)}}
                                                checked={store.roomCheckeds[room]? true: false}
                                            />
                                            <span className="label_text">{store.roomList[room]['name']}</span>
                                        </label>
                                    </li>
                                )) : ''}
                            </ul>
                        </dd>
                    </dl>
                </div>  {/*box_inner*/}
            </div>  {/*box_outer*/}
            <div className="box_outer">
                <div className="box_inner">
                    <dl className="lst_items">
                        <dt>메뉴 권한</dt>
                        <dd>
                            <ul>
                                <li>
                                    <label className="input_check">
                                        <input type="checkbox"  onChange={menuAllCheckControl} checked={store.menuAllCheckFlg}/>
                                        <span className="label_text">전체</span>
                                    </label>
                                </li>
                                {store.menuList ? Object.keys(store.menuList).map((menu: any, index: number) => (
                                    <li key={index}>
                                        <div className="check_all">
                                            <label className="input_check">
                                                <input type="checkbox"
                                                       onChange={(e:any) => {menuOnCheckControl(e, store.menuList[menu])}}
                                                       checked={store.menuCheckeds[menu]? true: false}
                                                />
                                                <span className="label_text">{store.menuList[menu]['name']}</span>
                                            </label>
                                        </div>

                                        <div className="cheak_unit">
                                            {store.menuList[menu]['sub'] ? 
                                                Object.keys(store.menuList[menu]['sub']).map((sub: any, subIdx: number) => (
                                                <label className="input_check" key={subIdx}>
                                                    <input type="checkbox"
                                                           onChange={(e:any) => {subMenuOnCheckControl(e, store.menuList[menu]['key'], sub)}}
                                                           checked={store.menuCheckeds[menu] && store.menuCheckeds[menu]['auth']? store.menuCheckeds[menu]['auth'][sub]? true : false : false}
                                                    />
                                                    <span className="label_text">{store.menuList[menu]['sub'][sub]}</span>
                                                        {subIdx === 2? <p/> : ''}
                                                </label>
                                                )) : 
                                                ('')
                                            }
                                        </div>

                                    </li>
                                )) : ''}
                            </ul>
                        </dd>
                    </dl>
                </div>  {/*box_inner*/}
            </div>  {/*box_outer*/}
            {store.workSiteCheckds[2] || (userInfo.classification !== "HEC" && Number(store.work_site) === 2)? (
                <div className="box_outer">
                    <div className="box_inner">
                        <dl className="lst_items">
                            <dt>건물(남양만) 권한</dt>
                            <dd>
                                <ul>
                                    <li style={{backgroundColor: userInfo.classification !== "HEC" && userInfo.classification !== "HMC"? '#f8f8f8' :''}}>
                                        <label className="input_check">
                                            <input 
                                                type="checkbox" 
                                                onChange={buildAllCheckControl} 
                                                checked={store.buildingAllCheckFlg}
                                                disabled={userInfo.classification !== "HEC" && userInfo.classification !== "HMC"}
                                            />
                                            <span className="label_text"
                                                style={{cursor: userInfo.classification !== "HEC" && userInfo.classification !== "HMC"? 'default' :''}}
                                            >전체</span>
                                        </label>
                                    </li>
                                    {store.buildingList ? Object.keys(store.buildingList).map((build: any, index: number) => (
                                        <li key={index} style={{backgroundColor: userInfo.classification !== "HEC" && userInfo.classification !== "HMC"? '#f8f8f8' :''}}>
                                            <label className="input_check">
                                                <input type="checkbox" 
                                                    onChange={(e:any) => {buildOnCheckControl(e, store.buildingList[build]);}}
                                                    checked={store.buildingCheckeds[build]? true: false}
                                                    disabled={userInfo.classification !== "HEC" && userInfo.classification !== "HMC"}
                                                />
                                                <span className="label_text"
                                                    style={{cursor: userInfo.classification !== "HEC" && userInfo.classification !== "HMC"? 'default' :''}}
                                                >{store.buildingList[build]['name']}</span>
                                            </label>
                                        </li>
                                    )) : ''}
                                </ul>
                            </dd>
                        </dl>
                    </div> 
                </div>
            ) :''}
        </div>  {/*lst_charge*/}

        <div className="submit">
            <div className="fl">
                <button className="btn btn_lg btn_gray" onClick={() => {store.init(); props.history.replace('/account/permission');}}>목록</button>
            </div>
            {ADMIN_TYPE[userInfo.classification] && updateAdminYN(userInfo.classification)? (
                <div className="fr">
                    <ConfirmBtn
                        className="btn btn_lg btn_outline"
                        action={() => {store.init(); props.history.replace('/account/permission');}}
                        label="취소"
                        title=""
                        msg={"취소하시겠습니까?"}
                    />
                    {store.clickData.employee_number && headerStore.writeRoleFlag ?(
                            <>
                            <ConfirmBtn
                                className="btn btn_lg btn_darkblue"
                                action={() => {authSave();}}
                                label="수정"
                                title=""
                                msg={"수정하시겠습니까?"}
                            />
                            </>
                    ) :''}
                    {store.clickData.employee_number && headerStore.deleteRoleFlag ?(
                            <ConfirmBtn
                                className="btn btn_lg btn_darkblue"
                                action={() => {adminDelete()}}
                                label="삭제"
                                title=""
                                msg={"삭제하시겠습니까?"}
                            />
                    ) :''}
                    {!store.clickData.employee_number && headerStore.writeRoleFlag ?(
                        <>
                        <ConfirmBtn
                            className="btn btn_lg btn_darkblue"
                            action={() => {authSave();}}
                            label="등록"
                            title=""
                            msg={"등록하시겠습니까?"}
                        />
                        </>
                    ) :''}
                </div>
            ): ''}
        </div>
        </div>
        </DefaultTemplate>
    </>
    );
});

export default AccountPermissionDetail;

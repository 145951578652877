import React, {useState, useEffect, useContext} from 'react';
import { isMobile } from 'react-device-detect';
import { core } from '../../../apis';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../../routes';
import { useSelector } from 'react-redux';

export interface Document {
  id: number
  name: string
  is_required: true
  visible_type: number
}

export interface File {
  id: number,
  path: string, // 실제 서버경로
  size: string,
  name: string  // 업로드 했을 때 파일명
}

export interface UploadedFile {
  file: File
  document: Document // document는 관리자가 [현장관리] - [입숙서류 설정]에서 삭제시 null이 나옴
}

export interface Props {
  value: UploadedFile[]
  category?: number
  downFlag : any
}

const FileForm = (props: Props) => {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const { userInfo = {} } = useSelector((state: any) => ({
      userInfo: state.user
  }));

  useEffect(() => {
    let temp: UploadedFile[] = []
    
    if (props.category) {
      temp = props.value.filter((file: UploadedFile) => file?.document?.visible_type === props.category)
    } else {
      temp = props.value
    }
    setFiles(temp);
  }, [props.value]);

  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    console.log(filename);
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');

    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
  
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');

    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };
  
    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);
  
    if(isImageOrPDFFile) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    else {
      //fileDownload
      try {
        let payload: any = {
          filename: filename,
          url : desiredParts,
          employeeNumber : userInfo.employeeNumber,
          classification : userInfo.classification,
        };
        console.log(payload);

        let { data } = await core.fileDownload(payload);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        //info("협조전 등록이 완료되었습니다.");
      } catch ({ data, status }) {
        console.log(data, status);
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        info("파일 다운로드 중 문제가 발생했습니다");
        return;
      }
    }
  }

  return (
    <>
    <h4>첨부파일</h4>
    <div className="table_normal_list table_write" >
      <table>
        <caption className="sr_only">첨부파일</caption>
        <colgroup>
            <col width="12.5%"/>
            <col width="*"/>
        </colgroup>
        <tbody>
          {files.map((file: UploadedFile, fileIdx: number) => (
            file?.document && (
              <tr key={fileIdx}>
                <th>{file?.document?.name}</th>
                <td>
                  <div className="items_area">
                    {props.downFlag ? 
                    <button 
                    className="btn item file btn_download"
                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${file.file.path}`, '')}
                    >
                    {file.file.name}
                    </button>
                    /*<a className="item file" href={`${process.env.REACT_APP_MEDIA}/${file.file.path}`} download 
                      target={`${file.file.path}`.split('.').pop() 
                              && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.file.path}`.split('.').pop() || '' ) 
                              && !isMobile? '_blank' : ''}
                    >
                      { file.file.name}
                    </a>*/
                    : <a className="item file" >{ file.file.name}</a>}
                  </div>
                </td>
              </tr>
            )
          ))} 
        </tbody>
      </table>
    </div>
    </>
  );
};

export default FileForm;

import {action, computed, makeObservable, observable} from 'mobx';
import * as apis from '../../apis';
import { BoardStore } from './boardStore';
import { ROOM_TYPE } from '../../constants';
import { debounce } from 'typescript-debounce-decorator';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class VocBuildingStore extends BoardStore {
  tabIndex = 0;

  room_types: any[] = [];
  buildings: any[] = [];
  floors: any[] = [];
  room_type = -1;
  building = -1;
  room: any = null;
  work_site: number = 0;
  name: any = null;
  dong: string = '';
  bName: string = '';
  
  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      tabIndex: observable,
      work_site: observable,
      dong: observable,
      name: observable,
      bName: observable,
      room_types: observable,
      buildings: observable,
      floors: observable,
      room_type: observable,
      building: observable,
      room: observable,
      
      buildingName: computed,
      buildingAndRoomName: computed,
      getItems: action,
    });
    this.initDatas();
  }

  initDatas () {
    const items = [{ value: -1, label: '숙소유형 선택'}];
    Object.keys(ROOM_TYPE)
      .forEach(key => items.push({ value: Number(key), label: ROOM_TYPE[key] }));
    this.room_types = items;
  }

  init () {
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.checkeds = {};
    this.item = null;
    this.orgItem = null;
  }

  async getBuildings () {
    const init = () => this.buildings = [{id: -1, name: '건물명 선택'}];

    if (this.room_type < 0) {
      init();
    } else {
      try {
        this.headerStore.isLoading = true;
        const { data } = await apis.build.getBuilds({
          //work_site: this.user.work_site,
          work_site: this.work_site,
          room_type: this.room_type,
        });

        if (data.length > 0) {
          this.buildings = [{id: -1, name: '건물명 선택'}, ...data];
        } else {
          init();
        }

        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  setRoomType(room_type: number) {
    // console.log('room_type', room_type);
    this.items = [];
    this.buildings = [];
    this.floors = [];
    this.room_type = room_type;
    this.building = -1;
    this.room = null;
    if (this.room_type === 7) {
      this.getItems();
    } else {
      this.getBuildings();
    }
  }

  selectedRoom (room: any) {
    this.room = room;
    this.getItems();
  }

  setBuinding(building: number) {
    this.items = [];
    this.room = null;
    this.building = building;
    this.buildings.map((item: any, indxx: number)=>{
      if(item.id === building){
        this.dong = item.dong;
        this.bName = item.name;
      }
    })
    this.getFloors();
  }

  get buildingName () {
    if(this.building != -1){
      const selectedBuilding: any = this.buildings.find(buinding => buinding.id === this.building)
      return selectedBuilding ? selectedBuilding.name + selectedBuilding.dong : '';
    }else {
      return '';
    }
  }

  get buildingAndRoomName () {
    return `${this.buildingName} ${this.room ? this.room.name + ' 호' : ''}`;
  }

  async getFloors () {
    const init = () => this.floors = [];
    if (this.building < 0) {
      init();
    }  else {
      try {
        this.headerStore.isLoading = true;
        // const today = getTodayDate()[0];
        const { data } = await apis.build.getFloorsVoc({
          building: this.building,
        });
        if (data.length > 0) {
          this.floors = data;
          // if (this.room_type !== 7) {
          //   let d = _.cloneDeep(_.orderBy(data, 'step', 'desc'))
          //   this.floors = data;
          // } else {
          //   this.floors = data;
          // }
        } else {
          init();
        }
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  @debounce(500)
  async getItems() {
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      work_site: this.work_site,
      room: this.room,
      room_name: this.room ? this.room.name : "",
      building_dong: this.dong,
      building_name: this.bName,
      room_type: this.room_type
    };

    if (this.room_type === 7) {
      filter.room_type = this.room_type;
    } else {
      if (this.room) {
        filter.places = this.room.id;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getVocs(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }
}

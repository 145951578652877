import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { formatByString, formatByString_YYYYMMDD } from '../../../../utils/date';
import { core, voc as api } from '../../../../apis';
import { VOC_CATEGORY, VOC_STATUS_TYPES, ROOM_TYPE } from '../../../../constants';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../../routes';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProvider, useStores } from '../../../../store/mobx';
import { observer } from 'mobx-react';
import xss from 'xss';
import { getWorkSitePhoneNum, getWorkSitePhoneNum2, parserEscape } from '../../../../utils/common';
import { indexOf, isEmpty } from 'lodash';
import ImageUpload from '../../../molecules/fileUpload';
import MngFileUpload from '../../../organismas/form/managementFile';
import Lodge from '../../../../apis/lodge';
import {getTodayDate} from '../../../../utils/date'
import FileSaver from 'file-saver';

const Comments = observer((props: any) => {
  const store = props.store;
  const item: any = !props.complete
    ? !store.comment ? store.newComment() : store.comment
    : null;
  const [contents, setContents] = useState<string>((item && item.contents) || '');
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const Answer = ({comment, index}: {comment: any, index: number}) => {
    return (
      <>
        <div className="table_normal_list table_write mt10">
          <table>
            <caption className="sr_only">VoC 답변</caption>
            <colgroup>
              <col width="200"/>
              <col width="*"/>
              <col width="200"/>
              <col width="*"/>
            </colgroup>
            <tbody>
            <tr>
              <th>작성일시</th>
              <td>{formatByString(comment.created_at, 'yyyy.MM.dd')}</td>
              <th>담당자</th>
              <td>
                {/* {!props.store.item || !props.store.item.managers[0]? comment.author : props.store.item.managers[0].name+'외'+(props.store.item.managers.length-1)+'명'} */}
                {comment.author}
              </td>
            </tr>
            <tr>
              <th>답변</th>
              <td colSpan={3}><div className="contents_area">{comment.contents}</div></td>
            </tr>
            <tr>
              <th>이미지</th>
              {/* {store.item.is_complete === false? (
              <td colSpan={3}>
                <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={store.answerImages}
                  onSetData={(data: any[]) => store.answerImages = data}
                  
                />
              </td>
              ) : ( */}
              <td colSpan={3}>
                <div className="images_area">
                {comment.images.map((image: any, index: number) => (
                    <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a key={index} >
                          <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                        </a>
                    </div>
                  ))}
                </div>
              </td>
              {/* )} */}
            </tr>
            </tbody>
          </table>
        </div>  {/*table*/}

        {/* 이미지 팝업 */}
        <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
            <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
            <div className="pop_wrap">
                <div className="contents_wrap">
                    <div className="slide">
                        <p className="img_area">
                            <img src={imgPath} alt=""/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  };

  const Question = ({comment, index}: {comment: any, index: number}) => {
    return (
      <>
        {index > 0 ? <h4>추가문의</h4> : <></>}
        <div className="table_normal_list table_write mt10">
          <table>
            <caption className="sr_only">추가문의</caption>
            <colgroup>
              <col width="200"/>
              <col width="*"/>
            </colgroup>
            <tbody>
            <tr>
              <th>작성일시</th>
              <td>{formatByString(comment.created_at, 'yyyy.MM.dd')}</td>
            </tr>
            <tr>
              <th>추가내용</th>
              <td><div className="contents_area">{comment.contents}</div></td>
            </tr>
            </tbody>
          </table>
        </div>  {/*table*/}
      </>
    );
  };

  // if (props.comments.length === 0) return <></>;

  return (
    <>
      {props.comments.map((comment: any, index: number) => (index + 1) % 2 === 1
        ? <Answer key={index} comment={comment} index={index} />
        : <Question key={index} comment={comment} index={index} />
      )}
      {item ? (
        <div className="table_normal_list table_write mt10">
          <table>
            <caption className="sr_only">VoC 답변</caption>
            <colgroup>
              <col width="200"/>
              <col width="*"/>
              <col width="200"/>
              <col width="*"/>
            </colgroup>
            <tbody>
            <tr>
              <th>작성일시</th>
              <td>{item.created_at}</td>
              <th>작성자</th>
              <td>
                {!props.store.item || !props.store.item.managers[0] ? 
                <>{item.author}</>
                : 
                props.store.item.managers[0].name+'외'+(props.store.item.managers.length-1)+'명'
                }
              </td>
              {/* <td>{item.author}</td> */}
            </tr>
            <tr>
              <th>답변</th>
              <td colSpan={3}>
                <textarea name="" id="" className="form_control"
                  value={contents}
                  onChange={(e: any) => {
                    const tmp = { ...store.comment };
                    setContents(e.target.value);
                    tmp.contents = e.target.value;
                    store.comment = tmp;
                  }}>
                </textarea>
              </td>
            </tr>
            <tr>
              <th>이미지</th>
              <td colSpan={3}>
              <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={store.answerImages}
                  onSetData={(data: any[]) => store.answerImages = data}
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      ) : (<></>)}
    </>
  );
});

const OnlyComments = observer((props: any) => {
  const store = props.store;
  const item: any = !props.complete
    ? !store.comment ? store.newComment() : store.comment
    : null;
  const [contents, setContents] = useState<string>((store.contents) || '');
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  
  const Answer = ({comment, index}: {comment: any, index: number}) => {
    return (
      <>
        {index === 0 ? <h4>답변</h4> : <></>}
        <div className="table_normal_list table_write mt10">
          <table>
            <caption className="sr_only">VoC 답변</caption>
            <colgroup>
              <col width="200"/>
              <col width="*"/>
              <col width="200"/>
              <col width="*"/>
            </colgroup>
            <tbody>
            <tr>
              <th>작성일시</th>
              <td>{formatByString(comment.created_at, 'yyyy.MM.dd')}</td>
              <th>담당자</th>
              <td>
                {/* {!props.store.item || !props.store.item.managers[0]? comment.author : props.store.item.managers[0].name+'외'+(props.store.item.managers.length-1)+'명'} */}
                {comment.author}
              </td>
            </tr>
            <tr>
              <th>답변</th>
              <td colSpan={3}><div className="contents_area">{comment.contents}</div></td>
            </tr>
            <tr>
              <th>이미지</th>
              {/* {store.item.is_complete === false? (
              <td colSpan={3}>
                <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={store.answerImages}
                  onSetData={(data: any[]) => store.answerImages = data}
                  
                />
              </td>
              ) : ( */}
              <td colSpan={3}>
                <div className="images_area">
                {comment.images.map((image: any, index: number) => (
                    <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a key={index} >
                          <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                        </a>
                    </div>
                  ))}
                </div>
              </td>
              {/* )} */}
            </tr>
            </tbody>
          </table>
        </div>  {/*table*/}

        {/* 이미지 팝업 */}
        <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
            <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
            <div className="pop_wrap">
                <div className="contents_wrap">
                    <div className="slide">
                        <p className="img_area">
                            <img src={imgPath} alt=""/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  };

  // if (props.comments.length === 0) return <></>;

  return (
    <>
      {props.comments.map((comment: any, index: number) => 
        <Answer key={index} comment={comment} index={index} />
      )}
      {props.writeRoleFlag && ( (store.item.author_employee_number === userInfo.employeeNumber) 
              || store.item.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0) ? (
        <div className="table_normal_list table_write mt10">
          <table>
            <caption className="sr_only">VoC 답변</caption>
            <colgroup>
              <col width="200"/>
              <col width="*"/>
              <col width="200"/>
              <col width="*"/>
            </colgroup>
            <tbody>
            <tr>
              <th>작성일시</th>
              <td>{getTodayDate()[0]}</td>
              <th>작성자</th>
              <td>
                {!props.store.item || !props.store.item.managers[0] ? 
                <>{item.author}</>
                : 
                props.store.item.managers[0].name+'외'+(props.store.item.managers.length-1)+'명'
                }
              </td>
              {/* <td>{item.author}</td> */}
            </tr>
            <tr>
              <th>답변</th>
              <td colSpan={3}>
                <textarea name="" id="" className="form_control"
                  value={store.contents}
                  onChange={(e: any) => {
                    const tmp = { ...store.comment };
                    setContents(e.target.value);
                    tmp.contents = e.target.value;
                    store.comment = tmp;
                    store.contents = e.target.value;
                  }}>
                </textarea>
              </td>
            </tr>
            <tr>
              <th>이미지</th>
              <td colSpan={3}>
              <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={store.answerImages}
                  onSetData={(data: any[]) => store.answerImages = data}
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      ) : (<></>)}
    </>
  );
});

const ViewContent = observer((props: any) => {
  const dispatch = useDispatch();
  const {vocStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const item = store.item;
  const baseUrl = '/lodged/maintenance';

  const { info, warning, error }: MessageImpl = useContext(MessageContext)
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');
  const [showChoiceMng, setShowChoiceMng] = useState<boolean>(false);
  const goBack = () => props.history.replace(baseUrl);

  const [transfermanagers, setTransfermanagers] = useState<any>([]);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  useEffect(() => {
    headerStore.getAuthMenuRole('3-2');
    if (item) {
      const c1: any = VOC_CATEGORY.find((c: any) => c.name === item.category1);
      const c2: any = c1.child.find((c: any) => c.name === item.category2);
      const c3: any = (c2 && c2.child.find((c: any) => c.name === item.category3)) || null;
      c3 && store.getCategories4();
    }
  }, []);

  // const onShowPlacePopupHandler = (isOpen: boolean) => {
  //   props.setIsPlaceOpen(isOpen);
  // }; // 열기

  // const onShowChoiceManagerPopupHandler = (isOpen: boolean) => {
  //   props.setIsChoiceManagerOpen(isOpen);
  // }; // 열기

  // const onDeletehandler = async () => {
  //   try {
  //     await api.deleteVoc(item.id);
  //     info('삭제 완료했습니다.');
  //   } catch ({ status, data }) {
  //     errorHandler({ status, data }, '삭제중 오류발생');
  //   }
  // };

  // const onUpdateStatus = () => {
  //     dispatch({
  //       type: SHOW_CONFIRM_MODAL,
  //       payload: {
  //         isModalShow: true,
  //         title: '확인',
  //         msg: `${VOC_STATUS_TYPES[status]}상태로 변경 하시겠습니까?`,
  //         action: async () => {
  //           console.log('onUpdateStatus');
  //           try {
  //             const data = { status: status };
  //             await api.updateVoc(item.id, data);

  //             const result = await api.getVoc(item.id);
  //             console.log(result);

  //             // setItem(result.data);
  //             info(`${VOC_STATUS_TYPES[status]}상태로 변경했습니다.`);
  //           } catch ({ status, data }) {
  //             errorHandler({ status, data }, '진행상태 변경중 오류발생');
  //           }
  //         },
  //       },
  //     });
  // };

  // const addCommentHandler = async () => {
  //   if (store.comment && store.comment.contents) {
  //     info('내용을 입력해주세요.');
  //     return;
  //   }

  //   try {
  //     store.saveComment();
  //     info('작성을 완료했습니다.');
  //   } catch ({ status, data }) {
  //     errorHandler({ status, data }, '답변 저장 중 오류발생');
  //   }
  // }

  // const CommentMessageConverter = ({comment}: {comment: any}) => {
  //   switch(comment.title) {
  //     case 'manager':
  //       return (<>{`${comment.contents}님을 담당자로 설정하였습니다.`}</>);
  //     case 'status':
  //       return (<><span className="item">{VOC_STATUS_TYPES[Number(comment.contents)]}</span>{'상태로  변경되었습니다.'}</>);
  //     default:
  //       return (<>{comment.contents}</>);
  //   }
  // }
  const onSave = async () => {
    if (store.comment && !store.comment.contents) {
      info('내용을 입력해주세요.');
      return;
    }

    try {
      headerStore.isLoading = true;
      
      let data : any = await store.saveComment();

      // setTimeout(function () {
        headerStore.isLoading = false;
        if(data.save) {
          
          info('작성을 완료했습니다.');
    
          // voc 답변인경우, 문자발송
          if(!item.is_maintenance) {
            let smsPayload: any = {
              cellphone : item.author_cellphone,
              message : '답변 완료 \n답변 : '+ store.comment.contents
                      + '\n문의사항 및 추가 문의는 AMS  VoC에서 재문의 부탁드립니다.'
                      + '\n신청진행 확인은 "고객센터>>My VoC" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum2(headerStore.workSite)
            }

            try {
              const { data: sms } = await Lodge.smsSendAPI(smsPayload);

              await store.getItem(item.id);
            } catch ({ status, data }) {
              headerStore.isLoading = false;
              if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }else {
                info("문제가 발생했습니다");
                return;
              }
            }
          }
        }else {
          // info("문제가 발생했습니다");
          return;
        }
      // }, 250);

    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      // errorHandler({ status, data }, '답변 저장 중 오류발생');
    }
  };

  const onCommentSave = async () => {
    
    if (store.comment && !store.comment.contents) {
      info('내용을 입력해주세요.');
      return;
    }

    try {
      headerStore.isLoading = true;
      
      let data : any = await store.saveOnlyComment();
      if(data.save) {
        info('작성을 완료했습니다.');
        await store.getItem(item.id);
      }

      headerStore.isLoading = false;
        
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      // errorHandler({ status, data }, '답변 저장 중 오류발생');
    }
  };

  const onSatusChange = async () => {

    try {
      headerStore.isLoading = true;
      let status = '완료';
      if(item.is_complete) status = '재요청';
      
      let data : any = await store.statusChage();
      if(data.save) {
        info(status+' 처리 되었습니다.');
        await store.getItem(item.id);
      }

      headerStore.isLoading = false;
        
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      // errorHandler({ status, data }, '답변 저장 중 오류발생');
    }
  };

  const placeName = () => {
    let placeName = '';
    if(item.places_etc && item.places_etc != '') {
      placeName = '기타 > ' + item.places_etc + ' > ' + item.places_etc2;
    }else if (item.places.length > 0) {
      const place = item.places[0];
      // console.log(ROOM_TYPE[place.room_type]);
      if (item.places.length === 1) {
        placeName =  ROOM_TYPE[place.room_type] + ` > ${place.building_name} > ${place.building_dong} > ${place.room_name}`;
      } else {
        placeName =  ROOM_TYPE[place.room_type] + ` > ${place.building_name} > ${place.building_dong} > ${place.room_name}외 ${item.places.length - 1}곳`;
      }
    }
    return placeName;
  };

  const viewTranferManagers = ((item : any)=> {
    setTransfermanagers(item.transfermanagers);
    setShowChoiceMng(true);
  })


  if (!item) return <></>;

  return (
    <>
      {item.is_maintenance ? 
        <h4>유지보수 정보</h4> :
        <h4>VoC 정보</h4>
      }
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">VoC 정보</caption>
          <colgroup>
            <col width="200"/>
            <col width="*"/>
            <col width="200"/>
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr>
              <th>유형</th>
              <td colSpan={3}>
                <div className="form_inline">
                  <input type="text" className="form_control" value={item.category1} disabled/>
                  {item.category2 ? <input type="text" className="form_control" value={item.category2} disabled/> : <></>}
                  {item.category2 ? <input type="text" className="form_control" value={item.category3} disabled/> : <></>}
                  {store.category4.length > 0 ? (
                    <select name="" id="" className="form_control"
                      disabled={!item.is_complete}
                      value={item.category4}
                      onChange={(e: any) => item.category4 = e.target.value}
                    >
                      <option value="">4차 분류항목 선택</option>
                      {store.category4.map((category: any, index: number) => (
                        <option key={`${index}`} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  ) : <></>}
                </div>
              </td>
            </tr>

            <tr>
              <th>발생장소</th>
              <td>
                {placeName()}
                {item.places.length > 0 ? (
                  <>
                    {headerStore.writeRoleFlag ? 
                      <button className="btn btn_sm btn_gray ml30" onClick={() => props.setIsPlaceOpen(true)}>보기</button> : <></>
                    }
                  </>
                ) : <></>}
              </td>
              <th>발생일시 / 작성자</th>
              <td>{formatByString(item.found_at, 'yyyy.MM.dd HH')}시경 / {item.author_employee_number}</td>
            </tr>

            <tr>
              <th>제목</th>
              <td className="fwb">{item.title}</td>
              <th>진행상태</th>
              <td>{item.is_complete ? '완료' : '처리중'}</td>
            </tr>

            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <div className="contents_area" id="editor" dangerouslySetInnerHTML={{ __html: parserEscape(item.contents) }}></div>
              </td>
            </tr>

            <tr>
              <th>이미지</th>
              <td colSpan={3}>
                <div className="images_area">
                  {item.images.map((image: any, index: number) => (
                    <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a key={index} >
                          <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                        </a>
                    </div>
                  ))}
                </div>
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan={3}>
                {item.files.map((file: any, index: number) => (
                  <>
                  {headerStore.downRoleFlag ? 
                    <a key={index} href={`${process.env.REACT_APP_MEDIA}/${file.path}`} className="item file"
                    target={`${file.path}`.split('.').pop() 
                            && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                            && !isMobile? '_blank' : ''}
                    >
                      {file.name} 
                    </a>
                    : <a key={index} className="item file">{file.name}</a>
                  }
                  </>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>{' '}
      {/*table*/}

      
      {item.is_maintenance ? 
      <>
        <OnlyComments complete={item.is_complete} comments={item.comments} store={store} writeRoleFlag={headerStore.writeRoleFlag} />
        {headerStore.writeRoleFlag && ((item.author_employee_number === userInfo.employeeNumber) ||
          store.item.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0) ?
        <div className="submit">
          <div className="fr">
            <button className="btn btn_lg btn_darkblue" onClick={onCommentSave}>답변저장</button>
          </div>
        </div>
        : ''}
      </>
      :
      <Comments complete={item.is_complete} comments={item.comments} store={store} />
      }

      <h4>이관 이력</h4>
      <div className="table_normal_list" >
        <table>
            <caption className="sr_only">이관 이력</caption>
            <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
            </colgroup>
            <thead>
            <tr>
                <th>등록자</th>
                <th>이관 사유</th>
                <th>이관 날짜</th>
                <th>이관 대상</th>
            </tr>
            </thead>
            <tbody>
                {/* // 데이타 없을 시 */}
                {!item.transfer || item.transfer.length <= 0? 
                (
                  <tr>
                      <td colSpan={4} className="blank_area">
                          <p className="txt_blank">이관이력이 없습니다</p>
                      </td>
                  </tr>
                ) :
                  <>
                  {item.transfer.map((trans : any, index : number) => (
                      <tr>
                          <td>{trans.author}</td>
                          <td>{trans.reason}</td>
                          <td>{formatByString_YYYYMMDD(trans.created_at)}</td>
                          <td><button className="btn btn_xs btn_gray btn_outline" onClick={()=> {viewTranferManagers(trans);}}>보기</button></td>
                      </tr>
                  ))}
                  </>
                }
            </tbody>
        </table>
      </div>  {/*table*/}

      <div className="submit">
        <div className="fl">
          <button className="btn btn_lg btn_gray" onClick={goBack}>목록</button>
        </div>
          {item.is_complete ? (
            item.is_maintenance && ( (item.author_employee_number === userInfo.employeeNumber) 
            || store.item.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0)? 
              <div className="fr">
                <button className="btn btn_lg btn_darkblue" onClick={onSatusChange}>재요청</button> 
              </div>
            : ''
          )
          : (
            <div className="fr">
              {/* 업무이관을 하면 업무이관을 받은사람만 업무이관 수정 완료가 가능 */}
              {/* {store.item.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0? (
              <> */}
              
              {/*{headerStore.writeRoleFlag && ( (item.author_employee_number === userInfo.employeeNumber) 
              || store.item.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0) ?
                  <>
                    <button className="btn btn_lg btn_outline" onClick={() => props.setIsChoiceManagerOpen(true)}>업무이관</button>
                    {(!item.is_complete && item.is_maintenance) && headerStore.writeRoleFlag?
                        <button className="btn btn_lg btn_darkblue" onClick={()=> props.setIsEdit(true)}>수정</button>
                    : 
                      <></>
                    }
                    {item.is_maintenance ? 
                      <button className="btn btn_lg btn_darkblue" onClick={onSatusChange}>완료</button>
                      :
                      <button className="btn btn_lg btn_darkblue" onClick={onSave}>완료</button>
                    }
                  </>
                : <></>
              } */}

              {item.is_maintenance && headerStore.writeRoleFlag ? 
                <> {/*유지운영*/}
                  { ( (item.author_employee_number === userInfo.employeeNumber) 
                    || store.item.managers.filter((item:any) => item.employee_number === userInfo.employeeNumber).length > 0) ?
                    <>
                      <button className="btn btn_lg btn_outline" onClick={() => props.setIsChoiceManagerOpen(true)}>업무이관</button> 
                      {(!item.is_complete && item.is_maintenance) && headerStore.writeRoleFlag?
                         <button className="btn btn_lg btn_darkblue" onClick={()=> props.setIsEdit(true)}>수정</button>
                      : 
                      <></>
                      }
                    </>
                    : 
                    <></>  
                  }
                  <button className="btn btn_lg btn_darkblue" onClick={onSatusChange}>완료</button>
                </>
                :
                <>
                  <button className="btn btn_lg btn_outline" onClick={() => props.setIsChoiceManagerOpen(true)}>업무이관</button>
                  <button className="btn btn_lg btn_darkblue" onClick={onSave}>완료</button>
                </>
              }
              {/* </>
              // ) : (
              // <></>
              // )} */}
            </div>
          )}
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
          <div className="pop_wrap">
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
      {/* 이관 대상 팝업 */}
      <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: showChoiceMng? "block": "none" }}>
          <div className="pop_wrap">
              <div className="title_wrap">
                  <h3>이관 대상</h3>
              <button className="pop_btn_close pop_close" onClick={() => setShowChoiceMng(false)}>닫기</button>
              </div>
              <div className="contents_wrap">
                <div className="process_list">
                  {transfermanagers.length <= 0 ? (
                    <dd className="blank_area type_white"><p className="txt_blank">데이터가 없습니다.</p></dd>
                  ) : (
                    <dl className="">
                       <dd className="on">
                        {transfermanagers.map((manager : any, index : number) => (
                            <a className="item mb10">{manager.name}</a>
                        ))}
                      </dd>
                    </dl>
                  )}
                </div>
              </div>
              <div className="button_wrap btn_length_1">
                <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => setShowChoiceMng(false)}>확인</button>
              </div>
          </div>
      </div>
    </>
  );
});

export default ViewContent;

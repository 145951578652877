import React from 'react';

import DefaultTemplate from '../../../components/templetes/default';
import VocDetail from '../../../components/templetes/voc/detail';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';

const VocDetailPage = observer((props: any) => {
  const {headerStore : headerStore} = useStores();
  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading ={headerStore.isLoading}
      {...props}
    >
      <VocDetail isEdit={false} {...props} />
    </DefaultTemplate>
  );
});

export default VocDetailPage;

import React from 'react';

import  Tag  from "./";
import {  STATUS, CHECKOUT_STATUS_TYPE } from '../../../constants';

interface Props {
  status: number;
  type?: string
}

const StatusTag = ({ status, type }: Props) => {
  const getColor = (status: number) => {
    switch (status) {
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
        return 'grey';
      case 4:
        return 'purple';
      case 5:
        return 'blue';
      case 6:
        return 'green';
      default:
        return 'blue';
    }
  };
  return type === CHECKOUT
    && <Tag label={CHECKOUT_STATUS_TYPE[status]} color={getColor(status)} />
    || <Tag label={STATUS[status]} color={getColor(status)} />
};


export const CHECKOUT = "checkout";
export default StatusTag;

import {action, makeObservable, observable} from 'mobx';
import { isTemplateExpression } from 'typescript';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { EXHIBIT_STATUS, NOTICE_TYPE, SEARCH_TYPE } from '../../constants';
import { parserEscape } from '../../utils/common';
import { getIntervalMonthDate, getNextMonth, getTodayDate } from '../../utils/date';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { BoardStore } from './boardStore';
import { HeaderStore } from './headerStore';

export class AuditStore extends BoardStore {
  searchTypes: any[] = [];
  exhibitStatus: any[] = [];
  created_after: string = getIntervalMonthDate(1)[1];
  created_before: string = getNextMonth(getTodayDate()[0])[0]
  searchType = -1;
  search = '';
  status = -1;
  guests: any[] = [];
  guestCheckeds: any = {};
  guestAllCheckFlg = false;
  isRejectPopup = false;
  selectedGuest: any;
  rejectDescription = '';
  rejectTerminated_at = '';
  worksite : any;
  
  
  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      searchTypes: observable,
      exhibitStatus: observable,
      created_after: observable,
      created_before: observable,
      searchType: observable,
      search: observable,
      status: observable,
      guests: observable,
      guestCheckeds: observable,
      guestAllCheckFlg: observable,
      isRejectPopup: observable,
      selectedGuest: observable,
      rejectDescription: observable,
      worksite : observable,
      
      
      getItems: action,
      getItem: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      isChange: action,
      saveItem: action,
      deleteItem: action,
      deleteItems: action,
      rejectGuest: action,
      approveGuest: action,
      rollbackGuest: action,
    });

    this.initDatas();
  }

  private initDatas () {
    // const _searchTypes = [{ value: '-1', label: '선택', optionIdx: 0 }];
    const _searchTypes : any = [];
    Object.keys(SEARCH_TYPE).forEach((key: string, index: number) => {
      _searchTypes.push({
        value: key,
        label: SEARCH_TYPE[Number(key)],
        optionIdx: index + 1,
      });
    });
    this.searchTypes = _searchTypes;

    const _exhibitStatus = [{ value: '-1', label: '전체', optionIdx: 0 }];
    Object.keys(EXHIBIT_STATUS).forEach((key: string, index: number) => {
      _exhibitStatus.push({
        value: key,
        label: EXHIBIT_STATUS[Number(key)],
        optionIdx: index + 1,
      });
    });
    this.exhibitStatus = _exhibitStatus;
  }

  init () {
    this.category = -1;
    this.searchType = -1;
    this.search = '';
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.status = -1;
    this.items = [];
    this.guests = [];
    this.checkeds = {};
    this.item = {};
  }

  async getItem(id: string) {
    const filter: any = { audit: id };
    if (this.status > -1) {
      filter['status'] = this.status;
    }
    try{
      this.headerStore.isLoading = true;
      // const results = await Promise.all([
      //   apis.audit.getAudit({ id }),
      //   apis.audit.getAuditExhibit(filter),
      // ])

      const results = [];
      let data : any = await apis.audit.getAudit({ id });
      let data2 : any = await apis.audit.getAuditExhibit(filter);
      
      results.push(data);
      results.push(data2);

      data2.data = data2.data.map((item:any, idx : number) => {
        if(item.guest.guest_cellphone !== '') {
          item['guest_cellphone'] = item.guest.guest_cellphone;
        }
        
        if(item.guest.email !== '') {
          item['email'] = item.guest.email;
        }else {
          item['email'] = '';
        }
        
        item.audit_list_id = item.id;
        item.id = item.guest.id;

        return item;
      })
      
      if (results) {
        const _item = results[0].data;
        _item.guests = results[1].data;
        this.guests = results[1].data;
        this.selectedItem(results[0].data);
      }
      this.headerStore.isLoading = false;
      
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  @debounce(500)
  async getItems() {
    // console.log('getItems: ', this.limit, this.offset);
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      worksite : this.worksite,
    };

    switch (this.searchType) {
      case 1: filter['title'] = this.search; break;
      case 2: filter['contents'] = this.search; break;
      case 3: filter['author'] = this.search; break;
      default:
        filter['search'] = this.search;
        break;
    }

    if (this.created_after) {
      filter['created_after'] = this.created_after;
    }

    if (this.created_before) {
      filter['created_before'] = this.created_before;
    }
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.audit.getAuditList(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;

      // return {
      //   'save' : true,
      //   'status' : 200,
      //   'data' : ''
      // };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        // return {
        //   'save' : false,
        //   'status' : status,
        //   'data' : data
        // };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        // return {
        //   'save' : false,
        //   'status' : status,
        //   'data' : data
        // };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        // return {
        //   'save' : false,
        //   'status' : status,
        //   'data' : data
        // };
      }

      // return {
      //   'save' : false,
      //   'status' : status,
      //   'data' : data
      // };

      return;
    }
  }

  async getGuests() {
    const filter: any = { audit: this.item.id };
    if (this.status > -1) {
      filter['status'] = this.status;
    }
    // console.log(filter);
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.audit.getAuditExhibit(filter);
      //this.guests = data;
      this.headerStore.isLoading = false;

      this.guests = data.map((guest : any) => {
        guest.audit_list_id = guest.id;
        guest.id = guest.guest.id;
        return guest;
      });

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async setStatus(status: number) {
    this.status = status;
    await this.getGuests();
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
    this.guests = [];
  }

  newItem() {
    const user = JSON.parse(window.sessionStorage.getItem('user') || '');
    this.item = {
      id: -1,
      category: 1,
      author_name: user.username,
      author_employee_number: user.employeeNumber,
      title: '',
      contents: '',
      upload_after: '',
      upload_before: '',
      images: [],
      files: [],
      guests: [],
      is_sms_send: false,
      sms_message: '',
      is_mail_send: false,
      mail_title: '',
      mail_message: '',
      is_instant: false,
      send_at: '',
      created_at: new Date(),
    };
    this.orgItem = { ...this.item };
    this.guests = [];
    this.guestCheckeds = {};
  }

  clone() {
    const user = (window as any).store.getState().user;
    const tmpItem = { ...this.orgItem };
    tmpItem.id = -1;
    tmpItem.author_name = user.username;
    tmpItem.author_employee_number = user.employeeNumber;
    tmpItem.title = `[복제] ${this.orgItem.title}`;
    tmpItem.created_at = new Date();
    tmpItem.guests = this.item.guests;
    this.selectedItem(tmpItem);
  }

  selectedItem(item: any) {
    this.item = item;
    this.orgItem = { ...item };
  }

  isChange() {
    return JSON.stringify(this.orgItem) !== JSON.stringify(this.item)
  }

  guestCheckAll(checked: boolean) {
    const temp: any = { ...this.guestCheckeds };
    this.items.map((item: any, index: number) => {
      if (checked) {
        this.allCheckFlg = true;
        temp[item.id] = item;
      } else {
        this.allCheckFlg = false;
        delete temp[item.id];
      }
    });
    this.guestCheckeds = temp;
  }

  guestCheckItem(checked: boolean, item: any) {
    const temp: any = { ...this.guestCheckeds };
    if (checked) {
      if (!temp[item.id]) {
        temp[item.id] = item;
      }
    } else {
      delete temp[item.id];
    }
    this.guestCheckeds = temp;

    if (Object.keys(temp).length === this.items.length) {
        this.guestAllCheckFlg = true;
    } else {
      this.guestAllCheckFlg = false;
    }
  }

  updateGuestsNote(text: string, id: string) {
    const findIdx = this.guests.findIndex((guest => guest.id === id));
    this.guests[findIdx].note = text;
  }

  async saveItem() {
    // console.log('AuditStore.saveItem:', this.item);
    const data: any = {
      author_name: this.item.author_name,
      title: this.item.title,
      contents: parserEscape(this.item.contents),
      upload_after: this.item.upload_after,
      upload_before: this.item.upload_before,
      is_sms_send: this.item.is_sms_send,
      sms_message: this.item.sms_message,
      is_mail_send: this.item.is_mail_send,
      mail_title: this.item.mail_title,
      mail_message: this.item.mail_message,
      is_instant: this.item.is_instant,
      send_at: new Date(),
      work_site_id : this.worksite
     };
    data.images = this.item.images && this.item.images.map((image: any) => image.id);
    data.files = this.item.files && this.item.files.map((file: any) => file.id);
    data.guests = this.item.guests && this.item.guests.map((guest: any) => guest.id);
    // console.log('data>>>>>>>>>>>>>>>>>>>>>>>>>>>' , data);
    if (this.item.id < 0) {
      try{
        this.headerStore.isLoading = true;
        await apis.audit.createAudit(data);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    } else {
      try{
        this.headerStore.isLoading = true;
        await apis.audit.updateAudit(this.item.id, data);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
    this.newItem();
  }

  async deleteItem() {
    // console.log('AuditStore.deleteItem:', this.item);
    if (this.item) {
      try{
        this.headerStore.isLoading = true;
        await apis.notice.deleteNotice(this.item.id);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItems() {
    const keys = Object.keys(this.checkeds);
    console.log(keys);
    if (keys.length > 0) {
      try{
        this.headerStore.isLoading = true;
        const actions = keys.map((checkKey: string) => apis.audit.deleteAudit(checkKey));
        await Promise.all(actions);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async rejectGuest() {
    if (this.selectedGuest) {
      try{
        this.headerStore.isLoading = true;
        await apis.audit.rejectAudit(this.selectedGuest?.id, {
          terminated_at: this.rejectTerminated_at,
          description: this.rejectDescription,
        });
        await this.getItem(this.item.id);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async approveGuest() {
    if (this.selectedGuest) {
      try{
        await apis.audit.approveAudit(this.selectedGuest.id);
        await this.getItem(this.item.id);

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async rollbackGuest() {
    if (this.selectedGuest) {
      try{
        await apis.audit.rollbackAudit(this.selectedGuest.id);
        await this.getItem(this.item.id);

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async updateExhibitNote() {
    if (this.selectedGuest) {
      try{
        await apis.audit.updateExhibitNote(this.selectedGuest.id, { note: this.selectedGuest.note });

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }
}

import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../organismas/pagination';
import { formatByString } from '../../../utils/date';
import { VOC_CATEGORY, VOC_STATUS_TYPES, WORK_SITE } from '../../../constants';
import Select from '../../atoms/select';
import DatePicker from '../../organismas/input/datepicker';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import LimitSelect from '../../atoms/select/limit';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { useDispatch } from 'react-redux';
import { SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import { isNull } from 'lodash';

const VocCategories = observer((props: any) => {
  const dispatch = useDispatch();
  const {vocStore} = useStores();
  const {headerStore: headerStore} = useStores();
  const {categoryStore: store} = vocStore;
  const baseUrl = '/lodged/maintenance';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    store.newItem();
    headerStore.getAuthBuilding();
    // headerStore.getAuthMenuRole();
    headerStore.getAuthRoomRole();
    headerStore.getAuthBuilding();

    vocStore.work_site = headerStore.workSite;
    vocStore.roomtype = headerStore.paramAuthRoomTypeRole;
    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
      vocStore.authBuilding = headerStore.paramAuthBuildingRole;
    }
    (async () => {
      headerStore.isLoading = true;
      store.categories1 = VOC_CATEGORY.filter(c => c.child.length > 0);
      await store.getItems();
      headerStore.isLoading = false;
    })();
    return () => {
      if (!store.findTabs(`${baseUrl}`)) {
        store.destory();
      }
    }
  }, []);

  const onSearch = store.getItems;

  useEffect(() => {
    (async () => {
      headerStore.isLoading = true;
      await store.getItems();
      headerStore.isLoading = false;
    })();
  }, [store.limit]);

  const onAdd = () => {
    store.newItem();
  };

  const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

  const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);

  const selectedItem = (item: any) => {
    headerStore.isLoading = true;
    setCategories(1, item.category1);
    setCategories(2, item.category2);
    store.selectedItem(item);
    headerStore.isLoading = false;
  };

  const setCategories = (step: number, value: string) => {
    const currentKey = 'categories' + step;
    const nextKey = 'categories' + (step + 1);
    const category: any = (store as any)[currentKey].find((c: any) => c.name === value);
    store.category1 = category ? category.name : '' ;
    store.category2 = '';
    if (step === 1) {
      store.category3 = '';
    }
    if (store.category1 && category.child.length > 0) {
      (store as any)[nextKey] = [
        { value: '', name: '2차 분류', child: [] },
        ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
      ];
    } else {
      (store as any)[nextKey]  = [];
    }
    if (step === 1) {
      store.categories3 = [];
    }
  }

  const onDeletehandler = async () => {
    const selectedItems = Object.keys(store.checkeds);
    if (selectedItems && selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data : any = await store.deleteItems();
            headerStore.isLoading = false;
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
                store.allCheckFlg = false;

                store.category1 = '';
                store.categories2 = [];
                store.category2 = '';
                store.categories3 = [];
                store.category3 = '';
                store.category4 = '';
                return;
              }else {
                // errorHandler({ status, data }, '삭제중 오류발생');
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            errorHandler({ status, data }, '삭제중 오류발생');
          }
        },
      }
    });
  };

  const onSaveHandler = async () => {
    if (!store.isChange) {
      info('변경된 항목이 없습니다.');
      return;
    }

    try {
      headerStore.isLoading = true;
      let msg = '등록 완료했습니다.';
      if (store.item.id > -1) {
        msg = '수정 완료했습니다.';
      }
      
      let data : any = await store.saveItem();
      headerStore.isLoading = false;
      setTimeout(function () {
        if(data.save) {
          info(msg);
          return;
        }else {
          // errorHandler({ data.status, data.data }, '저장 중 오류발생');
          return;
        }
      }, 250); 
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      errorHandler({ status, data }, '저장 중 오류발생');
    }
  };

  const onGoPage = (item: any) => {
    store.selectedItem(item);
    props.history.replace(`${baseUrl}/${item.id}`)
  };

  return (
    <>
    <div className={['tab_contents', vocStore.tabIndex === 2 ? 'on' : ''].join(' ')}>
      <h4>4차분류 설정</h4>
      <div className="table_modify_list">
        <dl className="">
          <dt>분류선택</dt>
          <dd>
            <select name="" id="" className="form_control"
              value={store.category1}
              onChange={(e) => {
                // console.log(e.target.value);
                const category: any = VOC_CATEGORY.find(c => c.name === e.target.value);
                store.category1 = category ? category.name : '' ;
                store.category2 = '';
                store.category3 = '';
                if (store.category1 && category.child.length > 0) {
                  store.categories2 = [
                    { value: '', name: '2차 분류', child: [] },
                    ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                  ];
                } else {
                  store.categories2 = [];
                }
                store.categories3 = [];
              }}
            >
              <option value="">1차 분류</option>
              {store.categories1.map((category: any, index: number) => (
                <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
              ))}
            </select>
          </dd>
        </dl>
        {store.categories2.length > 0 ? (
        <dl className="">
          <dt></dt>
          <dd>
            <select name="" id="" className="form_control"
              value={store.category2}
              onChange={(e) => {
                const category: any = store.categories2.find(c => c.name === e.target.value);
                store.category2 = category ? category.name : '';
                store.category3 = '';
                if (store.categories2 && category.child.length > 0) {
                  store.categories3 = [
                    { value: '', name: '3차 분류', child: [] },
                    ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child  }))
                  ];
                } else {
                  store.categories3 = [];
                }
              }}
            >
              {store.categories2.map((category: any, index: number) => (
                <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
              ))}
            </select>
          </dd>
        </dl>
        ) : <></>}
        {store.categories3.length > 0 ? (
        <dl className="">
          <dt></dt>
          <dd>
              <select name="" id="" className="form_control"
                value={store.category3}
                onChange={(e) => store.category3 = e.target.value}
              >
              {store.categories3.map((category: any, index: number) => (
                <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
              ))}
              </select>
          </dd>
        </dl>
        ) : <></>}
        {store.category3 ? (
        <dl className="room">
          <dt>4차분류</dt>
          <dd>
            <input type="text" className="form_control" placeholder="4차분류를 입력하세요"
              value={store.category4}
              onChange={(e:any) => store.category4 = e.target.value}
            />
          </dd>
        </dl>
        ) : <></>}
      </div>
      <div className="submit ac">
        {headerStore.writeRoleFlag?
        <button className="btn btn_lg btn_outline" onClick={onAdd}>신규등록</button>
        :
        <></>
        }
        { store.isChange ? 
          <>
          {headerStore.writeRoleFlag? 
          <button className="btn btn_lg btn_darkblue" onClick={onSaveHandler}>저장</button>
          :
          <></>
          }
          </>
         : <></>}
      </div>

      <h4>
        설정 리스트
        <div className="right_area">
          <LimitSelect
            className="form_control"
            limit={store.limit}
            onChange={(limit: number) => {
              store.limit = limit
              store.offset = 0
            }}
          />
        </div>
      </h4>
      <div className="table_normal_list event_on box_scroll_x">
        <table>
        <caption className="sr_only">본동 102동 802호 유지운영 내역</caption>
        <colgroup>
          <col width="4%"/>
          <col width="*"/>
          <col width="*"/>
          <col width="*"/>
          <col width="*"/>
        </colgroup>
        <thead>
        <tr>
          <th>
            <label className="input_check pop_notext">
              <input type="checkbox" id="" name="" checked={store.allCheckFlg} onChange={onCheckedByAll} />
              <span className="label_text"></span>
            </label>
          </th>
          <th>1차 분류</th>
          <th>2차 분류</th>
          <th>3차 분류</th>
          <th>4차 분류</th>
        </tr>
        </thead>
        <tbody>
        {/*데이타 없을 시*/}
        {/*<tr>
            <td colspan="" className="blank_area type_white">
                <p className="txt_blank">데이터가 없습니다.</p>
            </td>
        </tr>*/}
        {!store.items.length ? (
          <tr>
            <td colSpan={5} className="blank_area type_white">
              <p className="txt_blank">데이터가 없습니다.</p>
            </td>
          </tr>
        ) : (
          store.items.map((item, index) => (
            <tr key={item.id}>
              <td>
                <label className="input_check pop_notext">
                  <input
                    type="checkbox"
                    checked={store.checkeds[item.id] ? true : false}
                    onChange={(e: any) => onCheckedHandler(e, item)}
                  />
                  <span className="label_text"></span>
                </label>
              </td>
              <td style={{ cursor: 'pointer'}} onClick={() => selectedItem(item)}>{item.category1}</td>
              <td style={{ cursor: 'pointer'}}  onClick={() => selectedItem(item)}>{item.category2}</td>
              <td style={{ cursor: 'pointer'}}  onClick={() => selectedItem(item)}>{item.category3}</td>
              <td style={{ cursor: 'pointer'}}  onClick={() => selectedItem(item)}>{item.category4}</td>
            </tr>
        )))}
        </tbody>
        </table>
      </div>  {/*table*/}
      
      <div className="board_paging">
        <ul>
          <Pagination
              offset={store.offset}
              limit={store.limit}
              totalPage={store.totalPage}
              setOffset={(offset: number) => store.setOffset(offset)} />
        </ul>
      </div>   {/*board_paging*/}

      <div className="submit ar">
      {headerStore.deleteRoleFlag? 
          <button className="btn btn_lg btn_outline" onClick={onDeletehandler}>삭제</button>
          :
          <></>
      }
      </div>
    </div>
    </>
  );
});

export default VocCategories;

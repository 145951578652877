import _, { isNull } from 'lodash';
import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { NOTICE_TYPE, SEARCH_TYPE } from '../../constants';
import { formatByString_YYYYMMDD, format_YYYYMMDD, getIntervalMonthDate, getTodayDate } from '../../utils/date';
import { BoardStore } from './boardStore';
import { VocCategoryStore } from './vocCategoryStore';
import { VocBuildingStore } from './vocBuildingStore';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class VocStore extends BoardStore {

  categoryStore: VocCategoryStore;
  buildingStore: VocBuildingStore;
  tabIndex = 0;

  categories: any[] = [];
  searchTypes: any[] = [];
  created_after : string = getIntervalMonthDate(3)[1];
  created_before : string = getIntervalMonthDate(0)[0];
  searchType = -1;
  search = '';
  contents : string = '';
  category1: any[] = [];
  category2: any[] = [];
  category3: any[] = [];
  category4: any[] = [];
  comment: any = null;
  work_site: any;
  roomtype : any;
  authBuilding : any;
  auth_Menu: any;
  employee_number: any;
  answerImages : any = [];
  //소팅
  ordering: string = '';
  //loading
  headerStore: HeaderStore;

  constructor(root: any) {
    super(root);
    this.categoryStore = new VocCategoryStore(this);
    this.buildingStore = new VocBuildingStore(this);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      ordering: observable,
      answerImages : observable,
      auth_Menu: observable,
      employee_number: observable,
      tabIndex: observable,
      work_site: observable,
      categories: observable,
      searchTypes: observable,
      created_after: observable,
      created_before: observable,
      searchType: observable,
      search: observable,
      category1: observable,
      category2: observable,
      category3: observable,
      category4: observable,
      comment: observable,
      roomtype : observable,
      authBuilding : observable,
      contents : observable,
      

      initDatas: action,
      getItems: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      saveItem: action,
      deleteItem: action,
      deleteItems: action,
      newComment: action,
      saveComment: action,
      saveOnlyComment : action,
      statusChage : action,
    });

    this.initDatas();
  }

  initDatas () {
    const _categories = [{ value: '-1', label: '선택', optionIdx: 0 }];
    Object.keys(NOTICE_TYPE).forEach((key: string, index: number) => {
      _categories.push({
        value: key,
        label: NOTICE_TYPE[Number(key)],
        optionIdx: index + 1,
      });
    });
    this.categories = _categories;

    // const _searchTypes = [{ value: '-1', label: '선택', optionIdx: 0 }];
    const _searchTypes : any = [];
    Object.keys(SEARCH_TYPE).forEach((key: string, index: number) => {
      _searchTypes.push({
        value: key,
        label: SEARCH_TYPE[Number(key)],
        optionIdx: index + 1,
      });
    });
    this.searchTypes = _searchTypes;
  }

  init () {
    this.searchType = -1;
    this.tabIndex = 0;
    this.search = '';
    //this.created_after = '';
    //this.created_before = '';
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.item = null;
    this.orgItem = null;
  }

  destory () {
    super.destory();
    this.categoryStore.destory();
    this.buildingStore.destory();
  }

  async getCategories4 () {
    if (!this.item) return;

    const filter = {
      category1: this.item.category1,
      category2: this.item.category2,
      category3: this.item.category3,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getCategories(filter);
      // this.category4 = data.map((item: any) => ({ name: item.category4 }));
      this.category4 = [
        { value: '', name: '4차 분류항목을 선택하세요'},
        ...data.map((item: any) => ({ name: item.category4 }))
      ];
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async getItem(id: string) {
    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getVoc(id);
      if (data) {
        this.selectedItem(data);
      }
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  @debounce(500)
  async getItems() {
    // console.log('getItems: ', this.search, this.limit, this.offset);
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      work_site: this.work_site,
      // roomtype : this.roomtype,
      auth_Menu : this.auth_Menu,
      employee_number : this.employee_number
    };
    if(Number(this.work_site) === 2) {
      if(!isNull(this.authBuilding) && this.authBuilding !== undefined && this.authBuilding.length > 0 && this.authBuilding !== 'null') {
        filter.authBuilding = this.authBuilding;
      }
    }
    // if (this.category > 0) {
    //   filter['category'] = this.category;
    // }

    switch (this.searchType) {
      case 1: filter['title'] = this.search; break;
      case 2: filter['contents'] = this.search; break;
      case 3: filter['author'] = this.search; break;
      default:
        filter['search'] = this.search;
        break;
    }

    if (this.created_after) {
      filter['created_after'] = this.created_after;
    }

    if (this.created_before) {
      filter['created_before'] = this.created_before;
    }
    
    if (this.ordering) {
      filter['ordering'] = this.ordering;
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getVocs(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;

    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
  }

  newItem() {
    this.item = {
      id: -1,
      work_site: this.user.work_site,
      category1: '',
      category2: '',
      category3: '',
      category4: '',
      author_name: this.user.username,
      author_employee_number: this.user.employeeNumber,
      managers: [],
      title: '',
      contents: '',
      images: [],
      files: [],
      places: [],
      places_etc: '',
      places_etc2: '',
      places_room_type: 0,
      is_maintenance: false,
      is_complete: false,
      completed_at: '',
      found_at: '',
      found_at_date: getTodayDate()[0],
      found_at_time: '00',
      created_at: new Date(),
    };
    this.orgItem = { ...this.item };

    this.category1 = [];
    this.category2 = [];
    this.category3 = [];
    this.category4 = [];
  }

  setFoundAt(type: string, value: any) {
    if (this.item) {
      const dtms = this.item.found_at.split(' ');
      if (type === 'date') {
        this.item.found_at = `${value} ${dtms[1]}`;
      } else {
        this.item.found_at = `${dtms[0]} ${value}:00:00`;
      }
    }
  }

  selectedItem(item: any) {
    if (item) {
      item.found_at_date = formatByString_YYYYMMDD(item.found_at);
      item.found_at_time = (new Date(item.found_at).getHours()+'').padStart(2, '0')
      this.item = item;    
      this.orgItem = { ...item };
    } else {
      this.item = null;
      this.orgItem = null;
    }
  }

  get isChange() {
    return this.item && this.orgItem
      ? JSON.stringify(this.item) !== JSON.stringify(this.orgItem)
      : false
  }

  async saveItem() {
    console.log('VocStore.saveItem:', this.item);
    const data: any = {
      work_site: this.item.work_site,
      category1: this.item.category1,
      category2: this.item.category2,
      category3: this.item.category3,
      category4: this.item.category4,
      title: this.item.title,
      contents: this.item.contents,
      is_maintenance: this.item.is_maintenance,
      is_complete: this.item.is_complete,
      completed_at: this.item.completed_at,
      found_at: `${this.item.found_at_date} ${this.item.found_at_time}:00:00`,
      places_etc: this.item.places_etc,
      places_etc2: this.item.places_etc2,
    };

    data.images = this.item.images.map((image: any) => image.id);
    data.files = this.item.files.map((file: any) => file.id);
    data.places = this.item.places.map((place: any) => place.id);
    data.managers = this.item.managers.map((manager: any) => manager.id);

    try {
      this.headerStore.isLoading = true;
      if (this.item.id < 0) {
        await apis.voc.createVoc(data);
      } else {
        await apis.voc.updateVoc(this.item.id, data);
      }
      this.newItem();
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async deleteItem() {
    // console.log('VocStore.deleteItem:', this.item);
    if (this.item) {
      try {
        this.headerStore.isLoading = true;
        await apis.voc.deleteVoc(this.item.id);
        this.headerStore.isLoading = false;
        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItems() {
    const keys = Object.keys(this.checkeds);
    // console.log(keys);
    if (keys.length > 0) {
      try {
        this.headerStore.isLoading = true;
        const actions = keys.map((checkKey: string) => apis.voc.deleteVoc(checkKey));
        await Promise.all(actions);
        this.headerStore.isLoading = false;
        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  newComment () {
    this.comment = {
      voc: this.item.id,
      contents: '',
      author: this.user.username,
      created_at: getTodayDate()[0],
    };
    // this.item.comments.push(this.comment);
    return this.comment;
  }

  async saveComment () {
    // const comment = { ...this.comment };
    this.comment = {
      voc: this.item.id,
      contents: this.comment.contents,
      author: this.user.username,
      created_at: getTodayDate()[0],
    };
    this.comment.images = this.answerImages.map((image: any) => image.id);
    // delete comment.author;
    // delete comment.created_at;
    try {
      this.headerStore.isLoading = true;
      await apis.voc.addComment(this.comment);
      this.comment = null;
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async saveOnlyComment () {
    // const comment = { ...this.comment };
    this.comment = {
      voc: this.item.id,
      contents: this.comment.contents,
      author: this.user.username,
      created_at: getTodayDate()[0],
    };
    this.comment.images = this.answerImages.map((image: any) => image.id);
    // delete comment.author;
    // delete comment.created_at;
    try {
      this.headerStore.isLoading = true;
      await apis.voc.addOnlyComment(this.comment);
      this.comment = null;
      this.contents = '';
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async statusChage () {
    // const comment = { ...this.comment };
    let payload = {
      id: this.item.id,
      is_complete: !this.item.is_complete
    };

    try {
      this.headerStore.isLoading = true;
      await apis.voc.statusChagne(payload);
      
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}

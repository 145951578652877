import _ from 'lodash';
import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { getTodayDate} from '../../utils/date';
import { BoardStore } from './boardStore';
import { addMonths, format } from 'date-fns';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

//공제내역 조회 store
export class DeductDeductibleListPage extends BoardStore {
  tabIndex = 0;
  startYear = ''; //공제관리 리스트 시작year
  startMonth = ''; //공제관리 리스트 시작month
  endYear = ''; //공제관리 리스트 종료year
  endMonth = ''; //공제관리 리스트 종료month
  workSiteName = '';

  //loading
  headerStore : HeaderStore;
  
  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      tabIndex: observable,
      startYear : observable,
      startMonth : observable,
      endYear : observable,
      endMonth : observable,
      workSiteName: observable,
      
      initDatas: action,
      getItems: action,
    });

    this.initDatas();
  }

  initDatas () {
    const startDate = format(addMonths(new Date(getTodayDate()[0]), -3), 'yyyy-MM-dd').split('-'); //조회 시작 년월 3개월전
    const endDate = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');  //조회 종료 년월 1개월전
  
    this.startYear = startDate[0];
    this.startMonth = startDate[1]; 
    this.endYear = endDate[0];
    this.endMonth = endDate[1];
  }

  init () {
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.item = null;
    this.orgItem = null;
  }

  destory () {
    super.destory();
    this.init();
  }

  @debounce(500)
  async getItems() {
    const filter: any = {
      start_date: `${this.startYear}${this.startMonth}`,
      end_date: `${this.endYear}${this.endMonth}`,
    };

    //리스트 조회 api > 마감확정이된 공제내역에 대해서만 조회
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.getDeductList(filter);
      this.items = data.map((item: any, index: number) => ({ index: index + 1, ...item }));
      this.headerStore.isLoading = false;
      
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return;
    }
  }

}

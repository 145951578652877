import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { LocusDocumentType } from '../../hooks/useLocusDocument';

import DefaultTemplete from '../../components/templetes/default';
import LocusTotalTemplete from '../locusTotal';

import RoomTypeSelect from '../../components/atoms/select/roomTypeSelectBox';
import GuestType from '../../components/atoms/select/guestType';
import VisableType from '../../components/atoms/select/visibleType';
import Require from '../../components/atoms/select/require';

import Button from '../../components/atoms/button';

import { worksite } from '../../apis';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { WORK_SITE } from '../../constants';
import { MessageContext, MessageImpl } from '../../routes';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';


// 이용자 범위설정
const LocusDocument = observer((props: any) => {
  const { userInfo = {}, worksites = [], worksiteMap = {} } = useSelector((state: any) => ({
    worksites: state.base.worksites,
    worksiteMap: state.base.worksiteMap,
    userInfo: state.user
  }))
  const {headerStore: headerStore} = useStores();
  const [name, setName] = useState<string>("");
  const [roomType, setRoomType] = useState<number>(1);
  const [guestType, setGuestType] = useState<number>(1);
  const [visibleType, setVisibleType] = useState<number>(1);
  const [isRequire, setIsRequire] = useState<number>(-1);
  const id = props.match.params.id;
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  useEffect(() => {
    headerStore.getAuthMenuRole('1-1');
    (async () => {
      try{
        headerStore.isLoading = true;
        let { data }: {data: LocusDocumentType} = await worksite.getDocument(id, {})
        setName(data.name);
        setRoomType(data.room_type);
        setGuestType(data.guest_type);
        setVisibleType(data.visible_type);
        setIsRequire(data.is_required ? 1 : 0);
        headerStore.isLoading = false;
      }catch({status, data}){
        headerStore.isLoading = false;
        // console.log(data);
        // console.log(status);
        if (status === 400) {
          info('조회중 문제가 발생 하였습니다.')
          return;
        }else if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
          info('조회중 문제가 발생 하였습니다.')
          return;
      }
    })();
  }, [])

  const onSaveHandler = async () => {

    if(roomType !== 3 && visibleType === 2){
      info('배방시 입숙서류는 사택만 등록 하실 수 있습니다.');
      return
  }

    const payload: any = {
      work_site: headerStore.workSite,
      room_type: roomType,
      guest_type: guestType,
      visible_type: visibleType,
      is_required: isRequire ? 1 : 0,
      name,
    }

    try {
      headerStore.isLoading = true;
      let { data } = await worksite.updateDocument(id, payload);
      info('수정이 완료되었습니다.');
      props.history.replace('/locus/guest/document');
      headerStore.isLoading = false;
    } catch ({ data, status }) {
      headerStore.isLoading = false;
      // console.log(data);
      // console.log(status);
      if (status === 400) {
        info('이미 등록되어 있습니다.');
        return;
      }else if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
        info('이미 등록되어 있습니다.');
        return;
    }
  }

  const onDeleteHandler = async () => {
    try {
      headerStore.isLoading = true;
      let { data } = await worksite.deleteDocument(id, {});
      info("삭제가 완료 되었습니다.");
      props.history.push('/locus/guest/document');
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제발생");
      return;
    }
  }

  return (
    <LocusTotalTemplete
      className=""
      id="wrap"
      isLoading={headerStore.isLoading}
      {...props}
    >
      <h4>입숙서류 상세</h4>

      {/* table start */}
      <div className="tab_contents on">
        <div className="table_normal_list table_write" style={{ overflowX: 'auto' }}>
          <table>
            <caption className="sr_only">입숙서류 상세</caption>
            <colgroup>
              <col width="15%"></col>
              <col width="35%"></col>
              <col width="15%"></col>
              <col width="35%"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>사업장</th>
                <td>{WORK_SITE[headerStore.workSite]}</td>
                <th>숙소유형</th>
                <td>
                  <RoomTypeSelect value={roomType} onChange={(roomType: number) => setRoomType(roomType)}/>
                </td>
              </tr>
              <tr>
                <th>이용자 타입</th>
                <td>
                  <GuestType onlyData value={guestType} onChange={(guestType: number) => setGuestType(guestType)}/>
                </td>
                <th>표시공간</th>
                <td>
                 <VisableType onlyData value={visibleType} onChange={(isVisable: number) => setVisibleType(isVisable)}/>
                </td>
              </tr>
              <tr>
                <th>필수여부</th>
                <td>
                  <Require onlyData value={isRequire} onChange={(isRequire: number) => setIsRequire(isRequire)} />
                </td>
                <th>분류</th>
                <td >
                  <input className="form_control" value={name} onChange={(e: any) => setName(e.target.value)}></input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* 하단버튼 */}
      <div className="submit">
        <div className="fl">
          <Button large backgroundGray onClick={() => props.history.push('/locus/guest/document')}>목록</Button>
        </div>
        <div className="fr">
          {headerStore.writeRoleFlag ? 
            <Button large background onClick={onSaveHandler}>수정</Button> : <></>
          }
          {headerStore.deleteRoleFlag ? 
            <Button large outline onClick={onDeleteHandler}>삭제</Button> : <></>
          }
        </div>
      </div>
    </LocusTotalTemplete>
  );
});

export default LocusDocument;

import {action, computed, makeObservable, observable} from 'mobx';
import * as apis from '../../apis';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { BoardStore } from './boardStore';
import { HeaderStore } from './headerStore';

export class VocCategoryStore extends BoardStore {
  tabIndex = 0;
  category1 = '';
  category2 = '';
  category3 = '';
  category4 = '';
  categories1: any[] = [];
  categories2: any[] = [];
  categories3: any[] = [];
  categories4: any[] = [];

  //권한
  worksite : any;

  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      tabIndex: observable,
      categories1: observable,
      categories2: observable,
      categories3: observable,
      categories4: observable,
      category1: observable,
      category2: observable,
      category3: observable,
      category4: observable,
      
      isChange: computed,
      getItems: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      saveItem: action,
      deleteItem: action,
      deleteItems: action,
    });
  }

  init () {
    this.category1 = '';
    this.category2 = '';
    this.category3 = '';
    this.category4 = '';
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.checkeds = {};
    this.item = null;
    this.orgItem = null;
    this.categories1 = []
    this.categories2 = []
    this.categories3 = []
    this.categories4 = []
  }

  async getItem(id: string) {
    // console.log('getItem', id);
  }

  async getItems() {
    // console.log('VocCategoryStore.getItems: ', this.limit, this.offset);
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      worksite : this.worksite,
    };

    // if (this.item && this.item.category1) {
    //   filter['category1'] = this.item.category1;
    // }

    // if (this.item && this.item.category2) {
    //   filter['category2'] = this.item.category2;
    // }

    // if (this.item && this.item.category3) {
    //   filter['category3'] = this.item.category3;
    // }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.voc.getCategories(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;

    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  cancelItem() {}

  newItem() {
    this.category1 = '';
    this.category2 = '';
    this.category3 = '';
    this.category4 = '';
    this.item = {
      id: -1,
      category1: '',
      category2: '',
      category3: '',
      category4: '',
    }
    this.orgItem = { ...this.item };
  }

  selectedItem(item: any) {
    if (item) {
      this.item = item;
      this.category1 = item.category1;
      this.category2 = item.category2;
      this.category3 = item.category3;
      this.category4 = item.category4;
      this.orgItem = { ...item };
    } else {
      this.item = null;
      this.orgItem = null;
    }
  }

  get isChange() {
    if (!this.orgItem || this.category4.length === 0) return false;
    return JSON.stringify({
      category1: this.orgItem.category1,
      category2: this.orgItem.category2,
      category3: this.orgItem.category3,
      category4: this.orgItem.category4,
    }) !== JSON.stringify({
      category1: this.category1,
      category2: this.category2,
      category3: this.category3,
      category4: this.category4,
    });
  }

  async saveItem() {
    //debugger;
    const data: any = {
      category1: this.category1,
      category2: this.category2,
      category3: this.category3,
      category4: this.category4,
     };
    //  console.log(data);
    try {
      this.headerStore.isLoading = true;
      if (this.item.id < 0) {
        await apis.voc.createCategory(data);
      } else {
        await apis.voc.updateCategory(this.item.id, data);
      }
      await this.getItems();
      this.newItem();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async deleteItem() {
    // console.log('VocCategoryStore.deleteItem:', this.item);
    if (this.item) {
      try {
        this.headerStore.isLoading = true;
        await apis.voc.deleteCategory(this.item.id);
        await this.getItems();
        this.headerStore.isLoading = false;
        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItems() {
    const keys = Object.keys(this.checkeds);
    if (keys.length > 0) {
      try {
        this.headerStore.isLoading = true;
        const actions = Object.keys(this.checkeds).map((checkKey: string) => apis.voc.deleteCategory(checkKey));
        await Promise.all(actions);
        await this.getItems();
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }
}

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import TempleteDefault from '../../components/templetes/default';
import { ROOM_TYPE, ROOM_TYPE_CODE, GUEST_TYPE, MANAGEMENT_CATEGORY, GUEST_TYPE_CODE, LODGE_STATUS_CODE } from '../../constants';
import { formatByString_YYYYMMDD, getDate, getDays, getMonth, getMonths, getTodayDate, getYear, getYear5 } from '../../utils/date';
import GuestDetailForm from './guestDetail';
import MngFileUpload from '../../components/organismas/form/managementFile';
import { MessageContext, MessageImpl } from '../../routes';
import { showConfirm } from '../../utils/common';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import './index.scss';
import BackroomDormitoryPopup from '../../components/organismas/popup/dormitoryBackroom';
import VipBackRoom from '../../components/organismas/popup/vipBackroom';
import CompanyHouseBackroom from '../../components/organismas/popup/companyHouseBackroom';
import FamilyBackroom from '../../components/organismas/popup/familyBackroom';
import BusinessSingleBackroom from '../../components/organismas/popup/businessSingleBackroom';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { core } from '../../apis';
import FileSaver from 'file-saver';

const LodgedHistoryDetail = observer((props: any) => {
    const {lodgedHistoryStore: store} = useStores();
    const {headerStore: headerStore} = useStores();
    const {loadgedTotalStore: checkoutStore} = useStores();
    const {applicantJudgeStore: roomStore} = useStores();
    const dispatch = useDispatch();
    const { info }: MessageImpl = useContext(MessageContext);
    const [isGroupListPop, setIsGroupListPop] = useState<boolean>(false);
    const [isHistoryMngWritePop, setIsHistoryMngWritePop] = useState<boolean>(false);
    const [isHistoryMngPop, setIsHistoryMngPop] = useState<boolean>(false);
    const [detailId, setDetailId] = useState<number>();
    const [isCheckOutPop, setIsCheckOutPop] = useState<boolean>(false);
    const [isImgPop, setIsImgPop] = useState<boolean>(false);
    const [imgPath, setImgPath] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    },[reload]);

    const fetchData = async() => {
        try {
            headerStore.isLoading = true;
            const id: number = parseInt(props.match.params.id);
            setDetailId(id);
            await store.getItem(id);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
    }

    useEffect(() => {
        headerStore.getAuthMenuRole('2-1');
    },[]);

    const openGroupList = () => {
        // 단체 리스트 담기
        store.groupList = store.guestData.related_guests;
        setIsGroupListPop(true);
    }

    // 관리내역 수정 버튼 클릭
    const managementUpdate = async (id: number) => {
        try {
            headerStore.isLoading = true;
            await store.managementUpdateFrm(id);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
        setIsHistoryMngWritePop(true);
    }

    // 관리내역 등록/수정
    const managementCreate = async () => {
        if(store.category === null || store.category === 0) {
            info("카테고리를 선택해주세요.");
            return;
        }else if(store.contents === null || store.contents === "") {
            info("내용을 입력해주세요.");
            return;
        }

        try {
            headerStore.isLoading = true;
            let data : any = await store.managementSave();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('저장되었습니다');
                    store.getItem(detailId || 0);
                }else {
                  return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
        setIsHistoryMngWritePop(false);
    }

    // 관리내역 삭제
    const managementDelete = (id:number) => {
        showConfirm('삭제하시겠습니까?', dispatch, async () => {
            if(id) {
                try {
                    headerStore.isLoading = true;
                    let data : any = await store.managementDelete(id);
                    headerStore.isLoading = false;
                    setTimeout(function () {
                        if(data.save) {
                            info('삭제되었습니다');
                            store.getItem(detailId || 0);
                        }else {
                          return;
                        }
                    }, 250);
                } catch ({ status, data }) {
                    headerStore.isLoading = false;
                    if (status === 401) {
                      if (!window.localStorage.getItem("isRetryLogin")) {
                        window.localStorage.setItem("isRetryLogin", "retry");
                      }
                      window.location.href = '/';
                      return ;
                    } else if (status === 403) {
                      (window as any).store.dispatch({
                        type: SHOW_ALERT_MODAL,
                        payload: {
                          title: '권한오류',
                          msg: '접근권한이 없습니다.',
                          redirectPath: false,
                          statusCode: status,
                          errorCode: '',
                          type: 'error',
                          isModalShow: true,
                        },
                      });
                      return ;
                    }else if ( status === 500 ) {
                      window.location.href = '/500';
                      return;
                    }
                    return;
                }
            }
        });
    }

    // 이용이력 -> 관리내역 조회 팝업
    const openMngPop = async (gId: number) => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.lodgeManagementList(gId);
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    if(store.guestMngList.length > 0){
                      setIsHistoryMngPop(true);
                    }else {
                      setIsHistoryMngPop(false);
                      info('관리내역이 없습니다.');
                    }
                }else {
                  return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
    }

    // 퇴숙처리 팝업 초기화
    const popInit = () => {
        checkoutStore.manageYear = getYear();
        checkoutStore.manageMonth = getMonth();
        checkoutStore.manageDay = getDate();
        checkoutStore.isKeyReturn = true;
        checkoutStore.keyDescription = ''
        checkoutStore.keySelect = '열쇠 분실';
        checkoutStore.isEquipment  = false;
        checkoutStore.equipmentDescription = '';
        checkoutStore.images = [];
        checkoutStore.mealTicket = 0;
    }

    // 퇴숙처리
    const saveCheckOut = async() => {
        showConfirm('퇴숙처리 하시겠습니까?', dispatch, async () => {
            if(checkoutStore.manageYear === '' || checkoutStore.manageMonth === '' || checkoutStore.manageDay === ''){
                info('필수 입력사항을 체크하여주세요');
                return false;
            }else if(!checkoutStore.isKeyReturn && (checkoutStore.keyDescription === '' || checkoutStore.keyDescription === null)){
                info('열쇠 미 반납 이유를 적어주세요');
                return false;
            }else if(checkoutStore.isEquipment && (checkoutStore.equipmentDescription === '' || checkoutStore.equipmentDescription === null)){
                info('비품 이상 여부를 적어주세요');
                return false;
            }

            //if(!store.isKeyReturn)
            try {
                headerStore.isLoading = true;
                checkoutStore.checkoutData = store.guestData;
                let data : any = await checkoutStore.checkOut();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsCheckOutPop(false);
                        info('퇴숙처리가 완료되었습니다');
                        popInit();
                        fetchData();
                    }else {
                      return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                return;
            }
        });
    }

    // 방변경 버튼 클릭
    const RoomSelectHandler = async() => {
        roomStore.workSite = headerStore.workSite;
        roomStore.selectRow = store.guestData;
        //roomStore.selectRow.stay_after = store.guestData.checkin_at;
        roomStore.selectRow.stay_after = getTodayDate()[0];
        roomStore.selectRow.stay_before = store.guestData.stay_before;
        roomStore.selectRow.room_work_site = headerStore.workSite;
        roomStore.selectRow.booking_id = store.bookingData.id; 
        roomStore.selectRow.guest_id = detailId;    // 강제 이숙용 guestId
        roomStore.manageCheckChange = true;      // 강제 이숙 확인 데이터

        if(store.guestData.room_type === ROOM_TYPE_CODE.vip_room) {     // 영빈관
            roomStore.isVipRoomPop = true;
        }else if(store.guestData.room_type === ROOM_TYPE_CODE.company_room) {      // 사택
            roomStore.isCompanyRoomPop = true;
        }else if(store.guestData.room_type === ROOM_TYPE_CODE.dormitory_room) {     // 기숙사
            roomStore.isDormitoryPop = true;
        }else if(store.guestData.room_type === ROOM_TYPE_CODE.business_room_group || store.guestData.room_type === ROOM_TYPE_CODE.business_room) {     // 출장자 단체
            roomStore.isBusinessSinglePop = true;
        }else if(store.guestData.room_type === ROOM_TYPE_CODE.family_room) {     // 가족숙소
            let tmpList = [];
            tmpList[0] = store.guestData;
            roomStore.selectRow.guestList = tmpList;
            //roomStore.isBusinessSinglePop = true;
            roomStore.isFamilyPop = true;
        }
    }

    const moveComplete = async() => {
        try {
            headerStore.isLoading = true;
            await fetchData();
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
    }

    
    return (
        <>
        <TempleteDefault
            {...props}
            id="wrap"
            className=""
            isLoading={headerStore.isLoading}
        >
            <ul className="lst_roomtype mt30">
                <li>
                    <strong>사업장</strong>
                    <span>{store.guestData.room_work_site}</span>
                </li>
                <li>
                    <strong>숙소유형</strong>
                    <span>{ROOM_TYPE[store.guestData.room_type]}</span>
                </li>
                <li>
                    <strong>동호수</strong>
                    <span>{store.guestData.room_building_name} {store.guestData.room_building_dong} {store.guestData.room_name}</span>
                </li>
                <li>
                    <strong>입숙일</strong>
                    <span>{store.guestData.checkin_at}</span>
                </li>
                {store.guestData.room_type === ROOM_TYPE_CODE.dormitory_room || store.guestData.room_type === ROOM_TYPE_CODE.company_room ? 
                    (<li>
                        <strong>만료일</strong>
                        <span>{store.guestData.expired_at}</span>
                    </li>) : ''
                }
                <li>
                    <strong>퇴숙일</strong>
                    <span>{store.guestData.checkout_at ? store.guestData.checkout_at : '-'}</span>
                </li>
                <li>
                    <strong>신청자ID</strong>
                    <span>{store.bookingData.applicant_employee_number}</span>
                </li>
                <li>
                    <strong>신청자휴대폰</strong>
                    <span>{store.bookingData.applicant_cellphone}</span>
                </li>
            </ul>

            <h4>
                이용자정보
                <div className="right_area">
                    {/* {bookingData.room_type === ROOM_TYPE_CODE.dormitory_room || bookingData.room_type === ROOM_TYPE_CODE.company_room? 
                        <button type="button" className="btn btn_xs btn_outline">입숙신청서류</button> : ''
                    } */}
                    {store.guestData.room_type === ROOM_TYPE_CODE.business_room_group ? <button type="button" className="btn btn_xs btn_outline" onClick={openGroupList}>단체이용자내역</button> : '' }
                </div>
            </h4>
            <GuestDetailForm guestData={store.guestData} booking={store.bookingData} reload={()=>setReload(!reload)}/>

            {/* 퇴숙 완료인 경우 */}
            {store.guestData.checkout_at ? (
                <>
                <h4>퇴숙정보</h4>
                 <div className="table_normal_list table_write">
                    <table>
                        <caption className="sr_only">퇴숙정보</caption>
                        <colgroup>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>최종 퇴숙일</th>
                            <td>{store.guestData.checkout_at}</td>
                            <th>열쇠 반납 여부</th>
                            <td>
                                {store.guestData.is_key_return? '반납 완료' : 
                                store.guestData.key_select !== "기타" ? '미 반납 (' + store.guestData.key_select +')' :
                                '미 반납 (' + store.guestData.key_select + ' : ' + store.guestData.key_description + ' )'}
                            </td>
                            <th>비품 이상 여부</th>
                            <td>{!store.guestData.is_equipment_ok? '이상 없음' : '이상 있음 (' + store.guestData.equipment_description + ')'}</td>
                        </tr>
                        {store.guestData.room_type === ROOM_TYPE_CODE.business_room || store.guestData.room_type === ROOM_TYPE_CODE.business_room_group? (
                            <tr>
                                <th>식권수량</th>
                                <td>{store.guestData.meal_ticket}</td>
                                <th>반납수량</th>
                                <td>{store.guestData.return_meal_ticket}</td>
                                <th>사용수량</th>
                                <td>{(store.guestData.meal_ticket - store.guestData.return_meal_ticket)}</td>
                            </tr>
                        ): ''}
                        <tr>
                            <th>숙소 이미지</th>
                            <td colSpan={5}>
                                <div className="images_area">
                                    {store.guestData.checkout_files && store.guestData.checkout_files.length > 0 ? (
                                        store.guestData.checkout_files.map((image: any, index: number) => (
                                            <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                                <a key={index} >
                                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                                </a>
                                            </div>
                                        ))
                                    ) :''}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> 
                </>
            ) :''}

            <h4>관리내역</h4>
            {store.mngList.length <= 0 ? (
                <div className="table_normal_list table_write">
                    <table>
                    <caption className="sr_only">관리내역</caption>
                        <colgroup>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="120"/>
                        </colgroup>
                    <tbody>
                        <tr>
                            <td colSpan={7} className="blank_area ac">
                                <p className="txt_blank">작성된 관리내역이 없습니다</p>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            ): (
                store.mngList.map((list: any, index: number) => (
                    <div key={index} className={index > 0 ? "table_normal_list table_write mt20" : "table_normal_list table_write"}>
                    <table>
                        <caption className="sr_only">관리내역</caption>
                        <colgroup>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="120"/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>작성자</th>
                                <td>{list.author}</td>
                                <th>작성일</th>
                                <td>{formatByString_YYYYMMDD(list.created_at)}</td>
                                <th>카테고리</th>
                                <td>{MANAGEMENT_CATEGORY[list.category]}</td>
                                <td rowSpan={3} className="ac bdl">
                                    {headerStore.writeRoleFlag ? 
                                        <button type="button" className="btn btn_xs btn_gray" onClick={() => managementUpdate(list.id)}>수정</button> : <></>
                                    }
                                    {headerStore.deleteRoleFlag ? 
                                        <button type="button" className="btn btn_xs btn_outline mt10" onClick={() => managementDelete(list.id)}>삭제</button> : <></>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td colSpan={5}>
                                    <div className="contents_area">
                                        <pre>{list.contents}</pre>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>증빙서류</th>
                                <td colSpan={5}>
                                    {list.files? (
                                        list.files.map((file: any, index: number) => (
                                            <>
                                                {headerStore.downRoleFlag ? 
                                                    <a key={index} href={`${process.env.REACT_APP_MEDIA}/${file.path}`} className="item file"
                                                    target={`${file.path}`.split('.').pop() 
                                                            && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                                                            && !isMobile ? '_blank' : ''}
                                                    >
                                                        {file.name}
                                                    </a>
                                                    :<a key={index} className="item file">{file.name}</a>
                                                }
                                            </>
                                        ))
                                    ) : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                ))
            )}

            <h4 style={{ display: store.bookingData.room_type === ROOM_TYPE_CODE.family_room || store.guestData.guest_type !== GUEST_TYPE_CODE.inner_employer ? 'none' : '' }}>이용이력</h4>
            <div className="table_normal_list" style={{ display: store.bookingData.room_type === ROOM_TYPE_CODE.family_room || store.guestData.guest_type !== GUEST_TYPE_CODE.inner_employer ? 'none' : '' }}>
                <table>
                    <caption className="sr_only">이용이력</caption>
                    <colgroup>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>사업장</th>
                        <th>숙소유형</th>
                        <th>동호수</th>
                        <th>입숙일</th>
                        <th>퇴숙(이숙)일</th>
                        <th>비고</th>
                    </tr>
                    </thead>
                    <tbody>
                    {store.historyList <=0 ? (
                        // 데이타 없을 시
                        <tr>
                            <td colSpan={6} className="blank_area">
                                <p className="txt_blank">이용이력이 없습니다</p>
                            </td>
                        </tr>
                    ) : ( store.historyList.map((list: any, historyIdx: number) => (
                            <tr key={historyIdx}>
                                <td>{list.room_work_site}</td>
                                <td>{ROOM_TYPE[list.room_type]}</td>
                                <td>{list.room_building_name} {list.room_building_dong} {list.room_name}</td>
                                <td>{list.checkin_at}</td>
                                <td>{list.checkout_at}</td>
                                <td><button type="button" className="btn btn_xs btn_outline" onClick={(e) => openMngPop(list.id)}>관리내역</button></td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>  {/*table*/}

            <div className="submit">
                <div className="fl">
                    <button className="btn btn_lg btn_gray" onClick={() => props.history.replace('/guest/history')}>목록</button>
                </div>
                <div className="fr">
                    {headerStore.mobileRoleFlag && Number(store.guestData.status) === LODGE_STATUS_CODE.checkin? <button className="btn btn_lg btn_darkblue" onClick={(e) => RoomSelectHandler()}>방변경</button>:<></>}
                    {headerStore.finallyRoleFlag && Number(store.guestData.status) === LODGE_STATUS_CODE.checkin? <button className="btn btn_lg btn_darkblue" onClick={(e) => setIsCheckOutPop(true)}>퇴숙처리</button>:<></>}
                    {headerStore.writeRoleFlag? <button className="btn btn_lg btn_darkblue" onClick={(e) => setIsHistoryMngWritePop(true)}>관리내역작성</button>:<></>}
                </div>
            </div>
        </TempleteDefault>

        {/* 이미지 팝업 */}
        <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
            <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
            <div className="pop_wrap">
                <div className="contents_wrap">
                    <div className="slide">
                        <p className="img_area">
                            <img src={imgPath} alt=""/>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        {/* 출장자 단체 : 팝업 */}
        <div id="pop_userinfo_group" className="pop_dim_wrap pop_modal pop_lg"
            style={{ display: isGroupListPop ? 'block' : 'none' }}
        >
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>단체이용자정보</h3>
                    <button className="pop_btn_close pop_close" onClick={(e) => {setIsGroupListPop(false); store.initData();}}>닫기</button>
                </div>
                <div className="contents_wrap">
                    {store.groupList.length <=0 ? (
                        <div className="table_normal_list table_write">
                            <table>
                                <caption className="sr_only">이용자정보</caption>
                                <colgroup>
                                    <col width="160"/>
                                    <col width="*"/>
                                    <col width="160"/>
                                    <col width="*"/>
                                    <col width="160"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <td colSpan={6} className="blank_area type_white">
                                        <p className="txt_blank">데이터가 없습니다.</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        store.groupList.map((group: any, index: number) => (
                            <>
                            <div key={index} className="user_info pt10"><span className="user_name">이용자</span>{group.guest_name}</div>
                            <div key={index} className="table_normal_list table_write">
                                <table>
                                    <caption className="sr_only">이용자정보</caption>
                                    <colgroup>
                                        <col width="160"/>
                                        <col width="*"/>
                                        <col width="160"/>
                                        <col width="*"/>
                                        <col width="160"/>
                                        <col width="*"/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <th>소속회사유형</th>
                                        <td>{GUEST_TYPE[group.guest_type]}</td>
                                        <th>회사명(단체명)</th>
                                        <td>{group.guest_company? group.guest_company : '-'}</td>
                                        <th>사번</th>
                                        <td>{group.guest_employee_number}</td>
                                    </tr>
                                    <tr>
                                        <th>성별</th>
                                        <td>{group.guest_gender === 1 ? '남' : '여'}</td>
                                        <th>휴대폰번호</th>
                                        <td>{group.guest_cellphone}</td>
                                        <th>소속부서</th>
                                        <td>{group.guest_department}</td>
                                    </tr>
                                    <tr>
                                        <th>차량번호</th>
                                        <td>{group.guest_car_number1}</td>
                                        {/* <th>식권 수(장)</th>
                                        <td>{group.meal_ticket_provide_count}</td> */}
                                        <th>인솔자여부</th>
                                        <td>{group.is_leader? '여' : '부'}</td>
                                        <th>입숙일</th>
                                        <td>{group.checkin_at}</td>
                                    </tr>
                                    <tr>
                                        <th>퇴숙일</th>
                                        <td className="br0">{group.checkout_at}</td>
                                        <td className="blank" colSpan={4}></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>  {/*table*/}
                            </>
                        ))
                    )}
                </div>
                <div className="button_wrap">
                    <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={(e) => {setIsGroupListPop(false); store.initData();}}>확인</button>
                </div>
            </div>
        </div>  {/*단체이용자정보*/}
        
         {/* 관리내역 */}
        <div id="pop_history_manage" className="pop_dim_wrap pop_modal pop_lg"
            style={{ display: isHistoryMngPop ? 'block' : 'none' }}
        >
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>관리내역</h3>
                    <button className="pop_btn_close pop_close" onClick={(e) => {setIsHistoryMngPop(false); store.initData();}}>닫기</button>
                </div>
                <div className="contents_wrap">
                {store.guestMngList.length > 0 ? (
                    store.guestMngList.map((mng: any, index: number) => (
                        <div key={index} className={index > 0? "table_normal_list table_write mt20" : "table_normal_list table_write"}>
                            <table>
                                <caption className="sr_only">관리내역 상세</caption>
                                <colgroup>
                                    <col width="130"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>작성자</th>
                                    <td>{mng.author}</td>
                                </tr>
                                <tr>
                                    <th>작성일</th>
                                    <td>{formatByString_YYYYMMDD(mng.created_at)}</td>
                                </tr>
                                <tr>
                                    <th>카테고리</th>
                                    <td>{MANAGEMENT_CATEGORY[mng.category]}</td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td>
                                        <div className="contents_area">
                                            <pre>{mng.contents}</pre>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>증빙서류</th>
                                    <td>
                                        {mng.files? (
                                            mng.files.map((file: any, index: number) => (
                                                <>
                                                {headerStore.downRoleFlag ? 
                                                    <a key={index} href={`${process.env.REACT_APP_MEDIA}/${file.path}`} className="item file">
                                                        {file.name}
                                                    </a> 
                                                    : <a key={index} className="item file">{file.name}</a>
                                                }
                                                </>
                                            ))
                                        ) : ''}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    ))
                ) : ''}
                </div>
                <div className="button_wrap">
                    <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={(e) => {setIsHistoryMngPop(false); store.initData();}}>확인</button>
                </div>
            </div>
        </div>  {/*관리내역*/}

        <div id="pop_history_manage_write" className="pop_dim_wrap pop_modal pop_lg"
            style={{ display: isHistoryMngWritePop ? 'block' : 'none' }}
        >
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>관리내역 등록</h3>
                    <button className="pop_btn_close pop_close"  onClick={(e) => {setIsHistoryMngWritePop(false); store.initData();}}>닫기</button>
                </div>
                <div className="contents_wrap">
                    <div className="table_normal_list table_write">
                        <table>
                            <caption className="sr_only">관리내역 등록</caption>
                            <colgroup>
                                <col width="120"/>
                                <col width="*"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>작성자</th>
                                <td>{store.author}</td>
                            </tr>
                            <tr>
                                <th>작성일시</th>
                                <td>{store.mngCreateAt}</td>
                            </tr>
                            <tr>
                                <th>카테고리</th>
                                <td>
                                    <select name="" id="" className="form_control" value={store.category} onChange={(e: any) => store.category = e.target.value}>
                                        {Object.keys(MANAGEMENT_CATEGORY).map((key: string, index: number) => (
                                            <option key={index} value={key}>
                                                {MANAGEMENT_CATEGORY[Number(key)]}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <div className="contents_area">
                                        <textarea name="" className="form_control" value={store.contents} onChange={(e) => store.contents = e.target.value}></textarea>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>  {/*table*/}
                    <div className="table_normal_list mt20">
                        <table>
                            <caption className="sr_only">관리내역 등록</caption>
                            <colgroup>
                                <col width="*"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>증빙서류</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="al">
                                    <MngFileUpload  type="file" fileId="attacheFile" items={store.files} onSetData={(data: any[]) => store.files = [...data]} />
                                    {/* <div className="wrap_file w100 mb10">
                                        <input id="input_copy" className="upload_name form_control" placeholder="" disabled={true}/>
                                        <input type="file" id="input_upload_copy" className="upload_hidden"/>
                                        <label htmlFor="input_upload_copy" className="btn btn_sm btn_gray">파일찾기</label>
                                    </div>
                                    <a href="javascript:void(0);" className="item del">숙소이용규정.PDF</a>
                                    <a href="javascript:void(0);" className="item del">숙소이용규정.PDF</a> */}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>  {/*table*/}
                </div>
                <div className="button_wrap">
                    <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={managementCreate}>완료</button>
                </div>
            </div>
        </div>  {/*관리내역 등록*/}

        {/* 퇴숙처리 팝업 */}
        <div id="pop_change_request" className="pop_dim_wrap pop_modal pop_sm" style={{display: isCheckOutPop? 'block': 'none'}}>
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>퇴숙처리</h3>
                    <button className="pop_btn_close pop_close" onClick={() =>{popInit(); setIsCheckOutPop(false)}}>닫기</button>
                </div>
                <div className="contents_wrap">
                    {/* <ul className="request_date">
                        <li>
                            <strong>퇴숙요청일</strong>
                            <span>{checkoutStore.checkOutDetailItem.stay_before}</span>
                        </li>
                        <li>
                            <strong>신청일</strong>
                            <span></span>
                        </li>
                    </ul> */}
                    <div className="process_list">
                        <dl>
                            <dt>열쇠 반납 여부</dt>
                            <dd>
                                <div className="radio_box">
                                    <label className="input_radio" htmlFor="return_check">
                                        <input type="radio" id="return_check" name="return" checked={checkoutStore.isKeyReturn} onClick={() => checkoutStore.isKeyReturn = true}/> 
                                        <span className="label_text">반납 완료</span>
                                    </label>
                                    <label className="input_radio" htmlFor="return_check2">
                                        <input type="radio" id="return_check2" name="return" checked={!checkoutStore.isKeyReturn} onClick={() => checkoutStore.isKeyReturn = false}/> 
                                        <span className="label_text">미 반납</span>
                                    </label>
                                </div>
                                
                                {!checkoutStore.isKeyReturn? (
                                    <div className="input_box">
                                        <select className="form_control" value={checkoutStore.keySelect} onChange={(e:any) => checkoutStore.keySelect = e.target.value}>
                                            <option value="열쇠 분실">열쇠 분실</option>
                                            <option value="기타">기타</option>
                                        </select>
                                        <input type="text" className="form_control" placeholder="미 반납 이유를 적어주세요."
                                            value={checkoutStore.keyDescription} onChange={(e:any) => checkoutStore.keyDescription = e.target.value}/>
                                    </div>
                                ) : ''}
                            </dd>

                            <dt>비품 이상 여부</dt>
                            <dd>
                                <div className="radio_box">
                                    <label className="input_radio" htmlFor="fixtures_check">
                                        <input type="radio" id="fixtures_check" name="fixtures" checked={!checkoutStore.isEquipment} onClick={() => checkoutStore.isEquipment = false}/> 
                                        <span className="label_text">이상 없음</span>
                                    </label>
                                    <label className="input_radio" htmlFor="fixtures_check2">
                                        <input type="radio" id="fixtures_check2" name="fixtures" checked={checkoutStore.isEquipment} onClick={() => checkoutStore.isEquipment = true}/> 
                                        <span className="label_text">이상 있음</span>
                                    </label>
                                </div>

                                {checkoutStore.isEquipment? (
                                    <input type="text" className="form_control" placeholder="비품 이상 여부를 적어주세요."
                                        value={checkoutStore.equipmentDescription} onChange={(e:any) => checkoutStore.equipmentDescription = e.target.value}/>
                                ) : ''}
                            </dd>

                            {store.guestData.room_type === ROOM_TYPE_CODE.business_room || store.guestData.room_type === ROOM_TYPE_CODE.business_room_group? (
                                <>
                                    <ul className="request_date">
                                        <li>
                                            <strong>식권수량</strong>
                                            <span>{store.guestData.meal_ticket}</span>
                                        </li>
                                        <li>
                                            <strong>반납수량</strong>
                                            <span>
                                                <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요" style={{'width':'50px'}}
                                                    value={checkoutStore.mealTicket} onChange={(e:any) => checkoutStore.mealTicket = parseInt(e.target.value) || 0}
                                                    maxLength={4}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            <strong>사용수량</strong>
                                            <span>{(store.guestData.meal_ticket-checkoutStore.mealTicket)}</span>
                                        </li>
                                    </ul>
                                </>
                            ) : '' }
                        </dl>
                    </div>

                    <div className="table_normal_list mt10">
                        <table>
                            <caption className="sr_only">숙소 이미지 첨부</caption>
                            <colgroup>
                                <col width="*"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th className="ac">숙소 이미지 첨부</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="al">
                                    <MngFileUpload type="image" fileId="attacheFile" items={checkoutStore.images} onSetData={(data: any[]) => checkoutStore.images = [...data]} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>  {/*table*/}

                    <div className="process_list mt20">
                        <dl>
                            <dt>최종 퇴숙일</dt>
                            <dd>
                                <select className="form_control year" value={checkoutStore.manageYear} onChange={(e:any) => checkoutStore.manageYear = e.target.value}>
                                    {getYear5(true).map((year:any, index: number) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                                <select className="form_control month" value={checkoutStore.manageMonth} onChange={(e:any) => checkoutStore.manageMonth = e.target.value}>
                                    {getMonths(checkoutStore.manageYear, true).map((year:any, index: number) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                                <select className="form_control day" value={checkoutStore.manageDay} onChange={(e:any) => checkoutStore.manageDay = e.target.value}>
                                    {getDays(checkoutStore.manageYear, checkoutStore.manageMonth, false).map((year:any, index: number) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                            </dd>
                            {/* <dt>비고입력</dt>
                            <dd>
                                <textarea className="form_control"></textarea>
                            </dd> */}
                        </dl>
                    </div>
                </div>
                <div className="button_wrap btn_length_1">
                    {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                    <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={saveCheckOut}>완료</button>
                </div>
            </div>
        </div>

        {/* 기숙사 배방 */}
        <BackroomDormitoryPopup 
            moveComplete={moveComplete}
        /> 

        {/* 영빈관 배방 */}
        <VipBackRoom 
            moveComplete={moveComplete}
        />

        {/* 사택 배방 */}
        <CompanyHouseBackroom 
            moveComplete={moveComplete}
        />

        {/* 가족숙소 배방 */}
        <FamilyBackroom 
            moveComplete={moveComplete}
        />

        {/* 출장자 개인/단체 */}
        <BusinessSingleBackroom 
            moveComplete={moveComplete}
        />
    </>
    );
})

export default LodgedHistoryDetail;
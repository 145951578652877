import React from 'react';
import Signin from '../../components/templetes/signin';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';
import MobileSignIn from '../../components/templetes/mobileSignin';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';

const SigninPage = observer ((props: any) => {
  const {headerStore: headerStore} = useStores();
  if(isMobile) {
    return <MobileSignIn {...props} setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}/> 
  }
  return (
    // <>
    //   <MobileView>
    //     <MobileSignIn {...props} /> 
    //   </MobileView>
      // <BrowserView>
        <Signin {...props} setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}/>
      // </BrowserView>
    // </>
  );
});

export default SigninPage;

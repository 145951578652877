import React, { useEffect, useState } from 'react';
import { FILE_CODE, GUEST_TYPE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../../constants';
import FileSaver from 'file-saver';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import DeductDetail from '../../../components/organismas/popup/deduct';
import { core } from '../../../apis';
 
const GuestDetailForm = observer((props: any) => {
    const [isDeductionPop, setIsDeductionPop] = useState<boolean>(false);
    const guestData: any = props.guestData;
    const {headerStore: headerStore} = useStores();
    const {lodgedHistoryStore: store} = useStores();
    const fileDown  = (url: string, name:string) => {
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}/`+url, name);
    }

    useEffect(() => {
        headerStore.getAuthMenuRole('2-3');
    },[]);

    // 이용자 정보 하단
    const detailFrm = () => {
        if(props.guestData.room_type === ROOM_TYPE_CODE.dormitory_room){ 
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>근무조</th>
                        <td>{guestData.guest_party}</td>
                        <th>차량번호</th>
                        <td>{guestData.guest_car_number1}</td>
                    </tr>
                    <tr>
                        {/* <th>공제내역</th>
                        <td>
                            <button type="button" className="btn btn_xs btn_outline" onClick={(e) => store.isDeductionPop = true}>상세내역</button>
                        </td> */}
                        <th>입숙신청서류</th>
                        <td colSpan={5}>
                            {props.booking.files? (
                                props.booking.files.map((obj: any, index: number) => (
                                    <>
                                        {headerStore.downRoleFlag ? 
                                        <a href="#" onClick={() => fileDown(obj.file.path, obj.document.name)} className="item file">
                                            {obj.document.name}
                                        </a>
                                        : <a className="item file">{obj.document.name}</a>
                                    }
                                    </>
                                ))
                            ) : ''}
                        </td>
                    </tr>
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.company_room){  
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>차량번호1</th>
                        <td>{guestData.guest_car_number1}</td>
                        <th>차량번호2</th>
                        <td>{guestData.guest_car_number2}</td>
                    </tr>
                    <tr>
                        <th>차량번호3</th>
                        <td colSpan={5}>{guestData.guest_car_number3}</td>
                        {/* <th>공제내역</th>
                        <td colSpan={3}>
                        <button type="button" className="btn btn_xs btn_outline" onClick={(e) => store.isDeductionPop = true}>상세내역</button>
                        </td> */}
                    </tr>
                    <tr>
                        <th>입숙신청서류</th>
                        <td colSpan={5}>
                            {props.booking.files? (
                                props.booking.files.map((obj: any, index: number) => (
                                    <>
                                        {headerStore.downRoleFlag ? 
                                            <a href="#" onClick={() => fileDown(obj.file.path, obj.file.name)} className="item file">
                                                {obj.document.name}
                                            </a>
                                            :<a className="item file">{obj.document.name}</a>
                                        }
                                    </>
                                ))
                            ) : ''}
                        </td>
                    </tr>
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.vip_room){    
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>차량번호</th>
                        <td colSpan={3}>{guestData.guest_car_number1}</td>
                    </tr>
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.business_room){
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>식권 수 (장)</th>
                        <td>{guestData.meal_ticket}</td> 
                        <th>차량번호</th>
                        <td>{guestData.guest_car_number1}</td>
                    </tr>
                    {/* {props.guestData.room_type === WORK_SITE_CODE.울산공장 ? 
                    <tr>
                        <th>공제내역</th>
                        <td colSpan={5}>
                            <button type="button" className="btn btn_xs btn_outline" onClick={(e) => store.isDeductionPop = true}>상세내역</button>
                        </td>
                    </tr>
                    : <></>}    */}
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.business_room_group){
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>차량번호</th>
                        <td>{guestData.guest_car_number1}</td>
                        <th>식권 수 (장)</th>
                        <td>{guestData.meal_ticket}</td> 
                    </tr>
                    <tr>
                        <th>인솔자여부</th>
                        <td>
                            <label className="input_radio" htmlFor="user_type1">
                                <input type="radio" id="user_type1" name="user" checked={guestData.is_leader? true: false}/>
                                <span className="label_text">여</span>
                            </label>
                            <label className="input_radio" htmlFor="user_type2">
                                <input type="radio" id="user_type2" name="user" checked={!guestData.is_leader? true: false}/>
                                <span className="label_text">부</span>
                            </label>
                        </td>
                        <th>첨부파일</th>
                        <td colSpan={3}>
                            {props.booking.files? (
                                props.booking.files.map((obj: any, index: number) => (
                                    obj.document.visible_type === FILE_CODE.RFC? (
                                        <>
                                            {headerStore.downRoleFlag ? 
                                            <a href={`${process.env.REACT_APP_MEDIA}/${obj.file.path}`} className="item file">
                                                {obj.file.name}
                                            </a>
                                            : 
                                            <a className="item file">{obj.file.name}</a>
                                            }
                                        </>
                                    ) : ''
                                ))
                            ) : ''}
                        </td>
                    </tr>
                </>
            );
        }
        return '';
    }

    if(props.guestData.room_type === ROOM_TYPE_CODE.family_room) {
        return (
            <div className="table_normal_list">
                <table>
                    <caption className="sr_only">이용자정보</caption>
                    <colgroup>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>성명</th>
                        <th>성별</th>
                        <th>휴대폰번호</th>
                        <th>신청자와의 관계</th>
                        <th>차량번호</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{guestData.guest_name}</td>
                        <td>{guestData.guest_gender === 1 ? '남' : '여'}</td>
                        <td>{guestData.guest_cellphone}</td>
                        <td>{guestData.guest_relation}</td>
                        <td>{guestData.guest_car_number1}</td>
                    </tr>
                    {props.related_guests ? ((
                        props.related_guests.map((list: any, historyIdx: number) => (
                            <tr>
                                <td>{list.guest_name}</td>
                                <td>{list.guest_gender === 1 ? '남' : '여'}</td>
                                <td>{list.guest_cellphone}</td>
                                <td>{list.guest_relation}</td>
                                <td>{list.guest_car_number1}</td>
                            </tr>
                        ))
                    )) : '' }
                    </tbody>
                </table>
            </div>
        );
    }else {
        return(
            <>
            <div className="table_normal_list table_write">
                <table>
                    <caption className="sr_only">이용자정보</caption>
                    <colgroup>
                        <col width="200"/>
                        <col width="*"/>
                        <col width="200"/>
                        <col width="*"/>
                        <col width="200"/>
                        <col width="*"/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>소속 회사 유형</th>
                        <td>{guestData.guest_type !== 4 ? GUEST_TYPE[guestData.guest_type] : '신입(경력)'}</td>
                        <th>회사명(단체명)</th>
                        <td>{guestData.guest_company ? guestData.guest_company : '-'}</td>
                        <th>사번(아이디)</th>
                        <td>{guestData.guest_employee_number}</td>
                    </tr>
                    <tr>
                        <th>성명</th>
                        <td>{guestData.guest_name}</td>
                        <th>휴대폰번호</th>
                        <td>{guestData.guest_cellphone}</td>
                        <th>성별</th>
                        <td>{guestData.guest_gender === 1 ? '남' : '여'}</td>
                    </tr>
                    {detailFrm()}
                    
                    </tbody>
                </table>
            </div> 

            <DeductDetail/>
            {/* 공제내역 팝업 
            <div id="pop_history_deduction" className="pop_dim_wrap pop_modal pop_lg"
                style={{ display: isDeductionPop ? 'block' : 'none' }}
            >
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>공제내역</h3>
                        <button className="pop_btn_close pop_close" onClick={(e) => setIsDeductionPop(false)}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <div className="title_contents bd0">
                            <h3>총 공제내역 : <span className="color_green">54,000원</span></h3>
                            <div className="right_area form_inline">
                                <select name="" id="" className="form_control" style={{'width': 120+'px'}}>
                                    <option value="">2020</option>
                                </select>
                                <select name="" id="" className="form_control" style={{'width': 100+'px'}}>
                                    <option value="">10</option>
                                </select>
                            </div>
                        </div>
                        <div className="table_normal_list table_write mt10">
                            <table>
                                <caption className="sr_only">공제내역</caption>
                                <colgroup>
                                    <col width="130"/>
                                    <col width="*"/>
                                    <col width="130"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>이용료</th>
                                    <td className="ar">31,000원</td>
                                    <th>유틸리티비용</th>
                                    <td className="ar">31,000원</td>
                                </tr>
                                <tr>
                                    <th>키 분실</th>
                                    <td className="ar">31,000원</td>
                                    <th>침구비</th>
                                    <td className="ar">31,000원</td>
                                </tr>
                                <tr>
                                    <th>기타</th>
                                    <td className="ar">31,000원</td>
                                    <th>추가부담금</th>
                                    <td className="ar">31,000원</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>  
                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={(e) => setIsDeductionPop(false)}>확인</button>
                    </div>
                </div>
            </div>
            */}
            </>  
        );
    }
});

export default GuestDetailForm;
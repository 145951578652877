import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from '../../organismas/pagination';
import ConfirmBtn from '../../atoms/button/confirm';
import { getViewPageIdx } from '../../../utils/common';
import { formatByString } from '../../../utils/date';
import { NOTICE_TYPE } from '../../../constants';
import Select from '../../atoms/select';
import DatePicker from '../../organismas/input/datepicker';
import LimitSelect from '../../atoms/select/limit';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import CustomCalendar from '../../organismas/customCalendar';

const NoticeList = observer((props: any) => {
  const dispatch = useDispatch();
  const {noticeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/manage/notice'

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [items, setItems] = useState<any[]>(store.items || []);

  useEffect(() => {
    headerStore.getAuthMenuRole('4-2');
    (async () => {
      headerStore.isLoading = true;
      store.worksite = headerStore.workSite;
      await store.getItems();
      headerStore.isLoading = false;
    })();
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, [headerStore.workSite]);

  const onSearch = () => store.getItems();

  const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

  const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);

  const onEditHandler = () => {
    const selectedItems = Object.keys(store.checkeds);
    if (selectedItems && selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }
    if (selectedItems.length > 1) {
      info('다건의 항목이 선택되어 있습니다.');
      return;
    }
    const item = store.checkeds[selectedItems[0]];
    props.history.replace(`${baseUrl}/${item.id}/update`);
  };

  const onDeletehandler = async () => {
    const selectedItems = Object.keys(store.checkeds);
    // console.log(selectedItems);
    if (selectedItems && selectedItems.length === 0) {
      info('선택된 항목이 없습니다.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data : any = await store.deleteItems();
            headerStore.isLoading = false;
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
                return;
              }else {
                info("문제가 발생했습니다");
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            //errorHandler({ status, data }, '삭제중 오류발생');
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
              info("문제가 발생했습니다");
              return;
            }      
          }
        },
      }
    });
  };

  return (
    <>
      <h4>공지사항 검색</h4>
      <div className="table_modify_list">

        <dl className="">
          <dt>공지유형</dt>
          <dd>
            <Select
              className="form_control"
              options={store.categories}
              value={store.category}
              onChange={(e: any) => (store.category = Number(e.target.value))}
            />
          </dd>
        </dl>
        <dl className="dateonly" style={{ marginRight: '10px' }}>
          <dt>작성일자</dt>
          <dd>
            <div className="datepicker_searchwrap form_inline" style={{marginRight: '10px'}}>
              {/* <DatePicker
                value={store.created_after}
                width={250}
                onChange={(date: string) => (store.created_after = date)}
                isPrevMonthView={true}
              /> */}
              <CustomCalendar
                value={store.created_after}
                onChange={(date:string) => (store.created_after = date)}
              ></CustomCalendar> 
            </div>
            <div className="datepicker_searchwrap form_inline">
              {/* <DatePicker
                value={store.created_before}
                width={250}
                onChange={(date: string) => (store.created_before = date)}
                isPrevMonthView={true}
              /> */}
              <CustomCalendar
                value={store.created_before}
                onChange={(date:string) => (store.created_before = date)}
              ></CustomCalendar> 
            </div>
          </dd>
        </dl>
        <dl className="search">
          <dt>검색어</dt>
          <dd className="form_inline">
            <Select
              className="form_control form_inline"
              options={store.searchTypes}
              value={store.searchType}
              onChange={(e: any) => (store.searchType = Number(e.target.value))}
            />
            <input
              type="text"
              className="form_control form_inline"
              style={{marginRight: '10px'}}
              value={store.search}
              onKeyUp={e => (e.key === 'Enter' ? onSearch() : () => {})}
              onChange={e => (store.search = e.target.value)}
            />
            <button className="btn btn_sm btn_darkblue form_inline" onClick={onSearch}>검색</button>
          </dd>
        </dl>
      </div>

      <h4>공지사항 리스트
        <div className="right_area">
          <LimitSelect
            className="form_control"
            limit={store.limit}
            onChange={(limit: number) => {
              store.setLimit(limit);
              store.setOffset(0);
            }}
          />
        </div>
      </h4>

      <div className="table_normal_list event_on box_scroll_x">
        <table>
          <caption className="sr_only">공지사항 리스트</caption>
          <colgroup>
            {/*<col width="4%"/>*/}
            <col width="5%"/>
            <col width="*"/>
            <col width="8%"/>
            <col width="8%"/>
            <col width="10%"/>
            <col width="10%"/>
            <col width="10%"/>
          </colgroup>
          <thead>
          <tr>
            {/*<th>
              <label className="input_check pop_notext">
                  <input type="checkbox" id="" name="" checked={store.allCheckFlg} onChange={onCheckedByAll} />
                  <span className="label_text"></span>
              </label>
            </th>*/}
            <th>NO</th>
            <th>제목</th>
            <th>이미지</th>
            <th>첨부파일</th>
            <th>공지 유형</th>
            <th>작성자</th>
            <th>작성일</th>
          </tr>
          </thead>
          <tbody>
            {!store.items.length ? (
              <tr>
                <td colSpan={7} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((item, index) => (
                <tr>
               {/* <td>
                  <label className="input_check pop_notext">
                    <input
                      type="checkbox"
                      checked={store.checkeds[item.id] ? true : false}
                      onChange={(e: any) => onCheckedHandler(e, item)}
                    />
                    <span className="label_text"></span>
                  </label>
               </td>*/}
                <td>{getViewPageIdx(store.offset, store.limit, index)}</td>
                <td className="al">
                  <a
                    href="javascript:void(0);"
                    className="btn_link"
                    onClick={() => {
                      store.selectedItem(item);
                      props.history.replace(`${baseUrl}/${item.id}`);
                    }}>
                    {item.title}
                  </a>
                </td>
                <td><a href="javascript:void(0);" className={item.images.length > 0 ? "btn_image" : "btn_image blank"}></a></td>
                <td><a href="javascript:void(0);" className={item.files.length > 0 ? "btn_file" : "btn_file blank" }></a></td>
                <td>{NOTICE_TYPE[item.category]}</td>
                <td>{item.author_name}</td>
                <td>{formatByString(item.created_at, 'yyyy.MM.dd')}</td>
              </tr>
            )))}
          </tbody>
        </table>
      </div>  {/*table*/}

      <div className="board_paging">
        <ul>
          <Pagination
            offset={store.offset}
            limit={store.limit}
            totalPage={store.totalPage}
            setOffset={(offset: number) => store.setOffset(offset)} />
        </ul>
      </div>

      {/*board_paging*/}
      <div className="submit">
        <div className="fl">
          {headerStore.writeRoleFlag?
          <button
          type="button"
          className="btn_lg btn_darkblue"
          onClick={() => {
            store.newItem();
            props.history.replace(`${baseUrl}/create`);
          }}
          style={{ cursor: 'pointer' }}
          >
            등록
          </button>
          :
          <></>
        }
        </div>
        <div className="fr">
          {/* <button type="button" className="btn_md btn_darkblue btn_outline" onClick={onEditHandler}>
            수정
          </button> */}
          {/*<button type="button" className="btn_md btn_darkgray" onClick={onDeletehandler}>삭제</button>*/}
        </div>
      </div>
    </>
  );
});

export default NoticeList;

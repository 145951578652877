import React from 'react';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';
import MobileNotFound from '../mobile/notFound';

const NotFound = (props: any) => {
    return (
        <>
            <BrowserView>
                <div id="wrap" className="error">
                    <div id="header">
                        <div className="group_member">
                            <h1>
                                <a href="javascript:void(0);" className="sr_only">WESN AMS Platform</a>
                            </h1>
                        </div>
                    </div>

                    <div className="error_message">
                        <p className="ico_error">죄송합니다 <span>요청하신 페이지를 찾을 수 없습니다.</span></p>
                        <p className="detail">
                            방문하시려는 페이지의 주소가 잘못 입력되었거나 <br />
                            페이지 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다. <br /><br />
                            감사합니다.
                        </p>
                        <button type="button" className="btn_lg btn_darkblue" onClick={() => props.history.push("/home")}>Home</button>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <MobileNotFound {...props}/>
            </MobileView>
        </>
    );
};

export default NotFound;

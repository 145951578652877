import React, { useState, useEffect, useRef, useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import LocusTotalTemplete from '../locusTotal';
import Pagination from '../../components/organismas/pagination';
import { ROOM_TYPE_CODE, ROOM_TYPE } from '../../constants';
import { guide, user } from '../../apis';
import _, { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { formatByString_YYYYMMDD } from '../../utils/date';
import { parserEscape } from '../../utils/common';
import { core } from '../../apis';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../routes';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';

const Popup = observer((props: any) => {
  const [guides, setGuides] = useState<any>([]);
  const [checkedGuideIdx, setCheckedGuideIdx] = useState<number>(-1);
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    (async () => {
      headerStore.getAuthMenuRole('1-1');
      headerStore.getAuthRoomRole();
      headerStore.getAuthWorkSiteRole();
      headerStore.getAuthBuilding();
      const guides = await fetchGuides();
      setGuides(guides);
    })();
  }, [props.workSite, props.roomType, props.reload]);

  const fetchGuides = async () => {
    
    try {
      headerStore.isLoading = true;
      const { data } = await guide.getGuides({
        work_site: headerStore.workSite,
        room_type: props.roomType,
      });

      headerStore.isLoading = false;
      return data.sort((a: any, b: any) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  };

  const onChangePosition = async (movedIdx: number) => {
    if (!(movedIdx > -1 && movedIdx < guides.length)) return;

    let tempGuides = _.cloneDeep(guides);
    [tempGuides[movedIdx], tempGuides[checkedGuideIdx]] = [tempGuides[checkedGuideIdx], tempGuides[movedIdx]];

    tempGuides = tempGuides.map((g: any, gIdx: number) => ({ ...g, order: props.lastOrder + gIdx + 1 }));

    setGuides(tempGuides);
    setCheckedGuideIdx(movedIdx);
  };

  const onOrderChangeHandler = async () => {
    try {
      headerStore.isLoading = true;
      const { data } = await guide.patchGuideOrder(props.guideId,  guides);
      setCheckedGuideIdx(-1);
      props.setReload(!props.reload);
      props.onClose();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      // console.log(status);
      // console.log(data);
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info('수정중 문제발생');
      return;
    }
  };

  return (
    <div id="" className="pop_dim_wrap pop_modal pop_md" style={{ display: props.isShow ? 'block' : 'none' }}>
      <div className="pop_wrap">
        <button className="pop_btn_close" onClick={props.onClose}>
          닫기
        </button>
        <div className="title_wrap">이용 안내 표시 순서 설정</div>

        <div className="contents_wrap">
          <div className="order_setting">
            <ul>
              {guides.map((guide: any, guideIdx: number) => (
                <li key={guideIdx}>
                  <label className="input_check">
                    <input
                      type="checkbox"
                      id=""
                      name="orders"
                      checked={guideIdx === checkedGuideIdx}
                      onChange={() => setCheckedGuideIdx(guideIdx)}
                    />
                    <span className="label_text">{guide.title}</span>
                  </label>
                  <a style={{'cursor':'pointer'}} className="btn_move"></a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="btn_control clearfix">
          <ul className="fl">
            <li onClick={() => onChangePosition(0)}>
              <a style={{'cursor':'pointer'}}>맨 위로</a>
            </li>
            <li onClick={() => onChangePosition(checkedGuideIdx - 1)}>
              <a style={{'cursor':'pointer'}}>위로</a>
            </li>
            <li onClick={() => onChangePosition(checkedGuideIdx + 1)}>
              <a style={{'cursor':'pointer'}}>아래로</a>
            </li>
            <li onClick={() => onChangePosition(guides.length - 1)}>
              <a style={{'cursor':'pointer'}}>맨 아래로</a>
            </li>
          </ul>
        </div>

        <div className="button_wrap">
          <button type="button" className="btn_lg btn_darkblue" onClick={onOrderChangeHandler}>저장</button>
        </div>
      </div>
    </div>
  );
});

const Write = observer((props: any) => {
  const [title, setTitle] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [contents, setContents] = useState<string>('');
  const [guideFile, setGuideFile] = useState<any>(null);
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  const editorRef = useRef<any>(null);
  const editorInputRef = useRef<any>(null);
  const [itemContents, setItemContents] = useState<string>(contents);

  useEffect(() => {
    headerStore.getAuthMenuRole('1-1');
    headerStore.getAuthRoomRole();
    headerStore.getAuthWorkSiteRole();
    headerStore.getAuthBuilding();
    if (props.historyMode === MODE.ACTIVE) {
      setTitle(props.guide.title);
      setIsVisible(props.guide.is_visible);
      setContents(props.guide.contents);  
      setGuideFile(props.guide.guide_file);
    } else {
      setTitle('');
      setIsVisible(true);
      setContents('');
      setGuideFile(null);
      props.setSelectGuide({});
      props.setIsUpdateMode(false);
    }
    if(props.isUpdateMode === false){
      setTitle('');
    }
  }, [props.guide, headerStore.workSite, props.roomType]);

  const init = () => {
    setTitle('');
    setIsVisible(true);
    setContents('');
    setGuideFile(null);

    props.setSelectGuide({});
    props.setIsUpdateMode(false);
  };

  const isCheck = () => {
    setContents(itemContents);
    return title && itemContents
  };

  const onSaveHandler = async () => {
    setContents(itemContents);

    if (!isCheck()) {
      info('빈 항목이 존재합니다.');
      return;
    }
    
    try {
      headerStore.isLoading = true;
      const { data } = await guide.createGuide(makeFormData());
      info('등록이 완료 되었습니다.');
      props.setReload(!props.reload);
      init();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  };

  const onUpdateHandler = async () => {
    setContents(itemContents);
    
    if (!isCheck()) {
      alert('빈 항목이 존재합니다.');
      return;
    }

    try {
      headerStore.isLoading = true;
      const { data } = await guide.patchGuide(props.guide.id, makeFormData());
      info('수정이 완료 되었습니다.');
      props.setReload(!props.reload);
      init();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("문제가 발생했습니다");
      return;
    }
  };

  const makeFormData = () => {
    setContents(itemContents);
    let form = new FormData();
    form.append('title', title); 
    form.append('contents', parserEscape(itemContents)); 
    form.append("work_site", headerStore.workSite);
    form.append("room_type", props.roomType);
    form.append("order", props.lastOrder + 1);
    form.append("is_visible", isVisible ? "1": "0");
    form.append("employee_number", userInfo.employeeNumber);

    if (guideFile && typeof(guideFile) !== "string") {
      form.append("guide_file", guideFile);
    }

    return form
  }

  const onFileUploadHandler = async (e: any) => {
    setGuideFile(e.target.files[0])
  };

  const onWriteCancelHandler = () => {
    props.setReload(!props.reload);
    init();
  }

  const getGuideFile = () => {
    if (guideFile?.name) {
      return guideFile?.name;
    } else if (guideFile) {
      return guideFile;
    } else {
      return '';
    }
  }
  
  return (
    <div className="table_normal_list table_write">
      <table>
        <caption className="sr_only"></caption>
        <colgroup>
          <col width="130" />
          <col width="*" />
          <col width="130" />
          <col width="130" />
        </colgroup>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <input
                type="text"
                className="form_control"
                value={title}
                onChange={(e: any) => { setTitle(e.target.value)}}
                maxLength={100}
              />
            </td>
            <th>표시여부</th>
            <td>
              <select
                className="form_control"
                value={isVisible ? 1 : 0}
                onChange={(e: any) => setIsVisible(!!parseInt(e.target.value))}
              >
                <option value={1}>표시</option>
                <option value={0}>미표시</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td colSpan={3}>
              <Editor
                  id="tiny_editor_1"
                  apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={parserEscape(contents)}
                  onEditorChange={(e) => {
                    setItemContents(e);
                  }}
                  init={{
                    selector : '#tiny_editor_1',
                    width: 1000,
                    height: 500,
                    resize: 'both',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'image code'
                      //'image media link tinydrive code imagetools'
                    ],
                    toolbar:
                      'undo redo | fontselect fontsizeselect | ' +
                      'bold italic | forecolor backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_file_types : 'gif,jpg,jpeg,png',

                    file_picker_callback : function(callback, value, meta) {
                      if(meta.filetype == 'image') {
                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        editorInputRef.current = input;
                        (editorInputRef.current as any).click();
                        
                        input.onchange = function () {
                          let file : any = input.files;
                          file = file[0];

                          if (file.size >= 10485760) {        
                            alert(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
                            return;
                          }else {
                            let reader = new FileReader();

                            reader.onload = async function (e : any) {
                              let editorImageForm = new FormData();
                              editorImageForm.append('path', file);
                              let reusltData : any = null;
                              try {
                                reusltData = await core.fileUpload<{
                                  id?: number;
                                  path?: string;
                                  name?: string;
                                  category?: string | number;
                                }>(editorImageForm);

                                
                                callback(`${process.env.REACT_APP_MEDIA}/`+reusltData.data.path, {alt : file.name});
                              } catch (error) {
                                // console.log(error);
                                const { status, data } = error;

                                if (status === 401) {
                                  if (!window.localStorage.getItem("isRetryLogin")) {
                                    window.localStorage.setItem("isRetryLogin", "retry");
                                  }
                                  window.location.href = '/';
                                  return ;
                                } else if (status === 403) {
                                  alert('접근권한이 없습니다.');
                                  return ;
                                }else if ( status === 500 ) {
                                  window.location.href = '/500';
                                  return;
                                }

                                info("문제가 발생했습니다");
                                return;
                          
                              }

                            };
                            
                            reader.readAsDataURL(file);
                          }
                          editorInputRef.current = null;
                          input.remove();
                        };
                      }
                    }
                  }}
                />
            </td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td className="wrap_file w100" colSpan={3} style={{display: 'table-cell'}}>
              <input
                id="use-guide"
                className="upload_name form_control"
                value={`${getGuideFile()}`}
                placeholder="사용자가 다운로드 할 파일을 업로드해주세요."
                disabled
              />
              <input
                type="file"
                id="use-guide-download-file"
                className="upload_hidden"
                
                onChange={onFileUploadHandler}
                onClick={(e: any) => (e.target.value = null)}
              />
              <label htmlFor="use-guide-download-file" className="btn btn_sm btn_darkblue">
                파일찾기
              </label>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="submit ac mt15">
        {headerStore.writeRoleFlag ? <>
          {props.historyMode === MODE.ACTIVE &&
            <div className="">
            <button type="button" className="btn btn_md btn_outline" onClick={onWriteCancelHandler}>
              입력취소
            </button>
            {props.isUpdateMode ? 
              (<>{headerStore.writeRoleFlag ? <button type="button" className="btn btn_md btn_darkblue" onClick={onUpdateHandler}>수정</button> : <></>}</>) : 
              (<>{headerStore.writeRoleFlag ?<button type="button" className="btn btn_md btn_darkblue" onClick={onSaveHandler}>등록</button>: <></>} </>)
            }
            </div>
          } </>
        : <></>}
      </div>
    </div>
  );
});

const MODE = {
  ACTIVE: 0,
  HISTORY: 1,
};

const ACTION_STATUS:{[key: string]: string} = {
  update: "업데이트",
  partial_update: "업데이트",
  create: "추가",
  1: "최초생성",
}

const UseGuideManagePage = observer((props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }))
  const {headerStore: headerStore} = useStores();
  const [reload, setReload] = useState<boolean>(false);
  const [historyMode, setHistoryMode] = useState<number>(MODE.ACTIVE);
  const [limit, setLimit] = useState<number>(10);
  const [workSite, setWorkSite] = useState<number>(headerStore.workSite);
  const [roomType, setRoomType] = useState<number>();
  const [offset, setOffset] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [guides, setGuides] = useState<any>([]);
  const [guideId, setGuideId] = useState<number>(-1);
  const [selectGuide, setSelectGuide] = useState<any>({});
  const [isUpdteMode, setIsUpdateMode] = useState<boolean>(false);
  const [isOrderPopShow, setIsOrderPopShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    if(roomType === undefined || !roomType) {
      let room : any = headerStore.paramAuthRoomTypeRole[0]; 
      setRoomType(Number(room));
    }
  }, []);

  useEffect(()=>{
    setSelectGuide({});
    setIsUpdateMode(false);
  },[headerStore.workSite, roomType]);

  useEffect(() => {
    // if(roomType === undefined || !roomType) {
    //   let room : any = headerStore.paramAuthRoomTypeRole[0]; 
    //   setRoomType(Number(room));
    // }
    (async () => {
      if (historyMode === MODE.ACTIVE) {
        // console.log('historyMode>>>', historyMode);
        // console.log('MODE.ACTIVE>>>', MODE.ACTIVE);
        
        
        setGuides(await fetchGuides());  
        
      } else if (historyMode === MODE.HISTORY) {
        const d = await fetchGuidesByHistory();
        setGuides(d);
        //setGuides(await fetchGuides());
      }
    })();
  }, [reload, limit, historyMode,roomType,headerStore.workSite]);
  
  useEffect(() => {    
    headerStore.getAuthRoomRole();
    headerStore.getAuthMenuRole('1-1');
  },[reload]);

  //roomType, workSite

  const fetchGuides = async () => {

    try {
      headerStore.isLoading = true;
      const { data } = await guide.getGuides({
        work_site: headerStore.workSite,
        room_type: roomType? roomType : headerStore.paramAuthRoomTypeRole[0],
        limit : limit,
        offset : offset
      });
      setOffset(0);
      setTotalPage(0);
      setGuideId(data.results.length ? data.results[0].id : -1) ;
      headerStore.isLoading = false;
      return data.results.sort((a: any, b: any) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  };
  
  const fetchGuidesByHistory = async () => {
    try {
      headerStore.isLoading = true;
      const { data } = await guide.getGuidesByHistory({
        work_site: headerStore.workSite,
        room_type: roomType,
        offset,
        limit,
      });
  
      setTotalPage(Math.ceil(data.count / limit));
      setGuideId(-1);
      setSelectGuide({});
      headerStore.isLoading = false;
  
      return data.results.map((item: any) => ({
        ...item.data,
        user: item.user,
        action: item.action,
        created_at: item.created_at
      }));
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다");
      return;
    }
  };

  return (
    <>
      <LocusTotalTemplete id="wrap" className="" {...props} setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}>
        <h4>
          숙소이용안내 등록
          <div className="right_area">
            {/* workSite */} 
            {/*<OptionByWorksite value={headerStore.workSite} onChange={(worksite: number) => headerStore.setWorkSite(headerStore.workSite)}/>*/}
            {/* roomType */}
            <select
                name=""
                id="searchRoomType"
                className="form_control"
                value={roomType}
                onChange={(e: any) => {
                  setRoomType(e.target.value);
                  setWorkSite(headerStore.workSite);
                }}
            >
              {headerStore.roomRoleBusiness_room? 
                <option value={ROOM_TYPE_CODE.business_room}>
                  {ROOM_TYPE[ROOM_TYPE_CODE.business_room]}
                </option> :
                <></>
              }
              {headerStore.roomRoleBusiness_room_group? 
              <option value={ROOM_TYPE_CODE.business_room_group}>
                {ROOM_TYPE[ROOM_TYPE_CODE.business_room_group]}
              </option> : <></>
              }
              {headerStore.roomRoleVip_room? 
              <option value={ROOM_TYPE_CODE.vip_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.vip_room]}
              </option> : <></>
              } 
              {headerStore.roomRoleDormitory_room? 
              <option value={ROOM_TYPE_CODE.dormitory_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}
              </option> : <></>
              }
              {headerStore.roomRoleCompany_room? 
              <option value={ROOM_TYPE_CODE.company_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.company_room]}
              </option> : <></>
              }
              {headerStore.roomRoleFamily_room? 
              <option value={ROOM_TYPE_CODE.family_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.family_room]}
              </option> : <></>
              } 
            </select>
          </div>
        </h4>

        <Write
          guide={selectGuide}
          setSelectGuide={setSelectGuide}
          workSite={headerStore.workSite}
          roomType={roomType}
          isUpdateMode={isUpdteMode}
          setIsUpdateMode={setIsUpdateMode}
          lastOrder={(guides.length && guides[guides.length - 1].order) || 1}
          reload={reload}
          setReload={setReload}
          guideId={guideId}
          historyMode={historyMode}
          setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
        />

        <h4>
          숙소이용안내 등록현황
          <div className="right_area">
            {/* <select
                className="form_control"
                value={historyMode}
                onChange={(e: any) => {setIsUpdateMode(false);setHistoryMode(parseInt(e.target.value))}}
            >
              <option value={MODE.ACTIVE}>활성</option>
              <option value={MODE.HISTORY}>내역</option>
            </select> */}
            {/* limit */}
            <select className="form_control" value={limit} onChange={(e: any) => setLimit(parseInt(e.target.value))}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </h4>

        <div className="table_normal_list event_on box_scroll_x">
          <table>
            <caption className="sr_only"></caption>
            <colgroup>
              <col width="5%" />
              <col width="*" />
              <col width="10%" />
              <col width="20%" />
              <col width="10%" />
              {historyMode === MODE.HISTORY && <col width="10%" />}
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>제목</th>
                <th>표시 여부</th>
                <th>등록일자</th>
                <th>등록자ID</th>
                {historyMode === MODE.HISTORY && <th>구분</th>}
              </tr>
            </thead>
            <tbody>
              {guides.length === 0 ? (
                <tr>
                  <td colSpan={5} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              ):(
                guides.map((guide: any, guideIdx: number) => (
                  <tr
                    key={guideIdx}
                    style={{'cursor':'pointer'}}
                    onClick={() => {
                      setIsUpdateMode(true);
                      setSelectGuide(guide);
                    }}
                  >
                    <td>{(guideIdx + 1)}</td>
                    <td className="al">{guide.title}</td>
                    <td>{guide.is_visible? "표시": "미표시"}</td>
                    <td>{guide.created_at?  formatByString_YYYYMMDD(guide.created_at) : ''}</td>
                    <td>{guide.employee_number}</td>
                    {historyMode === MODE.HISTORY && <td>{ ACTION_STATUS[guide.action]}</td>}
                  </tr>
              )))}
            </tbody>
          </table>
        </div>
        {/*table*/}

        <div className="board_paging">
          {historyMode === MODE.HISTORY && (
            <ul>
              <Pagination offset={offset} limit={limit} setOffset={setOffset} totalPage={totalPage} />
            </ul>
          )}
        </div>
        {/*board_paging*/}
        <div className="submit ac mt15">
        {headerStore.writeRoleFlag ? <button type="button" className="btn btn_md btn_outline" onClick={() => setIsOrderPopShow(true)}>표시 순서 변경</button>:<></>}
        </div>
      </LocusTotalTemplete>

      <Popup
        setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
        guideId={guideId}
        isShow={isOrderPopShow}
        onClose={() => setIsOrderPopShow(false)}
        workSite={workSite}
        roomType={roomType}
        lastOrder={(guides.length && guides[guides.length - 1].order) || 1}
        reload={reload}
        setReload={setReload}
      />
    </>
  );
});

export default UseGuideManagePage;
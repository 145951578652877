import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LodgedTotal from '..';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import SearchForm from '../../../components/molecules/search';
import { formatByString_YYYYMMDD, getTodayDate } from '../../../utils/date';
import { getDate, getDays, getMonth, getMonths, getYear, getYear5 } from '../../../utils/date';
import LimitSelect from '../../../components/atoms/select/limit';
import Pagination from '../../../components/organismas/pagination';
import { getWorkSitePhoneNum, showConfirm } from '../../../utils/common';
import { GENDER, LODGE_MOVE, MOVE_STATUS_CODE, ROOM_TYPE_CODE, LODGE_MOVE_CODE } from '../../../constants';
import MngFileUpload from '../../../components/organismas/form/managementFile';
import ConfirmBtn from '../../../components/atoms/button/confirm';
import MoveBackroomDormitory from '../../../components/organismas/popup/movedormitoryBackroom';
import { isNull } from 'lodash';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';
import { lodge } from '../../../apis';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const LodgedCheckChange = observer((props: any) => {
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const today = getTodayDate();
    const dispatch = useDispatch();
    const { loadgedTotalStore : store } = useStores(); //입숙리스트 가져오기 api
    const {applicantJudgeStore: roomStore} = useStores();
    const {headerStore: headerStore} = useStores();
    const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext); //에러 핸들러 메세지
    const { info, warning, error }: MessageImpl = useContext(MessageContext); //메세지 핸들러
    const [ismoveAppPop, setIsmoveAppPop] = useState<boolean>(false);
    const [isShowReflect, setIsShowReflect] = useState<boolean>(false);
    const [isShowEscalation, setIsShowEscalation] = useState<boolean>(false);//업무이관 팝업 호출
    const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
    const [downloadText, setDownloadText] = useState<string>("");

    //rendering시 리스트 뽑아오기
    useEffect(()=> {
        (async ()=> {
            try{
                headerStore.isLoading = true;
                headerStore.getAuthMenuRole('2-3'); //권한 
                headerStore.getAuthBuilding();
                headerStore.getAuthRoomRole();
                store.work_site = headerStore.workSite;
                store.roomtype = headerStore.paramAuthRoomTypeRole;
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.building = headerStore.paramAuthBuildingRole;
                    }    
                }
                await store.getGuestMoveList();
                headerStore.isLoading = false;
            }catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
        return () => {
            if (store.findTabs('/lodged/type') < 0) {
                store.init();
            }
        }
    },[store.offset, headerStore.workSite])

    const onSearchHandler = async (option: string, text: string) => {
        store.offset = 0;
        store.option = option;
        store.text = text;
        await store.getGuestMoveList();
    }

    const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

    const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);
    
    const popInit = () => {
        store.manageYear = getYear();
        store.manageMonth = getMonth();
        store.manageDay = getDate();
        store.isKeyReturn = true;
        store.keyoutYear = getYear();
        store.keyoutMonth = getMonth();
        store.keyoutDay = getDate();
        store.keyDescription = ''
        store.keySelect = '1';
        store.isEquipment  = true;
        store.equipmentDescription = '';
        store.images = [];
        store.memo = '';
        store.status_description = '';
        roomStore.workSite = '';
        roomStore.selectRow = [];
        roomStore.room = [];
        store.employee_number = '';
        store.name = '';
        store.reason = '';
        store.auth_building = '';
    }

    // 정기 / 상시 이숙처리
    const moveAccess = async()=> {
        showConfirm('이숙처리 하시겠습니까?', dispatch, async () => {
            if(store.manageYear === '' || store.manageMonth === '' || store.manageDay === ''){
                info('필수 입력사항을 체크하여주세요');
                return false;
            }else if(!store.isKeyReturn && store.keySelect === '기타' && (store.keyDescription === '' || store.keyDescription === null)){
                info('열쇠 미 반납 이유를 적어주세요');
                return false;
            }else if(!store.isEquipment && (store.equipmentDescription === '' || store.equipmentDescription === null)){
                info('비품 이상 여부를 적어주세요');
                return false;
            }
            
            try {
                headerStore.isLoading = true;
                //store.roomId = parseInt(Object.keys(roomStore.room)[0]);
                let data : any = await store.moveAccess();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsmoveAppPop(false);
                        info('이숙처리가 완료되었습니다');
                        popInit();
                        store.getGuestMoveList();
                    }else {
                      return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        });
    }

    // 정기/상시 이숙 배방
    const roomMoveApprove = async() => {
        try {
            headerStore.isLoading = true;
            store.roomInfo = roomStore.room["room"].building_name +' '+ roomStore.room["room"].name +'호'
            store.roomId = parseInt(Object.keys(roomStore.room)[0]);
            let data : any = await store.moveApprove();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('승인이 완료되었습니다');
                    popInit();
                    store.getGuestMoveList();
                }else {
                    // info('승인이 실패되었습니다');
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("승인이 실패했습니다");
                return;
        }
    }

    // 1:1 승인
    const moveApprove = async()=> {
        showConfirm('승인 하시겠습니까?', dispatch, async () => {
            try {
                headerStore.isLoading = true;
                let data : any = await store.moveApprove();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        info('승인이 완료되었습니다');
                        store.getGuestMoveList();
                    }else {
                        info('승인이 실패되었습니다');
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })
    }

    // 거절처리
    const moveReject = async() => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.moveReject();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('이숙처리가 거절되었습니다');
                    setIsShowReflect(false);
                    popInit();
                    store.getGuestMoveList();
                }else {
                    info('이숙처리가 실패되었습니다');
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    // 1:1 이숙처리
    const oneToOneMoveAccess = async()=> {
        showConfirm('이숙처리 하시겠습니까?', dispatch, async () => {
            try {
                headerStore.isLoading = true;
                let data : any = await store.oneToOneMoveAccess();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        info('이숙처리가 완료되었습니다');
                        popInit();
                        store.getGuestMoveList();
                    }else {
                        info('이숙처리 중 문제가 발생되었습니다');
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })
    }

    // 이관 처리
    const escalationAccess = async()=> {
        if(store.auth_building === '') {
            info('이관할 사업장을 선택해주세요.');
            return ;
        }
        try {
            headerStore.isLoading = true;
            //이관처리 api 필요
            store.employee_number = userInfo.employeeNumber;
            //store.name = userInfo.username;
            let data : any = await store.escalationAccess();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('이관처리가 완료되었습니다');
                    popInit();
                    setIsShowEscalation(false);
                    store.getGuestMoveList();
                }else {
                    info('이관처리 중 문제가 발생되었습니다');
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    
    }

    const getMoveStatus = (item: any) => {
        let status = parseInt(item.status);
        if(status === MOVE_STATUS_CODE.apply && item.category !== LODGE_MOVE_CODE.one) {    // 정기/상시
            return (
                <>
                    신청완료
                    <>
                        {headerStore.mobileRoleFlag ? 
                            <>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={() => {store.moveAppData = item; setIsShowReflect(true)}}>거절</button>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={()=> {store.moveAppData = item; openRoomPop()}}>승인</button>
                            </>
                        :   <>
                            </>
                        }
                    </>
                </>
            )
        }else if(status === MOVE_STATUS_CODE.resApprove && item.category === LODGE_MOVE_CODE.one) {     // 1:1
            return (
                <>  신청완료
                    <>
                    {headerStore.mobileRoleFlag ?
                        <>                    
                            <button className="btn btn_xs btn_gray btn_outline" onClick={() => {store.moveAppData = item; setIsShowReflect(true)}}>거절</button>
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=> {store.moveAppData = item; moveApprove()}}>승인</button>
                        </>
                        :   <>
                            </>
                        }
                    </>
                </>
            )
        }else if(status === MOVE_STATUS_CODE.approve && item.category !== LODGE_MOVE_CODE.one) {    // 정기, 상시 이숙처리
            return  <>
                        {headerStore.finallyRoleFlag ? 
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=>{store.setmoveAppData(item); setIsmoveAppPop(true)}}>이숙처리</button>
                        : <></>}
                    </>
        }else if(status === MOVE_STATUS_CODE.approve && item.category === LODGE_MOVE_CODE.one) {    // 1:1 이숙처리
            return  <>
                        {headerStore.finallyRoleFlag ? 
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=>{store.setmoveAppData(item); oneToOneMoveAccess();}}>이숙처리</button>
                        : <></>}
                    </>
        }else if(status === MOVE_STATUS_CODE.complete) {
            return "이숙완료"
        }else if(status === MOVE_STATUS_CODE.reject) {
            return( 
                <>
                    <>
                        승인거절
                        {headerStore.mobileRoleFlag ?
                        <>
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=>{store.moveAppData = item; setIsShowReflect(true)}}>거절사유</button>
                        </>
                        : <></>}
                    </>
                </>
            )
        }else if(status === MOVE_STATUS_CODE.cancel) {
            return "신청취소"
        }
    }

    const openRoomPop = () => {
        //roomStore.workSite = store.moveAppData.guest.guest_work_site_id;
        roomStore.workSite = headerStore.workSite;
        roomStore.selectRow.room_type = ROOM_TYPE_CODE.dormitory_room;
        roomStore.selectRow.stay_after = store.moveAppData.moved_at;
        roomStore.selectRow.stay_before = store.moveAppData.guest.expired_at;
        roomStore.selectRow.guest_gender = parseInt(store.moveAppData.guest.guest_gender);
        roomStore.isDormitoryPop = true;
    }

    //업무이관 처리 버튼 생성
    const goEscalation = (item: any) => {
        let status = parseInt(item.status);
        if(status === MOVE_STATUS_CODE.apply && item.category !== LODGE_MOVE_CODE.one) {    // 정기/상시
            return (
                <>
                    신청완료
                    <>
                        {headerStore.mobileRoleFlag ? 
                            <>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={()=> {store.moveAppData = item; setIsShowEscalation(true)}}>업무이관</button>
                            </>
                        :   <>
                            </>
                        }
                    </>
                </>
            )
        }else if(status === MOVE_STATUS_CODE.resApprove && item.category === LODGE_MOVE_CODE.one) {     // 1:1
            return (
                <>  신청완료
                    <>
                    {headerStore.mobileRoleFlag ?
                        <>                    
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=> {store.moveAppData = item; setIsShowEscalation(true)}}>업무이관</button>
                        </>
                        :   <>
                            </>
                        }
                    </>
                </>
            )
        } else {
            return (
                <>{item.guest.memo ? item.guest.memo : ''}</>
            )
        }
    }
    
    // 다운로드 
    const xlsDownload = async()=>{
        try {
            headerStore.isLoading = true;
            const payload: any = {
                employee_number : userInfo.employeeNumber,
                reason : downloadText,
                moved_after : store.checkout_after,
                moved_before : store.checkout_before,
                worksite : store.work_site,
                roomtype : store.roomtype,
            }
        
            if(store.option && store.text) {
                payload[store.option] = store.text;
            }
            if(Number(store.work_site) === 2) {
                if(!isNull(store.building) && store.building !== undefined  && store.building.length > 0) {
                    payload.authBuilding = store.building;
                }
            }

            let { data } = await Core.xlsgeustlist4(payload);
            if(data) {
                FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
            }
            setIsShowDownloadPop(false);
            setDownloadText('');
            headerStore.isLoading = false;
        }catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            // console.log(status, data);

            info("문제가 발생했습니다");
                return;
        }
    }
    //파일업로드
    // const onDownloanHandler = async () => {
    //     console.log(store.checkeds);
    //     if(Object.keys(store.checkeds).length <= 0){
    //         showAlert('선택하신 체크 항목이 없습니다. \n 다운받으실 행을 선택해주세요', dispatch);
    //         return;
    //     }

    //     try {

    //         dispatch({
    //             type: SHOW_CONFIRM_MODAL,
    //             payload: {
    //                 isModalShow: true,
    //                 title: '확인',
    //                 msg: '다운로드 하시겠습니까?',
    //                 action: async () => {
    //                     try {
    //                         await store.downloadCheckOut();
    //                     } catch ({ status, data }) {
    //                         errorHandler({ status, data }, '다운로드 중 오류발생');
    //                         errorAlert("다운로드 중 문제가 발생했습니다.", dispatch);
    //                     }
    //                 },
    //             }
    //         });
    //     } catch (e) {
    //         console.log(e);
    //         errorAlert("다운로드 중 문제가 발생했습니다.", dispatch);
    //     }
    // }

    return (
        <LodgedTotal
            id=""
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
        <h4>이숙 설정</h4>
            <SearchForm
              options={[
                { value: "name", label: "성명" },
                { value: "employee_number", label: "사번" },
                { value: "department", label: "소속 부서" }
              ]}
              onSearch={onSearchHandler}
              dateAfter={store.checkout_after}
              dateBefore={store.checkout_before}
              onChangeStartDateHandler={(date: string) => { store.checkout_after = date }}
              onChangeEndDateHandler={(date: string) => { store.checkout_before = date }}
              
            />
            
            <h4>이숙 리스트
                <div className="right_area">
                    <LimitSelect
                        className="form_control mr10" 
                        limit={store.limit} 
                        onChange={(limit: number) => { store.limit = limit; store.offset = 0; store.getGuestMoveList();}} 
                    />
                    {headerStore.downRoleFlag ? <button className="btn btn_sm btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button> : <></>}
                </div>
            </h4>
            <div className="table_normal_list">
                <table>
                    <caption className="sr_only">퇴숙 리스트</caption>
                    <colgroup>
                        {/*<col width="60px"/>*/}
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        {/* <col width="*"/> */}
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="110px"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        {/*<th>
                            <label className="input_check pop_notext">
                                <input type="checkbox" id="" name="" 
                                    onChange={onCheckedByAll}
                                    checked={store.allCheckFlg}
                                />
                                <span className="label_text"></span>
                            </label>
                        </th>*/}
                        <th>신청일자</th>
                        <th>이숙유형</th>
                        <th>이숙요청일</th>
                        {/* <th>이숙사유</th> */}
                        <th>사번</th>
                        <th>성명</th>
                        <th>성별</th>
                        <th>휴대폰</th>
                        <th>소속부서</th>
                        <th>숙소정보(동호수)</th>
                        <th>입숙일</th>
                        <th>만료일</th>
                        <th>이숙일</th>
                        <th>비고</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/*데이타 없을 시*/}
                    {store.list.length <= 0 ? (
                        <tr>
                            <td colSpan={13} className="blank_area type_white">
                                <p className="txt_blank">데이터가 없습니다.</p>
                            </td>
                        </tr>
                    ) : (
                        store.list.map((item: any, idx: number) => (
                            <tr>
                                {/*<td>
                                    <label className="input_check pop_notext">
                                        <input type="checkbox"
                                            checked={store.checkeds[item.id] ? true : false}
                                            onChange={(e: any) => onCheckedHandler(e, item)}
                                        />
                                     <span className="label_text"></span>
                                    </label>
                                </td>
                                */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.created_at? formatByString_YYYYMMDD(item.created_at) : ''}</td>
                                {/* ↑ 신청일자 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{LODGE_MOVE[item.category]}</td>
                                {/* ↑ 숙소유형  */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.moved_at}</td>
                                {/* ↑ 이숙요청일 */}
                                {/* <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.claim : ''}</td> */}
                                {/* ↑ 이숙사유 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.guest_employee_number : ''}</td>
                                {/* ↑ 사번 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.guest_name: ''}</td>
                                {/* ↑ 성명 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? GENDER[item.guest.guest_gender] :''}</td>
                                {/* ↑ 성별 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.guest_cellphone :''}</td>
                                {/* ↑ 휴대폰 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.guest_department: ''}</td>
                                {/* ↑ 소속부서 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.room_building_name + ' ' + item.guest.room_building_dong +' '+ item.guest.room_name : ''}</td>
                                {/* ↑ 숙소정보  */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.checkin_at :''}</td>
                                {/* ↑ 입숙일 */}
                                <td style={{'cursor':'pointer'}} onClick={() => {store.tempCheckChangeDetailItem = item; props.history.replace('/guest/status/checkchange/detail')}}>{item.guest? item.guest.expired_at: ''}</td>
                                {/* ↑ 만료일 */}
                                <td>
                                    {getMoveStatus(item)}
                                    {/* {item.status? item.managed_at : (
                                        <button className="btn btn_xs btn_gray btn_outline"
                                            onClick={()=>{store.setmoveAppData(item); setIsmoveAppPop(true)}}>이숙처리</button>
                                    )} */}
                                </td>
                                {/* ↑ 이숙상태코드 */}
                                {/*<td style={{'cursor':'pointer'}} onClick={() => props.history.replace('/guest/status/checkchange/detail')}>{item.guest? item.guest.memo : ''}</td>*/}
                                <td>
                                    {/*<td style={{'cursor':'pointer'}} onClick={() => props.history.replace('/guest/status/checkchange/detail')}>{item.guest? item.guest.memo : ''}</td>*/}
                                    {item.guest && item.guest.guest_work_site_id === 2 || item.guest.guest_work_site_id === 6 || item.guest.guest_work_site_id === 7 ? 
                                        goEscalation(item) : (<>{item.guest.guest? item.guest.memo : ''}</>)
                                    }
                                </td>
                                {/* ↑ 비고 */}
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>  {/*table*/}

            <div className="board_paging">
                <ul>
                <Pagination
                    offset={store.offset}
                    limit={store.limit}
                    totalPage={store.totalPage}
                    setOffset={(offset: number) => {store.offset = offset; store.getGuestMoveList();}} />
                </ul>
            </div>   {/*board_paging*/}

            {/*<div className="submit ac">
                {headerStore.downRoleFlag ? <button className="btn btn_lg btn_darkblue btn_outline">다운로드</button> : <></> }
                <button className="btn btn_lg btn_darkblue">업무이관</button>
            </div>
             */}

           
            {/* 이숙처리 POPUP */}
            <div id="pop_change_request" className="pop_dim_wrap pop_modal pop_sm" style={{display: ismoveAppPop? 'block': 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>이숙완료처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() =>{popInit(); setIsmoveAppPop(false)}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <ul className="request_date">
                            <li>
                                <strong>이숙요청일</strong>
                                <span>{store.moveAppData.moved_at}</span>
                            </li>
                            <li>
                                <strong>신청일</strong>
                                <span>{store.moveAppData.created_at? formatByString_YYYYMMDD(store.moveAppData.created_at) : ''}</span>
                            </li>
                        </ul>
                        <div className="process_list">
                            <dl>
                                <dt>열쇠 반납 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="return_check">
                                            <input type="radio" id="return_check" name="return" checked={store.isKeyReturn} onClick={() => store.isKeyReturn = true}/> 
                                            <span className="label_text">반납 완료</span>
                                        </label>
                                        <label className="input_radio" htmlFor="return_check2">
                                            <input type="radio" id="return_check2" name="return" checked={!store.isKeyReturn} onClick={() => store.isKeyReturn = false}/> 
                                            <span className="label_text">미 반납</span>
                                        </label>
                                    </div>

                                    {!store.isKeyReturn? (
                                        <div className="input_box">
                                            <select className="form_control" value={store.keySelect} onChange={(e:any) => store.keySelect = e.target.value}>
                                                <option value="열쇠 분실">열쇠 분실</option>
                                                <option value="기타" >기타</option>
                                            </select>
                                            <input type="text" className="form_control" placeholder="미 반납 이유를 적어주세요."
                                                value={store.keyDescription} onChange={(e:any) => store.keyDescription = e.target.value} disabled={store.keySelect === '기타'? false : true}/>
                                        </div>
                                    ) :  (
                                        <>
                                        {/* <select className="form_control year" value={store.keyoutYear} onChange={(e:any) => store.keyoutYear = e.target.value}>
                                            {getYear5(false).map((year:any, index: number) => (
                                                <option key={index} value={year}>{year}</option>
                                                ))}
                                        </select>
                                        <select className="form_control month" value={store.keyoutMonth} onChange={(e:any) => store.keyoutMonth = e.target.value}>
                                            {getMonths(store.keyoutYear, false).map((year:any, index: number) => (
                                                <option key={index} value={year}>{year}</option>
                                                ))}
                                        </select>
                                        <select className="form_control day" value={store.keyoutDay} onChange={(e:any) => store.keyoutDay = e.target.value}>
                                            {getDays(store.keyoutYear, store.keyoutMonth, true).map((year:any, index: number) => (
                                                <option key={index} value={year}>{year}</option>
                                                ))}
                                        </select> */}
                                        </>
                                    )}
                                </dd>
                                    
                                <dt>비품 이상 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="fixtures_check">
                                            <input type="radio" id="fixtures_check" name="fixtures" checked={store.isEquipment} onClick={() => store.isEquipment = true}/> 
                                            <span className="label_text">이상 없음</span>
                                        </label>
                                        <label className="input_radio" htmlFor="fixtures_check2">
                                            <input type="radio" id="fixtures_check2" name="fixtures" checked={!store.isEquipment} onClick={()=> store.isEquipment= false}/> 
                                            <span className="label_text">이상 있음</span>
                                        </label>
                                    </div>

                                    {!store.isEquipment? (
                                        <input type="text" className="form_control" placeholder="비품 이상 여부를 적어주세요."
                                            value={store.equipmentDescription} onChange={(e:any) => store.equipmentDescription = e.target.value}/>
                                    ) : ''}
                                </dd>
                            </dl>
                        </div>

                        <div className="table_normal_list mt10">
                            <table>
                                <caption className="sr_only">숙소 이미지 첨부</caption>
                                <colgroup>
                                    <col width="*"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="ac">숙소 이미지 첨부</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="al">
                                    <MngFileUpload type="image" fileId="attacheFile" items={store.images} onSetData={(data: any[]) => store.images = [...data]} 
                                    // setIsLoading={(data: boolean)=> store.isLoading = data}
                                    />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>  {/*table*/}

                        {/* <ul className="request_date">
                            <li>
                                <strong>이숙숙소정보</strong>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={() => openRoomPop()}>배방</button>
                                <span>선택한 객실 : {roomStore.room['room'] ? roomStore.room['room'].building_name +' '+ roomStore.room['room'].name : ''}</span>
                                <span>이용자 희망 객실 : {store.moveAppData.desired_room}</span>

                            </li>
                        </ul> */}

                        <div className="process_list mt20">
                            <dl>
                                <dt>최종 이숙일</dt>
                                <dd>
                                    <select className="form_control year" value={store.manageYear} onChange={(e:any) => store.manageYear = e.target.value}>
                                        {getYear5(true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                            ))}
                                    </select>
                                    <select className="form_control month" value={store.manageMonth} onChange={(e:any) => store.manageMonth = e.target.value}>
                                        {getMonths(store.manageYear, true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                            ))}
                                    </select>
                                    <select className="form_control day" value={store.manageDay} onChange={(e:any) => store.manageDay = e.target.value}>
                                        {getDays(store.manageYear, store.manageMonth, false).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                            ))}
                                    </select>
                                </dd>
                                <dt>비고입력</dt>
                                <dd>
                                    <textarea className="form_control" value={store.memo} onChange={(e:any) => store.memo = e.target.value}></textarea>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={moveAccess}>완료</button>
                    </div>
                </div>
            </div>

            {/* 거절 popup */}
            <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowReflect? "block": "none" }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>거절처리</h3>
                    <button className="pop_btn_close pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                    <div className="process_list">
                        <dl>
                            <dt>사유</dt>
                            <dd>
                                {parseInt(store.moveAppData.status) === MOVE_STATUS_CODE.reject ? (
                                    <textarea className="form_control"
                                        value={store.moveAppData.status_description}
                                        disabled
                                    >
                                    </textarea>
                                ) : (
                                    <textarea className="form_control"
                                        value={store.status_description}
                                        onChange={(e: any) => store.status_description = e.target.value}
                                    >
                                    </textarea>
                                )}
                            </dd>
                        </dl>
                    </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {parseInt(store.moveAppData.status) === MOVE_STATUS_CODE.reject ? (
                            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>확인</button>
                        ) : (
                            <ConfirmBtn
                                className="btn btn_lg btn_darkblue pop_close"
                                action={moveReject}
                                title={""}
                                label="확인"
                                msg={"거절하시겠습니까?"}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* 업무이관 popup */}
            <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowEscalation? "block": "none" }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>이관처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() => {setIsShowEscalation(false); popInit();}}>닫기</button>
                       <div className="message_table">
                            <select name="" id="" className="form_control" value={store.auth_building} onChange={(e: any) => store.auth_building = e.target.value}> 
                                <option value=''>선택</option>
                                {headerStore.paramAuthBuildingRole?.split(",").map((key: any, index: number) => {
                                    if(key === '뉴타운') {
                                        return (<option value='본동'>본동</option>)
                                    } else if(key === '본동') {
                                        return (<option value='뉴타운'>뉴타운</option>)
                                     }
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="contents_wrap" style={{ paddingTop : "40px" }}>
                        <div className="process_list">
                            <dl>
                                <dt>사유</dt>
                                <dd>
                                {store.moveAppData.reason? (
                                    <textarea className="form_control"
                                        value={store.moveAppData.reason}
                                        disabled
                                    >
                                    </textarea>
                                ) : (
                                    <textarea className="form_control"
                                        value={store.reason}
                                        onChange={(e: any) => store.reason = e.target.value}
                                    >
                                    </textarea>
                                )}
                                </dd>
                            </dl>
                        </div>
                        <div className="button_wrap btn_length_1">
                            <ConfirmBtn
                                className="btn btn_lg btn_darkblue pop_close"
                                action={escalationAccess}
                                title={""}
                                label="확인"
                                msg={"이관 하시겠습니까?"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            
            {/* 기숙사 배방오픈 */}
            <MoveBackroomDormitory 
                desired_room={store.moveAppData.desired_room}
                moveApprove={roomMoveApprove}
            />

            {/* 다운로드 사유 팝업 */}
            <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
            <div className="pop_wrap">
                <div className="title_wrap">
                <h3>다운로드 사유</h3>
                <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
                </div>
                <div className="contents_wrap">
                <div className="process_list">
                    <dl>
                    <dt>사유</dt>
                    <dd>
                        <textarea className="form_control"
                        value={downloadText}
                        onChange={(e: any) => setDownloadText(e.target.value)}
                        >
                        </textarea>
                    </dd>
                    </dl>
                </div>
                </div>
                <div className="button_wrap btn_length_1">
                <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
                </div>
            </div>
            </div>
        </LodgedTotal>
    )
})

export default LodgedCheckChange;
import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo } from 'react';
import TempleteDefault from '../../components/templetes/default';
import { useStores } from '../../store/mobx';

interface Props {
    id: string;
    className: string;
    children: React.ReactNode;
    isNotLoginRequire?: boolean;
    history: any;
    isLoading?: boolean;
}

const LocusTotal =  observer((props: Props) => {
    const {headerStore: headerStore} = useStores();
    return (
        <TempleteDefault
            {...props}
            isLoading={headerStore.isLoading}
            id="wrap"
            className=""
        >
            <div className="group_tab mt30"> 
                <ul className="tab_lg tabs clearfix">
                    <li>
                        <a 
                            style={{'cursor':'pointer'}} 
                            onClick={(e:any) => props.history.replace("/locus/guest/type")} 
                            className={props.history.location.pathname.includes("/locus/guest/type") ? "on" : ""}
                        >
                            이용자이용가능 범위설정
                        </a>
                    </li>
                    <li>
                        <a 
                            style={{'cursor':'pointer'}} 
                            onClick={(e:any) => props.history.replace("/locus/guest/document")} 
                            className={props.history.location.pathname.includes("/locus/guest/document") ? "on" : ""}
                        >
                            입숙서류 설정
                        </a>
                    </li>
                    <li>
                        <a 
                            style={{'cursor':'pointer'}}
                            onClick={() => props.history.replace("/locus/guest/guide")} 
                            className={props.history.location.pathname.includes("/locus/guest/guide") ? "on" : ""}>
                            이용안내
                        </a>
                    </li>
                    <li>
                        <a 
                            style={{'cursor':'pointer'}} 
                            onClick={() => props.history.replace("/locus/guest/pledge")} 
                            className={props.history.location.pathname.includes("/locus/guest/pledge") ? "on" : ""}>
                            입숙서약서
                        </a>
                    </li>
                </ul>
            </div>
            <div className="tab_contents on">
                {props.children}
            </div> {/* tab_content */}
        </TempleteDefault>
    )
})

export default LocusTotal;
import { observer } from 'mobx-react';
import React from 'react';

import DefaultTemplate from '../../components/templetes/default';
import FaqList from '../../components/templetes/faq';
import { useStores } from '../../store/mobx';

const FaqListPage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <FaqList {...props} />
    </DefaultTemplate>
  );
});

export default FaqListPage;

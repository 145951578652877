import React, { useEffect, useRef, useState } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import DefaultTemplate from '../../../components/templetes/default';
import DeductTab from '../deductTab';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import UlsanWithout from './ulsanWithout';
import UlsanInit from './ulsanInit';
import Ulsan from './ulsan';
import { includes } from 'lodash';


const DeductManagementPage = observer((props: any) => {
  const baseUrl = '/guest/deductible';
  const {ulsanStore: store} = useStores();
  const {headerStore : headerStore} = useStores();

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <DeductTab {...props} />
      <div className="tab_contents on">
      {includes([1], Number(headerStore.workSite))
        ? store.step === 1
          ? <UlsanInit {...props} />
          : <Ulsan {...props} />  //당월공제자관리 리스트 > Ulsan : 울산 , UlsanWithout : 울산 외 나머지 사업장
        : <UlsanWithout {...props} /> } 
      </div>
    </DefaultTemplate>
  );
});
export default DeductManagementPage;

import { observer } from 'mobx-react';
import React from 'react';

import DefaultTemplate from '../../../components/templetes/default';
import NoticeDetail from '../../../components/templetes/notice/detail';
import { useStores } from '../../../store/mobx';

const NoticeUpdatePage = observer((props: any) => {
  const {noticeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <NoticeDetail isEdit={true} {...props} />
    </DefaultTemplate>
  );
});

export default NoticeUpdatePage;

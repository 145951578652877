import React from 'react';
import '../mb_common.scss';

const MobileServerError = (props: any) => {
    return (
        <>
            <div id="wrap" className="wrap_mobile error">
                <div id="" className="pop_modal">
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <h3>오류안내</h3>
                        </div>
                        <div className="contents_wrap">
                            <div className="error_message">
                                <p className="ico_error type2">죄송합니다 <span>기술적인 문제로 서비스 접속이 되지 않습니다.</span></p>
                                <p className="detail">
                                    일시적인 현상으로 잠시 후 다시 이용해 보시면 정상 접속할 수 있습니다 <br />
                                    해당 내용을 담당자가 확인 중에 있습니다. <br /><br />
                                    문제가 지속되는 경우에는 플랫폼 담당자(00-000-0000)로 알려주시면조속히 문제해결이 될 수 있도록 하겠습니다. <br /><br />
                                    이용에 불편을 드려 다시 한번 사과드립니다.
                                </p>
                            </div>
                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={() => props.history.push("/mobile/main")}>Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileServerError;

import React from 'react';
import { FILE_UPLOAD_CODE, FILE_UPLOAD } from '../../../constants';

interface Props {
  name?: string
  id?: string
  className?: string
  onChange: Function
  value: number
  onlyData?: boolean
}


//입숙,퇴숙 등 select
const VisableType = (props: Props) => {
  return (
    <select name={props.name} id={props.id} className={props.className || 'form_control pc'} value={props.value} onChange={(e: any) => props.onChange(parseInt(e.target.value))}>
      {!props.onlyData && (<option value={-1}>전체</option>)}
      <option value={FILE_UPLOAD_CODE.apply}>{FILE_UPLOAD[FILE_UPLOAD_CODE.apply]}</option>
      <option value={FILE_UPLOAD_CODE.assign}>{FILE_UPLOAD[FILE_UPLOAD_CODE.assign]}</option>
      <option value={FILE_UPLOAD_CODE.checkout}>{FILE_UPLOAD[FILE_UPLOAD_CODE.checkout]}</option>
    </select>
  );
};

export default VisableType;

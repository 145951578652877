const kr = {
  "not_requested": "인증을 진행하지 않았습니다.",
  "cannot_find_user": "사번과 핸드폰 번호로 사용자를 찾을 수 없습니다.",
  "cannot_find_guest": "사번 또는 핸드폰 번호로 사용자를 찾을 수 없습니다.",
  "invalid_number": "인증번호가 일치하지 않습니다.",
  "invalid_token": "잘못된 요청입니다.",
  "invalid_input": "요청된 데이터에 문제가 있습니다.",
  "invalid_date": "선택한 날짜에 문제가 있습니다.",
  "not_verified": "인증이 필요합니다.",
  "gender_not_match": "인증이 필요합니다.",
  "cannot_read_sheet": "엑셀파일의 워크시트를 여는도중 문제가 발생했습니다",
  "cannot_open_file": "업로드한 파일을 열 수 없습니다.",
  "wrong_personnel": "인사디비 조회중 문제가 발생했습니다.",
  "wrong_user": "입력한 사용자 정보에 문제가 발생했습니다.",
  "leader_required": "인솔자는 반드시 포함되어야 합니다.",
  "duplicate_guest": "해당 기간동안 이미 예약을 한 사용자가 있습니다.",
  "invalid_status": "해당 작업을 진행할 수 없는 상태입니다.",
  "room_is_not_available": "선택한 방은 이용불가능한 상태입니다.",
  "password_common": "비밀번호가 너무 일상적입니다. 또는 영문자, 특수문자, 숫자가 포함되어 있지 않습니다.",
};

export default kr;

import React, { useState, useEffect } from 'react';

import DayNav from '../dateNavigation/dayNav';
import Header from './header';
import Body from './body';

import { getTodayDate, getRangeDates, getFirstDayByMonth, isMonthSame } from '../../../utils/date';

const tasksParse = (tasks: any) => {
  const result: any = Array.from(Array.from(Array(32).keys()).map(item => []));
  tasks.forEach((task: any) => {
    const dateRanges = getRangeDates(task.startDate, task.endDate);
    const startDayIdx = parseInt(task.startDate.split('-')[2]);
    let taskIdx = 0;
    dateRanges.forEach(d => {
      const day = parseInt(d.split('-')[2]);
      if (startDayIdx < day) {
        for (let i = 0; i < taskIdx; ++i) {
          !result[day][i] && result[day].push({});
        }
      }
      result[day].push(task);
      taskIdx = result[day].length - 1;
    });
  });

  return result;
};

const Calendar = (props: any) => {
  const [focusDate, setFocusDate] = useState<[string, number]>(getTodayDate());
  const [rangeSelect, setRangeSelect] = useState<number[]>([-1, -1]);
  const [tasks, setTasks] = useState(tasksParse(props.tasks || []));
  
  useEffect(() => {
    if(!props.selectDate) return;
    
    if (!isMonthSame(props.selectDate, focusDate[0])) {
      setFocusDate([props.selectDate, getFirstDayByMonth(props.selectDate)])
    }
  }, [props.selectDate])

  useEffect(() => {
    setRangeSelect([-1, -1]);
  }, [focusDate]);

  const onClickByNavPrev = (prevDate: [string, number]) =>
    setFocusDate(prevDate);
  const onClickByNavNext = (nextDate: [string, number]) =>
    setFocusDate(nextDate);

  const onStartDateCell = (day: number) => setRangeSelect([day, -1]);
  const onEndDateCell = (day: number) => {
    let temp: number[] = [...rangeSelect];

    if (temp[0] < day) {
      temp[1] = day;
    } else {
      temp = [day].concat(temp);
    }

    setRangeSelect(temp);

    const rangeDates = [];
    for (let i = 0; i < temp.length; ++i) {
      const splited: string[] = focusDate[0].split('-');
      splited[2] = temp[i] < 10 ? `0${temp[i]}` : temp[i].toString();
      rangeDates.push(splited.join('-'));
    }

    props.onRangeSelectDay(rangeDates);
  };

  const onClickSelectDay = (day: number) => {
    const splited: string[] = focusDate[0].split('-');
    splited[2] = day < 10 ? `0${day}` : day.toString();
    const selectDate = splited.join('-');

    props.onClickSelectDay(selectDate);
  };
  // console.log(props.isPrevMonthView)
  return (
    <div
      style={{
        padding: 10,
        boxSizing: 'border-box',
      }}
    >
      <DayNav
        focusDate={focusDate}
        onClickByNavPrev={onClickByNavPrev}
        onClickByNavNext={onClickByNavNext}
        type={'month'}
        isPrevMonthView={props.isPrevMonthView}
      />

      <Header></Header>

      <Body
        focusDate={focusDate}
        selectDate={props.selectDate}
        tasks={tasks}
        rangeSelect={rangeSelect}
        onClickSelectDay={onClickSelectDay}
        onStartDateCell={onStartDateCell}
        onEndDateCell={onEndDateCell}
        beforeDisablePoint={props.beforeDisablePoint || ''}
        afterDisablePoint={props.afterDisablePoint || ''}
        disableDates={props.disableDates || []}
      />
    </div>
  );
};

export default Calendar;

import { useSelector } from 'react-redux';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import React, { useState, useEffect } from 'react';
import DefaultTemplate from '../../components/templetes/default';
import Select from '../../components/atoms/select';
import LimitSelect from '../../components/atoms/select/limit';
import Pagination from '../../components/organismas/pagination';
import { formatByString, formatByString_YYYYMMDD, formatByString_YYYYMMDDHH, format_YYYYMMDD, getTodayDate } from '../../utils/date';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
const SmsPage = observer((props: any) => {
    const { smsStore : store } = useStores();
    const {headerStore: headerStore} = useStores();

    useEffect(()=> {
         headerStore.getAuthMenuRole('4-3');
         headerStore.getAuthBuilding();
         headerStore.getAuthRoomRole();
        (async () => {
            try {
                headerStore.isLoading = true;
                store.work_site = headerStore.workSite;
                await store.smsList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
            }
        })();
    },[headerStore.workSite]);

    const onSearch = async() => {
        try{
            headerStore.isLoading = true;

            store.work_site = headerStore.workSite;
            store.getItems();

            headerStore.isLoading = false;
        }catch ({ status, data }) {
            headerStore.isLoading = false;

            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
        }
    }

    return(
        <DefaultTemplate
            id="wrap"
            className=""
            isLoading = {headerStore.isLoading}
            {...props}
        >
            <h4>문자발송 검색</h4>
            <div className="table_modify_list">
                <dl className="dateonly">
                    <dt>등록일자</dt>
                    <dd>
                        <div>
                        {/* <DatePicker
                            value={store.created_after}
                            width={250}
                            onChange={(date: string) => (store.created_after = date)}
                            isPrevMonthView={true}
                        /> */}
                        <CustomCalendar
                            value={store.created_after}
                            onChange={(date:string) => (store.created_after = date)}
                        ></CustomCalendar>
                        </div>
                        <span className="dash">~</span>
                        <div>
                        {/* <DatePicker
                            value={store.created_before}
                            width={250}
                            onChange={(date: string) => (store.created_before = date)}
                            isPrevMonthView={true}
                        /> */}
                        <CustomCalendar
                            value={store.created_before}
                            onChange={(date:string) => (store.created_before = date)}
                        ></CustomCalendar>
                        </div>
                    </dd>
                </dl>
                <dl className="">
                    <dt>발송일자</dt>
                    <dd>
                        <div>
                        {/* <DatePicker
                            value={getTodayDate()[0]}
                            width={250}
                            onChange={(date: string) => (store.send_date = date)}
                            isPrevMonthView={true}
                        /> */}
                        <CustomCalendar
                            value={store.send_date}
                            onChange={(date:string) => (store.send_date = date)}
                        ></CustomCalendar>
                        </div>
                    </dd>
                </dl>
                <dl className="">
                    <dt>발송상태</dt>
                    <dd>
                    <Select
                        className="form_control form_inline"
                        options={store.searchTypes2}
                        value={store.searchType2}
                        onChange={(e: any) => (store.searchType2 = Number(e.target.value))}
                    />
                    </dd>
                </dl>
                <dl className="search">
                    <dt>검색어</dt>
                    <dd>
                    <Select
                        className="form_control form_inline"
                        options={store.searchTypes3}
                        value={store.searchType3}
                        onChange={(e: any) => (store.searchType3 = Number(e.target.value))}
                    />
                    <input
                        type="text"
                        className="form_control form_inline"
                        style={{marginRight: '10px'}}
                        value={store.search}
                        onKeyUp={e => (e.key === 'Enter' ? onSearch() : () => {})}
                        onChange={e => (store.search = e.target.value)}
                    />
                        <button className="btn btn_sm btn_darkblue form_inline" onClick={onSearch}>검색</button>
                    </dd>
                </dl>
            </div>

            <h4>문자발송 리스트
                <div className="right_area">
                <LimitSelect
                    className="form_control"
                    limit={store.limit}
                    onChange={(limit: number) => {store.limit = limit; store.offset = 0; store.work_site = headerStore.workSite; store.smsList();}}
                />
                </div>
            </h4>
            <div className="table_normal_list">
                <table>
                    <caption className="sr_only">문자발송 리스트</caption>
                    <colgroup>
                        <col width="5%"/>
                        <col width="*"/>
                        <col width="12%"/>
                        <col width="8%"/>
                        {/* <col width="8%"/> */}
                        <col width="12%"/>
                        <col width="8%"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>NO</th>
                        <th>메시지 내용</th>
                        <th>발송일</th>
                        <th>발송상태</th>
                        {/* <th>발송결과</th> */}
                        <th>등록일자</th>
                        <th>등록자ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {store.items.length <= 0? (
                        <tr>
                            <td colSpan={6} className="blank_area type_white">
                                <p className="txt_blank">데이터가 없습니다.</p>
                            </td>
                        </tr>
                        ) : (
                        store.items.map((item: any, index : number) => (
                            <tr>
                                <td>{index+1}</td>
                                {item.send_status === '발송예정'? (
                                    <td className="al" onClick={(index)=> {store.smsSendDetail=item; props.history.replace(`/manage/sms/detail`)}}>
                                        <a href="javascript:void(0);" className="btn_link">{item.message}</a>
                                    </td>
                                ) : (
                                    <td className="al"><a className="btn_link">{item.message}</a></td>
                                )}
                                <td>{item.send_date ? formatByString_YYYYMMDD(item.send_date) : ''}</td>
                                <td>{item.send_status}</td>
                                {/* <td>{item.return_status}</td> */}
                                <td>{item.created_at? formatByString_YYYYMMDD(item.created_at) : ''}</td>
                                <td>{item.employee_number}</td>
                            </tr>
                    )))}
                    </tbody>
                </table>
            </div>  {/*table*/}

            <div className="board_paging">
                <ul>
                    <Pagination
                    offset={store.offset}
                    limit={store.limit}
                    totalPage={store.totalPage}
                    setOffset={(offset: number) => {store.offset = offset; store.work_site = headerStore.workSite; store.smsList();}} />
                </ul>
            </div>   {/*board_paging*/}

            <div className="submit">
                <div className="fl">
                {headerStore.writeRoleFlag? 
                    <button className="btn btn_lg btn_darkblue" onClick={() => props.history.replace(`/manage/sms/push`)}>등록</button>:<></>}
                </div>
                <div className="fr">
                    {/*<button className="btn btn_lg btn_outline">수정</button>*/}
                    {/* <button className="btn btn_lg btn_gray">삭제</button> */}
                </div>
            </div>
    </DefaultTemplate>
    );
});

export default SmsPage;
import React from 'react';

interface Porps {
  value: string
  src: string
  
  onlySrc?: boolean
  outer?: boolean // 선만 표시할지 말지
  small?: boolean
  large?: boolean
  pc?: boolean
}

//파일 다운로드 버튼
const Button = (props: Porps) => {
  let classNames = ['btn', 'btn_file', 'ml10']
  let src = [process.env.REACT_APP_MEDIA]
  
  props.outer && classNames.push('btn_outline');

  if (props.onlySrc) {
    src[0] = props.src
  } else {
    src.push(props.src)
  }

  if (props.small) {
    classNames.push('btn_sm');
  }

  if (props.pc) {
    classNames.push('pc');
  }

  return (
    <a href={src.join('/')} download>
      <button className={classNames.join(' ')}>
        {props.value}
        {/* <i className="ico_download ml10"></i> */}
      </button>
    </a>
  );
};

export default Button;

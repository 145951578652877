import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Editor } from '@tinymce/tinymce-react';

import { FAQ_CATEGORY } from '../../../../constants';
import FileUpload from '../../../molecules/fileUpload';
import ImageUpload from '../../../molecules/fileUpload'; 

import { formatByString } from '../../../../utils/date';
import ConfirmBtn from '../../../atoms/button/confirm';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import { useStores } from '../../../../store/mobx';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../../routes';
import { observer } from 'mobx-react';
import { parserEscape } from '../../../../utils/common';
import { core } from '../../../../apis';


const WriteContent = observer((props: any) => {
  const dispatch = useDispatch();
  const {faqStore} = useStores();
  const {headerStore : headerStore} = useStores();
  const baseUrl = '/manage/faq';

  const _state: any | null = faqStore.item;
  const id = _state?.id || -1;
  const author_name = _state?.author_name || '';
  const author_employee_number = _state?.author_employee_number || '';
  const isCreate = props.isEdit && !props.match.params.id;

  const [created_at, setCreated_at] = useState<string>(_state?.created_at || new Date());
  const [category, setCategory] = useState<number>(_state?.category || -1);
  const [title, setTitle] = useState<string>(_state?.title || '');
  const [contents, setContents] = useState<string>(_state?.contents || '');
  const [images, setImages] = useState<any[]>(_state?.images || []);
  const [files, setFiles] = useState<any[]>(_state?.files || []);
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const editorRef = useRef<any>(null);
  const editorInputRef = useRef<any>(null);

  useEffect(() => {
    headerStore.getAuthWorkSiteRole();
    faqStore.work_site = headerStore.workSite;
  }, [headerStore.workSite]);
  const goBack = () => props.history.replace(isCreate ? baseUrl : `${baseUrl}/${id}`);

  const onSaveHandler = async () => {
    if (!faqStore.isChange()) {
      info('변경된 항목이 없습니다.');
      return;
    }

    if (category < 0) {
      info('항목을 선택해주세요.');
      return;
    }
    try {
      headerStore.isLoading = true;
      let msg = '등록 완료했습니다.';
      if (id > -1) {
        msg = '수정 완료했습니다.';
      }
      //console.log("faq>>>>>>>>>>>>>>>> 등록 " ,msg+":::::" + headerStore.workSite);
      faqStore.work_site = headerStore.workSite;
      //console.log("faq>>>>>>>>>>>>>>>> 등록 " ,msg+":::::" + faqStore.work_site);

      let data : any = await faqStore.saveItem();
      headerStore.isLoading = false;
      setTimeout(function () {
        if(data.save) {
          faqStore.selectedItem({});
          info(msg);
          goBack();
          return;
        }else {
          info("문제가 발생했습니다");
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      // errorHandler({ status, data }, '저장 중 오류발생');
    }
  };

  const onCancelhandler = () => {
    if (!isCreate) {
      setCategory(_state?.category || -1);
      setTitle(_state?.title || '');
      setContents(_state?.contents || '');
      setImages(_state?.images || []);
      setFiles(_state?.files || []);
      faqStore.cancelItem();
    }
    goBack();
  };

  const onBackHandler = () => {
    if (!faqStore.isChange()) {
      goBack();
    } else {
      dispatch({
        type: SHOW_CONFIRM_MODAL,
        payload: {
          isModalShow: true,
          title: '확인',
          msg: '수정한 내용이 삭제됩니다.\n 목록으로 이동하시겠습니까?',
          action: goBack
        },
      });
    }
  };

  const onChangeValue = (key: string, value: any) => {
    const _temp = { ...faqStore.item };
    _temp[key] = value;
    faqStore.item = _temp;
  };

  return (
    <>
      <h4>FAQ { isCreate ? '등록' : '수정' }</h4>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">FAQ 상세</caption>
          <colgroup>
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>작성자</th>
              <td>{author_name}({author_employee_number})</td>
              <th>작성일시</th>
              <td>{formatByString(created_at, 'yyyy.MM.dd HH:mm')}</td>
            </tr>
            <tr>
              <th>항목선택</th>
              <td colSpan={3}>
                <div className="search_form">
                  <select
                    className="form_control"
                    value={category}
                    onChange={(e: any) => {
                      const val = Number(e.target.value);
                      onChangeValue('category', val);
                      setCategory(val);
                    }}
                  >
                    <option value="">선택</option>
                    {Object.keys(FAQ_CATEGORY).map((key: string, index: number) => (
                      <option key={index} value={key}>
                        {FAQ_CATEGORY[Number(key)]}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th><span className="emp_red">질문</span></th>
              <td colSpan={3}>
                <input
                  type="text"
                  className="form_control"
                  value={title}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    onChangeValue('title', val);
                    setTitle(val);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th><span className="emp_red">답변</span></th>
              <td colSpan={3}>
                {/* <Editor
                  value={parserEscape(contents)}
                  onChange={(text: string) => {
                    onChangeValue('contents', text);
                    setContents(text)
                  }} /> */}
                <Editor
                  apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={parserEscape(contents)}
                  onEditorChange={(e) => {
                    onChangeValue('contents', e);
                  }}
                  init={{
                    width: 1100,
                    height: 500,
                    resize: 'both',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                      'image code'
                      //'image media link tinydrive code imagetools'
                    ],
                    toolbar:
                      'undo redo | fontselect fontsizeselect | ' +
                      'bold italic | forecolor backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    images_file_types : 'gif,jpg,jpeg,png',
                    file_picker_callback : function(callback, value, meta) {
                      if(meta.filetype == 'image') {
                        let input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        editorInputRef.current = input;
                        (editorInputRef.current as any).click();
                        
                        input.onchange = function () {
                          let file : any = input.files;
                          file = file[0];

                          if (file.size >= 10485760) {        
                            alert(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
                            return;
                          }else {
                            let reader = new FileReader();

                            reader.onload = async function (e : any) {
                              let editorImageForm = new FormData();
                              editorImageForm.append('path', file);
                              let reusltData : any = null;
                              try {
                                headerStore.isLoading = true;
                                reusltData = await core.fileUpload<{
                                  id?: number;
                                  path?: string;
                                  name?: string;
                                  category?: string | number;
                                }>(editorImageForm);
                                headerStore.isLoading = false;
                                
                                callback(`${process.env.REACT_APP_MEDIA}/`+reusltData.data.path, {alt : file.name});
                              } catch (error) {
                                // console.log(error);

                                headerStore.isLoading = false;
                                const { status, data } = error;

                                if (status === 401) {
                                  if (!window.localStorage.getItem("isRetryLogin")) {
                                    window.localStorage.setItem("isRetryLogin", "retry");
                                  }
                                  window.location.href = '/';
                                  return ;
                                } else if (status === 403) {
                                  alert('접근권한이 없습니다.');
                                  return ;
                                }else if ( status === 500 ) {
                                  window.location.href = '/500';
                                  return;
                                }
                          
                              }

                            };
                            
                            reader.readAsDataURL(file);

                          }
                          editorInputRef.current = null;
                          input.remove();
                        };
                      }
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>이미지</th>
              <td colSpan={3}>
                <ImageUpload
                  type="image"
                  fileId="imageFile"
                  items={images}
                  onSetData={(data: any[]) => {
                    onChangeValue('images', [...data]);
                    setImages([...data]);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>첨부파일</th>
              <td colSpan={3}>
                <FileUpload
                  type="file"
                  fileId="attacheFile"
                  items={files}
                  onSetData={(data: any[]) => {
                    onChangeValue('files', [...data]);
                    setFiles([...data]);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="submit ar">
        <div className="fl">
          <button type="button" className="btn_lg btn_darkgray" onClick={() => props.history.replace(baseUrl)}>
            목록
          </button>
        </div>
        <div className="fr">
          <button type="button" className="btn_lg btn_darkblue btn_outline" onClick={onSaveHandler}>
            완료
          </button>
          <button type="button" className="btn_lg btn_darkblue" onClick={onCancelhandler}>취소</button>
          {/* <ConfirmBtn
            className="btn_md btn_darkblue"
            action={onCancelhandler}
            label="취소"
            title=""
            msg="현재 항목을 취소하시겠습니까?"
          /> */}
        </div>
      </div>
    </>
  );
});

export default WriteContent;

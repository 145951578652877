import _ from 'lodash';
import {action, makeObservable, observable, ObservableMap} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { getTodayDate } from '../../utils/date';
import { BoardStore } from './boardStore';
import { addMonths, differenceInCalendarDays, format } from 'date-fns';
import { HeaderStore } from './headerStore';
import { SHOW_ALERT_MODAL } from '../modules/modal';

export class DeductUlsanDormitoryStore extends BoardStore {
  static ROOM_FEE = 10000;
  static FOOD_FEE = 5182;
  year = '';
  month = '';
  department = '';
  employee_number = '';
  is_finalize = '';
  date_type = '';

  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      year: observable,
      month: observable,
      department: observable,
      employee_number: observable,
      is_finalize : observable,
      date_type : observable,

      initDatas: action,
      getItems: action,
      getUlsanSingleSelect: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      setMonth: action,
      setYear: action,
    });

    this.initDatas();
  }

  initDatas () {
    const date = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');
    this.year = date[0];
    this.month = date[1];
  }

  init () {
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.item = null;
    this.orgItem = null;
    //this.date_type = '';
  }

  destory () {
    super.destory();
  }

  async setMonth(month: string) {
    this.month = month;
    await this.getItems();
  }

  async setYear(year: string) {
    this.year = year;
    await this.getItems();
  }

  async setFinalize(is_finalize: string) {    
    this.is_finalize = is_finalize;
    this.getItems();
  }

  //검색 기준 
  async setDatetype (dateType: string) {    
    this.date_type = dateType;
    this.getItems();
  }

  async getItem(id: string) {}

  @debounce(500)
  async getItems() {
    const filter: any = {
      worksite: '울산',
      search_date : `${this.year}${this.month}`,
      department: this.department,
      employee_number: this.employee_number,
    };

    this.item = null;
    this.items = [];
    await this.getUlsanSingleSelect();
  
  }

  async getUlsanSingleSelect() {
    
    this.item = null;
    this.items = [];
    
    const filter: any = {
      worksite: '울산',
      search_date : `${this.year}${this.month}`,
      department: this.department,
      employee_number: this.employee_number,
    };

    if (this.department) {
      filter.department = this.department;
    }

    if (this.employee_number) {
      filter.employee_number = this.employee_number;
    }
    
    if (this.is_finalize !== '') {
      filter.is_finalize = this.is_finalize;
    }

    if(this.date_type != '') {
      filter.date_type = this.date_type;
    }

    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.gets('ulsansingleselect', filter);
      if (data.length > 0) {
        this.items = data.map((item: any) => {
          const result: any = {
            deduct_date: `${this.year}${this.month}`,
            employee_number: item.guest_employee_number,
            name: item.guest_name,
            department: item.guest_department,
            rank: item.guest_rank,
            stay_before: item.checkin_managed_at, 
            stay_after: item.checkout_managed_at,
            is_key_return: item.is_key_return ? 'Y' : 'N',
            checkout_id: item.checkout_id,
            status: item.status,
          }
          const day_count = differenceInCalendarDays(
            new Date(item.checkout_managed_at),
            new Date(item.checkin_managed_at),
          )
          if(item.is_finalize === 'Y') {
            result.is_finalize = 'Y';
          } else {
            result.is_finalize = 'N';
          }
          let meal_ticket = 0;
          let unreturn_charge = item.is_key_return ? 0 : 15000;
          
          meal_ticket = Number(item.checkin_meal_ticket) - Number(item.return_meal_ticket);

          const stay_before_after = `${item.checkin_managed_at} ~ ${item.checkout_managed_at}`;
          const room_charge = DeductUlsanDormitoryStore.ROOM_FEE * day_count;
          const meal_charge = DeductUlsanDormitoryStore.FOOD_FEE * meal_ticket;
          const total_deduct = room_charge + meal_charge;
            
          result.meal_ticket = meal_ticket;
          result.stay_before_after = stay_before_after;
          result.room_charge = room_charge;
          result.meal_charge = meal_charge;
          result.unreturn_charge = unreturn_charge;
          result.total_deduct = total_deduct;
          result.meal_ticket = meal_ticket;
          result.day_count = day_count;
          return result;
        });
        this.headerStore.isLoading = false;
      } else {
        this.items = [];
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
  }

  newItem() {
    this.item = {
      id: -1,
      created_at: new Date(),
    };
    this.orgItem = { ...this.item };
  }

  selectedItem(item: any) {
    if (item) {
      this.item = item;
      this.orgItem = { ...item };
    } else {
      this.item = null;
      this.orgItem = null;
    }
  }

  get isChange() {
    return this.item && this.orgItem
      ? JSON.stringify(this.item) !== JSON.stringify(this.orgItem)
      : false
  }

  async save(items: any[]) {
    // const date = getTodayDate()[0].split('-');
    
    const data: any = {
      deduct_date: `${this.year}${this.month}`,
      worksite: '울산',
      employee_number: this.user.employeeNumber,
      ulsanvolume: items.map(item => ({
          employee_number: item.employee_number,
          name: item.name,
          department: item.department,
          rank: item.rank,
          stay_before: item.stay_before,
          stay_after: item.stay_after,
          meal_ticket: item.meal_ticket,
          is_key_return: item.is_key_return,
          room_charge: item.room_charge,
          meal_charge: item.meal_charge,
          unreturn_charge: item.unreturn_charge,
          total_deduct: item.total_deduct,    
          is_finalize: 'Y',
          checkout_id: item.checkout_id,
        })),
    };
    try {
      await apis.deduct.post('ulsansingle', data);
      await this.getItems();
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}
